import { Button, Grid, withStyles, Typography, Paper } from '@material-ui/core'
import React, { Component } from 'react'
import { Query, Mutation, Subscription } from 'react-apollo'
import uuid from 'uuid'
import { ADMIN_SHOP_DISCOUNTS } from '../../gql/admin-queries'
import { ADMIN_CREATE_EXPORT_REPORT_REQUEST } from '../../gql/admin-mutations'
import { ADMIN_REPORT_REQUEST_SUBSCRIPTION } from '../../gql/admin-subscriptions'
import { MEDIA_CDN_URL } from '../../../../../brikl-config'
import FieldsSelection from './FieldsSelection'
import { REPORT_FIELDS } from '../content/config'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
const styles = theme => ({
  root: {
    overflow: 'hidden',
    padding: 24,
    margin: '0 auto'
  },
  table: {
    width: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    marginLeft: 20,
    fontSize: 30,
    padding: 25,
    fontWeight: 'medium',
    color: '#424242'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
})

class ReportCoupons extends Component {
  state = {
    reportRequestStatus: null,
    reportRequestId: null,
    testSubscription: false,
    selectedFields: []
  }

  handleFieldSelection(selectedFields: string[]) {
    this.setState({ selectedFields })
  }

  handleExportBtn(
    createExportReportRequest,
    fileType: 'CSV' | 'XLS',
    discountId: string | null
  ) {
    const requestId = uuid.v4()
    this.setState({
      reportRequestId: requestId,
      reportRequestStatus: 'LOADING'
    })
    try {
      createExportReportRequest({
        variables: {
          input: {
            fields: this.state.selectedFields,
            fileType,
            filter: {
              discountId
            },
            id: requestId,
            reportType: 'REPORT_COUPONS'
          }
        }
      })
    } catch (error) {
      this.setState({
        reportRequestStatus: null
      })
    }
  }

  render() {
    const { classes, defaultLanguage, shopId } = this.props
    return (
      <Grid className={classes.root}>
        <Grid
          container={true}
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ marginBottom: '20px' }}
        >
          <Grid item={true} xs={12}>
            <Typography
              style={{
                fontSize: 28,
                fontWeight: 'medium',
                color: '#424242',
                marginBottom: '20px'
              }}
            >
              Report Coupons
            </Typography>
          </Grid>
          <Mutation
            update={(store, { data: { createExportReportRequest } }) => {
              console.log(
                'createExportReportRequest',
                createExportReportRequest
              )
              if (createExportReportRequest.id) {
                // start subscribing here
                // this.setState({
                //   reportRequestId: createExportReportRequest.id
                // });
              }
            }}
            mutation={ADMIN_CREATE_EXPORT_REPORT_REQUEST}
          >
            {createExportReportRequest => (
              <Grid item={true} xs={12}>
                <Paper style={{ padding: 24 }}>
                  <Query
                    query={ADMIN_SHOP_DISCOUNTS}
                    variables={{ id: shopId }}
                  >
                    {({ loading, error, data }) => {
                      let shopDiscounts = []
                      try {
                        data.shop.discounts.edges.forEach(s => {
                          shopDiscounts.push(
                            <option value={s.node.id}>
                              {getTextFromLanguage(
                                s.node.title.text,
                                defaultLanguage
                              )}
                            </option>
                          )
                        })
                      } catch (error) {}
                      return (
                        <select
                          onChange={e => {
                            this.setState({
                              discountId: e.target.value ? e.target.value : null
                            })
                          }}
                          value={
                            this.state.discountId
                              ? this.state.discountId
                              : 'MYBRIKL'
                          }
                        >
                          <option value={null}></option>
                          {shopDiscounts}
                        </select>
                      )
                    }}
                  </Query>
                  <FieldsSelection
                    availableFields={REPORT_FIELDS.REPORT_COUPONS}
                    selectedFields={this.state.selectedFields}
                    onSelect={sel => this.handleFieldSelection(sel)}
                  />
                  <Grid container={true} spacing={1} style={{ marginTop: 16 }}>
                    <Grid item={true}>
                      <Button
                        color="primary"
                        variant="outlined"
                        style={{
                          marginLeft: 'auto'
                        }}
                        onClick={() =>
                          this.handleExportBtn(
                            createExportReportRequest,
                            'CSV',
                            this.state.discountId
                          )
                        }
                        disabled={this.state.reportRequestStatus === 'LOADING'}
                      >
                        {this.state.reportRequestStatus === 'LOADING'
                          ? 'Export in progress...'
                          : 'Export to CSV'}
                      </Button>
                    </Grid>
                    <Grid item={true}>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() =>
                          this.handleExportBtn(
                            createExportReportRequest,
                            'XLS',
                            this.state.discountId
                          )
                        }
                        disabled={this.state.reportRequestStatus === 'LOADING'}
                      >
                        {this.state.reportRequestStatus === 'LOADING'
                          ? 'Export in progress...'
                          : 'Export to XLS'}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Mutation>
          {this.state.reportRequestId ? (
            <Subscription
              onSubscriptionData={({ subscriptionData }) => {
                try {
                  if (
                    subscriptionData.data.events[0] &&
                    subscriptionData.data.events[0].id ===
                      this.state.reportRequestId &&
                    subscriptionData.data.events[0].status === 'SUCCESS'
                  ) {
                    const link = document.createElement('a')
                    link.href =
                      MEDIA_CDN_URL +
                      '/' +
                      subscriptionData.data.events[0].event.filePath
                    const fileType = link.href.split('.').pop()
                    link.download = 'brikl-coupons-export.' + fileType
                    console.log('link', link)
                    link.click()
                    this.setState({
                      reportRequestStatus: null
                    })
                  }
                } catch (e) {
                  console.error(e)
                  alert('Your export has failed: Unknown error')
                  this.setState({
                    reportRequestStatus: null
                  })
                }
              }}
              subscription={ADMIN_REPORT_REQUEST_SUBSCRIPTION}
              variables={{
                id: this.state.reportRequestId,
                shopId: shopId
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(ReportCoupons)
