import React, { Component } from 'react'
import { Typography, Grid, Paper } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import GlobalStyles from '../../../globalStyles'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Query, Mutation } from 'react-apollo'
import { ADMIN_GET_DEPLOYMENTS } from '../../gql/admin-queries'
import { ADMIN_TRIGGER_DEPLOYMENT } from '../../gql/admin-mutations'
import moment from 'moment'

const styles = theme => ({
  container: {
    padding: '8px 0px'
  }
})

class DeploymentPage extends Component {
  state = {
    deployNowInProgress: false
  }
  render() {
    const { classes, shopId } = this.props
    return (
      <Query query={ADMIN_GET_DEPLOYMENTS} variables={{ id: shopId }}>
        {({ loading, error, data }) => {
          if (error) return `Error! ${error.message}`
          var deployments = [
            <TableRow>
              <TableCell className={classes.cell} component="th" scope="row">
                {loading ? 'Loading' : 'No deployments'}
              </TableCell>
            </TableRow>
          ]
          try {
            deployments = data.shop.deployments.edges.map(dpNode => {
              return (
                <TableRow
                  key={dpNode.node.id}
                  style={{ textDecoration: 'none' }}
                >
                  <TableCell
                    className={classes.cell}
                    component="th"
                    scope="row"
                  >
                    {dpNode.node.id}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {dpNode.node.status}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {dpNode.node.finishedAt
                      ? moment
                          .unix(dpNode.node.finishedAt / 1000)
                          .format('MM/DD/YYYY HH:mm:ss Z')
                      : null}
                  </TableCell>
                </TableRow>
              )
            })
          } catch (error) {}

          return (
            <Mutation
              update={(store, { data: { triggerDeployment } }) => {}}
              mutation={ADMIN_TRIGGER_DEPLOYMENT}
            >
              {(triggerDeployment, { data, loading, error }) => {
                return (
                  <Grid
                    container
                    style={{ maxWidth: '1000px', margin: 'auto', padding: 24 }}
                  >
                    <GlobalStyles />
                    <Grid item xs={6} style={{ marginTop: 24 }}>
                      <Typography variant="h1">Deployment</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: 24 }}>
                      <Button
                        style={{ float: 'right' }}
                        variant="outlined"
                        color="primary"
                        disabled={this.state.deployNowInProgress}
                        onClick={async () => {
                          this.setState({
                            deployNowInProgress: true
                          })
                          try {
                            const deploymentTrigger = await triggerDeployment({
                              variables: {
                                id: shopId
                              }
                            })
                            this.setState({
                              deployNowInProgress: false
                            })
                          } catch (error) {}
                          this.setState({
                            deployNowInProgress: false
                          })
                        }}
                      >
                        {this.state.deployNowInProgress === true
                          ? 'Loading'
                          : 'Deploy now'}
                      </Button>
                    </Grid>
                    {/* <Grid item xs={12} style={{ marginTop: 24 }}>
                      <Typography variant="body1">
                        List of previous deployments
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 24 }}>
                      <Paper elevation={0}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.cell}>Id</TableCell>
                              <TableCell className={classes.cell}>
                                Status
                              </TableCell>
                              <TableCell className={classes.cell}>
                                Finished at
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>{deployments}</TableBody>
                        </Table>
                      </Paper>
                    </Grid> */}
                  </Grid>
                )
              }}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

DeploymentPage.propTypes = {
  classes: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default withStyles(styles)(DeploymentPage)
