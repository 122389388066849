import React, { Component } from 'react';
import Template from '../content/Template';
import PagesListContainer from '../content/PagesListContainer';
import PageMetaData from './PageMetaData';
class Pages extends Component {
  render() {
    const { client, shopId, shop } = this.props;
    if (!shop) return null;
    return (
      <div>
        {this.props.pageId ? (
          this.props.location.pathname.indexOf('content') !== -1 ? (
            <Template
              shopUrl={shop.url}
              defaultLanguage={shop.defaultLanguage}
              languages={shop.languages}
              shopId={shopId}
              pageId={this.props.pageId}
            />
          ) : (
            <PageMetaData
              shopUrl={shop.url}
              defaultLanguage={shop.defaultLanguage}
              languages={shop.languages}
              shopId={shopId}
              pageId={this.props.pageId}
            />
          )
        ) : (
          <PagesListContainer
            defaultLanguage={shop.defaultLanguage}
            languages={shop.languages}
            shopId={shopId}
            client={client}
          />
        )}
      </div>
    );
  }
}

export default Pages;
