export const SHOP_ID = __SHOP_ID__;
export const SHOP_LANGUAGES = __SHOP_LANGUAGES__;
export const SHOP_DEFAULT_LANGUAGE = __SHOP_DEFAULT_LANGUAGE__;
export const SHOP_LANGUAGE_WITHOUT_PREFIX = __SHOP_LANGUAGE_WITHOUT_PREFIX__;
export const SHOP_DEFAULT_CURRENCY = __SHOP_DEFAULT_CURRENCY__;
export const SHOP_CURRENCIES = __SHOP_CURRENCIES__;
export const SHOP_COUNTRIES = __SHOP_COUNTRIES__;
export const SHOP_DEFAULT_COUNTRY = __SHOP_DEFAULT_COUNTRY__;
export const API_URL = __API_URL__;
export const PUBLIC_CDN_URL = __PUBLIC_CDN_URL__;
export const CONTENT_AS_A_SERVICE_API = __CONTENT_AS_A_SERVICE_API__;
export const SHOP_LOGO = __SHOP_LOGO__;
export const TEAMSTORE_LOGO = __TEAMSTORE_LOGO__;
export const COGNITO_REGION = __COGNITO_REGION__;
export const COGNITO_IDENTITY_POOL_ID = __COGNITO_IDENTITY_POOL_ID__;
export const COGNITO_USER_POOL_ID = __COGNITO_USER_POOL_ID__;
export const COGNITO_WEBCLIENT_ID = __COGNITO_WEBCLIENT_ID__;
export const ADMIN_COGNITO_IDENTITY_POOL_ID = __ADMIN_COGNITO_IDENTITY_POOL_ID__;
export const ADMIN_COGNITO_USER_POOL_ID = __ADMIN_COGNITO_USER_POOL_ID__;
export const ADMIN_COGNITO_WEBCLIENT_ID = __ADMIN_COGNITO_WEBCLIENT_ID__;
export const SHOP_INTEGRATION_SETTINGS = __SHOP_INTEGRATION_SETTINGS__;
export const DEFAULT_LANGUAGE = SHOP_DEFAULT_LANGUAGE;
export const DEFAULT_CURRENCY = SHOP_DEFAULT_CURRENCY;
export const DEFAULT_COUNTRY = SHOP_DEFAULT_COUNTRY;
export const MENUS = __MENUS__;
export const DEFAULT_LENGTH_UNIT = 'cm';
export const DEFAULT_WEIGHT_UNIT = 'kg';
// export const API_URL =
//   document.location.hostname == 'app.brikl.io'
//     ? 'https://api.brikl.io'
//     : document.location.hostname == 'staging.app.brikl.io'
//       ? 'https://staging.api.brikl.io'
//       : document.location.hostname == 'staging.app.ap-south-1.brikl.io'
//         ? 'https://staging.api.ap-south-1.brikl.io'
//         : 'http://localhost:3000';

// export const PUBLIC_ENGINE_URL =
//   document.location.hostname == 'app.brikl.io'
//     ? // TODO change to prod engine
//       'https://api.brikl.io/data/'
//     : document.location.hostname == 'staging.app.brikl.io'
//       ? 'https://staging.engine.brikl.io/graphql'
//       : 'http://localhost:9696/graphql';

// export const UPLOAD_API_URL =
//   document.location.hostname === 'app.brikl.io'
//     ? 'https://upload.brikl.io/uploads3'
//     : document.location.hostname === 'staging.app.brikl.io'
//       ? 'https://staging.upload.brikl.io/uploads3'
//       : 'https://staging.upload.brikl.io/uploads3';

// export const LIB_URL =
//   document.location.hostname === 'app.brikl.io'
//     ? 'https://lib.brikl.io'
//     : document.location.hostname === 'staging.app.brikl.io'
//       ? 'https://staging.lib.brikl.io'
//       : 'https://staging.lib.brikl.io';

// export const CDN_DISCOVERY_URL =
//   document.location.hostname === 'app.brikl.io'
//     ? 'https://s3-ap-southeast-1.amazonaws.com/cdn.discovery.brikl.io'
//     : document.location.hostname === 'staging.app.brikl.io'
//       ? 'https://s3-ap-southeast-1.amazonaws.com/staging.cdn.discovery.brikl.io'
//       : 'https://s3-ap-southeast-1.amazonaws.com/staging.cdn.discovery.brikl.io';

// export const WS_URL =
//   document.location.hostname === 'app.brikl.io'
//     ? 'wss://gql-subscription.brikl.io/subscriptions'
//     : document.location.hostname === 'staging.app.brikl.io'
//       ? `wss://staging.gql-subscription.brikl.io./subscriptions`
//       : // `wss://gql-subscription.brikl.io/subscriptions`
//         // `wss://staging.gql-subscription.brikl.io./subscriptions`
//         'ws://localhost:7171/subscriptions';

// if (!API_URL) {
//   console.error(
//     'Set `API_URL` in `client/src/app/js/actions/index.js` to your stage endpoint'
//   );
// }
