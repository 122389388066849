var DesignRoot = null;
var canvas_other = null;
var imageExtract = null;

if (typeof window !== `undefined`) {
  canvas_other = document.createElement('canvas');
  imageExtract = new Image();
}

export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot;
}

export function prepareVarColorZone(TEMPLATE_SET_DATA) {
  try {
    // statements

    const colorData = TEMPLATE_SET_DATA.colorData;

    for (var i = 0; i < colorData.length; i++) {
      DesignRoot.titleZoneColor.push(colorData[i].title);
      DesignRoot.defaultZoneColor.push(colorData[i].color.toLowerCase());
      DesignRoot.defaultZoneColorMap[
        colorData[i].color.toLowerCase()
      ] = hexToRgb(colorData[i].color.toLowerCase(), 'array');
      DesignRoot.currentChangeColor.push(colorData[i].color.toLowerCase());
      DesignRoot.collectColorPosition[i] = [];
      DesignRoot.defaultZoneColorObject[colorData[i].color.toLowerCase()] =
        colorData[i];
    }

    DesignRoot.collectOtherColorPosition = [];
  } catch (e) {
    // statements
    console.error('canvas_coloring.prepareVarColorZone.error', e);
  }
}

export function initCanvasColor(templateSRC, logoSRC) {
  try {
    // statements

    console.log('initCanvasColor', templateSRC);

    DesignRoot.imageLoad.onload = function() {
      // DesignRoot.imageLoad.src =
      //   templateSRC + '&datetime=' + new Date().getTime();
      // DesignRoot.imageLoad.crossOrigin = 'anonymous';
      // DesignRoot.logoLoad.onload = function () {

      //     DesignRoot._LOGO_OVERLAY = DesignRoot.logoLoad;
      //     var canvasReturn = recolorImage('init');
      //     DesignRoot.Loading.activeLoading(false);
      // };
      // DesignRoot.logoLoad.src = logoSRC;

      recolorImage('init');
      DesignRoot.Loading.activeLoading(false);
    };
    DesignRoot.imageLoad.crossOrigin = 'anonymous';
    DesignRoot.imageLoad.src = DesignRoot.tools._wrapTimeToSrc(templateSRC);
  } catch (e) {
    // statements
    console.error('canvas_coloring.initCanvasColor.error', e);
  }
}

export function updateTexture(option) {
  try {
    // statements

    if ( DesignRoot._MAP_OVERLAY !== null ) {

      DesignRoot.fabric_function.addTemplate(
        'template',
        DesignRoot._MAP_OVERLAY.toDataURL('image/png'),
        0,
        0
      );
    }
  } catch (e) {
    // statements
    console.error('canvas_coloring.updateTexture.error', e);
  }
}

export function recolorImage(option, indexColor) {
  try {
    var canvas_main = null;
    if (typeof window !== `undefined`) {
      canvas_main = document.createElement('canvas');
    }
    var ctx = canvas_main.getContext('2d');
    var w = DesignRoot.imageLoad.width;
    var h = DesignRoot.imageLoad.height;

    canvas_main.width = w;
    canvas_main.height = h;

    // draw the image on the temporary canvas
    ctx.drawImage(DesignRoot.imageLoad, 0, 0, w, h);

    // pull the entire image into an array of pixel data
    var imageData = ctx.getImageData(0, 0, w, h);

    console.time();
    console.log('getImageData');

    if (option === 'init') {
      for (var i = 0; i < imageData.data.length; i += 4) {
        var checkNoZone = true;

        DesignRoot.defaultZoneColor.forEach(function(element, index) {
          var currentRGB = DesignRoot.defaultZoneColorMap[element];

          if (
            imageData.data[i] === currentRGB[0] &&
            imageData.data[i + 1] === currentRGB[1] &&
            imageData.data[i + 2] === currentRGB[2] &&
            imageData.data[i + 3] !== 0
          ) {
            DesignRoot.collectColorPosition[index].push(i);
            checkNoZone = false;
          }
        });

        if (checkNoZone === true && imageData.data[i + 3] !== 0) {
          DesignRoot.collectOtherColorPosition.push(i);
        }
      }

      // console.log('DesignRoot.collectColorPosition', DesignRoot.collectColorPosition);
      // console.log(JSON.stringify(DesignRoot.collectColorPosition));
    }

    if (option !== 'init' || DesignRoot.onLoad === true) {
      DesignRoot.collectColorPosition.forEach(function(element, index) {
        var defaultColor = DesignRoot.defaultZoneColor[index];
        var currentColor = DesignRoot.currentChangeColor[index];

        if (option === 'hover' && indexColor === index) {
          if (DesignRoot.bucketStatus === true) {
            var currentRGB = hexToRgb(DesignRoot.bucketColor, 'array');
          } else {
            var currentRGB = hexToRgb(DesignRoot.hoverColor, 'array');
          }
        } else {
          var currentRGB = hexToRgb(currentColor, 'array');
        }

        // console.log('recolorImage', defaultColor, currentColor);

        if (defaultColor !== currentColor || option === 'hover') {
          for (var i = 0; i < element.length; i++) {
            var colorIndex = element[i];
            imageData.data[colorIndex] = currentRGB[0];
            imageData.data[colorIndex + 1] = currentRGB[1];
            imageData.data[colorIndex + 2] = currentRGB[2];
            imageData.data[colorIndex + 3] = 255;
          }
        }
      });

      var currentColor = DesignRoot.currentChangeColor[0];
      var currentRGB = hexToRgb(currentColor, 'array');

      DesignRoot.collectOtherColorPosition.forEach(function(element, index) {
        var colorIndex = element;
        imageData.data[colorIndex] = currentRGB[0];
        imageData.data[colorIndex + 1] = currentRGB[1];
        imageData.data[colorIndex + 2] = currentRGB[2];
        imageData.data[colorIndex + 3] = 255;
      });
    }

    // put the altered data back on the canvas
    ctx.putImageData(imageData, 0, 0);
    console.log('putImageData');
    console.timeEnd();
    // put the re-colored image back on the image

    // if ( loadLogo === true ) {

    // 	ctx.drawImage(logoLoad, 0, 0, w, h);
    // }

    //   logoSET.push({
    // 	logo: logoAdded,
    // 	X: X,
    // 	Y: Y,
    // });

    // console.log('logoSET', logoSET);
    //   for (var i = 0; i < logoSET.length; i++) {

    //   	var newW = (logoSET[i].logo.width / 2);
    //   	var newH = (logoSET[i].logo.height / 2);
    //   	var newX = logoSET[i].X - (newW / 2);
    //   	var newY = logoSET[i].Y - (newH / 2);
    //   	ctx.drawImage(logoSET[i].logo, newX, newY, newW, newH);
    //   }

    // return c.toDataURL('image/png');
    DesignRoot._MAP_OVERLAY = canvas_main;
    updateTexture();

    if (option === 'init') {
      DesignRoot.Loading.activeLoading(false);
      DesignRoot.tools.renderComponent('DesignRoot');
      DesignRoot.props.MainTemplate.setState({ loading: false });
    }

    if (DesignRoot.onLoad === true) {
      DesignRoot.onLoad = false;
    }

    return canvas_main;
  } catch (error) {
    console.error('canvas_coloring.recolorImage.error', error);
  }
}

export function hexToRgb(hex, returnType) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (returnType === 'array') {
    return result
      ? [
          parseInt(result[1], 16),
          parseInt(result[2], 16),
          parseInt(result[3], 16)
        ]
      : null;
  } else {
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null;
  }
}

export function rgbToHex(r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

export function extractColor(base64) {
  try {
    // statements

    if (base64) {
      // DesignRoot.extractColor
      console.log('extractColor', base64);

      imageExtract.onload = function() {
        // pixelReader(imageExtract);
        DesignRoot.extractImage = base64;
        DesignRoot.setState({ render: true });
      }; // END LOAD IMAGE

      imageExtract.src = base64;
    } // END IF
  } catch (e) {
    // statements
    console.error('canvas_coloring.extractColor.error', e);
  }
}

export function pixelReader(img) {
  try {
    // statements

    var ctx = canvas_other.getContext('2d');
    var w = img.width;
    var h = img.height;

    canvas_other.width = w;
    canvas_other.height = h;

    // draw the image on the temporary canvas
    ctx.drawImage(img, 0, 0, w, h);

    // pull the entire image into an array of pixel data
    var imageData = ctx.getImageData(0, 0, w, h);
    var collectColor = {};

    for (var i = 0; i < imageData.data.length; i += 4) {
      var hex = rgbToHex(
        imageData.data[i],
        imageData.data[i + 1],
        imageData.data[i + 2]
      );
      collectColor[hex] = hex;
    }

    console.log('collectColor', collectColor);
  } catch (e) {
    // statements
    console.error('canvas_coloring.pixelReader.error', e);
  }
}

export function getPixelXY(event) {
  event.persist();
  console.log('getPixelXY', event);
}
