import { getColorHex } from './save_load';

export const transformDataForLoad = (
  productId: string,
  latestRevision: any,
  designTemplates: any,
  elements: any
): any => {
  const designTemplate =
    designTemplates.find(
      o => o.id === latestRevision.selectedDesignTemplateId
    ) || designTemplates[0];
  return {
    SVG: null,
    allItem: setupAllItems(
      latestRevision.textDesigns,
      latestRevision.logoDesigns
    ),
    config2D: null,
    directElm: setupAllElements(latestRevision.designElements, elements),
    id: productId,
    logoActive: null,
    mapColor: designTemplate
      ? setupColors(latestRevision.zoneColors, designTemplate.colors)
      : null,
    revisionId: latestRevision.id,
    seamActive: null,
    templateId: latestRevision.selectedDesignTemplateId,
    printDesigns: latestRevision.printDesigns
  };
};

export const setupColors = (
  zoneColors: any[],
  designTemplateColors: any[]
): any => {
  const mapColor = {};
  if (!zoneColors || !designTemplateColors) {
    return mapColor;
  }
  designTemplateColors.forEach(color => {
    let mapColorHex = color.customColorHex;
    const zoneColor = zoneColors.find(o => o.zoneId === color.id);
    if (zoneColor) {
      mapColorHex = zoneColor.colorHex ? zoneColor.colorHex : zoneColor.color.hex;
    }
    mapColor[color.customColorHex] = mapColorHex;
  });
  return mapColor;
};

const setupAllItems = (textDesigns: any[], logoDesigns: any[]): any[] => {
  const results: any[] = [];

  if (textDesigns) {
    textDesigns.forEach(o => {
      results.push({
        canvasData: o.textDesign.designAttributes,
        objectType: 'TEXT',
        userData: {
          boundingGroup: o.textDesign.boundingGroup,
          boundingId: o.textDesign.boundingId,
          content: o.textDesign.content,
          defaultItem: o.textDesign.defaultItem,
          fontStyle: o.textDesign.fontFamily,
          id: o.id,
          lineSpacing: o.textDesign.lineSpacing,
          orderSeq: o.textDesign.orderSeq,
          outlineColor: o.textDesign.outlineHex,
          outlineSize: o.textDesign.outlineSize,
          textColor: o.textDesign.colorHex,
          visible: o.isVisible
        }
      });
    });
  }

  if (logoDesigns) {
    logoDesigns.forEach(o => {
      results.push({
        canvasData: o.logoDesign.designAttributes,
        objectType: 'LOGO',
        userData: {
          boundingGroup: o.logoDesign.boundingGroup,
          boundingId: o.logoDesign.boundingId,
          defaultItem: o.logoDesign.defaultItem,
          filename: o.logoDesign.name,
          id: o.id,
          orderSeq: o.logoDesign.orderSeq,
          s3Url: o.logoDesign.src,
          visible: o.isVisible
        }
      });
    });
  }
  return results;
};

const setupAllElements = (designElements: any[], elements: any[]): any => {
  const result = {};
  if (designElements && designElements.length > 0) {
    designElements.forEach(item => {
      if (item.colorHex || item.countryFlag || item.productDesignPrintId) {
        result[item.baseElement.name] = {
          color: item.colorId ? getColorHex(item.baseElement.name, item.colorId, elements) : item.colorHex,
          countryFlag: item.countryFlag,
          id: item.id,
          productDesignPrintId: item.productDesignPrintId
        };
        if (item.productDesignPrintId) {
          result[item.baseElement.name].productDesignPrintSrc = item.productDesignPrint.src;
          result[item.baseElement.name].designAttributes = item.designAttributes;
        }
      }
    });
  }
  return result;
};
