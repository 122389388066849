import _ from 'lodash';
import { logSentry } from '../../../../helper/errorHandling';
import {
  EditorState,
  convertFromRaw,
  ContentState,
  convertToRaw
} from 'draft-js';
import { IMGIX_URL } from '../../../../../brikl-config';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import { v4 as uuid } from 'uuid';
import { CONFIG } from './config';
const shortid = require('shortid');
const axios = require('axios');

export function slugWithUrlPrefix(language: string, slug: string): string {
  try {
    if (SHOP_LANGUAGE_WITHOUT_PREFIX === language) {
      return slug;
    } else {
      return language.substring(0, 2) + '/' + slug;
    }
  } catch (error) {
    return language.substring(0, 2) + '/' + slug;
  }
}

export function convertTimestamp(timestamp: any): any {
  var d = new Date(timestamp * 1), // Convert the passed timestamp to milliseconds
    yyyy = d.getFullYear(),
    mm = ('0' + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
    dd = ('0' + d.getDate()).slice(-2), // Add leading 0.
    hh = d.getHours(),
    h = hh,
    min = ('0' + d.getMinutes()).slice(-2), // Add leading 0.
    ampm = 'AM',
    time;

  if (hh > 12) {
    h = hh - 12;
    ampm = 'PM';
  } else if (hh === 12) {
    h = 12;
    ampm = 'PM';
  } else if (hh == 0) {
    h = 12;
  }

  // ie: 2014-03-24, 3:00 PM
  time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;
  return time;
}

export function remapAlignmentForLayout(
  alignment: string
): 'flex-end' | 'flex-start' | 'center' | 'space-between' {
  switch (alignment) {
    case 'right':
    case 'flex_end':
    case 'flex-end':
      return 'flex-end';
    case 'left':
    case 'flex_start':
    case 'flex-start':
      return 'flex-start';
    case 'space_between':
    case 'space-between':
      return 'space-between';
    default:
      return 'center';
  }
}

export function saveDataOnLeave(
  localUnsaved: any,
  selectedColRow: any
): boolean {
  if (!localUnsaved) {
    return false;
  }

  let editing = false;

  for (const key in selectedColRow) {
    if (localUnsaved.hasOwnProperty(key) && key !== 'text') {
      if (selectedColRow[key] !== localUnsaved[key]) {
        editing = true;
      }
    }
    if (
      localUnsaved.hasOwnProperty(key) &&
      localUnsaved[key] &&
      localUnsaved[key].hasOwnProperty('text')
    ) {
      localUnsaved[key].text.forEach(textField => {
        const selectedColRowTextContent = selectedColRow[key].text.find(
          o => o.langCode === textField.langCode
        ).content;
        if (textField.content !== selectedColRowTextContent) {
          editing = true;
        }
      });
    }
  }
  return editing;
}

export function normalizeTextData(text: any, typoVariant: string): any[] {
  return {
    ...text,
    text: text.text.map(data => {
      if (
        isJSON(data.content) &&
        CONFIG.showRichTextForVariants.indexOf(typoVariant) === -1
      ) {
        data.content = convertDraftRawToPlainText(data.content);
      }

      if (
        !isJSON(data.content) &&
        CONFIG.showRichTextForVariants.indexOf(typoVariant) !== -1
      ) {
        data.content = convertPlainTextToDraft(data.content);
      }

      return data;
    })
  };
}

export function getBase64(file: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

// TODO add returning type and signedurl from gql schema
export function uploadContentMedia(
  files: { name: string; size: number }[],
  uploadFile: (arg0: {
    variables: {
      type: string;
      fileName: string;
      prefixVariables: Array<string>;
      input: any;
    };
  }) => void
): Promise<string> {
  return new Promise(async (resolve, reject) => {
    try {
      for (var i = 0; i < files.length; i++) {
        const myFile = files[i];
        if (files[i].size < 20000000) {
          const fileName =
            files[i].name.replace(/\.[^/.]+$/, '').replace(/\s/g, '-') +
            '-' +
            shortid.generate() +
            '.' +
            files[i].name.split('.').pop();
          const signedUrlResult = await uploadFile({
            variables: {
              type: 'CONTENT_MEDIA',
              fileName,
              prefixVariables: [],
              input: {}
            }
          });
          const signedUrl = signedUrlResult.data.uploadFile.url;
          const options = {
            headers: {
              'Content-Type': signedUrlResult.data.uploadFile.contentType
            }
          };
          if (signedUrlResult.data.uploadFile.acl) {
            options.headers['x-amz-acl'] = signedUrlResult.data.uploadFile.acl;
          }
          if (signedUrlResult.data.uploadFile.tagging) {
            options.headers['x-amz-tagging'] =
              signedUrlResult.data.uploadFile.tagging;
          }
          const uploadResult = await axios.put(signedUrl, myFile, options);
          resolve(fileName);
        } else {
          alert('Only files up to 20MB supported.');
          reject();
        }
      }
    } catch (error) {
      console.error('uploaderror', error);
      logSentry(error, {
        functionReference: 'uploadContentMedia'
      });
      reject();
    }
  });
}

export function remapAlignmentForData(alignment: string): string {
  switch (alignment) {
    case 'flex-end':
      return 'right';
    case 'flex-start':
      return 'left';
    default:
      return alignment;
  }
}

export function cleanTextInputField(value: any): any {
  if (!value) {
    return null;
  }

  const obj = value;
  delete obj.__typename;
  for (const item of obj.text) {
    delete item.__typename;
  }
  return obj;
}

export function generateSubIds(obj: any, elements: string[]): any {
  for (const item of elements) {
    if (obj.hasOwnProperty(item)) {
      obj[item].id = uuid();
    }
  }
  return obj;
}

export function getImageUrl(image: string, shopId: string): string {
  if (!image || image.indexOf('undefined') !== -1) {
    return '';
  }

  let splitImage = '';
  let fileName = '';

  if (image.indexOf('https://') !== -1) {
    splitImage = image.split('/');
    fileName = splitImage.pop();
  } else {
    fileName = image;
  }

  const path = `${IMGIX_URL}/${shopId}/media/`;

  return path + fileName;
}

export function useTheme(input: any, theme: any, type: string): any {
  const dataToReplace = {
    BUTTON: [
      'fontSize',
      'fontFamily',
      'backgroundColor',
      'fontWeight',
      'textTransform'
    ],
    TEXT: [
      'fontFamily',
      'fontSize',
      'fontWeight',
      'letterSpacing',
      'textTransform'
    ]
  };

  if (!dataToReplace[type]) {
    return input;
  }

  dataToReplace[type].forEach(data => {
    input.columnRowDetail[data] = theme[data];
  });
  return input;
}

export function setTranslatedTextsInObject(
  obj: any,
  fields: string[],
  currentLanguage: string
): any {
  try {
    for (const field of fields) {
      if (obj[field] && obj[field].text) {
        const newFieldName = 'new' + _.capitalize(field);
        obj[newFieldName] =
          getTextFromLanguage(obj[field].text, currentLanguage) || '';
      }
    }
    return obj;
  } catch (error) {
    return obj;
  }
}

export function addPercent(
  value: number | string,
  defaultValue?: number | string
): string | number | null {
  return addPxOrPercent('%', value, defaultValue);
}

export function addPx(
  value: number | string,
  defaultValue?: number | string
): string | number | null {
  return addPxOrPercent('px', value, defaultValue);
}

function addPxOrPercent(
  what: 'px' | '%',
  value: number | string,
  defaultValue?: number | string
): string | number | null {
  if (!value || value === '0') {
    if (!defaultValue) {
      return null;
    } else {
      return defaultValue;
    }
  }
  if (typeof value === 'string' && value.indexOf(what) !== -1) {
    return value;
  } else {
    return `${value}${what}`;
  }
}

export function isJSON(value: string): boolean {
  try {
    const obj = JSON.parse(value);
    return typeof obj !== 'number';
  } catch (e) {
    return false;
  }
}

export function manageText(
  fields: any,
  fieldName: string,
  language: string,
  value: string
): any {
  const t = fields[fieldName];
  t.text = fields[fieldName].text.map((text: any) => {
    return {
      ...text,
      content: language === text.langCode ? value : text.content
    };
  });
  if (!fields[fieldName].text.find((e: any) => e.langCode === language)) {
    t.text.push({
      content: value,
      langCode: language
    });
  }
  return t;
}

export function normalizeTextTitle(title: string): any {
  const normalizeText = s => s.replace(new RegExp(/[ .]/g), '-');
  return normalizeText(title);
}

export function convertDraftRawToPlainText(raw: string): string {
  try {
    const editorState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(raw))
    );
    return editorState.getCurrentContent().getPlainText();
  } catch (e) {
    return '';
  }
}

export function convertPlainTextToDraft(text: string): string {
  const content = ContentState.createFromText(text);
  const editorState = EditorState.createWithContent(content);

  return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
}
