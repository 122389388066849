import client from '../../../../gql/apollo-config'
import clientAdmin from '../../../admin/gql/admin-apollo-config'
import {
  UPDATE_PRODUCT_DESIGN_REVISION,
  CREATE_TEXT_DESIGN_REVISION,
  UPDATE_TEXT_DESIGN_REVISION,
  DELETE_TEXT_DESIGN_REVISION,
  CREATE_LOGO_DESIGN_REVISION,
  UPDATE_LOGO_DESIGN_REVISION,
  DELETE_LOGO_DESIGN_REVISION,
  CREATE_PRINT_DESIGN_REVISION,
  UPDATE_PRINT_DESIGN_REVISION,
  DELETE_PRINT_DESIGN_REVISION,
  CREATE_ELEMENT_DESIGN_REVISION,
  UPDATE_ELEMENT_DESIGN_REVISION
} from '../../../../gql/mutations'
import { getColorId } from './save_load'

export const transformDataForSave = (
  data: any,
  zoneColors: any[],
  designTemplate: any,
  pxToCm?: number,
): any => {
  console.log('transformDataForSave')

  return {
    backgroundColorHex: null,
    revisionName: null,
    selectedDesignTemplateId: designTemplate ? designTemplate.id : null,
    pxToCm : pxToCm || null,
    zoneColors: zoneColors || null
  }
}

export const setupTexts = (text: any): any => {
  return {
    isVisible: text.userData.visible,
    textDesign: {
      colorHex: text.userData ? text.userData.textColor : null,
      colorType: 'custom',
      content: text.userData ? text.userData.content : null,
      designAttributes: text.designAttributes,
      fontFamily: text.userData ? text.userData.fontStyle : null,
      height: text.designAttributes ? text.designAttributes.height : null,
      // colorId: ID,
      // printingTechniqueId: ID,
      lineSpacing: text.userData ? text.userData.lineSpacing : null,
      orderSeq: text.userData ? text.userData.orderSeq : null,
      defaultItem: text.userData ? text.userData.defaultItem : null,
      boundingId: text.userData ? text.userData.boundingId : null,
      boundingGroup: text.userData ? text.userData.boundingGroup : null,
      outlineHex: text.userData ? text.userData.outlineColor : null,
      outlineSize: text.userData ? text.userData.outlineSize : null,
      width: text.designAttributes ? text.designAttributes.width : null
    }
  }
}

export const setupLogo = (item: any, ownedByOrganisationId: string): any => {
  return {
    isVisible: item.userData.visible,
    logoDesign: {
      defaultItem: item.userData ? item.userData.defaultItem : null,
      boundingId: item.userData ? item.userData.boundingId : null,
      boundingGroup: item.userData ? item.userData.boundingGroup : null,
      designAttributes: item.designAttributes,
      name: item.userData ? item.userData.filename : null,
      orderSeq: item.userData ? item.userData.orderSeq : null,
      ownedByOrganisationId,
      src: item.userData ? item.userData.filename : null
    }
  }
}

export const setupElement = (
  item: any,
  designAttributes?: any,
  colorHex?: string,
  countryFlag?: string,
  productDesignPrintId?: string
): any => {
  const name =
    item._OBJECT_TYPE === 'SVG' ? item._USER_DATA.element3DName : item.name
  return {
    baseElement: {
      name
    },
    colorHex,
    colorId: colorHex ? getColorId(name, colorHex) : undefined,
    countryFlag: countryFlag || undefined,
    designAttributes: designAttributes || undefined,
    productDesignPrintId: productDesignPrintId || undefined
  }
}

export const setDesignAttributes = (item: any): any => {
  return {
    angle: item.angle,
    flipX: item.flipX,
    flipY: item.flipY,
    height: item.height,
    left: item.left,
    lock: item.lock,
    repeat: item.repeat,
    scaleX: item.scaleX,
    scaleY: item.scaleY,
    top: item.top,
    width: item.width,
    offsetX: item.offsetX,
    offsetY: item.offsetY
  }
}

export const saveProductDesignRevisionData = async (
  productDesignId: string,
  revisionNo: number,
  input: any,
  isAdmin: boolean
): Promise<any> => {
  const options = {
    mutation: UPDATE_PRODUCT_DESIGN_REVISION,
    variables: {
      input,
      productDesignId,
      revisionNo
    }
  }
  isAdmin ? await clientAdmin.mutate(options) : await client.mutate(options)
}

export const createTextDesignRevisionData = (
  productDesignId: string,
  revisionNo: number,
  input: any,
  isAdmin: boolean
): any => {
  const options = {
    mutation: CREATE_TEXT_DESIGN_REVISION,
    variables: {
      input,
      productDesignId,
      revisionNo
    }
  }
  return isAdmin ? clientAdmin.mutate(options) : client.mutate(options)
}

export const updateTextDesignRevisionData = (
  id: string,
  productDesignId: string,
  revisionNo: number,
  input: any,
  isAdmin: boolean
): any => {
  const options = {
    mutation: UPDATE_TEXT_DESIGN_REVISION,
    variables: {
      id,
      input,
      productDesignId,
      revisionNo
    }
  }
  return isAdmin ? clientAdmin.mutate(options) : client.mutate(options)
}

export const deleteTextDesignRevisionData = (
  id: string,
  productDesignId: string,
  revisionNo: number,
  isAdmin: boolean
): any => {
  const options = {
    mutation: DELETE_TEXT_DESIGN_REVISION,
    variables: {
      id,
      productDesignId,
      revisionNo
    }
  }
  return isAdmin ? clientAdmin.mutate(options) : client.mutate(options)
}

export const createLogoDesignRevisionData = (
  productDesignId: string,
  ownedByOrganisationId: string,
  revisionNo: number,
  input: any,
  isAdmin: boolean
): any => {
  const options = {
    mutation: CREATE_LOGO_DESIGN_REVISION,
    variables: {
      input,
      ownedByOrganisationId,
      productDesignId,
      revisionNo
    }
  }
  return isAdmin ? clientAdmin.mutate(options) : client.mutate(options)
}

export const updateLogoDesignRevisionData = (
  id: string,
  productDesignId: string,
  ownedByOrganisationId: string,
  revisionNo: number,
  input: any,
  isAdmin: boolean
): any => {
  const options = {
    mutation: UPDATE_LOGO_DESIGN_REVISION,
    variables: {
      id,
      input,
      ownedByOrganisationId,
      productDesignId,
      revisionNo
    }
  }
  return isAdmin ? clientAdmin.mutate(options) : client.mutate(options)
}

export const deleteLogoDesignRevisionData = (
  id: string,
  productDesignId: string,
  ownedByOrganisationId: string,
  revisionNo: number,
  isAdmin: boolean
): any => {
  const options = {
    mutation: DELETE_LOGO_DESIGN_REVISION,
    variables: {
      id,
      ownedByOrganisationId,
      productDesignId,
      revisionNo
    }
  }
  return isAdmin ? clientAdmin.mutate(options) : client.mutate(options)
}

export const createPrintDesignRevisionData = (
  productDesignId: string,
  ownedByOrganisationId: string,
  revisionNo: number,
  input: any,
  isAdmin: boolean
): any => {
  const options = {
    mutation: CREATE_PRINT_DESIGN_REVISION,
    variables: {
      input,
      ownedByOrganisationId,
      productDesignId,
      revisionNo
    }
  }
  return isAdmin ? clientAdmin.mutate(options) : client.mutate(options)
}

export const updatePrintDesignRevisionData = (
  id: string,
  productDesignId: string,
  ownedByOrganisationId: string,
  revisionNo: number,
  input: any,
  isAdmin: boolean
): any => {
  const options = {
    mutation: UPDATE_PRINT_DESIGN_REVISION,
    variables: {
      id,
      input,
      ownedByOrganisationId,
      productDesignId,
      revisionNo
    }
  }
  return isAdmin ? clientAdmin.mutate(options) : client.mutate(options)
}

export const deletePrintDesignRevisionData = (
  id: string,
  productDesignId: string,
  ownedByOrganisationId: string,
  revisionNo: number
): any => {
  return client.mutate({
    mutation: DELETE_PRINT_DESIGN_REVISION,
    variables: {
      id,
      ownedByOrganisationId,
      productDesignId,
      revisionNo
    }
  })
}

export const createElementDesignRevisionData = (
  productDesignId: string,
  revisionNo: number,
  input: any,
  isAdmin: boolean
): any => {
  const options = {
    mutation: CREATE_ELEMENT_DESIGN_REVISION,
    variables: {
      input,
      productDesignId,
      revisionNo
    }
  }
  return isAdmin ? clientAdmin.mutate(options) : client.mutate(options)
}

export const updateElementDesignRevisionData = (
  id: string,
  productDesignId: string,
  revisionNo: number,
  input: any,
  isAdmin: boolean
): any => {
  const options = {
    mutation: UPDATE_ELEMENT_DESIGN_REVISION,
    variables: {
      id,
      input,
      productDesignId,
      revisionNo
    }
  }
  return isAdmin ? clientAdmin.mutate(options) : client.mutate(options)
}

export const appendToFilename = (filename: string, string: string): string => {
  const dotIndex = filename.lastIndexOf('.')
  if (dotIndex === -1) return filename + string
  else
    return (
      filename.substring(0, dotIndex) + string + filename.substring(dotIndex)
    )
}
