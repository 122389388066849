import React, { Component } from 'react';
import { API_STAGE } from '../../../../../brikl-config';
import { Clear } from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';
import { Grid, Typography, Button } from '@material-ui/core';

class MarkerLayoutPlane extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot;
    DesignRoot.MarkerLayoutPlane = this;
    DesignRoot.setState({ update: true });
    // PROVIDE COMPONENT TO MAIN
  }

  handleExit() {
    try {
      // statements

      var DesignRoot = this.props.DesignRoot;
      DesignRoot.marker_layout_control.clearCanvasMarker();
      DesignRoot.setState({ activeML: false });

      for (var key in DesignRoot.StackLegend.legendRecord) {
        if (DesignRoot.StackLegend.legendRecord.hasOwnProperty(key)) {
          var stackList = DesignRoot.fabric_function.getObjectByType('STACK');

          for (var i = 0; i < stackList.length; i++) {
            if (
              stackList[i]._USER_DATA.size === key &&
              DesignRoot.StackLegend.legendRecord[key] === true
            ) {
              stackList[i].set({ visible: true });
            }
          }

          DesignRoot.canvas.renderAll();
        }
      }
    } catch (e) {
      // statements
      console.error('MarkerLayoutPlane.handleExit.error', e);
    }
  }

  handleExport() {
    var DesignRoot = this.props.DesignRoot;
    DesignRoot.marker_layout_control.exportCanvasMarker();
  }

  render() {
    return (
      <div className="MarkerLayoutPlane">
        <div
          style={{
            width: 'calc(100vw - 60px)',
            height: '100%',
            overflowY: 'auto'
          }}
        >
          <canvas id="canvasMarker" />
        </div>
        <div className="bottomTools">
          <Button
            style={{ minWidth: 16, marginRight: 5 }}
            variant="contained"
            color="primary"
            onClick={this.handleExport.bind(this)}
          >
            EXPORT TIFF
          </Button>

          <Button
            style={{ minWidth: 16, marginRight: 5 }}
            variant="contained"
            color="primary"
            onClick={this.handleExit.bind(this)}
          >
            Exit Marker layout mode
          </Button>
        </div>
      </div>
    );
  }
}

export default MarkerLayoutPlane;
