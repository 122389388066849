import React from 'react';
import {
  createStyles,
  Grid,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import { Query } from 'react-apollo';
import uuid from 'uuid';
import _ from 'lodash';
import client from '../../gql/admin-apollo-config';
import { removeKeys } from '../../../../helper/removeKeys';
import { ADMIN_GET_CONTENT_MENU } from '../../gql/admin-queries';
import {
  CONTENT_CREATE_MODULE,
  CONTENT_UPDATE_MODULE,
  CONTENT_DELETE_MODULE
} from '../../gql/admin-mutations';
import {
  SOCIAL_LINKS_INPUT,
  PAYMENT_OPTIONS_INPUT,
  SEARCH_INPUT_INPUT,
  CART_COUNTER_INPUT,
  LANGUAGE_SWITCH_INPUT,
  CURRENCY_SWITCH_INPUT,
  COUNTRY_SWITCH_INPUT,
  CONTACTS_INPUT,
  SHOP_LOGO_INPUT,
  TEAMSTORE_LOGO_INPUT,
  TERMS_LINK_INPUT
} from '../content/MutationInputs';
import ModuleList from './Module/ModuleList';
import ModuleAdd from './Module/ModuleAdd';

const drawerWidth = 240;
const styles = theme =>
  createStyles({
    root: {
      overflow: 'hidden',
      maxWidth: '1000px',
      padding: 24,
      margin: '0 auto',
      width: '100%',
      display: 'block'
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        position: 'relative',
        height: '100vh',
        background: '#ffffff'
      },
      content: {
        flexGrow: 1,
        background: '#f2f2f2',
        height: 'calc(100vh - 70px)',
        overflowY: 'auto',
        padding: '24px',
        marginTop: '70px',
        [theme.breakpoints.up('sm')]: {
          marginTop: '70px'
        }
      },
      container: {
        flex: 1,
        minHeight: 'calc(100vh - 48px)',
        borderRadius: 0
      },
      row: {
        position: 'relative',
        minHeight: '50px',
        border: '3px dotted #6B90F2',
        borderRadius: '6px'
      },
      elevation: {
        boxShadow:
          '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
      },
      h1: {
        fontSize: 30,
        fontWeight: 'medium',
        color: '#424242',
        flex: 1
      }
    }
  });

interface Props extends WithStyles<typeof styles> {
  data: any[];
  shop: any;
  onDelete(id: string): void;
}

const ModulesContainer: React.FunctionComponent<Props> = props => {
  const { classes, shopId } = props;

  const handleAdd = async (
    type: string,
    label: string,
    options?: any
  ): Promise<any> => {
    const inputsType = {
      CART_COUNTER: CART_COUNTER_INPUT,
      CONTACTS: CONTACTS_INPUT,
      COUNTRY_SWITCH: COUNTRY_SWITCH_INPUT,
      CURRENCY_SWITCH: CURRENCY_SWITCH_INPUT,
      LANGUAGE_SWITCH: LANGUAGE_SWITCH_INPUT,
      PAYMENT_OPTIONS: PAYMENT_OPTIONS_INPUT,
      SEARCH_INPUT: SEARCH_INPUT_INPUT,
      SHOP_LOGO: SHOP_LOGO_INPUT,
      TEAMSTORE_LOGO: TEAMSTORE_LOGO_INPUT,
      SOCIAL_LINKS: SOCIAL_LINKS_INPUT,
      TERMS_LINK: TERMS_LINK_INPUT
    };
    const input = _.cloneDeep(inputsType[type]);
    input.label = label;
    input.contentDetail.id = uuid.v4();
    if (options) {
      input.contentDetail = { ...input.contentDetail, ...options };
    }

    await client.mutate({
      mutation: CONTENT_CREATE_MODULE,
      update: (cache, { data: { createContentModule } }) => {
        if (createContentModule.id) {
          const { shop } = cache.readQuery({
            query: ADMIN_GET_CONTENT_MENU,
            variables: { id: shopId }
          });

          shop.modules.edges.push({
            __typename: 'ContentModule',
            node: createContentModule
          });
        }
      },
      variables: {
        input
      }
    });
  };

  const handleDelete = async (id: string): Promise<any> => {
    await client.mutate({
      mutation: CONTENT_DELETE_MODULE,
      update: (cache, { data: { deleteContentModule } }) => {
        const { shop } = cache.readQuery({
          query: ADMIN_GET_CONTENT_MENU,
          variables: { id: shopId }
        });

        shop.modules.edges = shop.modules.edges.filter(o => o.node.id !== id);
      },
      variables: {
        id
      }
    });
  };

  const handleSaveSettings = async (
    id: string,
    contentDetail: any
  ): Promise<any> => {
    let input = contentDetail;
    input = removeKeys(input, '__typename');

    await client.mutate({
      mutation: CONTENT_UPDATE_MODULE,
      variables: {
        id,
        input: {
          contentDetail
        }
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid
        container={true}
        justify="flex-start"
        alignItems="center"
        style={{ height: '60px', marginBottom: '20px' }}
      >
        <Typography
          component="h1"
          className={classes.h1}
          data-cy="Header-Pages"
        >
          Modules
        </Typography>
      </Grid>

      <Grid container={true}>
        <ModuleAdd onSave={handleAdd} />
      </Grid>

      <Query query={ADMIN_GET_CONTENT_MENU} variables={{ id: shopId }}>
        {({ loading, error, data }) => {
          if (loading) return 'Loading...';
          if (error) return `Error! ${error.message}`;

          const modules =
            data.shop.modules && data.shop.modules.edges
              ? data.shop.modules.edges.filter(
                  o =>
                    o.node.contentDetail &&
                    o.node.contentDetail.type &&
                    o.node.label
                )
              : [];
          return (
            <Grid container={true}>
              <ModuleList
                data={modules}
                shop={data.shop}
                onSaveSettings={handleSaveSettings}
                onDelete={handleDelete}
              />
            </Grid>
          );
        }}
      </Query>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ModulesContainer);
