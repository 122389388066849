import ws from 'ws'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { split, ApolloLink } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import fetch from 'isomorphic-fetch'
import {
  API_URL,
  DEFAULT_CURRENCY,
  PUBLIC_CDN_URL,
  DEFAULT_LANGUAGE,
  SHOP_ID
} from '../helper/config'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionQueryResultData from './fragmentTypes.json'
import { getBearer } from '../helper/getToken'
import { WebSocketLink } from 'apollo-link-ws'
import {
  getCurrency,
  getLanguage,
  getCountry,
  getShopId
} from '../helper/storage'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})
const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: object => {
    if (object) {
      switch (object.__typename) {
        // case 'BoardProductDesign':
        // return object.__typename + ':' + object.boardProductId; // use `boardProductId` as the primary key for BoardProductDesign which equals id
        // case 'bar': return `bar:${object.blah}`; // use `bar` prefix and `blah` as the primary key
        default:
          return defaultDataIdFromObject(object) // fall back to default handling
      }
    } else {
      return null
    }
  }
})

var store = require('store')
const authLink = new setContext(async (_, sth) => {
  var headers = sth.headers

  const token = await getBearer()
  try {
    if (
      process.env.GATSBY_BRIKL_IS_DASHBOARD === 'yes' &&
      process.env.GATSBY_API_STAGE !== 'production'
    ) {
      alert(
        'You are using the wrong apollo client. Admin should use the admin-apollo-config.js'
      )
    }
  } catch (e) {}
  let shopId
  try {
    if (process.env.GATSBY_BRIKL_IS_DASHBOARD === 'yes') {
      shopId = await getShopId()
    } else {
      shopId = SHOP_ID
    }
  } catch (error) {}
  return {
    headers: {
      ...headers,
      'x-brikl-shop-id': shopId,
      authorization: token ? token : null

      // 'Cache-Control': _.operationName === 'publicUser' || _.operationName === 'publicOrganisation' ? 'public, max-age=31536000' : null
    }
  }
})

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    try {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
          if (window && window.Sentry) {
            const screenId = Math.random().toString(36)
            // window.Sentry.captureException(message,{
            //   tags:{
            //     screenId: screenId,
            //     component: 'app',
            //     errortype: 'graphQLErrors',
            //     operation: operation && operation.operationName ? operation.operationName : operation ? JSON.stringify(operation) : '',
            //     response: response ? JSON.stringify(response) : ''
            //   }
            // });

            //take screenshot
            try {
              // takeScreenShotToS3(
              //   screenId,
              //   operation && operation.operationName ? '-graphQLErrors-'+operation.operationName : '-graphQLErrors',
              //   window.document.body
              // )
            } catch (error) {}
          }
        })
      if (networkError) {
        const screenId2 = Math.random().toString(36)
        if (window && window.Sentry) {
          // window.Raven.captureException(networkError,{
          //   tags:{
          //     screenId: screenId2,
          //     component: 'app',
          //     errortype: 'networkError',
          //     operation: operation && operation.operationName ? operation.operationName : operation ? JSON.stringify(operation) : '',
          //     response: response ? JSON.stringify(response) : ''
          //   }
          // });
        }
        //take screenshot
        try {
          // takeScreenShotToS3(
          //   screenId2,
          //   operation && operation.operationName ? '-networkError-'+operation.operationName : '-networkError',
          //   window.document.body
          // )
        } catch (error) {}
        console.error(`[Network error]: ${networkError}`)
      }
    } catch (error) {
      console.error(`[onError error]: ${error}`)
    }
  }
)
// const link = createPersistedQueryLink({
//   useGETForHashedQueries: true
// }).concat(createHttpLink({ uri: API_URL, fetch }))
const publicCDNLink = createHttpLink({
  uri:
    PUBLIC_CDN_URL +
    `?lang=${
      typeof window !== `undefined` && getLanguage()
        ? getLanguage()
        : DEFAULT_LANGUAGE
    }&cur=${
      typeof window !== `undefined` && getCurrency()
        ? getCurrency()
        : DEFAULT_CURRENCY
    }&country=${
      typeof window !== `undefined` && getCountry() ? getCountry() : null
    }`,
  useGETForQueries: true,
  fetch
})

const httpLink = createHttpLink({
  uri:
    API_URL +
    `?lang=${
      typeof window !== `undefined` && getLanguage()
        ? getLanguage()
        : DEFAULT_LANGUAGE
    }&cur=${
      typeof window !== `undefined` && getCurrency()
        ? getCurrency()
        : DEFAULT_CURRENCY
    }&country=${
      typeof window !== `undefined` && getCountry() ? getCountry() : null
    }`,
  fetch
})
// const batchLink = new BatchHttpLink({
//   uri: API_URL,
//   fetch,
//   batchInterval: 20,
//   batchMax: 20
// });
const httpLinks = split(
  // split based on operation or query attributes
  ({ query }) => {
    const mainDefinition = getMainDefinition(query)
    if (
      mainDefinition &&
      mainDefinition.name &&
      mainDefinition.name.value &&
      (mainDefinition.name.value === 'shopConfiguration' ||
        mainDefinition.name.value === 'productConfiguration')
      // ||
      // mainDefinition.name.value === 'getRandomUsers'
    ) {
      return true
    } else {
      return false
    }
  },
  // publicCDNLink,
  httpLink,
  httpLink
)

// Create a WebSocket link:
// const wsLink =
//   typeof window !== `undefined`
//     ? new WebSocketLink({
//         // uri: 'wss://staging-brikl-hasura.herokuapp.com/v1/graphql', //WS_URL
//         // uri: 'wss://dby9o3hyj9.execute-api.ap-southeast-1.amazonaws.com/dev',
//         uri:
//           process.env.GATSBY_API_STAGE === 'production'
//             ? 'wss://events.mybrikl.com/v1/graphql'
//             : process.env.GATSBY_API_STAGE === 'staging'
//             ? 'wss://staging.events.mybrikl.com/v1/graphql'
//             : 'wss://dev.events.mybrikl.com/v1/graphql',
//         options: {
//           reconnectionAttempts: 3,
//           reconnect: true,
//           async connectionParams() {
//             // console.log('sth', sth)
//             // get the authentication token from local storage if it exists
//             const token = await getBearer()
//             console.log('token', token)
//             return {
//               headers: {
//                 Authorization: token
//               }
//             }
//           },
//           lazy: true
//         },
//         ws
//       })
//     : null

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
// const wsAndHttpLinks =
//   typeof window !== `undefined`
//     ? split(
//         // split based on operation type
//         ({ query }) => {
//           const { kind, operation } = getMainDefinition(query)
//           // console.log('operation', operation)
//           return kind === 'OperationDefinition' && operation === 'subscription'
//         },
//         wsLink,
//         httpLinks
//       )
//     : httpLinks

const client = new ApolloClient({
  cache,
  ssrMode: !typeof window !== `undefined`,
  link: authLink.concat(errorLink.concat(httpLinks)),
  name: SHOP_ID,
  version: 'v1'
})

export default client
