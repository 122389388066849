import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import '../products/ProductFileDrop.css';
import { Mutation } from 'react-apollo';
import { I18n } from '@aws-amplify/core';
import { MEDIA_CDN_URL } from '../../../../../brikl-config';
import { uploadContentMedia, getImageUrl } from './utils';
import { ADMIN_UPLOAD_FILE } from '../../gql/admin-mutations';
import FileDrop from 'react-file-drop';

interface Props {
  image: string;
  isCDNField: boolean;
  field: string;
  dataCy?: string;
  handleChangeField(value: string, fieldName: string): void;
}

const Uploader: React.FunctionComponent<Props> = props => {
  const { image, isCDNField, field, handleChangeField, dataCy, shopId } = props;
  const [isUploading, setIsUploading] = useState(false);

  return (
    <Mutation
      update={(store, { data: { uploadFile } }) => {
        // do sth after upload
      }}
      mutation={ADMIN_UPLOAD_FILE}
    >
      {uploadFile => (
        <div
          style={{
            width: '208px'
          }}
        >
          <div
            data-cy={dataCy}
            style={{
              marginTop: '16px',
              border: '1px solid #E5E5E5',
              borderRadius: '3px'
            }}
          >
            <FileDrop
              onDrop={async files => {
                try {
                  setIsUploading(true);
                  const newFileName = await uploadContentMedia(
                    files,
                    uploadFile
                  );
                  const url = `${newFileName}`;

                  handleChangeField(url, field);
                } catch (error) {
                  console.log(error);
                }
                setIsUploading(false);
              }}
            >
              <Grid container={true} direction="column">
                <Grid item={true}>
                  <span>
                    {I18n.get('Drag your file here ')}
                    <br />( .png or .jpg )
                  </span>
                </Grid>
              </Grid>
            </FileDrop>

            {isUploading ? <div>Upload in progress...</div> : null}
          </div>
          <Grid
            container
            wrap="nowrap"
            style={{
              height: '64px',
              width: '100%',
              marginTop: '8px'
            }}
          >
            <Typography variant="body1">
              {image ? (
                <img
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    borderRadius: '0px'
                  }}
                  src={getImageUrl(image, shopId)}
                  alt={getImageUrl(image, shopId)}
                  title={getImageUrl(image, shopId)}
                />
              ) : null}
            </Typography>
          </Grid>
        </div>
      )}
    </Mutation>
  );
};

export default Uploader;
