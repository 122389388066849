import React, { Component } from 'react';
import { API_STAGE } from '../../../../brikl-config';

class Selection extends Component {
  constructor(props) {
    super(props);

    this.initSelection = {
      selection: null,
      selectionObject: null,
      selectionObjectELM: null, // FOR LCE OR NO COLOR ZONE USE
      selectDirectELM: null,
      selectDirectELMObject: null
    };

    this.state = this.initSelection;
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot;
    DesignRoot.Selection = this;
    DesignRoot.setState({ update: true });
    // PROVIDE COMPONENT TO MAIN
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    try {
      // statements

      const { DesignRoot } = nextProps;

      console.log('UNSAFE_componentWillUpdate', DesignRoot);

      const {
        selection,
        selectionObject,
        selectDirectELM,
        selectDirectELMObject
      } = nextState;
      if ( (selection !== null && selection !== 'template') || (selectDirectELM !== null && selectDirectELMObject !== null) ) {
        // SELECTION CHECK
        console.log('SELECTION', selection, selectionObject, selectDirectELM, selectDirectELMObject);
        DesignRoot.template_control.selectionUpdateState(selectionObject);
      } else {
        console.log('NO SELECTION', selection, selectDirectELM, selectDirectELMObject);
        DesignRoot.template_control.selectionUpdateState(null);
      }

      DesignRoot.tools.renderComponent('FillStep');
    } catch (e) {
      // statements
      console.error('Selection.UNSAFE_componentWillUpdate.error', e);
    }
  }

  render() {
    const { selection, selectDirectELM } = this.state;

    return (
      <div className="Selection">
        {API_STAGE !== 'PRODUCTION' && (
          <div>
            {API_STAGE}
            <br />
            SELECTION : {selection}
            <br />
            SELECTION DIRECT : {selectDirectELM}
          </div>
        )}
      </div>
    );
  }
}

export default Selection;
