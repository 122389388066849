import * as tools from '../tools/tools';
import { API_STAGE } from '~/brikl-config';
let SHOP_ID = tools.getQueryStringValue('s');

var DesignRoot = null;
var svgOBJECT = null;

var originalWidth = 0;
var originalHeight = 0;

var _DIM_CHECK = false;
var _DIM_W = null;
var _DIM_H = null;
var _DIM_ELM = null;

export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot;
  if ( !SHOP_ID ) {

    SHOP_ID = DesignRoot.SHOP_ID;
  }
}

export function addSVG(id, src, x, y, canvas, base_width, base_height) {
  try {
    // statements

    if (
      svgOBJECT === null ||
      DesignRoot.fabric_function.getObjectByType('SVG').length === 0
    ) {
      console.log('addSVG', id, src, x, y, canvas, SHOP_ID);

      src = DesignRoot.tools._wrapTimeToSrc(src);

      window.fabric.loadSVGFromURL(src, function(objects, options) {
        console.log('addSVG objects', objects, options);

        originalWidth = options.width;
        originalHeight = options.height;

        DesignRoot.svgW = originalWidth;
        DesignRoot.svgH = originalHeight;

        if ( SHOP_ID === 'hydrodynamic' ) {

          base_width = originalWidth;
          DesignRoot.imageLoad.width = originalWidth;
          DesignRoot.imageLoad.height = originalHeight;
        }

        var scaleX = base_width / options.width;

        var initColor = 'transparent';

        if (DesignRoot._ACTIVE_COLOR_ZONE === false) {
          var newHeight = options.height * scaleX;

          if ( SHOP_ID !== 'hydrodynamic' ) {

            DesignRoot.imageLoad.height = newHeight;
          }

          initColor = '#FFF';

          DesignRoot.canvas.setDimensions({
            width: base_width * DesignRoot._CANVAS_SIZE,
            height: newHeight * DesignRoot._CANVAS_SIZE
          });

          if (DesignRoot._APP_TYPE === '2d-3d') {
            // RENDER SCENE 100%
            DesignRoot._CANVAS_RENDER.setDimensions({
              width: base_width,
              height: newHeight
            });
          }

          DesignRoot.canvas.requestRenderAll();
        }

        for (var i = 0; i < objects.length; i++) {
          var fullID = objects[i].id;

          // CHECK REF FROM SVG ELM

          if (fullID.indexOf('DIM_') !== -1) {
            _DIM_W = fullID.match(/(W\d*.\d*)/g);
            _DIM_H = fullID.match(/(H\d*.\d*)/g);

            if (_DIM_W !== null && _DIM_W.length) {
              _DIM_W = _DIM_W[0];
              _DIM_W = _DIM_W.replace('W', '');
              _DIM_W = parseFloat(_DIM_W);
            } else {
              _DIM_W = null;
            }

            if (_DIM_H !== null && _DIM_H.length) {
              _DIM_H = _DIM_H[0];
              _DIM_H = _DIM_H.replace('H', '');
              _DIM_H = parseFloat(_DIM_H);
            } else {
              _DIM_H = null;
            }

            if (_DIM_W === null && _DIM_H === null) {
              _DIM_CHECK = false;
            } else {
              _DIM_CHECK = true;
              _DIM_ELM = objects[i];
            }

            console.log('DIM_W', _DIM_W, 'DIM_H', _DIM_H, _DIM_CHECK, _DIM_ELM);
            DesignRoot._PXTOCM = 0;
          }

          // CHECK REF FROM SVG ELM

          var idSplit = fullID.split('-');

          if (idSplit.length > 1) {
            var newId = 'SVG-' + idSplit[0] + '-' + idSplit[1];
            var index = idSplit[0] + '-' + idSplit[1];
          } else {
            var newId = 'SVG-' + idSplit[0] + '-' + i;
            var index = idSplit[0] + '-' + i;
          }

          // XCEED

          var bounding = false;
          var boundingType = null;
          var evented = true;
          var stroke = '#fff';
          var zoneGroup = null;
          var zoneLimitItem = 0;
          var zoneNumber = null;
          var strokeWidth = 1;
          var visible = true;

          if (DesignRoot._SVG_BOUNDING === true) {
            var splitName = fullID.split('.');
            var boundingCheck = splitName[0];
            var type = splitName[1];
            var zoneGroup = splitName[2];
            var zoneLimitItem = splitName[3];
            var zoneNumber = splitName[4];
            var zoneAngle = splitName[5];

            if (splitName[6] !== undefined) {
              var zoneName = splitName[6].replace(/([A-Z])/g, ' $1').trim();
            } else {
              var zoneName = zoneGroup;
            }

            initColor = 'transparent';

            if (
              fullID.indexOf('BOUNDING') !== -1 &&
              fullID.indexOf('TEXT') !== -1
            ) {
              initColor = 'transparent';
              evented = false;
              bounding = true;
              stroke = '#f00';
              strokeWidth = 0;
              boundingType = 'TEXT';
            } else if (
              fullID.indexOf('BOUNDING') !== -1 &&
              fullID.indexOf('LOGO') !== -1
            ) {
              initColor = 'transparent';
              evented = false;
              bounding = true;
              stroke = '#0f0';
              strokeWidth = 0;
              boundingType = 'LOGO';
            } else if (
              fullID.indexOf('BOUNDING') !== -1 &&
              fullID.indexOf('FLAG') !== -1
            ) {
              DesignRoot._SHOW_AOP_FLAG = true;
              initColor = 'transparent';
              evented = true;
              bounding = true;
              stroke = '#00f';
              strokeWidth = 0;
              boundingType = 'FLAG';
            } else if (
              fullID.indexOf('BOUNDING') !== -1 &&
              fullID.indexOf('BOTH') !== -1
            ) {
              initColor = 'transparent';
              evented = false;
              bounding = true;
              stroke = '#00f';
              strokeWidth = 0;
              boundingType = 'BOTH';
            }
          }
          else {

            if ( fullID.indexOf('BOUNDING') !== -1 ) {

              evented = false;
              visible = false;
              strokeWidth = 0;
            }
          }

          // XCEED

          objects[i].set({
            id: newId,
            scaleX: scaleX,
            scaleY: scaleX,
            top: objects[i].top * scaleX,
            left: objects[i].left * scaleX,
            strokeWidth: strokeWidth,
            stroke: stroke,
            _USER_DATA: { hasChange: false, index: index, fullID: fullID },
            _OBJECT_TYPE: 'SVG',
            fill: initColor,
            evented: evented,
            visible: visible
          });
          objects[i].selectable = false;
          objects[i].hascontrols = false;
          objects[i].hasBorders = false;

          if (bounding === true) {
            objects[i]._BOUNDING_TYPE = boundingType;
            objects[i]._ZONE_GROUP = zoneGroup;
            objects[i]._ZONE_LIMIT_ITEM = zoneLimitItem;
            objects[i]._ZONE_NUMBER = zoneNumber;
            objects[i]._ZONE_ANGLE = zoneAngle;
            objects[i]._ZONE_NAME = zoneName;
            objects[i].strokeDashArray = [5, 10];
            DesignRoot._SVG_BOUNDING_SET.push(objects[i]);
          } else {
            addSVG_Border(objects[i], canvas);
          }

          canvas.add(objects[i]);
        }

        svgOBJECT = objects;
        canvas.requestRenderAll();
        DesignRoot.fabric_function.reArrageItem();
        DesignRoot.fabric_function.mapSvgWith3DElm(base_width, newHeight);

        if (DesignRoot._LOAD_DATA !== null) {
          var SVG = DesignRoot._LOAD_DATA.SVG;
          loadSVG(canvas, SVG);
          DesignRoot.fabric_function.reArrageItem();
        }

        // LOAD BOUNDING LOGO

        console.log(
          'LOAD BOUNDING LOGO',
          DesignRoot.onLoad,
          DesignRoot._LOAD_DATA
        );

        if (DesignRoot.onLoad === true || DesignRoot._LOAD_DATA !== null) {
          var allItem = DesignRoot._LOAD_DATA.allItem;

          for (var i = 0; i < allItem.length; i++) {
            if (
              allItem[i].objectType === 'LOGO' &&
              (allItem[i].userData.defaultItem === true ||
                allItem[i].userData.boundingId !== null)
            ) {
              var src = allItem[i].userData.s3Url + '?t=' + new Date().getDay();
              allItem[i].userData.base64 = src;

              var userData = allItem[i].userData;

              var boundingId = userData.boundingId;
              var boundingGroup = userData.boundingGroup;

              var boundingData = DesignRoot.fabric_function.getObjectById(
                boundingId
              );

              userData.centerPoint = boundingData.getCenterPoint();
              userData.boundingId = boundingData.id;
              userData.boundingGroup = boundingData._ZONE_GROUP;

              var canvasData = {
                left: boundingData.left,
                top: boundingData.top,
                scaleX: boundingData.scaleX,
                scaleY: boundingData.scaleY,
                width: boundingData.width,
                height: boundingData.height,
                flipX: allItem[i].canvasData.flipX,
                flipY: allItem[i].canvasData.flipY
              };

              DesignRoot.fabric_function.addItem(
                allItem[i].id || userData.id,
                allItem[i].name,
                src,
                boundingData.left,
                boundingData.top,
                userData,
                allItem[i].objectType,
                'ON-LOAD',
                canvasData,
                true
              );
            }
            else if (
              allItem[i].objectType === 'TEXT' &&
              DesignRoot._TEXT_LOAD === false && 
              (allItem[i].userData.defaultItem === true ||
                allItem[i].userData.boundingId !== null)
            ) {

              DesignRoot._TEXT_LOAD = true;
            }
          }
        }

        // LOAD BOUNDING LOGO

        // STACK LOAD

        if ( SHOP_ID === 'yuepai' ) {

          for (var i = 0; i < DesignRoot._STACK.length; i++) {
            addSTACK(
              DesignRoot._STACK[i].stackId,
              DesignRoot._STACK[i].stackSvg,
              x,
              y,
              canvas,
              base_width,
              base_height,
              DesignRoot._STACK[i].legendColor,
              DesignRoot._STACK[i].svgCalElm,
              DesignRoot._STACK[i].svgCalCm
            );
          }
        }

        

        // STACK LOAD

        // AFTER LOAD ALL TEMPLATE THEN FIT SCENE
        DesignRoot.fabric_function.fitInScene();

        if (_DIM_CHECK === true) {
          if (_DIM_W !== null) {
            var w = _DIM_ELM.width * _DIM_ELM.scaleX;
            console.log('CHECK DIMENSION', w, _DIM_ELM);
            var ref = _DIM_W / w;
            DesignRoot._PXTOCM = ref;
            DesignRoot._PXBASE = w;
            DesignRoot._CMBASE = _DIM_W;
            console.log('PX TO CM', DesignRoot._PXTOCM);

            // DEBUG BOX DIMENSION

            // var box = new window.fabric.Rect({
            //   top: 0,
            //   left: 0,
            //   width: w,
            //   height: w,
            //   fill: 'transparent',
            //   stroke: 'red',
            //   originX: 'center',
            //   originY: 'center',
            // });
            // DesignRoot.canvas.add(box);

            // DEBUG BOX DIMENSION
          } else if (_DIM_H !== null) {
            var h = _DIM_ELM.height * _DIM_ELM.scaleX;
            console.log('CHECK DIMENSION', h);
            var ref = _DIM_H / h;
            DesignRoot._PXTOCM = ref;
            DesignRoot._PXBASE = h;
            DesignRoot._CMBASE = _DIM_H;
            console.log('PX TO CM', DesignRoot._PXTOCM);
          }
        }

        tools.renderComponent('DesignRoot');
        
      });
    } // END IF
    else if ( DesignRoot._ACTIVE_PERSONALIZATION === false ) {
      // ALREADY HAVE SVG THEN ADJUST SCALE

      var scaleX = base_width / DesignRoot.svgW;

      var initColor = 'transparent';

      if (DesignRoot._ACTIVE_COLOR_ZONE === false) {

        var newHeight = DesignRoot.svgH * scaleX;

        if ( SHOP_ID !== 'hydrodynamic' ) {

          DesignRoot.imageLoad.height = newHeight;
        }

        initColor = '#FFF';

        // DesignRoot.canvas.setDimensions({
        //   width: base_width * DesignRoot._CANVAS_SIZE,
        //   height: newHeight * DesignRoot._CANVAS_SIZE
        // });

        if (DesignRoot._APP_TYPE === '2d-3d') {
          // RENDER SCENE 100%
          DesignRoot._CANVAS_RENDER.setDimensions({
            width: base_width,
            height: newHeight
          });
        }

        DesignRoot.canvas.requestRenderAll();

        var allSVG = DesignRoot.fabric_function.getObjectByType('SVG');

        for (var i = 0; i < allSVG.length; i++) {
          allSVG[i].set({
            fill: initColor
          });
          allSVG[i]._USER_DATA.hasChange = false;
          allSVG[i]._USER_DATA.fillType = 'COLOR';
        }

        DesignRoot.canvas.requestRenderAll();
      }
      else {

        var initColor = 'transparent';
        var allSVG = DesignRoot.fabric_function.getObjectByType('SVG');
        for (var i = 0; i < allSVG.length; i++) {
          allSVG[i].set({
            fill: initColor
          });
          allSVG[i]._USER_DATA.hasChange = false;
          allSVG[i]._USER_DATA.fillType = 'COLOR';
        }

        DesignRoot.canvas.requestRenderAll();
      }

      // ALREADY HAVE SVG THEN ADJUST SCALE

      DesignRoot.fabric_function.fitInScene();
      DesignRoot.fabric_function.reArrageItem();
    }
  } catch (e) {
    // statements
    console.error('svg_control.addSVG.error', e);
  }
}

export function addSTACK(
  id,
  src,
  x,
  y,
  canvas,
  base_width,
  base_height,
  legendColor,
  svgCalElm,
  svgCalCm
) {
  try {
    // statements

    console.log('addSTACK', id, src, x, y, canvas);

    window.fabric.loadSVGFromURL(src, function(objects, options) {
      console.log('objects', objects);

      var scaleX = base_width / options.width;

      for (var i = 0; i < objects.length; i++) {
        var fullID = objects[i].id;
        var idSplit = fullID.split('-');
        var newId = 'STACK-' + id + '-' + idSplit[0] + '-' + idSplit[1];
        var index = idSplit[0] + '-' + idSplit[1];

        objects[i].set({
          id: newId,
          scaleX: scaleX,
          scaleY: scaleX,
          top: objects[i].top * scaleX,
          left: objects[i].left * scaleX,
          strokeWidth: 2,
          _USER_DATA: {
            hasChange: false,
            size: id,
            index: index,
            fullID: fullID,
            legendColor: legendColor,
            svgCalElm: svgCalElm,
            svgCalCm: svgCalCm
          },
          _OBJECT_TYPE: 'STACK',
          fill: 'transparent',
          stroke: legendColor,
          strokeDashArray: [10, 10],
          visible: false
        });
        objects[i].selectable = false;
        objects[i].hascontrols = false;
        objects[i].hasBorders = false;
        canvas.add(objects[i]);
        canvas.sendToBack(objects[i]);
      }

      canvas.requestRenderAll();
    });
  } catch (e) {
    // statements
    console.error('svg_control.addSTACK.error', e);
  }
}

export function loadSVG(canvas, SVG) {
  try {
    // statements

    console.log('loadSVG', DesignRoot, canvas, SVG);

    for (var key in SVG) {
      if (SVG.hasOwnProperty(key)) {
        var svgData = DesignRoot.fabric_function.getObjectById(key);

        console.log('svgData', svgData);

        if (svgData !== null) {
          if (SVG[key].fillType === 'COLOR') {
            DesignRoot.change_color.changeColorPickerSelectedSVGELM(
              SVG[key].color,
              svgData
            );
          } else if (SVG[key].fillType === 'AOP') {
            if (SVG[key].aopSrc.indexOf('s3-') !== -1) {
              DesignRoot._S3_AOP_SRC[key] = SVG[key].aopSrc;

              DesignRoot.AopStep.aopList.push({
                file_name: SVG[key].aopSrc,
                file_size: null,
                file_type: null,
                FILE_DATA: [],
                base64: SVG[key].aopSrc
              });

              DesignRoot.AopStep.setState({ render: true });
            }

            DesignRoot.change_color.changeAOPSelectedSVGELM(
              SVG[key].aopSrc,
              svgData,
              SVG[key].transform
            );
          }
        }
      }
    } // END FOR
  } catch (e) {
    // statements
    console.error('svg_control.addSTACK.error', e);
  }
}

export function addSVG_Border(object, canvas) {
  try {

    var strokeWidth = 0.1;
    if ( DesignRoot._APP_TYPE === '2d-3d' ) {

      strokeWidth = 1;
      svgHover(object);
    }

    var objectBorder = window.fabric.util.object.clone(object);
    objectBorder.set({
      id: objectBorder.id + '_BORDER',
      _OBJECT_TYPE: 'SVG_BORDER',
      fill: 'transparent',
      stroke: '#b8b8b8',
      evented: false,
      strokeWidth: strokeWidth,
      excludeFromExport: false
    });
    canvas.add(objectBorder);
  } catch (e) {
    console.error('svg_control.addSVG_Border.error', e);
  }
}

export function svgHover(object) {

  try {

    if ( DesignRoot._APP_TYPE !== '2d-3d' ) { return; }

    object.on({
      'mouseover': function(evt) {
        // console.log('mouseover', evt);

        if ( DesignRoot._ACTIVE_TEMPLATE_ID !== 'BLANK' ) { return; }

        var target = evt.target;

        if ( target && target._OBJECT_TYPE !== undefined && target._OBJECT_TYPE === 'SVG' ) {

          var borderObject = DesignRoot.fabric_function.getObjectById(target.id+'_BORDER');
          borderObject.set({
            stroke: '#00f',
            strokeWidth: 5
          });

          DesignRoot.canvas.requestRenderAll();

        }

      }
    });

    object.on({
      'mouseout': function(evt) {
        // console.log('mouseout', evt);

        if ( DesignRoot._ACTIVE_TEMPLATE_ID !== 'BLANK' ) { return; }

        var target = evt.target;

        if ( target && target._OBJECT_TYPE !== undefined && target._OBJECT_TYPE === 'SVG' ) {

          var borderObject = DesignRoot.fabric_function.getObjectById(target.id+'_BORDER');
          borderObject.set({
            stroke: '#b8b8b8',
            strokeWidth: 1
          });

          DesignRoot.canvas.requestRenderAll();

        }

      }
    });

  } catch (e) {
    console.error('svg_control.svgHover.error', e);
  }
  
}

export function svg_border_default_set() {

  try {

    if ( DesignRoot._APP_TYPE !== '2d-3d' || DesignRoot._ACTIVE_TEMPLATE_ID !== 'BLANK' ) { return; }

    var allSvgBorder = DesignRoot.fabric_function.getObjectByType('SVG_BORDER');

    var checkRender = false;
    for (var i = 0; i < allSvgBorder.length; i++) {
      

      if ( allSvgBorder[i].strokeWidth !== 1 ) {

        checkRender = true;

        allSvgBorder[i].set({
          stroke: '#b8b8b8',
          strokeWidth: 1
        });
      }

    }

    if ( checkRender === true ) {

      DesignRoot.canvas.requestRenderAll();
    }


  } catch (e) {
    console.error('svg_control.svg_border_default_set.error', e);
  }
}