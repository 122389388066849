import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Select from '../../../common/Select';
import _cloneDeep from 'lodash/cloneDeep';

import _get from 'lodash/get';
const omitDeep = require('omit-deep');
const styles = {
  root: {}
};
const DiscountRuleCondition = ({
  classes,
  discountRule,
  discountRuleIndex,
  discount,
  discountId,
  updateDiscountRule
}) => {
  return (
    <Grid item xs={12}>
      <Select
        label="Rule condition"
        className={classes.select}
        value={discountRule ? discountRule.discountRuleCondition : ''}
        onChange={e => {
          updateDiscountRule({
            optimisticResponse: {
              __typename: 'Mutation',
              updateDiscountRule: {
                ...discountRule,
                discountRuleCondition: e.target.value
              }
            },
            variables: {
              input: {
                discountRuleCondition: e.target.value
              }
            }
          });
        }}
      >
        <option value={''}>Select a condition</option>
        {discountRule &&
        (discountRule.discountRuleObjectType === 'CUSTOMER' ||
          discountRule.discountRuleObjectType === 'COLLECTION' ||
          discountRule.discountRuleObjectType === 'PRODUCTPACKAGE' ||
          discountRule.discountRuleObjectType === 'PRODUCT_TYPE' ||
          discountRule.discountRuleObjectType === 'PRODUCT' ||
          discountRule.discountRuleObjectType === 'SALESCHANNEL') ? (
          <option value={'IN'}>includes</option>
        ) : null}
        {discountRule &&
        (discountRule.discountRuleObjectType === 'CART_VALUE' ||
          discountRule.discountRuleObjectType === 'CART_ITEM_QUANTITY') ? (
          <option value={'GT'}>greater than</option>
        ) : null}
        {discountRule &&
        (discountRule.discountRuleObjectType === 'CART_VALUE' ||
          discountRule.discountRuleObjectType === 'CART_ITEM_QUANTITY') ? (
          <option value={'LT'}>lower than</option>
        ) : null}
      </Select>
    </Grid>
  );
};
DiscountRuleCondition.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DiscountRuleCondition);
