import React from 'react'

interface Props {
  designTemplate: any; 
  latestRevisionData: any;
}

const ColorZones: React.FunctionComponent<Props> = props => {

  const { designTemplate, latestRevisionData } = props

  const checkForCustom = (color: any) => {
    const customData = latestRevisionData && latestRevisionData.zoneColors
      ? latestRevisionData.zoneColors.find((z: any) => z.zoneId === color.id)
      : null
    if (customData) {
      customData.name = color.name
      customData.id = customData.zoneId
      return customData
    } else {
      color.colorHex = color.customColorHex
      return color
    }
  }

  return (
    <>
      <h2>Color Zones</h2>
      <table className="table">
        <thead>
          <tr style={{ fontWeight: 'bold' }}>
            <th>Name</th>
            <th>Color Name</th>
            <th>Color Code</th>
            <th>Color</th>
          </tr>
        </thead>
        <tbody>
        {designTemplate
          ? designTemplate.colors.map((color: any, key: number) => {
              color = checkForCustom(color)
              return (
                
                  <tr key={`color-zone-${key}`} style={{ textAlign: 'center' }} data-testid="color-zone">
                    <td>{color.name}</td>
                    <td>{color.color.name}</td>
                    <td>{(color.color.hex) ? color.color.hex : color.colorHex }</td>
                    <td style={{backgroundColor: (color.color.hex) ? color.color.hex : color.colorHex }}></td>
                  </tr>
                
              )
            })
          : null}
        </tbody>
      </table>
    </>
  )
}

export default ColorZones
