import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Layer from '../../../beCreative/step/Layer'
import MainTemplate from '../../../beCreative/MainTemplate'

class DesignRoom extends Component {
  findGetParameter(parameterName) {
    try {
      var result = null,
        tmp = []
      if (typeof window !== `undefined`) {
        var items = window.location.search.substr(1).split('&')
        for (var index = 0; index < items.length; index++) {
          tmp = items[index].split('=')
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
        }
      }
      console.log('parameterName', parameterName, result)

      return result
    } catch (error) {
      return null
    }
  }

  render() {
    return (
      <Grid>
        <MainTemplate
          proID={this.findGetParameter('p')}
          templateID={this.findGetParameter('t')}
          loadID={this.findGetParameter('lid')}
          shopId={this.findGetParameter('s')}
          productDesignId={this.findGetParameter('pd')}
          organisationId={this.findGetParameter('o')}
          ownedByOrganisationId={this.findGetParameter('o')}
        />
      </Grid>
    )
  }
}
export default DesignRoom
