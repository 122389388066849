import { UPLOAD_FILE } from '../../../gql/mutations'
import client from '../../../gql/apollo-config'
import clientAdmin from '../../admin/gql/admin-apollo-config'

const axios = require('axios')

let DesignRoot = null

export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot
}

export function renderComponent(type) {
  try {
    // statements

    if (type === 'DesignRoot') {
      DesignRoot.setState({ render: true }) // RENDER FOR UPDATE LAYER CONTROL
    } else if (type === 'CONTROL_TOOLS') {
      DesignRoot['LogoStep'].setState({ render: true })
      DesignRoot['TextStep'].setState({ render: true })

      if (
        DesignRoot['MenuStep'] &&
        DesignRoot._ACTIVE_PERSONALIZATION === true
      ) {
        DesignRoot.setState({ render: true })
      }

      if (DesignRoot['PatternStep']) {
        DesignRoot['PatternStep'].setState({ render: true })
      }
      if (DesignRoot['LayerControl']) {
        DesignRoot['LayerControl'].setState({ render: true })
      }
    } else {
      if (DesignRoot[type]) {
        DesignRoot[type].setState({ render: true })
      }
    }
  } catch (e) {
    // statements
    console.error('tools.renderComponent.error', e)
  }
}

export function uploadFile(option) {
  try {
    // statements

    var file_upload = document.getElementById('file-upload')

    if (file_upload.files[0]) {
      var DATA = file_upload.files[0]
    } else {
      alert('PLEASE SELECT FILE')
      return
    }

    if (option === 'EXTRACT_COLOR') {
      getBase64(DATA).then(data =>
        DesignRoot.canvas_coloring.extractColor(data)
      )
    }
  } catch (e) {
    // statements
    console.error('tools.uploadFile.error', e)
  }
}

export async function uploadFileWithPresignedUrl(
  myFile,
  fileName,
  ownedByOrganisationId,
  type,
  isAdmin
) {
  const mutationOptions = {
    mutation: UPLOAD_FILE,
    variables: {
      type,
      fileName: fileName,
      prefixVariables: ['ownedByOrganisationId'],
      input: {
        ownedByOrganisationId
      }
    }
  }
  const signedUrlResult = isAdmin
    ? await clientAdmin.mutate(mutationOptions)
    : await client.mutate(mutationOptions)

  const signedUrl = signedUrlResult.data.uploadFile.url
  const options = {
    headers: {
      'Content-Type': signedUrlResult.data.uploadFile.contentType
    }
  }
  if (signedUrlResult.data.uploadFile.acl) {
    options.headers['x-amz-acl'] = signedUrlResult.data.uploadFile.acl
  }
  if (signedUrlResult.data.uploadFile.tagging) {
    options.headers['x-amz-tagging'] = signedUrlResult.data.uploadFile.tagging
  }
  const uploadResult = await axios.put(signedUrl, myFile, options)

  // AI files wait 10 seconds to convert them > replace this with a subscription rather than guessing the value
  if (signedUrl.indexOf('.ai')) {
    await new Promise(resolve => setTimeout(resolve, 10000))
  }

  return signedUrl.substring(0, signedUrl.indexOf('?'))
}

export function getBase64(file) {
  try {
    // statements

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  } catch (e) {
    // statements
    console.error('tools.getBase64.error', e)
  }
}

export function uuidv4() {
  // TOBIAS
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

export function getQueryStringValue(key) {
  try {
    if (window !== `undefined`) {
      return decodeURIComponent(
        window.location.search.replace(
          new RegExp(
            '^(?:.*[&\\?]' +
              encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') +
              '(?:\\=([^&]*))?)?.*$',
            'i'
          ),
          '$1'
        )
      )
    }
  } catch (error) {
    return null
  }
}

export function _wrapTimeToSrc(src) {
  // check if https and NOT a S3 signed url - wrap with a timestamp to fix CORS

  try {
    console.log('_wrapTimeToSrc', src)

    if (
      src &&
      src.indexOf('https') !== -1
      // &&
      // src.indexOf('X-Amz-Expires') === -1 &&
      // src.indexOf('AWSAccessKeyId') === -1
    ) {
      return src + '&datetime=' + new Date().getTime()
    } else {
      return src
    }
  } catch (e) {
    console.error('tools._wrapTimeToSrc.error', e)
    return src
  }
}

export function _urlToBase64(img) {

  try {
    console.log('_urlToBase64', img)

    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL;
    // return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

  } catch (e) {
    console.error('tools._urlToBase64.error', e)
  }
}