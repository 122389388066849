import React, { Component } from 'react'
import {
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import Select from '../../../common/Select'
import { Link } from 'gatsby'
import { Query, Mutation } from 'react-apollo'
import {
  ADMIN_SHOP_COLLECTIONS,
  ADMIN_GET_PRODUCT
} from '../../gql/admin-queries'
import {
  ADMIN_CREATE_COLLECTION_PRODUCT,
  ADMIN_DELETE_COLLECTION_PRODUCT
} from '../../gql/admin-mutations'
import { getTextFromLanguage } from '../../../../helper/languageHelper'

class ProductCollections extends Component {
  state = {
    selectedCollectionId: null,
    loading: false
  }
  render() {
    const { product, shopId, defaultLanguage } = this.props
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography data-cy="Product-Collections-header" variant="subtitle1">
            Collections
          </Typography>
        </Grid>
        <Query query={ADMIN_SHOP_COLLECTIONS} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            var sortedCollections = null
            try {
              sortedCollections = shop.collections.edges.sort(function(a, b) {
                if (
                  getTextFromLanguage(a.node.title.text, defaultLanguage) <
                  getTextFromLanguage(b.node.title.text, defaultLanguage)
                ) {
                  return -1
                }
                if (
                  getTextFromLanguage(a.node.title.text, defaultLanguage) >
                  getTextFromLanguage(b.node.title.text, defaultLanguage)
                ) {
                  return 1
                }
                return 0
              })
            } catch (error) {
              console.error('pvo.error', error)
            }
            return (
              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                <Paper elevation={0} style={{ padding: 24 }}>
                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item>
                      <Select
                        dataCy="Product-Collections-Select"
                        onChange={e => {
                          this.setState({
                            selectedCollectionId: e.target.value
                          })
                        }}
                        value={this.state.selectedCollectionId}
                        label="Collections"
                      >
                        <option value={''}>Choose</option>
                        {sortedCollections
                          ? sortedCollections.map(pa => {
                              if (pa && pa.node && pa.node.title) {
                                return (
                                  <option value={pa.node.id}>
                                    {getTextFromLanguage(
                                      pa.node.title.text,
                                      defaultLanguage
                                    )
                                      ? getTextFromLanguage(
                                          pa.node.title.text,
                                          defaultLanguage
                                        )
                                      : getTextFromLanguage(
                                          pa.node.title.text,
                                          'fr_FR'
                                        )
                                      ? getTextFromLanguage(
                                          pa.node.title.text,
                                          'fr_FR'
                                        )
                                      : getTextFromLanguage(
                                          pa.node.title.text,
                                          'nl_NL'
                                        )
                                      ? getTextFromLanguage(
                                          pa.node.title.text,
                                          'nl_NL'
                                        )
                                      : pa.node.name}{' '}
                                    ({pa.node.no})
                                  </option>
                                )
                              } else {
                                return null
                              }
                            })
                          : null}
                      </Select>
                    </Grid>
                    <Grid item>
                      <Mutation
                        mutation={ADMIN_CREATE_COLLECTION_PRODUCT}
                        update={(
                          cache,
                          { data: { createCollectionProduct } }
                        ) => {
                          if (createCollectionProduct) {
                            const data = cache.readQuery({
                              query: ADMIN_GET_PRODUCT,
                              variables: { id: product.id }
                            })
                            const newCollection = shop.collections.edges.find(
                              c => {
                                return (
                                  c.node.id === this.state.selectedCollectionId
                                )
                              }
                            )
                            // console.log('newCollection', newCollection);
                            data.product.collections.unshift({
                              collection: {
                                ...newCollection.node
                              },
                              __typename: 'ProductCollection'
                            })
                            cache.writeQuery({
                              query: ADMIN_GET_PRODUCT,
                              variables: { id: product.id },
                              data
                            })
                          }
                        }}
                      >
                        {(
                          createCollectionProduct,
                          { data, loading, error }
                        ) => {
                          return (
                            <Button
                              data-cy="Product-Collections-Assign-btn"
                              onClick={async () => {
                                await createCollectionProduct({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    createCollectionProduct: {
                                      collectionId: this.state
                                        .selectedCollectionId,
                                      product: {
                                        ...product
                                      },
                                      productId: product.id,
                                      __typename: 'CollectionProduct'
                                    }
                                  },
                                  variables: {
                                    shopId: shopId,
                                    productId: product.id,
                                    collectionId: this.state
                                      .selectedCollectionId
                                  }
                                })
                              }}
                              variant="contained"
                              color="primary"
                              style={{ fontWeight: 400 }}
                            >
                              Assign collection
                            </Button>
                          )
                        }}
                      </Mutation>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: 8 }}>
                    {this.state.loading === true ? (
                      <CircularProgress />
                    ) : product.collections ? (
                      product.collections.map(pc => (
                        <Grid
                          container
                          alignItems="center"
                          style={{ padding: '8px 0px' }}
                        >
                          <Grid
                            item
                            data-cy={
                              'Product-Collection-Item-' + pc.collection.no
                            }
                            style={{
                              border: '1px solid #B23566',
                              height: 36,
                              borderRadius: 3,
                              display: 'flex',
                              alignContent: 'center',
                              padding: 8,
                              marginRight: 8
                            }}
                          >
                            {pc.collection.title
                              ? getTextFromLanguage(
                                  pc.collection.title.text,
                                  defaultLanguage
                                )
                              : 'N/A'}
                            &nbsp;({pc.collection.no})
                          </Grid>
                          <Grid item>
                            <Mutation
                              mutation={ADMIN_DELETE_COLLECTION_PRODUCT}
                              update={(
                                cache,
                                { data: { deleteCollectionProduct } }
                              ) => {
                                if (deleteCollectionProduct) {
                                  const data = cache.readQuery({
                                    query: ADMIN_GET_PRODUCT,
                                    variables: { id: product.id }
                                  })
                                  data.product.collections = data.product.collections.filter(
                                    c => c.collection.id !== pc.collection.id
                                  )
                                  cache.writeQuery({
                                    query: ADMIN_GET_PRODUCT,
                                    variables: { id: product.id },
                                    data
                                  })
                                }
                              }}
                            >
                              {(
                                deleteCollectionProduct,
                                { data, loading, error }
                              ) => {
                                return (
                                  <Button
                                    data-cy={
                                      'Product-Collection-Delete-btn-' +
                                      pc.collection.no
                                    }
                                    onClick={async () => {
                                      if (pc) {
                                        await deleteCollectionProduct({
                                          optimisticResponse: {
                                            __typename: 'Mutation',
                                            deleteCollectionProduct: true
                                          },
                                          variables: {
                                            productId: product.id,
                                            collectionId: pc.collection.id
                                          }
                                        })
                                      }
                                    }}
                                    color="primary"
                                    variant="contained"
                                  >
                                    <DeleteIcon style={{ fontSize: '20' }} />
                                  </Button>
                                )
                              }}
                            </Mutation>
                          </Grid>
                        </Grid>
                      ))
                    ) : null}
                  </Grid>
                </Paper>
              </Grid>
            )
          }}
        </Query>
      </Grid>
    )
  }
}

export default ProductCollections
