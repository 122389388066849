import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MuiTextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

const styles = {};

class TextField extends React.Component {
  render() {
    const {
      classes,
      placeholder,
      label,
      value,
      onBlur,
      onChange,
      variant,
      rowsMax,
      multiline,
      width,
      border,
      dataCy,
      autoComplete
    } = this.props;

    return (
      <div data-cy={dataCy}>
        <InputLabel>{label}</InputLabel>
        <MuiTextField
          inputProps={{
            autoComplete: autoComplete
          }}
          value={value ? value : ''}
          placeholder={placeholder ? placeholder : ''}
          onChange={onChange ? onChange : ''}
          onBlur={onBlur}
          variant={variant}
          rowsMax={rowsMax}
          multiline={multiline}
        />
      </div>
    );
  }
}

TextField.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TextField);
