import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { Slider } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Select from '../../common/Select';
import Input from '../../common/Input';
import { Delete, Add, KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';

import * as template_control from '../template_control';
import * as tools from '../tools/tools';
import LayerControl from './LayerControl';
import { appendToFilename } from '../tools/Functions/save';

import blank_img from '../TEMPLATE_ASSET/no-image.png';
import { Localization } from '../../../lang/localization';
import shortid from 'shortid';

import { API_STAGE } from '~/brikl-config';

let SHOP_ID = tools.getQueryStringValue('s');

const styles = theme => ({
  colorSwatch: {
    background: '#BBBBBB',
    width: 27,
    height: 27,
    border: '3px solid #BBBBBB',
    borderRadius: '100%',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer'
    }
  },
  logoTumbnail: {
    background: '#BBBBBB',
    width: 46,
    height: 46,
    border: '3px solid #BBBBBB',
    borderRadius: '6px',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer'
    }
  },
  slider: {
    padding: '8px 0px',
    width: '100%'
  },
  track: {
    backgroundColor: '#000'
  },
  thumb: {
    backgroundColor: '#000'
  },
  rail:{
    backgroundColor: '#e5e5e5'
  }
});

class LogoStep extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      rotate: 0,
      scale: 1,
      selectedId: null,
      preview_upload: null
    };
    this.state = this.initState;

    if ( !SHOP_ID ) {

      SHOP_ID = this.props.DesignRoot.SHOP_ID;
    }
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot;
    DesignRoot.LogoStep = this;
    DesignRoot.setState({ update: true });
    // PROVIDE COMPONENT TO MAIN
  }

  uploadLogo(domId, type, option, boundingData) {
    try {
      // statements

      var DesignRoot = this.props.DesignRoot;

      var file_upload = document.getElementById(domId);
      if (file_upload.files[0]) {
        var FILE_DATA = file_upload.files[0];
      } else {
        alert('Upload your logo');
        return;
      }

      var file_name = FILE_DATA.name;
      const gen_filename = appendToFilename(
        file_name,
        '-' + shortid.generate()
      );
      var file_size = FILE_DATA.size;
      var file_type = FILE_DATA.type;

      const self = this;
      tools.getBase64(FILE_DATA).then(async data => {
        var uuid = tools.uuidv4();

        // ADD TO ZONE LOGIC

        var isBouding = false;
        var canvasData = null;
        let boundingUserData = {};
        var left = 0;
        var top = 0;
        if (option && option === 'ADD_TO_ZONE' && type === 'LOGO') {


          // CHECK MAXIMUM PLACEHOLDER HERE

          var maxItem = DesignRoot.fabric_function.get_bounding_limit_item_by_group(
            'LOGO',
            boundingData._ZONE_GROUP
          );

          var numberOfItem = DesignRoot.fabric_function.get_bounding_current_item_by_group(
            'LOGO',
            boundingData._ZONE_GROUP
          );

          if ( numberOfItem >= maxItem ) {

            console.log(boundingData);
            alert('Please remove current logo before add new once.');
            return;
          }

          // CHECK MAXIMUM PLACEHOLDER HERE


          left = boundingData.left;
          top = boundingData.top;
          isBouding = true;
          canvasData = {
            left: left,
            top: top,
            scaleX: boundingData.scaleX,
            scaleY: boundingData.scaleY,
            width: boundingData.width,
            height: boundingData.height
            // centerPoint: boundingData.getCenterPoint(),
            // boundingId: boundingData.id,
            // boundingGroup: boundingData._ZONE_GROUP
          };
          boundingUserData = {
            centerPoint: boundingData.getCenterPoint(),
            boundingId: boundingData.id,
            boundingGroup: boundingData._ZONE_GROUP
          };
        }

        // ADD TO ZONE LOGIC

        if (DesignRoot.localData === true) {
          var imageUrl = data;
        } else {
          DesignRoot.Loading.activeLoading(true);

          tools.uploadFileWithPresignedUrl(
            FILE_DATA,
            gen_filename,
            DesignRoot.ownedByOrganisationId,
            'PRODUCTDESIGN_LOGO',
            DesignRoot._APP_TYPE === '2d-3d'
          );

          DesignRoot.Loading.activeLoading(false);
        }

        DesignRoot.fabric_function.addItem(
          uuid,
          gen_filename,
          data,
          left,
          top,
          {
            ...boundingUserData,
            filename: gen_filename,
            base64: data,
            s3Url: imageUrl
          },
          type,
          null,
          canvasData,
          isBouding
        );
        document.getElementById(domId).value = null;
        self.setState({ preview_upload: null });
      });
    } catch (e) {
      // statements
      console.error('LogoStep.uploadLogo.error', e);
    }
  }

  previewSelected(id) {
    try {
      // statements
      const DesignRoot = this.props.DesignRoot;
      const item = DesignRoot.fabric_function.getObjectById(id);

      if (item) {
        return item._USER_DATA.base64;
      }
    } catch (e) {
      // statements
      console.error('LogoStep.previewSelected.error', e);
    }
  }

  previewUpload() {
    try {
      // statements

      console.log('previewUpload');
      var DesignRoot = this.props.DesignRoot;

      var file_upload = document.getElementById('file-upload');
      if (file_upload.files[0]) {
        var FILE_DATA = file_upload.files[0];
      } else {
        alert('Upload your logo');
        return;
      }

      var file_name = FILE_DATA.name;
      var file_size = FILE_DATA.size;
      var file_type = FILE_DATA.type;

      const self = this;
      tools.getBase64(FILE_DATA).then(data => {
        var uuid = tools.uuidv4();
        self.setState({ preview_upload: data });
      });
    } catch (e) {
      // statements
      console.error('LogoStep.previewUpload.error', e);
    }
  }

  replaceDefaultLogo(src) {
    try {
      var DesignRoot = this.props.DesignRoot;
      var activeObject = DesignRoot.canvas.getActiveObject();

      console.log('replaceDefaultLogo', activeObject);

      if (
        activeObject &&
        activeObject._OBJECT_TYPE === 'LOGO' &&
        activeObject._DEFAULT_ITEM &&
        activeObject._DEFAULT_ITEM === true
      ) {
        var defaultId = activeObject.id;
        // DesignRoot.fabric_function.removeItem(defaultId);

        var item = DesignRoot.fabric_function.getObjectById(activeObject.id);
        var image = new Image();
        image.crossOrigin = 'anonymous';
        image.onload = function() {
          if (image.width > image.height) {
            var newScale = item._KEEP_SCALE_W / image.width;
          } else {
            var newScale = item._KEEP_SCALE_H / image.height;
          }

          // DesignRoot.canvas.discardActiveObject();
          item._element = image;

          item.set({
            width: image.width,
            height: image.height,
            scaleX: newScale,
            scaleY: newScale
          });

          item.setCoords();

          item._USER_DATA.s3Url = image.src;
          item._USER_DATA.base64 = image.src;

          DesignRoot.canvas.renderAll();
          DesignRoot.fabric_function.updateDimension(defaultId, 'byID');
          DesignRoot.tools.renderComponent('LogoStep');
        };
        image.src = src + '?t=' + new Date().getDay();

        // activeObject._element.src = src + '?t=' + new Date().getDay();
        // DesignRoot.canvas.renderAll();
      }
    } catch (e) {
      // statements
      console.error('LogoStep.replaceDefaultLogo.error', e);
    }
  }

  SLTDefaultLogo() {
    try {
      

      // GEN ZONE LIST FROM SVG

      var DesignRoot = this.props.DesignRoot;

      if (DesignRoot._ACTIVE_PERSONALIZATION === false) {
        return null;
      }

      var boundingSET = DesignRoot.fabric_function.get_bounding_by_type('LOGO');

      console.log('_SVG_BOUNDING_SET LOGO', boundingSET);

      var zoneList = [];
      var lastZone = null;

      for (var i = 0; i < boundingSET.length; i++) {
        var _BOUNDING_TYPE = boundingSET[i]._BOUNDING_TYPE;
        var _ZONE_NUMBER = boundingSET[i]._ZONE_NUMBER;
        var _ZONE_LIMIT_ITEM = boundingSET[i]._ZONE_LIMIT_ITEM;
        var _ZONE_GROUP = boundingSET[i]._ZONE_GROUP;
        var _ZONE_ANGLE = boundingSET[i]._ZONE_ANGLE
          ? boundingSET[i]._ZONE_ANGLE
          : 0;
        var _ZONE_NAME = boundingSET[i]._ZONE_NAME;

        if (_ZONE_GROUP !== lastZone) {
          var maxItem = DesignRoot.fabric_function.get_bounding_limit_item_by_group(
            'LOGO',
            _ZONE_GROUP
          );

          var numberOfItem = DesignRoot.fabric_function.get_bounding_current_item_by_group(
            'LOGO',
            _ZONE_GROUP
          );

          var addItem = (
            <Button
              variant="contained"
              style={{
                backgroundColor: '#171717',
                color: '#FFFFFF',
                marginTop: 10,
                marginBottom: 10
              }}
              component="span"
              onClick={this.uploadLogo.bind(
                this,
                'file-upload',
                'LOGO',
                'ADD_TO_ZONE',
                boundingSET[i]
              )}
            >
              PLACE LOGO TO {_ZONE_NAME}
            </Button>
          );

          if (numberOfItem >= maxItem) {
            addItem = (
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#171717',
                  color: '#FFFFFF',
                  marginTop: 10,
                  marginBottom: 10
                }}
                component="span"
              >
                ALREADY ADDED TO {_ZONE_NAME}
              </Button>
            );
          }

          var toggleZone = null;

          if (true) {
            toggleZone = (
              <Button
                variant="outlined"
                style={{
                  marginTop: 10,
                  marginBottom: 10
                }}
                component="span"
                onClick={DesignRoot.fabric_function.toggle_bounding_stroke.bind(
                  DesignRoot,
                  'LOGO',
                  _ZONE_GROUP
                )}
              >
                TOGGLE ZONE
              </Button>
            );
          }

          zoneList.push(
            <Grid container justify="center" style={{ margin: 0 }}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {_ZONE_NAME} (Max Item : {maxItem} ({numberOfItem}) )
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {addItem}:{toggleZone}
              </Grid>
            </Grid>
          );

          lastZone = _ZONE_GROUP;
        }
      }

      // GEN ZONE LIST FROM SVG

      return (
        <Grid container justify="center" style={{ margin: 0 }}>
          <Divider
            light
            style={{ width: 180, marginTop: 40, marginBottom: 40 }}
          />
          {zoneList}
        </Grid>
      );
    } catch (e) {
      // statements
      console.error('LogoStep.SLTDefaultLogo.error', e);
    }
  }

  handleTransformChange(type, evt, value) {

    try {

      const { classes, DesignRoot, logoConfiguration, langCode } = this.props;

      // console.log('handleTransformChange', type, value);

      var valueNumber = this.state[type];

      if ( value === '+' ) {
        if ( type === 'rotate' ) { valueNumber = parseInt(valueNumber) + 1; }
        else if ( type === 'scale' ) { valueNumber = parseFloat(valueNumber) + 0.01; }
      }
      else if ( value === '-' ) {
        if ( type === 'rotate' ) { valueNumber = parseInt(valueNumber) - 1; }
        else if ( type === 'scale' ) { valueNumber = parseFloat(valueNumber) - 0.01; }
      }
      else {

        valueNumber = value;
      }

      if ( type === 'rotate' ) {

        valueNumber = valueNumber.toFixed(0);
        valueNumber = parseInt(valueNumber);
      }
      else if ( type === 'scale' ) {

        valueNumber = valueNumber.toFixed(2);
        valueNumber = parseFloat(valueNumber);
      }

      var activeObject = DesignRoot.canvas.getActiveObject();

      if (
        activeObject &&
        activeObject._OBJECT_TYPE === 'LOGO'
      ) {

        var item = DesignRoot.fabric_function.getObjectById(activeObject.id);

        if ( type === 'rotate' ) {

          item.set({angle: valueNumber});

          item.canvas.requestRenderAll();
          DesignRoot.fabric_function.mutate_object_modify(item);
          DesignRoot.fabric_function.updateDimension(item.id, 'byID');
        }
        else if ( type === 'scale' ) {

          item.set({
            scaleX: valueNumber,
            scaleY: valueNumber
          });

          item.canvas.requestRenderAll();
          DesignRoot.fabric_function.mutate_object_modify(item);
          DesignRoot.fabric_function.updateDimension(item.id, 'byID');
        }

      }

      this.setState({[type]: valueNumber});

    } catch (e) {

      console.error('LogoStep.handleTransformChange.error', e);
    }

  }

  render() {
    const { classes, DesignRoot, logoConfiguration, langCode } = this.props;
    const { rotate, scale, preview_upload } = this.state;

    var approxWidth = 0;
    var approxHeight = 0;

    if ( DesignRoot.Dimension !== null ) {

      approxWidth = DesignRoot.Dimension.state.approxWidth ? DesignRoot.Dimension.state.approxWidth : 0;
      approxHeight = DesignRoot.Dimension.state.approxHeight ? DesignRoot.Dimension.state.approxHeight : 0;
    }

    

    return (
      <Grid
        container
        style={{
          padding: 24,
          overflowY: 'auto',
          height: '100%',
          textAlign: 'center'
        }}
      >
        <Grid item xs={12}>
          <Grid container style={{ margin: 0 }}>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ textAlign: 'center' }}>
                {Localization.get('beCreative_upload_label')}
              </Typography>
            </Grid>
          </Grid>

          <Grid item style={{ flex: 1 }}>
            <input
              type="file"
              id="file-upload"
              style={{ display: 'none' }}
              onChange={this.previewUpload.bind(this)}
            />
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#171717',
                  color: '#FFFFFF',
                  marginTop: 20
                }}
                component="span"
              >
                {Localization.get('beCreative_upload_button')}
              </Button>
            </label>
          </Grid>
        
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            spacing={1}
            style={{ margin: 0, marginTop: 16 }}
          >
            <Typography id="label" style={{marginBottom: 16}}>Rotate {rotate}&deg;</Typography>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={10}>
              <Slider
                value={rotate}
                aria-labelledby="label"
                step={1}
                min={-360}
                max={360}
                onChange={this.handleTransformChange.bind(this, 'rotate')}
                classes={{
                  track: classes.track,
                  thumb: classes.thumb,
                  rail: classes.rail
                }}
              />
              </Grid>
              <Grid item xs={2}>
                <Grid container direction={'column'}>
                  <Button style={{ marginBottom: 2, minWidth: 0 }} variant="outlined" onClick={(e)=> { this.handleTransformChange('rotate', e, '+')}}><KeyboardArrowUp style={{ fontSize: 14 }} /></Button>
                  <Button style={{ marginBottom: 2, minWidth: 0 }} variant="outlined" onClick={(e)=> { this.handleTransformChange('rotate', e, '-')}}><KeyboardArrowDown style={{ fontSize: 14 }} /></Button>
                  </Grid>
              </Grid>
          </Grid>
          </Grid>

          <Grid
            container
            justify="flex-start"
            alignItems="center"
            spacing={1}
            style={{ margin: 0, marginTop: 16 }}
          >
            <Typography id="label" style={{marginBottom: 8}}>Resize {approxWidth} x {approxHeight} CM</Typography>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={10}>
                <Slider
                value={scale}
                aria-labelledby="label"
                step={0.01}
                min={0.01}
                max={5}
                onChange={this.handleTransformChange.bind(this, 'scale')}
                classes={{
                  track: classes.track,
                  thumb: classes.thumb,
                  rail: classes.rail
                }}
              />
              </Grid>
              <Grid item xs={2}>
                <Grid container direction={'column'}>
                  <Button style={{ marginBottom: 2, minWidth: 0 }} variant="outlined" onClick={(e)=> { this.handleTransformChange('scale', e, '+')}}><KeyboardArrowUp style={{ fontSize: 14 }} /></Button>
                  <Button style={{ marginBottom: 2, minWidth: 0 }} variant="outlined" onClick={(e)=> { this.handleTransformChange('scale', e, '-')}}><KeyboardArrowDown style={{ fontSize: 14 }} /></Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justify="center" style={{ margin: 0 }}>
            <Divider
              light
              style={{ width: 180, marginTop: 40, marginBottom: 40 }}
            />

            <Grid item xs={12}>
              <Typography variant="h5" style={{marginBottom: 24}}>
                {Localization.get('beCreative_logo_list')}
              </Typography>
            </Grid>
          </Grid>

          

          
            {preview_upload !== null && (
              <Grid
              container
              style={{ margin: 0, marginTop: 4, marginBottom: 32}}
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={4} style={{
                padding: '8px',
                height: 66,
                border: '1px solid #E5E5E5',
                borderRadius: '4px',
                }}>
                <img
                  src={preview_upload}
                  alt=""
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%'
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <Grid
                    container
                    style={{ margin: 0, marginTop: 4 }}
                    alignItems="center"
                    justify='center'
                  >
                    
                    {DesignRoot._ACTIVE_PERSONALIZATION === false && (
            
                        <Button
                          style={{
                            backgroundColor: '#171717',
                            color: '#FFFFFF',
                          }}
                          variant="outlined"
                          onClick={this.uploadLogo.bind(this, 'file-upload', 'LOGO')}
                        >
                          {Localization.get('beCreative_place_logo')}
                        </Button>
                
                    )}
                  </Grid>
              </Grid>
            </Grid>
            )}
       

        
            <LayerControl
              type="LOGO"
              logoConfiguration={logoConfiguration}
              DesignRoot={DesignRoot}
              langCode={langCode}
            />
          

          </Grid>

          {this.SLTDefaultLogo()}
          
      </Grid>
    );
  }
}

LogoStep.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LogoStep);
