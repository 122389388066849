import React from 'react';
import AdminHome from './AdminHome';

const AdminMain = () => {
  return process.env.GATSBY_BRIKL_IS_DASHBOARD === 'yes' ? (
    <AdminHome />
  ) : (
    <div>Empty</div>
  );
};
export default AdminMain;
