import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CustomTypography from '../../../../../store/content/typography/Typography'
import { IMGIX_URL } from '../../../../../../../brikl-config'
import ButtonStyled from '../../Button'
import { addPx } from '../../utils'
import Imgix from 'react-imgix'

const styles = theme => ({
  root: {
    flex: 1,
    minHeight: '250px',
    maxHeight: 'calc(100vh - 50px)',
    [theme.breakpoints.down('xs')]: {
      minHeight: '450px',
      maxHeight: 'calc(100vh - 50px)'
    }
  },
  banner: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
})
class MainBanner extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.props.state
  }

  render() {
    const {
      classes,
      modules,
      imageUrl,
      title,
      subTitle,
      buttonText,
      verticalAlign,
      horizontalAlign,
      titleFontFamily,
      subTitleFontFamily,
      buttonFontFamily,
      titleFontSize,
      subTitleFontSize,
      titleColor,
      titleBackgroundColor,
      subTitleColor,
      buttonTextColor,
      buttonPadding,
      buttonBackgroundColor,
      buttonBorder,
      buttonBorderRadius,
      containerPadding,
      buttonFontSize,
      detail,
      disabled,
      height,
      copyrightContent,
      copyrightFontColor,
      copyrightHorizontalAlignment,
      copyrightVerticalAlignment,
      copyrightFontFamily,
      buttonUrl,
      urlType,
      textAlign,
      shopId,
      objectFit,
      urlPrefix,
      collection,
      page,
      langCode
    } = this.props

    // TODO use proper width here depending on parent container?
    let imgixParams = `?w=2000&auto=compress&fit=crop&txt=${encodeURIComponent(
      copyrightContent ? copyrightContent : ''
    )}&txtlead=2&txtpad=15&txtfont=${encodeURIComponent(
      copyrightFontFamily ? copyrightFontFamily : ''
    )}&txtclr=${encodeURIComponent(
      copyrightFontColor ? copyrightFontColor : ''
    )}&txtalign=${encodeURIComponent(
      copyrightVerticalAlignment ? copyrightVerticalAlignment.toLowerCase() : ''
    )},${encodeURIComponent(
      copyrightHorizontalAlignment
        ? copyrightHorizontalAlignment.toLowerCase()
        : ''
    )}`

    let slug
    try {
      if (collection && collection.slugs) {
        slug = collection.slugs.find(s => s.langCode === langCode).content
      }
      if (page && page.slugs) {
        slug = page.slugs.find(s => s.langCode === langCode).content
      }
    } catch (error) {}

    return (
      <div
        data-cy="banner-content"
        className={classes.root}
        style={{ height: height ? height : '450px' }}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ position: 'relative', height: '100%' }}
        >
          <Imgix
            src={`${IMGIX_URL}/${shopId}/media/${imageUrl}${imgixParams}`}
            alt="Banner"
            style={{ objectFit: objectFit ? objectFit : 'cover' }}
            className={classes.banner}
          />
          <Grid
            container
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              zIndex: '2',
              padding: containerPadding
            }}
          >
            <Grid
              container
              style={{ maxWidth: 1000, margin: 'auto', padding: 24 }}
              justify={verticalAlign}
              alignItems={
                horizontalAlign && horizontalAlign.indexOf('_')
                  ? horizontalAlign.replace('_', '-')
                  : 'center'
              }
              direction="column"
              spacing={2}
            >
              {title ? (
                <Grid item>
                  <CustomTypography
                    variant="h1"
                    content={title}
                    color={titleColor}
                    background={titleBackgroundColor}
                    fontSize={titleFontSize}
                    fontFamily={titleFontFamily}
                    align={textAlign}
                    data-cy="banner-title"
                  />
                </Grid>
              ) : null}
              {subTitle ? (
                <Grid item>
                  <CustomTypography
                    variant="body1"
                    content={subTitle}
                    color={subTitleColor}
                    background={titleBackgroundColor}
                    fontSize={subTitleFontSize}
                    fontFamily={subTitleFontFamily}
                    align={textAlign}
                    data-cy="banner-sub-title"
                  />
                </Grid>
              ) : null}
              {buttonText ? (
                <Grid item>
                  <ButtonStyled
                    background={buttonBackgroundColor}
                    border={buttonBorder}
                    color={buttonTextColor}
                    text={buttonText}
                    border={
                      detail.borderThickness ||
                      detail.borderType ||
                      detail.borderColor
                        ? detail.borderThickness.toString() +
                          'px ' +
                          detail.borderType +
                          ' ' +
                          detail.borderColor
                        : detail.buttonBorder
                    }
                    borderRadius={buttonBorderRadius}
                    padding={buttonPadding}
                    disabled={false}
                    fontSize={addPx(buttonFontSize)}
                    fontFamily={buttonFontFamily}
                    url={collection || page ? slug : buttonUrl}
                    urlPrefix={urlPrefix}
                    data-cy="banner-button"
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

MainBanner.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(MainBanner)
