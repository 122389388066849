import React, { Component } from 'react';
import { API_STAGE } from '../../../../../brikl-config';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Typography } from '@material-ui/core';

class StackLegend extends Component {
  constructor(props) {
    super(props);
    this.legendRecord = {}; // FOR RE CHECK AFTER MARKER BUILD
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot;
    DesignRoot.StackLegend = this;
    DesignRoot.setState({ update: true });
    // PROVIDE COMPONENT TO MAIN
  }

  buildLegend() {
    var DesignRoot = this.props.DesignRoot;

    var legendList = [];
    for (var i = 0; i < DesignRoot._STACK.length; i++) {
      legendList.push(
        <FormControlLabel
          style={{ marginLeft: '16px' }}
          control={
            <Checkbox
              onChange={this.handleChange.bind(this)}
              value={DesignRoot._STACK[i].stackId}
              style={{ color: DesignRoot._STACK[i].legendColor }}
            />
          }
          label={DesignRoot._STACK[i].stackId}
        />
      );
    }

    return legendList;
  }

  handleChange(e) {
    try {
      // statements

      var DesignRoot = this.props.DesignRoot;

      e.persist();
      console.log('handleChange', e);

      var id = e.target.value;
      var checked = e.target.checked;

      this.legendRecord[id] = checked;

      var stackList = DesignRoot.fabric_function.getObjectByType('STACK');

      for (var i = 0; i < stackList.length; i++) {
        if (stackList[i]._USER_DATA.size === id) {
          stackList[i].set({ visible: checked });
        }
      }

      DesignRoot.canvas.renderAll();

      // CHECK FOR TOGGLE VIEW
      var checkVisible = false;
      for (var i = 0; i < stackList.length; i++) {
        if (stackList[i].visible === true) {
          checkVisible = true;
          break;
        }
      }

      if (checkVisible === false && DesignRoot.state.hidden3D !== '') {
        DesignRoot.setState({ hidden3D: '' });
      } else if (DesignRoot.state.hidden3D !== 'hidden3D') {
        DesignRoot.setState({ hidden3D: 'hidden3D' });
      }

      // CHECK FOR TOGGLE VIEW
    } catch (e) {
      // statements
      console.error('StackLegend.handleChange.error', e);
    }
  }

  render() {
    return (
      <div className="StackLegend">
        <Typography variant="subtitle1" className="sizeLabel">
          Auto-scale sizes:{this.buildLegend()}
        </Typography>
      </div>
    );
  }
}

export default StackLegend;
