const uuidv4 = require('uuid/v4')


export var HUBSPOTFORM_CARD_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'HUBSPOT_FORM',
        
  }
}

export var CARD_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'CARD',
    thumbnailImg:
      'https://s3-ap-southeast-1.amazonaws.com/staging-brikl-shop-common-assets/pagebuilder/card_thumbnail.jpg',
    width: '100%',
    height: '300px',
    shadow: '0px 1px 3px 0px rgba(0,0,0,0.2)',
    border: '0px solid white',
    borderRadius: '4px',
    link: null,
    backgroundColor: 'white',
    titleFontSize: '18px',
    descriptionFontSize: '16px',
    buttonFontSize: '20px',
    buttonBorder: '1px solid #0a0a0a',
    titleColor: '#0a0a0a',
    titleBackgroundColor: 'none',
    descriptionColor: '#0a0a0a',
    buttonTextColor: '#0a0a0a',
    titleAlign: 'left',
    descriptionAlign: 'left',
    buttonAlign: 'left',
    callToAction: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Button'
        }
      ]
    },
    description: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'description'
        }
      ]
    },
    title: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'title'
        }
      ]
    }
  }
}

export var COLLECTION_CARD_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'COLLECTION_CARD',
    collectionIds: [],
    buttonText: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Shop The Collection'
        }
      ]
    }
  }
}

export var PAGE_CARD_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'PAGE_CARD',
    pageIds: []
  }
}

export var TEAMSTORE_CARD_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'TEAMSTORE_CARD',
    teamstoreIds: []
  }
}

export var PRODUCT_CARD_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'PRODUCT_CARD',
    productIds: [],
    buttonText: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Order Now'
        }
      ]
    }
  }
}

export var TEXT_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'TEXT',
    alignment: 'center',
    text: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Placeholder text'
        }
      ]
    },
    typoVariant: 'h1',
    color: 'black',
    bold: false,
    italic: false,
    underline: false,
    crossed: false,
    fontSize: '12px',
    fontFamily: `'Roboto', 'sans-serif'`,
    fontWeight: '100',
    verticalAlign: 'center',
    textTransform: 'capitalize'
  }
}

export var IMAGE_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'IMAGE',
    imageUrl:
      'https://s3-ap-southeast-1.amazonaws.com/staging-brikl-shop-common-assets/pagebuilder/placeholder-image.jpg',
    width: '100%',
    height: '150px',
    maxWidth: '100%',
    objectFit: 'contain',
    imageAlt: 'description'
  }
}

export var BANNER_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'BANNER',
    imageUrl:
      'https://staging-brikl-shop-common-assets.s3-ap-southeast-1.amazonaws.com/pagebuilder/placeholder-banner-IgvS86NsR.png',
    objectFit: 'contain',
    verticalAlign: 'center',
    horizontalAlign: 'center',
    title: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Title'
        }
      ]
    },
    subTitle: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Sub-title'
        }
      ]
    },
    buttonText: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Learn More'
        }
      ]
    },
    titleFontFamily: `'Roboto', 'sans-serif'`,
    subTitleFontFamily: `'Roboto', 'sans-serif'`,
    buttonFontFamily: `'Roboto', 'sans-serif'`,
    titleColor: '#000000',
    titleBackgroundColor: 'transparent',
    subTitleColor: '#000000',
    buttonTextColor: '#ffffff',
    titleFontSize: '45px',
    subTitleFontSize: '25px',
    buttonFontSize: '15px',
    buttonPadding: '10px 25px 10px 25px',
    buttonBackgroundColor: '#000000',
    buttonBorder: '1px solid #000000',
    buttonBorderRadius: '7px',
    containerPadding: '10px'
  }
}

export var DISCOVER_CARD_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'DISCOVER_CARD',
    title: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'SHOPS'
        }
      ]
    },
    subTitle: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Vermarc Sport selects its distribution points carefully.'
        }
      ]
    },
    buttonText: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Discover all shops >'
        }
      ]
    }
  }
}

export var MEDIA_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'MEDIA',
    url: null,
    width: '640px',
    height: '390px'
  }
}

export var SOCIAL_MEDIA_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'SOCIAL_MEDIA',
    socialMediaType: 'INSTAGRAM',
    username: 'vermarcsport'
  }
}

export var BUTTON_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'BUTTON',
    text: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Button'
        }
      ]
    },
    padding: '6px 16px 6px 16px',
    color: '#000000',
    backgroundColor: 'transparent',
    border: '0px solid #B23566',
    borderRadius: '4px',
    fontFamily: `'Roboto', 'sans-serif'`,
    fontSize: '15px',
    buttonLinkType: 'EXTERNAL',
    textTransform: 'lowercase'
    // url: 'http://localhost:1234/admin/template'
  }
}

export var SUBSCRIBE_CARD_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'SUBSCRIBE_CARD',
    title: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Title'
        }
      ]
    },
    content: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Content goes here'
        }
      ]
    },
    callToAction: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Button'
        }
      ]
    },
    termsContent: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'I HAVE READ AND HAVE APPROVED THE '
        }
      ]
    },
    width: '100%',
    height: '250px',
    padding: '15px 15px 15px 15px',
    border: '1px solid #B23566',
    borderRadius: '7px',
    hasTerms: true,
    isAccepted: false,
    hasInput: true,
    placeholder: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'placeholder'
        }
      ]
    },
    label: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'label'
        }
      ]
    }
  }
}

export var LOCATION_CARD_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'LOCATION_CARD',
    url:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.1841333896145!2d-73.98773128427685!3d40.75797467932697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25855c6480299%3A0x55194ec5a1ae072e!2sTimes%20Square!5e0!3m2!1sen!2sth!4v1571200190628!5m2!1sen!2sth',
    shopName: 'Placeholder',
    address: 'Times Square',
    website: 'Website URL',
    email: 'test@test.com',
    phone: '12345678900',
    openDays: 'Mon - Fri',
    closedDays: 'Sat - Sun',
    hasPremiumDealership: false
  }
}

export var CONTACT_CARD_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'CONTACT_CARD',
    hasTerms: true,
    title: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Contact us'
        }
      ]
    },
    contentLabel: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Message'
        }
      ]
    },
    contentPlaceholder: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Please type your message here'
        }
      ]
    },
    nameLabel: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Name'
        }
      ]
    },
    namePlaceholder: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Name'
        }
      ]
    },
    emailLabel: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Email'
        }
      ]
    },
    emailPlaceholder: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'Email'
        }
      ]
    }
  }
}

export var INPUTFIELD_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'INPUT',
    text: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'input'
        }
      ]
    },
    width: '64px',
    height: '25px',
    padding: '15px',
    color: '#B23566',
    backgroundColor: 'transparent',
    border: '1px solid #B23566',
    borderRadius: '7px',
    placeholder: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'placeholder'
        }
      ]
    },
    label: {
      id: null,
      text: [
        {
          langCode: 'en_UK',
          content: 'label'
        }
      ]
    }
  }
}

export const DIVIDER_INPUT = {
  alignmentType: 'justifyContent',
  alignment: 'center',
  sortOrder: 0,
  columns: 1,
  columnRowDetail: {
    id: null,
    type: 'DIVIDER',
    border: '1px solid black',
    width: '100%',
    padding: '0px',
    margin: '0px',
    text: {
      id: null,
      text: []
      // text: [
      //   {
      //     langCode: 'en_UK',
      //     content: 'text'
      //   }
      // ]
    },
    textAlign: 'center',
    textPadding: '0px',
    textBackground: 'transparent'
  }
}

export const SOCIAL_LINKS_INPUT = {
  contentDetail: {
    id: null,
    type: 'SOCIAL_LINKS',
    socialLinks: []
  }
}

export const PAYMENT_OPTIONS_INPUT = {
  contentDetail: {
    id: null,
    type: 'PAYMENT_OPTIONS',
    paymentOptions: []
  }
}

export const SEARCH_INPUT_INPUT = {
  contentDetail: {
    id: null,
    type: 'SEARCH_INPUT'
  }
}

export const CART_COUNTER_INPUT = {
  contentDetail: {
    id: null,
    type: 'CART_COUNTER'
  }
}

export const LANGUAGE_SWITCH_INPUT = {
  contentDetail: {
    id: null,
    type: 'LANGUAGE_SWITCH'
  }
}

export const COUNTRY_SWITCH_INPUT = {
  contentDetail: {
    id: null,
    type: 'COUNTRY_SWITCH'
  }
}

export const CONTACTS_INPUT = {
  contentDetail: {
    id: null,
    type: 'CONTACTS'
  }
}

export const SHOP_LOGO_INPUT = {
  contentDetail: {
    id: null,
    type: 'SHOP_LOGO',
    isAlternative: false,
    isLink: true,
    width: null,
    height: '30px',
    maxWidth: null,
    maxHeight: null
  }
}

export const TEAMSTORE_LOGO_INPUT = {
  contentDetail: {
    id: null,
    type: 'TEAMSTORE_LOGO',
    isAlternative: false,
    isLink: true,
    width: null,
    height: '30px',
    maxWidth: null,
    maxHeight: null
  }
}

export const TERMS_LINK_INPUT = {
  contentDetail: {
    id: null,
    type: 'TERMS_LINK'
  }
}

export const CURRENCY_SWITCH_INPUT = {
  contentDetail: {
    id: null,
    type: 'CURRENCY_SWITCH'
  }
}
