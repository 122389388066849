import swal from 'sweetalert';
import axios from 'axios';
import { UPLOAD_FILE } from '../../gql/mutations';
import apolloClient from '../../gql/apollo-config';
import clientAdmin from '../admin/gql/admin-apollo-config';
import _cloneDeep from 'lodash/cloneDeep';
import { save } from './tools/Functions/save_load';
import * as tools from './tools/tools';

var DesignRoot = null;

let SHOP_ID = tools.getQueryStringValue('s');
export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot;
  if ( !SHOP_ID ) {

    SHOP_ID = DesignRoot.props.SHOP_ID;
  }
}

export function changeActiveTemplate(template_id, no, src) {
  try {
    // statements

    var productDataSet = DesignRoot.productDataSet;
    DesignRoot._ACTIVE_TEMPLATE_ID = template_id;

    if (template_id === 'BLANK') {
      DesignRoot._ACTIVE_COLOR_ZONE = false;
      DesignRoot._BLANK_TEMPLATE_ACTIVE = true;
      DesignRoot._MAP_OVERLAY = null;
    } else {
      DesignRoot._ACTIVE_COLOR_ZONE = true;
      DesignRoot._BLANK_TEMPLATE_ACTIVE = false;
    }

    // DesignRoot.Main3D.ThreejsFunction.loadModel();
    // DesignRoot.setState({render: true});
    // DesignRoot.productDataSet.ACTIVE_TEMPLATE = 1;
    // console.log('changeActiveTemplate Main3D.storeMappingChild', DesignRoot.Main3D.storeMappingChild);
    // const local_pattern = productDataSet.TEMPLATE_SET_BY_ID[DesignRoot._ACTIVE_TEMPLATE_ID].template_src;

    DesignRoot.Loading.activeLoading(true);
    DesignRoot.design_template.canvascolor_fabricjs_init();
    DesignRoot.Selection.setState(DesignRoot.Selection.initSelection);
    DesignRoot.tools.renderComponent('FillStep');
    DesignRoot.tools.renderComponent('DesignRoot');
    DesignRoot.zoneColors = null;

    if (DesignRoot.isSaveEnabled) {
      console.log('save changeActiveTemplate');
      save();
    }
  } catch (e) {
    // statements
    console.error('template_control.changeActiveTemplate.error', e);
  }
}

export function setSelectColorZone(colorZone) {
  try {
    // statements

    if ( DesignRoot._ACTIVE_PERSONALIZATION === true ) {

      console.log('FORCE COLOR ZONE', DesignRoot._SELECT_COLOR_ZONE);
      return;
    }

    console.log('setSelectColorZone', colorZone);

    DesignRoot._SELECT_COLOR_ZONE = colorZone;

    DesignRoot.Main3D.directELM = null;
    DesignRoot.Selection.setState({
      selectDirectELM: null,
      selectDirectELMObject: null
    });
  } catch (e) {
    // statements
    console.error('template_control.setSelectColorZone.error', e);
  }
}

export function selectionUpdateState(selectionObject) {
  try {
    // statements

    if (selectionObject === null) {
      clearSelection();
    } else if (selectionObject._OBJECT_TYPE) {
      if (selectionObject._OBJECT_TYPE === 'TEXT') {
        DesignRoot.TextStep.setState({
          selectTextId: selectionObject.id,
          content: selectionObject._USER_DATA.content,
          fontStyle: selectionObject._USER_DATA.fontStyle,
          textColor: selectionObject._USER_DATA.textColor,
          orderSeq: selectionObject._USER_DATA.orderSeq,
          defaultItem: selectionObject._USER_DATA.defaultItem,
          boundingId: selectionObject._USER_DATA.boundingId,
          boundingGroup: selectionObject._USER_DATA.boundingGroup,
          outlineColor: selectionObject._USER_DATA.outlineColor,
          outlineSize: selectionObject._USER_DATA.outlineSize,
          lineSpacing: selectionObject._USER_DATA.lineSpacing,
          triggerDidUpdate: false,
          scale: selectionObject.scaleX.toFixed(2),
          rotate: selectionObject.angle.toFixed(0)
        });

        DesignRoot.fabric_function.updateDimension(selectionObject.id, 'byID');
      } else if (selectionObject._OBJECT_TYPE === 'LOGO') {
        DesignRoot.LogoStep.setState({
          selectedId: selectionObject.id,
          filename: selectionObject._USER_DATA.filename,
          base64: selectionObject._USER_DATA.base64,
          scale: selectionObject.scaleX.toFixed(2),
          rotate: selectionObject.angle.toFixed(0),
        });
        DesignRoot.fabric_function.updateDimension(selectionObject.id, 'byID');
      } else if (selectionObject._OBJECT_TYPE === 'PATTERN') {
        DesignRoot.PatternStep.setState({
          selectedId: selectionObject.id,
          filename: selectionObject._USER_DATA.filename,
          base64: selectionObject._USER_DATA.base64
        });
        DesignRoot.fabric_function.updateDimension(selectionObject.id, 'byID');
      } else if (selectionObject._OBJECT_TYPE === 'SVG') {
        clearSelection();

        var _SPECIAL_ELM = false;
        if (
          selectionObject._SPECIAL_ELM &&
          selectionObject._SPECIAL_ELM === true
        ) {
          _SPECIAL_ELM = true;
        }

        if (DesignRoot.AopStep !== null) {

          DesignRoot.AopStep.setState({ SPECIAL_ELM: _SPECIAL_ELM });
        }

        // CHECK DIMENSION

        // var w = selectionObject.width * selectionObject.scaleX;
        // var h = selectionObject.height * selectionObject.scaleX;

        // console.log('CHECK DIMENSION', w, h);

        // // COMPARE CM

        // var w_cm = 60.70;
        // var h_cm = 5.13;

        // // 1 cm = 37.795276 px; 1 px = 0.026458 cm
        // var w_px = w_cm * 37.795276;
        // var h_px = h_cm * 37.795276;

        // var w_diff = w_px-w;
        // var h_diff = h_px-h;

        // var ref = w_cm / w;

        // console.log('PX DIMENSION', w_px, h_px);
        // console.log('DIFF DIMENSION', w_diff, h_diff);
        // console.log('DIFF SCALE DIMENSION', w_diff / 3000);
        // console.log('1 PX IS', ref);
        // console.log('PROVE CM ', ref * w, ref * h);

        // COMPARE CM

        // CHECK DIMENSION

        // UPDATE TRANSFORM

        if (selectionObject._USER_DATA.fillType === 'AOP') {
          var transform = selectionObject._USER_DATA.transform;

          if ( DesignRoot.AopStep !== null ) {

            DesignRoot.AopStep.setState({
              scale: transform.scaleX,
              angle: transform.angle,
              offsetX: transform.offsetX,
              offsetY: transform.offsetY,
              width: transform.scaleX * selectionObject.fill.img.width,
              height: transform.scaleX * selectionObject.fill.img.height
            });
          }

          
          DesignRoot.fabric_function.updateDimension(
            selectionObject.id,
            'byID'
          );
        } else if (selectionObject._USER_DATA.fillType === 'COLOR') {
          DesignRoot.FillStep.setState({
            colorSVG: (selectionObject._USER_DATA.color) ? selectionObject._USER_DATA.color : '#ffffff'
          });
        }

        // UPDATE TRANSFORM
      } else {
        clearSelection();
      }
    } else {
      clearSelection();
    }
  } catch (e) {
    // statements
    console.error(
      'template_control.selectionUpdateState.error',
      e,
      selectionObject
    );
  }
}

export function clearSelection() {

  try {

    console.log('clearSelection ', DesignRoot);

    if (DesignRoot.TextStep === null) {
      console.log('PLEASE REFRESH');

      if (window.confirm('Auto build fail. Please refresh the page.') === true) {
        window.location.reload();
      }

      return;
    } else {
      DesignRoot.TextStep.setState(DesignRoot.TextStep.initState);
      DesignRoot.LogoStep.setState(DesignRoot.LogoStep.initState);

      if (DesignRoot.AopStep !== null) {
        DesignRoot.AopStep.setState({ scale: 1 });
      }

      if (DesignRoot.PatternStep !== null) {
        DesignRoot.PatternStep.setState(DesignRoot.PatternStep.initState);
      }

      DesignRoot.FillStep.setState({ colorSVG: '#FFFFFF' });
      DesignRoot.Dimension.setState({
        approxWidth: null,
        approxHeight: null,
        rotation: null
      });
    }

  } catch (e) {
    // statements
    console.error('template_control.clearSelection.error', e);
  }
}






// DEBUG

export function logDebug(prefix) {
  console.log('logDebug', prefix, DesignRoot[prefix]);
}

export function triggerExport3DScene() {
  // DesignRoot.Main3D.export3D = true; // Fix for trigger white bg

  DesignRoot.Main3D.renderer_virtual.render(DesignRoot.Main3D.scene, DesignRoot.Main3D.camera);
  DesignRoot.Main3D.export3DTransparent = true; // Fix for trigger white bg
}

export function triggerExport3DSceneTransparent() {

  DesignRoot.Main3D.renderer_virtual.render(DesignRoot.Main3D.scene, DesignRoot.Main3D.camera);
  DesignRoot.Main3D.export3DTransparent = true; // Fix for trigger white bg
}

export function export3DScene(option) {
  console.log('export3DScene');
  var Main3D = DesignRoot.Main3D;

  try {
    var strDownloadMime = 'image/octet-stream';
    var strMime = 'image/png';

    var imgData = Main3D.renderer_virtual.domElement.toDataURL(strMime);

    console.log('imgData', imgData);

    if (option && option === 'RETURN') {
      return imgData;
    } else {
      var link = document.createElement('a');
      link.download = '3D_MODEL_EXPORT.png';
      link.href = imgData;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (e) {
    console.error('template_control.export3DScene.error', e);
    return;
  }
}

export function export2DScene(option, type) {
  console.log('export2DScene', option, type);

  if (DesignRoot._APP_TYPE === '2d-3d') {
    var canvasExport = DesignRoot._CANVAS_RENDER;
  } else {
    var canvasExport = DesignRoot.canvas;
  }

  try {
    if (type && type === 'JPG') {
      var imgData = canvasExport.toDataURL({
        enableRetinaScaling: true,
        format: 'jpeg'
      });
      var fileName = '2D_MODEL_EXPORT.jpg';
    } else {
      var imgData = canvasExport.toDataURL({
        enableRetinaScaling: true,
        format: 'jpeg'
      });
      var fileName = '2D_MODEL_EXPORT.jpg';
    }

    if (option && option === 'RETURN') {
      return imgData;
    } else {
      // console.log(imgData);

      var link = document.createElement('a');
      link.download = fileName;
      link.href = imgData;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (e) {
    console.error('template_control.export2DScene.error', e);
    return;
  }
}

export function export2DSceneTIFF(type) {
  console.log('export2DSceneTIFF', DesignRoot.canvas, SHOP_ID);

  if (DesignRoot._APP_TYPE === '2d-3d') {
    // if (SHOP_ID === 'yuepai') {
    //   var svgCalBase = DesignRoot.fabric_function.getObjectById(
    //     'SVG-' + DesignRoot.productDataSet.dimension.svgCalElm
    //   );
    //   var calPx = svgCalBase.width * svgCalBase.scaleX;
    //   var realPx = DesignRoot.productDataSet.dimension.svgCalCm * 37.795276;
    //   var ratioScale = realPx / calPx;

    //   DesignRoot._CANVAS_RENDER.setZoom(ratioScale);
    //   var templateBase = DesignRoot.fabric_function.getObjectById('template');
    //   DesignRoot._CANVAS_RENDER.setDimensions({
    //     width: templateBase.width * ratioScale,
    //     height: templateBase.height * ratioScale
    //   });

    //   DesignRoot._TRIGGER_EXPORT = true;
    // } else if (SHOP_ID === 'OBS' || SHOP_ID === 'xceed') {
    //   var realWorldPx = DesignRoot._CMBASE * 37.795276;
    //   var diff = realWorldPx - DesignRoot._PXBASE + 3000;

    //   var ratioScale = diff / 3000;
    //   DesignRoot._CANVAS_RENDER.setZoom(ratioScale);
    //   var templateBase = DesignRoot.fabric_function.getObjectById('template');
    //   DesignRoot._CANVAS_RENDER.setDimensions({
    //     width: templateBase.width * ratioScale,
    //     height: templateBase.height * ratioScale
    //   });

    //   DesignRoot._TRIGGER_EXPORT = true;
    // } else {
    //   DesignRoot._TRIGGER_EXPORT_TYPE = type;
    //   var canvasExport = DesignRoot._CANVAS_RENDER;
    //   export2DSceneTIFF_RealSize(canvasExport);
    // }

    var templateBase = DesignRoot.fabric_function.getObjectById('template');
    console.log('templateBase', templateBase, DesignRoot._CANVAS_RENDER);

    if ( templateBase === null ) {

      templateBase = {};
      templateBase.width = DesignRoot._CANVAS_RENDER.width;
      templateBase.height = DesignRoot._CANVAS_RENDER.height;
      DesignRoot._CANVAS_RENDER_BASE = templateBase;
    }
    else {

      DesignRoot._CANVAS_RENDER_BASE = templateBase;
    }

    console.log('templateBase 2', templateBase, DesignRoot._CANVAS_RENDER);

    var diff = (DesignRoot.svgW) / templateBase.width;

    var ratioScale = diff;
    DesignRoot._CANVAS_RENDER.setDimensions({
      width: templateBase.width * ratioScale,
      height: templateBase.height * ratioScale
    });
    DesignRoot._CANVAS_RENDER.setZoom(ratioScale);

    // HIDE ZONE

    DesignRoot.fabric_function.hide_all_bounding_stroke('LOGO');
    DesignRoot.fabric_function.hide_all_bounding_stroke('TEXT');
    DesignRoot.fabric_function.hide_all_bounding_stroke('BOTH');

    // HIDE ZONE

    DesignRoot._TRIGGER_EXPORT_TYPE = type;
    DesignRoot._TRIGGER_EXPORT = true;
    
  } else {
    if (SHOP_ID === 'xceed') {
      // var realWorldPx = DesignRoot._CMBASE * 37.795276;
      // var diff = realWorldPx - DesignRoot._PXBASE + 3000;
      // var diff = realWorldPx / DesignRoot._PXBASE;

      // var ratioScale = diff / 3000;

      var templateBase = DesignRoot.fabric_function.getObjectById('template');

      var diff = (DesignRoot.svgW * 1.3336) / templateBase.width;

      var ratioScale = diff;

      // [1.4645666666666666, 0, 0, 1.4645666666666666, 0, 0]
      DesignRoot.canvas.setDimensions({
        width: templateBase.width * ratioScale,
        height: templateBase.height * ratioScale
      });
      DesignRoot.canvas.setZoom(ratioScale);

      // HIDE ZONE

      DesignRoot.fabric_function.hide_all_bounding_stroke('LOGO');
      DesignRoot.fabric_function.hide_all_bounding_stroke('TEXT');
      DesignRoot.fabric_function.hide_all_bounding_stroke('BOTH');

      // HIDE ZONE

      DesignRoot._TRIGGER_EXPORT_TYPE = type;
      DesignRoot._TRIGGER_EXPORT = true;
    } else {
      
      var templateBase = DesignRoot.fabric_function.getObjectById('template');

      var diff = (DesignRoot.svgW) / templateBase.width;

      var ratioScale = diff;
      DesignRoot.canvas.setDimensions({
        width: templateBase.width * ratioScale,
        height: templateBase.height * ratioScale
      });
      DesignRoot.canvas.setZoom(ratioScale);

      // HIDE ZONE

      DesignRoot.fabric_function.hide_all_bounding_stroke('LOGO');
      DesignRoot.fabric_function.hide_all_bounding_stroke('TEXT');
      DesignRoot.fabric_function.hide_all_bounding_stroke('BOTH');

      // HIDE ZONE

      DesignRoot._TRIGGER_EXPORT_TYPE = type;
      DesignRoot._TRIGGER_EXPORT = true;

    }
  }
}

export function export2DSceneTIFF_RealSize(canvasExport) {
  try {
    DesignRoot._TRIGGER_EXPORT = false;

    window.CanvasToTIFF.toBlob(canvasExport, function(blob) {
      if (
        DesignRoot._TRIGGER_EXPORT_TYPE &&
        DesignRoot._TRIGGER_EXPORT_TYPE === 'JPG'
      ) {
        if (DesignRoot._APP_TYPE === '2d-3d' && SHOP_ID !== 'xceed') {
          // var canvas = document.getElementById('canvasRender');

          DesignRoot._CANVAS_RENDER.set({backgroundColor: '#ffffff'});
          var imgData = DesignRoot._CANVAS_RENDER.toDataURL({
            format: 'jpeg'
            // multiplier: ratioScale,
            // width: templateBase.width * ratioScale,
            // height: templateBase.height * ratioScale
          });
        } else {
          var canvas = document.getElementById('canvasEditor');
          var imgData = canvas.toDataURL('image/jpeg');
        }

        var fileName = '2D_MODEL_EXPORT.jpg';
        var link = document.createElement('a');
        link.download = fileName;
        link.href = imgData;

        console.log(imgData);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (
        DesignRoot._TRIGGER_EXPORT_TYPE &&
        DesignRoot._TRIGGER_EXPORT_TYPE === 'PNG'
      ) {
        if (DesignRoot._APP_TYPE === '2d-3d') {
          var imgData = DesignRoot._CANVAS_RENDER.toDataURL();
        } else {
          var imgData = DesignRoot.canvas.toDataURL();
        }

        var fileName = '2D_MODEL_EXPORT.png';
        var link = document.createElement('a');
        link.download = fileName;
        link.href = imgData;

        console.log(imgData);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // blob object can be converted to an objectURL and then
        // set as source for images, or as download target:
        var url = URL.createObjectURL(blob);
        console.log(blob);

        var link = document.createElement('a');
        link.download = 'PATTERN_EXPORT.TIFF';
        link.href = url;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        console.log(link);
      }

      if (SHOP_ID === 'yuepai') {
        var templateBase = DesignRoot.fabric_function.getObjectById('template');
        DesignRoot._CANVAS_RENDER.setZoom(1);
        DesignRoot._CANVAS_RENDER.setDimensions({
          width: templateBase.width,
          height: templateBase.height
        });
      } else if (SHOP_ID === 'xceed') {
        var templateBase = DesignRoot.fabric_function.getObjectById('template');
        DesignRoot.canvas.setZoom(1);
        DesignRoot.canvas.setDimensions({
          width: templateBase.width,
          height: templateBase.height
        });
      }
      else {

        if (DesignRoot._APP_TYPE === '2d-3d') {
          // RENDER SCENE 100%

          DesignRoot._CANVAS_RENDER.set({backgroundColor: 'transparent'});
          DesignRoot._CANVAS_RENDER.setZoom(1);
          DesignRoot._CANVAS_RENDER.setDimensions({
            width: DesignRoot._CANVAS_RENDER_BASE.width,
            height: DesignRoot._CANVAS_RENDER_BASE.height
          });
        }


      }



    });
  } catch (e) {
    console.error('template_control.export2DSceneTIFF_RealSize.error', e);
    return;
  }
}

export function toggleLogoOverlay() {
  try {
    // statements

    if (DesignRoot._CURRENT_LOGO_SRC === null) {
      swal({
        icon: 'info',
        text: 'Logo not found'
      });
    } else {

      console.log(DesignRoot._CURRENT_LOGO_SRC);

      DesignRoot._LOGO_TOGGLE = !DesignRoot._LOGO_TOGGLE;
      DesignRoot.canvas_coloring.updateTexture();
    }

  } catch (e) {
    // statements
    console.error('template_control.toggleLogoOverlay.error', e);
  }
}

export function toggleRedLine() {
  try {
    // statements

    if (DesignRoot._CURRENT_SEAM_SRC === null) {
      swal({
        icon: 'info',
        text: 'Seam allowance not found'
      });
    } else {

      console.log(DesignRoot._CURRENT_LOGO_SRC);
      
      DesignRoot._SEAM_TOGGLE = !DesignRoot._SEAM_TOGGLE;
      DesignRoot.canvas_coloring.updateTexture();
    }
  } catch (e) {
    // statements
    console.error('template_control.toggleRedLine.error', e);
  }
}

export function renderTexture() {
  try {
    // statements

    var storeMappingChild = DesignRoot.Main3D.storeMappingChild;
    if (storeMappingChild.length) {
      for (var i = 0; i < storeMappingChild.length; i++) {
        // storeMappingChild[i].map.image = document.getElementById('example');
        storeMappingChild[i].map.needsUpdate = true;
      }
    }
  } catch (e) {
    // statements
    console.error('template_control.renderTexture.error', e);
  }
}

export function toggle2DScene() {
  try {
    // statements

    DesignRoot._SHOW_2D = !DesignRoot._SHOW_2D;
    DesignRoot.tools.renderComponent('DesignRoot');
  } catch (e) {
    // statements
    console.error('template_control.toggle2DScene.error', e);
  }
}

export function view3D(option) {
  try {
    // statements

    if (option === 'RESET') {
      DesignRoot.Main3D.controls.reset();
    }
  } catch (e) {
    // statements
    console.error('template_control.view3D.error', e);
  }
}

const dataURLtoFile = (dataurl, filename) => {
  try {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  } catch (error) {
    console.error('datURLtoFile.error', error);
    return null;
  }
};

export async function uploadFile(fileName, imgData) {
  try {
    const optionsMutation = {
      mutation: UPLOAD_FILE,
      variables: {
        type: 'DESIGN_PREVIEW',
        fileName: fileName,
        prefixVariables: ['productDesignId'],
        input: {
          ownedByOrganisationId: DesignRoot.props.ownedByOrganisationId,
          productDesignId: DesignRoot.props.productDesignId
        }
      }
    };
    const signedUrlResult = DesignRoot._APP_TYPE === '2d-3d' ? await clientAdmin.mutate(optionsMutation) : await apolloClient.mutate(optionsMutation);
    const signedUrl = signedUrlResult.data.uploadFile.url;
    let options = {
      headers: {
        'Content-Type': signedUrlResult.data.uploadFile.contentType
      }
    };
    if (signedUrlResult.data.uploadFile.acl) {
      options.headers['x-amz-acl'] = signedUrlResult.data.uploadFile.acl;
    }
    if (signedUrlResult.data.uploadFile.tagging) {
      options.headers['x-amz-tagging'] =
        signedUrlResult.data.uploadFile.tagging;
    }
    axios.put(signedUrl, dataURLtoFile(imgData, fileName), options);
  } catch (error) {
    console.error('uploadFile.error');
  }
}

export function capture4view() {
  console.log('capture4view');

  return new Promise((resolve, reject) => {
    try {
      DesignRoot.Main3D.captureDirection = null;
      DesignRoot.Main3D.captureSET = {
        '0': null,
        '90': null,
        '180': null,
        '270': null
      };

      DesignRoot.Main3D.currentView = _cloneDeep(
        DesignRoot.Main3D.camera.position
      );
      DesignRoot.Main3D.currentPan = _cloneDeep(
        DesignRoot.Main3D.controls.target
      );
      DesignRoot.Main3D.capture3D = true;

      // for (var key in DesignRoot.Main3D.captureSET) {
      //   DesignRoot.Main3D.captureDirection = key;
      //   DesignRoot.control_view.changeView(key);
      // }
      resolve();
    } catch (error) {
      console.error('template_control.capture4view.error', error);
      resolve();
    }
  });
}

export function makeCapture() {
  try {
    console.log(
      'makeCapture',
      DesignRoot.Main3D.captureDirection,
      DesignRoot.Main3D.captureSET
    );

    const strDownloadMime = 'image/octet-stream';
    const strMime = 'image/jpeg';
    const imgData = DesignRoot.Main3D.renderer_virtual.domElement.toDataURL(
      strMime
    );
    // console.log('imgData', imgData);

    if (DesignRoot.Main3D.captureDirection !== null) {
      DesignRoot.Main3D.captureSET[
        DesignRoot.Main3D.captureDirection
      ] = imgData;
    }

    const timestamp = new Date().getTime();

    if (DesignRoot.Main3D.captureDirection === '0') {
      try {
        this.uploadFile('latest.jpg', imgData);
      } catch (error) {
        console.error('template_control.makeCapture.upload.error', error);
      }
    }
    try {
      this.uploadFile(DesignRoot.Main3D.captureDirection + '.jpg', imgData);
      this.uploadFile(
        timestamp + '-' + DesignRoot.Main3D.captureDirection + '.jpg',
        imgData
      );
    } catch (error) {
      console.error('template_control.makeCapture.uploadFile.error', error);
    }
  } catch (e) {
    console.error('template_control.makeCapture.error', e);
  }
}

export function export2DSceneSVG(type) {
  
  console.log('export2DSceneSVG', DesignRoot._CANVAS_RENDER);

  var svg = DesignRoot._CANVAS_RENDER.toSVG();

  console.log(svg);

  //convert svg source to URI data scheme.
  var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);
  //set url value to a element's href attribute.

  var link = document.createElement('a');
  link.download = 'PATTERN_EXPORT.SVG';
  link.href = url;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  console.log(link);

  // var fileName = '2D_MODEL_EXPORT.svg';
  // let w = window.open('')
  // w.document.write(DesignRoot._CANVAS_RENDER.toSVG());

}