import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid/Grid'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import { ADMIN_GET_SHOP_SETTINGS } from '../../gql/admin-queries'
import { ADMIN_UPDATE_SHOP_SETTINGS } from '../../gql/admin-mutations'
import Input from '../../../common/Input'
import CircularProgress from '@material-ui/core/CircularProgress'
import ShopLogo from './ShopLogo'
import Select from '../../../common/Select'
import CookieNote from './CookieNote'
import _sortBy from 'lodash/sortBy'
import { countries } from '../../../../helper/countryList'
import SocialLinksSettings from './SocialLinksSettings'
import PaymentOptionsSettings from './PaymentOptionsSettings'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    marginTop: '24px !important',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {},
  cssRootInput: {
    fontSize: '13px',
    width: 'auto'
  },
  paperSelect: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '0',
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    border: 'none',
    width: '100%',
    background: 'transparent',
    padding: '6px 16px',
    height: '42px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  }
})

const steps = [
  {
    value: 'Fabric',
    label: 'Fabric'
  },
  {
    value: 'Text',
    label: 'Text'
  },
  {
    value: 'Printing',
    label: 'Printing'
  },
  {
    value: 'Payment',
    label: 'Payment'
  }
]

const ShopLanguage = ({ updateShop, shop, shopId, classes, language }) => (
  <Checkbox
    classes={{
      root: classes.cssRootInput,
      input: classes.cssinput
    }}
    onChange={e => {
      updateShop({
        optimisticResponse: {
          __typename: 'Mutation',
          updateShop: {
            ...shop,
            languages: e.target.checked
              ? shop.languages && shop.languages.includes(language)
                ? shop.languages
                : [...shop.languages, language]
              : shop.languages.filter(c => c !== language || c == null)
          }
        },
        variables: {
          id: shopId,
          input: {
            languages: e.target.checked
              ? shop.languages && shop.languages.includes(language)
                ? shop.languages
                : [...shop.languages, language]
              : shop.languages.filter(c => c !== language || c == null)
          }
        }
      })
    }}
    placeholder={language}
    type="checkbox"
    checked={shop.languages && shop.languages.includes(language)}
    id={'language-' + language}
  />
)

const ShopCurrency = ({ updateShop, shop, shopId, classes, currency }) => (
  <Checkbox
    classes={{
      root: classes.cssRootInput,
      input: classes.cssinput
    }}
    onChange={e => {
      updateShop({
        optimisticResponse: {
          __typename: 'Mutation',
          updateShop: {
            ...shop,
            currencies: e.target.checked
              ? shop.currencies && shop.currencies.includes(currency)
                ? shop.currencies
                : [...shop.currencies, currency]
              : shop.currencies.filter(c => c !== currency)
          }
        },
        variables: {
          id: shopId,
          input: {
            currencies: e.target.checked
              ? shop.currencies && shop.currencies.includes(currency)
                ? shop.currencies
                : [...shop.currencies, currency]
              : shop.currencies.filter(c => c !== currency)
          }
        }
      })
    }}
    placeholder={currency}
    type="checkbox"
    checked={shop.currencies && shop.currencies.includes(currency)}
    id={'cur-' + currency}
  />
)

class Settings extends Component {
  state = {
    step: 'Fabric'
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  render() {
    const { classes, shopId } = this.props
    return (
      <Grid container className={classes.root}>
        <Grid
          container
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ height: 60, marginBottom: 20 }}
        >
          <Typography
            style={{
              fontSize: 28,
              fontWeight: 400,
              color: '#424242'
            }}
            data-cy="Header-General"
          >
            General
          </Typography>
          <div style={{ flex: 1 }} />
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  General
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Provide general information about your business
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Query query={ADMIN_GET_SHOP_SETTINGS} variables={{ id: shopId }}>
                {({ loading, error, data }) => {
                  if (loading) return <CircularProgress />
                  if (error) return <div>Error :(</div>
                  if (data.shop) {
                    const shop = data.shop
                    return (
                      <Mutation
                        update={(store, { data: { updateShop } }) => {}}
                        mutation={ADMIN_UPDATE_SHOP_SETTINGS}
                      >
                        {(updateShop, { data, loading, error }) => {
                          return (
                            <Grid container spacing={3}>
                              <ShopLogo shop={shop} updateShop={updateShop} />
                              <Grid item xs={12}>
                                <Input
                                  label="Shop name"
                                  placeholder="Shop name"
                                  value={shop.name}
                                  margin="normal"
                                  onChange={e => {
                                    updateShop({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateShop: {
                                          ...shop,
                                          name: e.target.value
                                        }
                                      },
                                      variables: {
                                        id: shopId,
                                        input: {
                                          name: e.target.value
                                        }
                                      }
                                    })
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Input
                                  label="Account email"
                                  placeholder="Enter the account email"
                                  value={shop.email}
                                  onChange={e => {
                                    updateShop({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateShop: {
                                          ...shop,
                                          email: e.target.value
                                        }
                                      },
                                      variables: {
                                        id: shopId,
                                        input: {
                                          email: e.target.value
                                        }
                                      }
                                    })
                                  }}
                                  id="outlined-logo"
                                  margin="normal"
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                style={{ position: 'relative' }}
                              >
                                <Input
                                  label="Shop main color"
                                  placeholder="Shop main color"
                                  onChange={e => {
                                    updateShop({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateShop: {
                                          ...shop,
                                          brandSettings: {
                                            ...shop.brandSettings,
                                            mainColor: e.target.value
                                          }
                                        }
                                      },
                                      variables: {
                                        id: shopId,
                                        input: {
                                          brandSettings: {
                                            mainColor: e.target.value
                                          }
                                        }
                                      }
                                    })
                                  }}
                                  id="outlined-maincolor"
                                  value={shop.brandSettings.mainColor}
                                  margin="normal"
                                />
                                <div
                                  style={{
                                    display: 'inline-block',
                                    width: '30px',
                                    height: '30px',
                                    position: 'absolute',
                                    borderRadius: '3px',
                                    top: 50,
                                    right: 0,
                                    transform: 'translate(-50%,-50%)',
                                    border: '1px solid #eee',
                                    background: shop.brandSettings.mainColor
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                style={{ position: 'relative' }}
                              >
                                <Input
                                  label="Shop secondary color"
                                  placeholder="Shop secondary color"
                                  onChange={e => {
                                    updateShop({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateShop: {
                                          ...shop,
                                          brandSettings: {
                                            ...shop.brandSettings,
                                            secondaryColor: e.target.value
                                          }
                                        }
                                      },
                                      variables: {
                                        id: shopId,
                                        input: {
                                          brandSettings: {
                                            secondaryColor: e.target.value
                                          }
                                        }
                                      }
                                    })
                                  }}
                                  id="outlined-secondarycolor"
                                  value={shop.brandSettings.secondaryColor}
                                  margin="normal"
                                />
                                <div
                                  style={{
                                    display: 'inline-block',
                                    width: '30px',
                                    height: '30px',
                                    position: 'absolute',
                                    borderRadius: '3px',
                                    top: 50,
                                    right: 0,
                                    transform: 'translate(-50%,-50%)',
                                    border: '1px solid #eee',
                                    background:
                                      shop.brandSettings.secondaryColor
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                  Currencies
                                </Typography>
                                <Typography variant="body1">
                                  EUR{' '}
                                  <ShopCurrency
                                    updateShop={updateShop}
                                    shop={shop}
                                    classes={classes}
                                    currency={'EUR'}
                                    shopId={shopId}
                                  />
                                </Typography>
                                <Typography variant="body1">
                                  USD{' '}
                                  <ShopCurrency
                                    updateShop={updateShop}
                                    shop={shop}
                                    classes={classes}
                                    currency={'USD'}
                                    shopId={shopId}
                                  />
                                </Typography>
                                <Typography variant="body1">
                                  GBP{' '}
                                  <ShopCurrency
                                    updateShop={updateShop}
                                    shop={shop}
                                    classes={classes}
                                    currency={'GBP'}
                                    shopId={shopId}
                                  />
                                </Typography>
                                <Typography variant="body1">
                                  THB{' '}
                                  <ShopCurrency
                                    updateShop={updateShop}
                                    shop={shop}
                                    classes={classes}
                                    currency={'THB'}
                                    shopId={shopId}
                                  />
                                </Typography>
                                <Typography variant="body1">
                                  MXN{' '}
                                  <ShopCurrency
                                    updateShop={updateShop}
                                    shop={shop}
                                    classes={classes}
                                    currency={'MXN'}
                                    shopId={shopId}
                                  />
                                </Typography>
                                <Typography variant="body1">
                                  CNY{' '}
                                  <ShopCurrency
                                    updateShop={updateShop}
                                    shop={shop}
                                    classes={classes}
                                    currency={'CNY'}
                                    shopId={shopId}
                                  />
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                Default Currency: {shop.defaultCurrency}
                                {/* <Select
                                  label="Default Currency"
                                  className={classes.select}
                                  value={shop.defaultCurrency}
                                  onChange={e => {
                                    updateShop({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateShop: {
                                          ...shop,
                                          defaultCurrency: e.target.value
                                        }
                                      },
                                      variables: {
                                        id: shopId,
                                        input: {
                                          defaultCurrency: e.target.value
                                        }
                                      }
                                    });
                                  }}
                                >
                                  <option value={''}>...</option>
                                  {shop.currencies
                                    ? shop.currencies.map((c, cl) => {
                                        return (
                                          <option key={cl} value={c}>
                                            {c}
                                          </option>
                                        );
                                      })
                                    : null}
                                </Select> */}
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                  Languages
                                </Typography>
                                <Grid container alignItems="center">
                                  <Typography
                                    variant="body1"
                                    style={{ width: 43 }}
                                  >
                                    en_UK{' '}
                                  </Typography>
                                  <ShopLanguage
                                    shopId={shopId}
                                    updateShop={updateShop}
                                    shop={shop}
                                    classes={classes}
                                    language={'en_UK'}
                                  />
                                </Grid>
                                <Grid container alignItems="center">
                                  <Typography
                                    variant="body1"
                                    style={{ width: 43 }}
                                  >
                                    nl_NL{' '}
                                  </Typography>
                                  <ShopLanguage
                                    shopId={shopId}
                                    updateShop={updateShop}
                                    shop={shop}
                                    classes={classes}
                                    language={'nl_NL'}
                                  />
                                </Grid>
                                <Grid container alignItems="center">
                                  <Typography
                                    variant="body1"
                                    style={{ width: 43 }}
                                  >
                                    fr_FR{' '}
                                  </Typography>
                                  <ShopLanguage
                                    shopId={shopId}
                                    updateShop={updateShop}
                                    shop={shop}
                                    classes={classes}
                                    language={'fr_FR'}
                                  />
                                </Grid>
                                <Grid container alignItems="center">
                                  <Typography
                                    variant="body1"
                                    style={{ width: 43 }}
                                  >
                                    th_TH{' '}
                                  </Typography>
                                  <ShopLanguage
                                    shopId={shopId}
                                    updateShop={updateShop}
                                    shop={shop}
                                    classes={classes}
                                    language={'th_TH'}
                                  />
                                </Grid>
                                <Grid container alignItems="center">
                                  <Typography
                                    variant="body1"
                                    style={{ width: 43 }}
                                  >
                                    zh_CN{' '}
                                  </Typography>
                                  <ShopLanguage
                                    shopId={shopId}
                                    updateShop={updateShop}
                                    shop={shop}
                                    classes={classes}
                                    language={'zh_CN'}
                                  />
                                </Grid>
                                <Grid container alignItems="center">
                                  <Typography
                                    variant="body1"
                                    style={{ width: 43 }}
                                  >
                                    ja_JP{' '}
                                  </Typography>
                                  <ShopLanguage
                                    shopId={shopId}
                                    updateShop={updateShop}
                                    shop={shop}
                                    classes={classes}
                                    language={'ja_JP'}
                                  />
                                </Grid>
                                <Grid container alignItems="center">
                                  <Typography
                                    variant="body1"
                                    style={{ width: 43 }}
                                  >
                                    es_MX{' '}
                                  </Typography>
                                  <ShopLanguage
                                    shopId={shopId}
                                    updateShop={updateShop}
                                    shop={shop}
                                    classes={classes}
                                    language={'es_MX'}
                                  />
                                </Grid>
                                <Grid container alignItems="center">
                                  <Typography
                                    variant="body1"
                                    style={{ width: 43 }}
                                  >
                                    de_DE{' '}
                                  </Typography>
                                  <ShopLanguage
                                    shopId={shopId}
                                    updateShop={updateShop}
                                    shop={shop}
                                    classes={classes}
                                    language={'de_DE'}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Select
                                  label="Default Language"
                                  className={classes.select}
                                  value={shop.defaultLanguage}
                                  onChange={e => {
                                    updateShop({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateShop: {
                                          ...shop,
                                          defaultLanguage: e.target.value
                                        }
                                      },
                                      variables: {
                                        id: shopId,
                                        input: {
                                          defaultLanguage: e.target.value
                                        }
                                      }
                                    })
                                  }}
                                >
                                  <option value={''}>...</option>
                                  {shop.languages
                                    ? shop.languages.map((l, il) => {
                                        return (
                                          <option key={il} value={l}>
                                            {l}
                                          </option>
                                        )
                                      })
                                    : null}
                                </Select>
                              </Grid>
                              <Grid item xs={12}>
                                <Select
                                  label="Language to remove url prefix"
                                  className={classes.select}
                                  value={shop.languageWithoutUrlPrefix}
                                  onChange={e => {
                                    updateShop({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateShop: {
                                          ...shop,
                                          languageWithoutUrlPrefix: e.target
                                            .value
                                            ? e.target.value
                                            : null
                                        }
                                      },
                                      variables: {
                                        id: shopId,
                                        input: {
                                          languageWithoutUrlPrefix: e.target
                                            .value
                                            ? e.target.value
                                            : null
                                        }
                                      }
                                    })
                                  }}
                                >
                                  <option value={null}>
                                    Always show language code in URL
                                  </option>
                                  {shop.languages
                                    ? shop.languages.map((l, il) => {
                                        return (
                                          <option key={il} value={l}>
                                            {l}
                                          </option>
                                        )
                                      })
                                    : null}
                                </Select>
                              </Grid>
                              <CookieNote
                                shopId={shopId}
                                languages={shop.languages}
                                classes={classes}
                                cookieNote={
                                  shop.contentSettings
                                    ? shop.contentSettings.cookieNote
                                    : ''
                                }
                                shop={shop}
                              />
                            </Grid>
                          )
                        }}
                      </Mutation>
                    )
                  } else {
                    return <div>No shop settings</div>
                  }
                }}
              </Query>
            </Paper>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Store address
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  This address will appear on your invoices.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Query query={ADMIN_GET_SHOP_SETTINGS} variables={{ id: shopId }}>
                {({ loading, error, data }) => {
                  if (loading) return <CircularProgress />
                  if (error) return <div>Error :(</div>
                  let shop = data && data.shop ? data.shop : null
                  return (
                    <Mutation
                      update={(store, { data: { updateShop } }) => {}}
                      mutation={ADMIN_UPDATE_SHOP_SETTINGS}
                    >
                      {(updateShop, { data, loading, error }) => {
                        return (
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Input
                                label="Legal name of the business"
                                placeholder="Enter the legal name of the business"
                                onChange={e => {
                                  updateShop({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateShop: {
                                        ...shop,
                                        businessAddress: {
                                          ...shop.businessAddress,
                                          company: e.target.value
                                        }
                                      }
                                    },
                                    variables: {
                                      id: shopId,
                                      input: {
                                        businessAddress: {
                                          company: e.target.value
                                        }
                                      }
                                    }
                                  })
                                }}
                                value={shop.businessAddress.company}
                                id="outlined-logo"
                                margin="normal"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Input
                                label="Phone"
                                placeholder="Enter phone number"
                                onChange={e => {
                                  updateShop({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateShop: {
                                        ...shop,
                                        businessAddress: {
                                          ...shop.businessAddress,
                                          phone: e.target.value
                                        }
                                      }
                                    },
                                    variables: {
                                      id: shopId,
                                      input: {
                                        businessAddress: {
                                          phone: e.target.value
                                        }
                                      }
                                    }
                                  })
                                }}
                                value={shop.businessAddress.phone}
                                id="outlined-logo"
                                margin="normal"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Input
                                label="Email"
                                placeholder="info@yourstore.com"
                                onChange={e => {
                                  updateShop({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateShop: {
                                        ...shop,
                                        businessAddress: {
                                          ...shop.businessAddress,
                                          email: e.target.value
                                        }
                                      }
                                    },
                                    variables: {
                                      id: shopId,
                                      input: {
                                        businessAddress: {
                                          email: e.target.value
                                        }
                                      }
                                    }
                                  })
                                }}
                                value={shop.businessAddress.email}
                                id="outlined-logo"
                                margin="normal"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Input
                                label="Street"
                                placeholder="Enter street"
                                onChange={e => {
                                  updateShop({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateShop: {
                                        ...shop,
                                        businessAddress: {
                                          ...shop.businessAddress,
                                          address1: e.target.value
                                        }
                                      }
                                    },
                                    variables: {
                                      id: shopId,
                                      input: {
                                        businessAddress: {
                                          address1: e.target.value
                                        }
                                      }
                                    }
                                  })
                                }}
                                value={shop.businessAddress.address1}
                                id="outlined-address1"
                                margin="normal"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Input
                                label="City"
                                placeholder="City"
                                onChange={e => {
                                  updateShop({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateShop: {
                                        ...shop,
                                        businessAddress: {
                                          ...shop.businessAddress,
                                          city: e.target.value
                                        }
                                      }
                                    },
                                    variables: {
                                      id: shopId,
                                      input: {
                                        businessAddress: {
                                          city: e.target.value
                                        }
                                      }
                                    }
                                  })
                                }}
                                value={shop.businessAddress.city}
                                id="outlined-city"
                                margin="normal"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Input
                                label="Postal / ZIP code"
                                placeholder="Postal / ZIP code"
                                onChange={e => {
                                  updateShop({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateShop: {
                                        ...shop,
                                        businessAddress: {
                                          ...shop.businessAddress,
                                          zip: e.target.value
                                        }
                                      }
                                    },
                                    variables: {
                                      id: shopId,
                                      input: {
                                        businessAddress: {
                                          zip: e.target.value
                                        }
                                      }
                                    }
                                  })
                                }}
                                value={shop.businessAddress.zip}
                                id="outlined-zip"
                                margin="normal"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Select
                                label="Country"
                                className={classes.select}
                                onChange={e => {
                                  updateShop({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateShop: {
                                        ...shop,
                                        businessAddress: {
                                          ...shop.businessAddress,
                                          countryCode: e.target.value
                                        }
                                      }
                                    },
                                    variables: {
                                      id: shopId,
                                      input: {
                                        businessAddress: {
                                          countryCode: e.target.value
                                        }
                                      }
                                    }
                                  })
                                }}
                                id="outlined-logo"
                                value={shop.businessAddress.countryCode}
                              >
                                {_sortBy(countries, ['name']).map(country => {
                                  return (
                                    <option
                                      key={'country-menu-' + country.code}
                                      value={country.code}
                                    >
                                      {country.name}
                                    </option>
                                  )
                                })}
                              </Select>
                            </Grid>
                            <Grid item xs={6}>
                              <Input
                                label="Province"
                                placeholder="Province"
                                onChange={e => {
                                  updateShop({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateShop: {
                                        ...shop,
                                        businessAddress: {
                                          ...shop.businessAddress,
                                          province: e.target.value
                                        }
                                      }
                                    },
                                    variables: {
                                      id: shopId,
                                      input: {
                                        businessAddress: {
                                          province: e.target.value
                                        }
                                      }
                                    }
                                  })
                                }}
                                value={shop.businessAddress.province}
                                id="outlined-province"
                                margin="normal"
                              />
                            </Grid>
                          </Grid>
                        )
                      }}
                    </Mutation>
                  )
                }}
              </Query>
            </Paper>
          </Grid>
        </Grid>
        <Query query={ADMIN_GET_SHOP_SETTINGS} variables={{ id: shopId }}>
          {({ loading, error, data }) => {
            if (loading) return <CircularProgress />
            if (error) return <div>Error :(</div>
            if (data.shop) {
              const shop = data.shop
              return (
                <>
                  <SocialLinksSettings shop={shop} />
                  <PaymentOptionsSettings shop={shop} />
                </>
              )
            }
          }}
        </Query>
      </Grid>
    )
  }
}

export default withStyles(styles)(Settings)
