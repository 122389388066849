import cat from '../FABRIC_ASSET/cat.jpg';
import adidas from '../FABRIC_ASSET/adidas.png';
import resize from '../FABRIC_ASSET/icons/resize.svg';
import rotate from '../FABRIC_ASSET/icons/rotate.svg';
import remove from '../FABRIC_ASSET/icons/remove.svg';
import lock from '../FABRIC_ASSET/icons/lock.svg';
import up from '../FABRIC_ASSET/icons/up.svg';
import down from '../FABRIC_ASSET/icons/down.svg';

import swal from 'sweetalert';

import * as text_function from '../step/appFunc/text_function';
import * as svg_control from './svg_control';
import {
  setupLogo,
  setupTexts,
  createTextDesignRevisionData,
  updateTextDesignRevisionData,
  deleteTextDesignRevisionData,
  createLogoDesignRevisionData,
  updateLogoDesignRevisionData,
  deleteLogoDesignRevisionData,
  setDesignAttributes
} from '../tools/Functions/save';
import { save } from '../tools/Functions/save_load';

import _cloneDeep from 'lodash/cloneDeep';

import { API_STAGE } from '~/brikl-config';
import { wait } from '../../Utils';

import * as tools from '../tools/tools';

const uuidv4 = require('uuid/v4');

var DesignRoot = null;
var templateBase = null;
var overlayBase = null;
var redlineBase = null;

var drawMouseMove = null;

var fitInSceneInit = true;

var intersectCheck = null;

export var canvas;

let SHOP_ID = tools.getQueryStringValue('s');
export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot;
  if ( !SHOP_ID ) {

    SHOP_ID = DesignRoot.SHOP_ID;
  }
}

export async function initFabric() {
  try {
    // statements

    if (DesignRoot._2D_BUILD === false) {
      return;
    }

    console.log('initFabric', window.fabric);

    canvas = new window.fabric.Canvas('canvasEditor', {
      width: 1,
      height: 1,
      preserveObjectStacking: true,
      backgroundColor: 'transparent',
      enableRetinaScaling: false,
      stopContextMenu: true
    });

    if (DesignRoot._APP_TYPE === '2d-3d') {
      DesignRoot._CANVAS_RENDER = new window.fabric.Canvas('canvasRender', {
        width: 1,
        height: 1,
        preserveObjectStacking: true,
        backgroundColor: 'transparent',
        enableRetinaScaling: false
      });

      DesignRoot._CANVAS_RENDER.selection = false;
    }

    canvas.selection = false;

    // console.log(canvas.getZoom());
    // canvas.setZoom(DesignRoot._CANVAS_SIZE);
    // DesignRoot._CANVAS_RENDER.setZoom(0.5);

    window.fabric.Canvas.prototype.customiseControls({
      tl: {
        action: 'rotate',
        cursor: 'pointer'
      },
      tr: {
        action: 'scale'
      },
      bl: {
        // action: 'remove',
        action: function(e, target) {
          canvas.remove(target);
          canvas.requestRenderAll();
          DesignRoot.tools.renderComponent('CONTROL_TOOLS');
          DesignRoot.Selection.setState({
            selection: null,
            selectionObject: null
          });
        },
        // action: function(e, target) {
        //   if ( window.confirm('Are you sure you want to remove this item ?') === true ) {

        //     var _OBJECT_TYPE = target._OBJECT_TYPE;
        //     canvas.remove(target);
        //     canvas.requestRenderAll();

        //     if ( _OBJECT_TYPE === 'TEXT' ) { DesignRoot.tools.renderComponent('TextStep'); }
        //     else if ( _OBJECT_TYPE === 'LOGO' ) { DesignRoot.tools.renderComponent('LogoStep'); }
        //     else if ( _OBJECT_TYPE === 'PATTERN' ) { DesignRoot.tools.renderComponent('PatternStep'); }

        //   }
        //   else {

        //     return false;
        //   }
        // },
        cursor: 'pointer'
      },
      br: {
        action: function(e, target) {
          toggleLock(target);
        },
        cursor: 'pointer'
      },
      mt: {
        // action: 'moveUp',
        action: function(e, target) {
          moveTo(target, 'moveUp');
        },
        cursor: 'pointer'
      },
      mb: {
        // action: 'moveDown',
        action: function(e, target) {
          moveTo(target, 'moveDown');
        },
        cursor: 'pointer'
      }
    });

    window.fabric.Object.prototype.setControlsVisibility({
      mtr: false,
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      tr: false,
      tl: false,
      br: false,
    });

    if (DesignRoot._APP_TYPE === '2d-3d') {
      canvas.on({
        'object:moving': updateCanvas,
        'object:scaling': updateDimension,
        'object:rotating': updateDimension,
        'object:removed': updateCanvas,
        'after:render': updateCanvas
      });

      DesignRoot._CANVAS_RENDER.on({
        'after:render': updateTexture
      });
    } else {
      canvas.on({
        'object:scaling': updateDimension,
        'object:rotating': updateDimension,
        'after:render': updateTexture
      });
    }

    // ADD DOT REF POS
    DesignRoot.rectPos = new window.fabric.Rect({
      id: 'DEBUG',
      top: 0,
      left: 0,
      width: 10,
      height: 10,
      fill: 'red',
      originX: 'center',
      originY: 'center',
      opacity: 0,
      evented: false,
      excludeFromExport: true
    });
    DesignRoot.rectPos.hascontrols = false;
    DesignRoot.rectPos.hasBorders = false;
    DesignRoot.rectPos.selectable = false;
    canvas.add(DesignRoot.rectPos);
    // ADD DOT REF POS

    drawMouseMove = new window.fabric.Rect({
      id: 'DEBUG',
      top: 0,
      left: 0,
      width: 10,
      height: 10,
      fill: 'green',
      originX: 'center',
      originY: 'center',
      opacity: 0,
      evented: false,
      excludeFromExport: true
    });
    drawMouseMove.hascontrols = false;
    drawMouseMove.hasBorders = false;
    drawMouseMove.selectable = false;
    canvas.add(drawMouseMove);

    if (DesignRoot._DOT_ACTIVE === true) {
      drawMouseMove.set({ opacity: 1 });
      DesignRoot.rectPos.set({ opacity: 1 });
    }

    intersectCheck = new window.fabric.Rect({
      id: 'DEBUG',
      top: 0,
      left: 0,
      width: 1,
      height: 1,
      fill: 'blue',
      originX: 'center',
      originY: 'center',
      opacity: 0,
      evented: false,
      excludeFromExport: true
    });
    intersectCheck.hascontrols = false;
    intersectCheck.hasBorders = false;
    intersectCheck.selectable = false;
    canvas.add(intersectCheck);

    eventControl();
    // addTemplate('template', DesignRoot._CURRENT_TEMPLATE_SRC, 0, 0);
    // addLogo('adidas_id', 'adidas_name', adidas, 684, 1084);

    DesignRoot.canvas = canvas;

    if (DesignRoot.onLoad === true || DesignRoot._LOAD_DATA !== null) {
      console.log('initFabric LOAD', DesignRoot._LOAD_DATA);
      var allItem = DesignRoot._LOAD_DATA.allItem;

      for (var i = 0; i < allItem.length; i++) {
        // addItem(id, name, src, left, top, userData, type)

        if (allItem[i].canvasData === null) {
          console.log('canvasData NULL', allItem[i]);
          continue;
        }

        if (allItem[i].objectType === 'TEXT') {

          if ( DesignRoot._SVG_ACTIVE === false || DesignRoot._SVG_BOUNDING === false ) {

            DesignRoot._TEXT_LOAD = true;
          }

        } else if (allItem[i].objectType === 'LOGO') {
          var src = allItem[i].userData.s3Url + '?t=' + new Date().getDay();
          allItem[i].userData.base64 = src;

          // BOUNDING LOAD

          if (
            allItem[i].userData.defaultItem === true ||
            allItem[i].userData.boundingId !== null
          ) {
            // var userData = allItem[i].userData;
            // var boundingId = userData.boundingId;
            // var boundingGroup = userData.boundingGroup;
            // var boundingData = getObjectById(boundingId);
            // userData.centerPoint = boundingData.getCenterPoint();
            // userData.boundingId = boundingData.id;
            // userData.boundingGroup = boundingData._ZONE_GROUP;
            // var canvasData = {
            //   left: left,
            //   top: top,
            //   scaleX: boundingData.scaleX,
            //   scaleY: boundingData.scaleY,
            //   width: boundingData.width,
            //   height: boundingData.height
            // };
            // addItem(
            //   allItem[i].id || userData.id,
            //   allItem[i].name,
            //   src,
            //   boundingData.left,
            //   boundingData.top,
            //   userData,
            //   allItem[i].objectType,
            //   'ON-LOAD',
            //   canvasData,
            //   true
            // );
            // BOUNDING LOAD
          } else if (DesignRoot._ACTIVE_LOGO_STEP === true) {
            addItem(
              allItem[i].id || allItem[i].userData.id,
              allItem[i].name,
              src,
              allItem[i].canvasData.left,
              allItem[i].canvasData.top,
              allItem[i].userData,
              allItem[i].objectType,
              'ON-LOAD',
              allItem[i].canvasData
            );
          }

          DesignRoot._S3_LOGO_SRC[allItem[i].id] = allItem[i].userData.s3Url;
        } else if (allItem[i].objectType === 'PATTERN') {
          var src = allItem[i].userData.s3Url + '?t=' + new Date().getDay();
          allItem[i].userData.base64 = src;
          addItem(
            allItem[i].id || allItem[i].userData.id,
            allItem[i].name,
            src,
            allItem[i].canvasData.left,
            allItem[i].canvasData.top,
            allItem[i].userData,
            allItem[i].objectType,
            'ON-LOAD',
            allItem[i].canvasData
          );
          DesignRoot._S3_LOGO_SRC[allItem[i].id] = allItem[i].userData.s3Url;
        }
      } // END FOR allItem
    } else {
      if (DesignRoot.isSaveEnabled) {
        console.log('save initFabric');
        save();
      }
    }

  } catch (e) {
    // statements
    console.error('fabric_function.initFabric.error', e);
  }
}

const WAIT_INTERVAL = 250;
var timerTrigger = null;

export function mutate_object_modify(target) {

  try {

    clearTimeout(timerTrigger);

    timerTrigger = setTimeout(async () => {
      console.log('FINISHED >>> TRIGGER SAVE', target);
      console.log('OBJECT MODIFY', target.id, target);

      if (DesignRoot.isSaveEnabled) {
        if (target._OBJECT_TYPE === 'TEXT') {
          const inputText = setupTexts({
            userData: target._USER_DATA,
            designAttributes: setDesignAttributes(target)
          });
          await updateTextDesignRevisionData(
            target.id,
            DesignRoot.props.productDesign.id,
            1,
            inputText,
            DesignRoot._APP_TYPE === '2d-3d'
          );
        } else if (target._OBJECT_TYPE === 'LOGO') {
          const inputLogo = setupLogo(
            {
              userData: target._USER_DATA,
              designAttributes: setDesignAttributes(target)
            },
            DesignRoot.ownedByOrganisationId
          );
          await updateLogoDesignRevisionData(
            target.id,
            DesignRoot.props.productDesign.id,
            DesignRoot.ownedByOrganisationId,
            1,
            inputLogo,
            DesignRoot._APP_TYPE === '2d-3d'
          );
        }
      }
    }, WAIT_INTERVAL);

  } catch (e) {
    // statements
    console.error('fabric_function.mutate_object_modify.error', e);
  }
}

export function eventControl() {
  try {
    // statements

    // canvas.on({
    //   'object:moved': function(evt) {
    //     console.log('object:moved', evt);
    //   }
    // });

    // canvas.on({
    //   'object:moving': function(evt) {
    //     console.log('object:moving', evt);
    //   }
    // });

    canvas.on({
      'object:modified': function(evt) {
        console.log('object:modified', evt);
        mutate_object_modify(evt.target);
        find_attach_position_element(evt.target);
      }
    });

    canvas.on({
      'mouse:move': function(evt) {
        if (DesignRoot.Main3D.onControl === true) {
          return;
        }

        // console.log('mouse:move', evt);

        drawMouseCoord(evt.absolutePointer.x, evt.absolutePointer.y, 'mouse:move', evt);

        // evt.e.stopPropagation();
        // evt.e.preventDefault();

        if (this.isDragging) {
          // this.viewportTransform[4] += evt.e.clientX - this.lastPosX;
          // this.viewportTransform[5] += evt.e.clientY - this.lastPosY;
          // this.requestrequestRenderAll();
          // this.lastPosX = evt.e.clientX;
          // this.lastPosY = evt.e.clientY;
        } else if (DesignRoot.mouseDown === true) {
          // console.log('mouse:move', evt, evt.e, evt.e.clientY);

          if (DesignRoot.targetObject !== null) {
            DesignRoot.targetObjectPos = {
              left: evt.e.clientX,
              top: evt.e.clientY
            };
            DesignRoot.targetObjectHover = evt.target;
          }
        } else {
          // console.log('mouse:move', evt, evt.target);

          if (DesignRoot.onAddLogo === true) {
            // if (document.body.style.cursor !== 'crosshair') {
            //   document.body.style.cursor = 'crosshair';
            // }
            return;
          }

          if (evt.target) {
            DesignRoot.targetObjectHover = evt.target;

            if (evt.target.__corner === 0) {
              // console.log('cursor', document.body.style.cursor);

              if (document.body.style.cursor !== 'move') {
                // document.body.style.cursor = 'move';
              }
            } else if (evt.target.__corner === 'bl') {
              if (
                document.body.style.cursor !== 'not-allowed' &&
                DesignRoot._DEV_TOOL === false
              ) {
                document.body.style.cursor = 'not-allowed';
              }
            } else if (evt.target.__corner === 'br') {
              if (
                document.body.style.cursor !== 'pointer' &&
                DesignRoot._DEV_TOOL === false
              ) {
                document.body.style.cursor = 'pointer';
              }
            } else {
              if (
                document.body.style.cursor !== 'pointer' &&
                DesignRoot._DEV_TOOL === false
              ) {
                document.body.style.cursor = 'pointer';
              }
            }
          } else {
            DesignRoot.targetObjectHover = null;

            if (
              document.body.style.cursor !== 'default' &&
              DesignRoot._DEV_TOOL === false
            ) {
              document.body.style.cursor = 'default';
            }
          }
        }
      }
    });

    canvas.on({
      'mouse:down': function(evt) {
        drawMouseCoord(evt.absolutePointer.x, evt.absolutePointer.y, 'mouse:down', evt);

        // mouseDown = true;
        // console.log('mouse:down', evt, DesignRoot.mouseDown, evt.e.clientX, evt.e.clientY, );
        // console.log('mouse:down', evt);

        if (evt.e.ctrlKey === true) {
          this.isDragging = true;
          this.lastPosX = evt.e.clientX;
          this.lastPosY = evt.e.clientY;
        } else {
          if (DesignRoot.mouseDown === false) {
            if (evt.target) {
              DesignRoot.Main3D.ThreejsFunction.drawCanvasXY(
                evt.absolutePointer.x,
                evt.absolutePointer.y,
                'click',
                evt
              );

              console.log('target', evt.target);
              // console.log('target __corner', evt.target.__corner)
              DesignRoot.targetControl = evt.target.__corner;
              DesignRoot.targetObject = evt.target;
              DesignRoot.targetObjectPos = {
                left: evt.e.clientX,
                top: evt.e.clientY
              };
              DesignRoot.targetObjectHover = evt.target;

              if (
                evt.target._DEFAULT_ITEM &&
                evt.target._DEFAULT_ITEM === true
              ) {
                evt.target.setControlsVisibility({
                  tl: false,
                  tr: false,
                  bl: false,
                  br: false,
                  mt: false,
                  mb: false
                });
              } else {
                evt.target.setControlsVisibility({
                  tl: true,
                  tr: true,
                  bl: true,
                  br: false,
                  mt: false,
                  mb: false
                });
              }

              DesignRoot.Selection.setState({
                selection: DesignRoot.targetObject.id,
                selectionObject: DesignRoot.targetObject
              });
            } else {
              DesignRoot.targetControl = null;
              DesignRoot.targetObject = null;
              DesignRoot.targetObjectPos = null;
              DesignRoot.targetObjectHover = null;
              DesignRoot.Selection.setState({
                selection: null,
                selectionObject: null
              });
            }
          } else if (DesignRoot.targetObject !== null) {
            DesignRoot.targetObjectPos = {
              left: evt.e.clientX,
              top: evt.e.clientY
            };
            DesignRoot.targetObjectHover = evt.target;
          }

          if (DesignRoot.onAddLogo === true) {
            DesignRoot.onAddLogoObject.set({
              left: drawMouseMove.left,
              top: drawMouseMove.top
            });
            canvas.add(DesignRoot.onAddLogoObject);
            canvas.setActiveObject(DesignRoot.onAddLogoObject);
            canvas.bringToFront(DesignRoot.onAddLogoObject);
            DesignRoot.canvas.requestRenderAll();
            DesignRoot.Selection.setState({
              selection: DesignRoot.onAddLogoObject.id,
              selectionObject: DesignRoot.onAddLogoObject
            });

            var canvasData = {
              top: DesignRoot.onAddLogoObject.top,
              left: DesignRoot.onAddLogoObject.left,
              width: DesignRoot.onAddLogoObject.width,
              height: DesignRoot.onAddLogoObject.height,
              scaleX: DesignRoot.onAddLogoObject.scaleX,
              scaleY: DesignRoot.onAddLogoObject.scaleY,
              angle: DesignRoot.onAddLogoObject.angle,
              flipX: DesignRoot.onAddLogoObject.flipX,
              flipY: DesignRoot.onAddLogoObject.flipY,
              lock: DesignRoot.onAddLogoObject.lockMovementX
            };
            if (DesignRoot.isSaveEnabled) {
              if (DesignRoot.onAddLogoObject._OBJECT_TYPE === 'LOGO') {
                const inputLogo = setupLogo(
                  {
                    userData: DesignRoot.onAddLogoObject._USER_DATA,
                    designAttributes: canvasData
                  },
                  DesignRoot.ownedByOrganisationId
                );
                updateLogoDesignRevisionData(
                  DesignRoot.onAddLogoObject.id,
                  DesignRoot.props.productDesign.id,
                  DesignRoot.ownedByOrganisationId,
                  1,
                  inputLogo,
                  DesignRoot._APP_TYPE === '2d-3d'
                );
              } else if (DesignRoot.onAddLogoObject._OBJECT_TYPE === 'TEXT') {
                const inputText = setupTexts({
                  userData: DesignRoot.onAddLogoObject._USER_DATA,
                  designAttributes: canvasData
                });
                updateTextDesignRevisionData(
                  DesignRoot.onAddLogoObject.id,
                  DesignRoot.props.productDesign.id,
                  1,
                  inputText,
                  DesignRoot._APP_TYPE === '2d-3d'
                );
              }
            }

            find_attach_position_element(DesignRoot.onAddLogoObject);

            DesignRoot.onAddLogo = false;
            DesignRoot.onAddLogoObject = null;

            DesignRoot.tools.renderComponent('CONTROL_TOOLS');
          }
        } // END ELSE ctrlKey
      }
    });

    canvas.on({
      'mouse:up': function(evt) {
        // console.log('mouse:up', evt);
        // mouseDown = false;
        // console.log('mouse:up');
        this.isDragging = false;
        DesignRoot.onAddLogo = false;
        DesignRoot.onAddLogoObject = null;

        // if (DesignRoot.mouseDown === false) {

        //   var evt = new MouseEvent('mouseup', {
        //     bubbles: true
        //   });
        //   document.querySelector('#THREEJS').dispatchEvent(evt);
        // }
      }
    });

    if (DesignRoot._APP_TYPE === '2d-3d') {
      canvas.on('mouse:wheel', function(opt) {
        if (opt.e.altKey === true) {
          opt.e.preventDefault();
          opt.e.stopPropagation();

          console.log('mouse:wheel', opt.e, opt.e.deltaY, canvas);

          var delta = opt.e.deltaY;
          var zoom = canvas.getZoom();

          if (delta < 0) {
            // ZOMM IN

            zoom += 0.01;
          } else if (delta > 0) {
            // ZOOM OUT

            zoom -= 0.01;
          }

          DesignRoot._CANVAS_SIZE = zoom;
          canvas.setZoom(zoom);

          DesignRoot.canvas.setDimensions({
            width: DesignRoot._CANVAS_RENDER.width * DesignRoot._CANVAS_SIZE,
            height: DesignRoot._CANVAS_RENDER.height * DesignRoot._CANVAS_SIZE
          });
        } else if (opt.e.ctrlKey === true) {
          opt.e.preventDefault();
          opt.e.stopPropagation();

          var PLANE_2D = document.querySelector('#PLANE_2D');

          if (opt.e.deltaY > 0) PLANE_2D.scrollLeft += 50;
          else PLANE_2D.scrollLeft -= 50;
        }
      });
    }
  } catch (e) {
    // statements
    console.error('fabric_function.eventControl.error', e);
  }
}

export function addTemplate(id, src, left, top) {
  try {
    // statements

    console.log('addTemplate', id, src, left, top);

    if (DesignRoot._2D_BUILD === false) {
      return;
    }

    if (templateBase !== null) {
      canvas.remove(templateBase);
      templateBase = null;
    }

    var image = document.createElement('img');
    image.src = src;
    image.crossOrigin = 'Anonymous';
    window.fabric.Image.fromURL(
      image.src,
      function(img) {
        // canvas.setDimensions({
        //   width: img.width * DesignRoot._CANVAS_SIZE,
        //   height: img.height * DesignRoot._CANVAS_SIZE
        // });

        if (img.width <= 0 || img.height <= 0) {
          img.width = 1;
          img.height = 1;
        }

        if (DesignRoot._APP_TYPE === '2d-3d') {
          // RENDER SCENE 100%
          DesignRoot._CANVAS_RENDER.setDimensions({
            width: img.width,
            height: img.height
          });
        } else {
          canvas.setDimensions({
            width: img.width * DesignRoot._CANVAS_SIZE,
            height: img.height * DesignRoot._CANVAS_SIZE
          });
        }

        img.set({
          id: id,
          left: left,
          top: top
        });

        img.hascontrols = false;
        img.hasBorders = false;
        img.selectable = false;

        canvas.add(img);
        // canvas.sendToBack(img);

        templateBase = img;

        console.log('templateBase', templateBase);

        DesignRoot.canvas.requestRenderAll();

        if (DesignRoot._SVG_ACTIVE === true) {
          svg_control.addSVG(
            'svg',
            DesignRoot._CURRENT_SVG_SRC,
            0,
            0,
            canvas,
            img.width,
            img.height
          );
        } // END IF SVG ACTIVE
        else {
          fitInScene();
        }



        // LOGO OVERLAY + SEAM
        
        if ( DesignRoot._CURRENT_LOGO_SRC !== null ) {

          // CHANGE TO BASE64 FOR EXPORT REASON

          var image_url = DesignRoot.tools._wrapTimeToSrc(DesignRoot._CURRENT_LOGO_SRC);

          var load_overlay = new Image();
          load_overlay.crossOrigin = 'anonymous';
          load_overlay.onload = function() {

            var base64Img = tools._urlToBase64(load_overlay);
            addOverlay(
              'template',
              base64Img,
              0,
              0
            );

          };
          load_overlay.src = image_url;

        }

        if ( DesignRoot._CURRENT_SEAM_SRC !== null ) {

          // CHANGE TO BASE64 FOR EXPORT REASON

          var image_url = DesignRoot.tools._wrapTimeToSrc(DesignRoot._CURRENT_SEAM_SRC);

          var load_seam = new Image();
          load_seam.crossOrigin = 'anonymous';
          load_seam.onload = function() {

            var base64Img = tools._urlToBase64(load_seam);
            addSeam(
              'template',
              base64Img,
              0,
              0
            );

          };
          load_seam.src = image_url;

        }

        // LOGO OVERLAY + SEAM




        reArrageItem();
      },
      { crossOrigin: 'Anonymous' }
    );
  } catch (e) {
    // statements
    console.error('fabric_function.addTemplate.error', e);
  }
}

export function addBlankTemplate(id, width, height, left, top) {
  try {
    // statements
    if (DesignRoot._2D_BUILD === false) {
      return;
    }

    if (templateBase !== null) {
      canvas.remove(templateBase);
      templateBase = null;
    }

    var blankDimension = { width: width, height: height };

    if (DesignRoot._SVG_ACTIVE === true) {
      svg_control.addSVG(
        'svg',
        DesignRoot._CURRENT_SVG_SRC,
        0,
        0,
        canvas,
        blankDimension.width,
        blankDimension.height
      );
    } // END IF SVG ACTIVE


    if ( SHOP_ID === 'bodywearlab' && DesignRoot._CURRENT_SEAM_SRC !== null ) {

      // addSeam("template", DesignRoot._CURRENT_SEAM_SRC, 0, 0);
    }

    // LOGO OVERLAY + SEAM
        
    if ( DesignRoot._CURRENT_LOGO_SRC !== null ) {

      // CHANGE TO BASE64 FOR EXPORT REASON

      var image_url = DesignRoot.tools._wrapTimeToSrc(DesignRoot._CURRENT_LOGO_SRC);

      var load_overlay = new Image();
      load_overlay.crossOrigin = 'anonymous';
      load_overlay.onload = function() {

        var base64Img = tools._urlToBase64(load_overlay);
        addOverlay(
          'template',
          base64Img,
          0,
          0
        );

      };
      load_overlay.src = image_url;

    }

    if ( DesignRoot._CURRENT_SEAM_SRC !== null ) {

      // CHANGE TO BASE64 FOR EXPORT REASON

      var image_url = DesignRoot.tools._wrapTimeToSrc(DesignRoot._CURRENT_SEAM_SRC);

      var load_seam = new Image();
      load_seam.crossOrigin = 'anonymous';
      load_seam.onload = function() {

        var base64Img = tools._urlToBase64(load_seam);
        addSeam(
          'template',
          base64Img,
          0,
          0
        );

      };
      load_seam.src = image_url;

    }

    // LOGO OVERLAY + SEAM

    reArrageItem();
  } catch (e) {
    // statements
    console.error('fabric_function.addBlankTemplate.error', e);
  }
}

export function addOverlay(id, src, left, top) {
  try {

    console.log('addOverlay', id, src, DesignRoot._LOGO_TOGGLE, templateBase, templateBase.width);
    // statements
    if (DesignRoot._2D_BUILD === false) {
      return;
    }

    if (
      DesignRoot._LOGO_TOGGLE === false &&
      DesignRoot._OVERLAY_BASE !== null
    ) {
      canvas.remove(DesignRoot._OVERLAY_BASE);
      DesignRoot._OVERLAY_BASE = null;
      DesignRoot.canvas.requestRenderAll();
    } else if (
      DesignRoot._LOGO_TOGGLE === true &&
      DesignRoot._OVERLAY_BASE === null
    ) {
      var image = document.createElement('img');
      image.crossOrigin = 'anonymous';
      image.src = src;
      window.fabric.Image.fromURL(
        image.src,
        function(img) {

          img.set({
            id: id,
            left: left,
            top: top,
            evented: false,
            _USER_DATA: {type: 'OVERLAY'}
          });

          img.hascontrols = false;
          img.hasBorders = false;
          img.selectable = false;

          canvas.add(img);

          if (templateBase !== null) {

            img.scaleToWidth(templateBase.width * DesignRoot._CANVAS_SIZE);
            // canvas.sendToBack(img);
            // canvas.sendToBack(templateBase);
          }
          else {
            
            img.scaleToWidth(3000  * DesignRoot._CANVAS_SIZE); // IF BLANK TEMPLATE
          }

          DesignRoot._OVERLAY_BASE = img;

          console.log('DesignRoot._OVERLAY_BASE', DesignRoot._OVERLAY_BASE, img.width);

          DesignRoot.canvas.requestRenderAll();

          reArrageItem();
        },
        { crossOrigin: 'Anonymous' }
      );
    }
  } catch (e) {
    // statements
    console.error('fabric_function.addOverlay.error', e);
  }
}

export function addSeam(id, src, left, top) {
  try {
    // statements

    if (DesignRoot._2D_BUILD === false || DesignRoot._CURRENT_SEAM_SRC === null) {
      return;
    }

    if (DesignRoot._SEAM_TOGGLE === false && redlineBase !== null) {
      canvas.remove(redlineBase);
      redlineBase = null;
      DesignRoot.canvas.requestRenderAll();
    } else if (
      DesignRoot._SEAM_TOGGLE === true &&
      redlineBase === null &&
      src !== null
    ) {
      var image = document.createElement('img');
      image.crossOrigin = 'anonymous';
      image.src = src;
      window.fabric.Image.fromURL(
        image.src,
        function(img) {
          // canvas.setDimensions({ width: img.width, height: img.height });

          img.set({
            id: id,
            left: left,
            top: top,
            evented: false,
            opacity: 0.3,
            _USER_DATA: {type: 'SEAM'}
          });

          img.hascontrols = false;
          img.hasBorders = false;
          img.selectable = false;

          canvas.add(img);

          if (templateBase !== null) {

            img.scaleToWidth(templateBase.width  * DesignRoot._CANVAS_SIZE);

            // canvas.sendToBack(img);

            // if (overlayBase !== null) {
            //   canvas.sendToBack(overlayBase);
            // }

            // canvas.sendToBack(templateBase);
          }
          else {

            img.scaleToWidth(3000  * DesignRoot._CANVAS_SIZE); // IF BLANK TEMPLATE
          }

          redlineBase = img;

          console.log('redlineBase', redlineBase);

          DesignRoot.canvas.requestRenderAll();

          reArrageItem();
        },
        { crossOrigin: 'Anonymous' }
      );
    }
  } catch (e) {
    // statements
    console.error('fabric_function.addSeam.error', e);
  }
}

export function addItem(
  id,
  name,
  src,
  left,
  top,
  userData,
  type,
  option,
  canvasData,
  defaultItem
) {
  try {
    // statements

    if (DesignRoot._2D_BUILD === false) {
      return;
    }

    // CHECK DUPLICATE

    var checkDupObject = getObjectById(id);

    if (checkDupObject !== null) {
      console.log('DUPLICATE OBJECT', checkDupObject, id);
      return false;
    }

    // CHECK DUPLICATE

    if (defaultItem) {
      defaultItem = defaultItem;
    } else {
      defaultItem = false;
    }

    if ( type === 'PATTERN' ) { // SOME PATTERN HAVE BIG FILE SO MAKE LOADING

      DesignRoot.Loading.activeLoading(true);
    }

    var image = document.createElement('img');
    image.crossOrigin = 'anonymous';
    image.src = src;
    window.fabric.Image.fromURL(
      image.src,
      async function(img) {
        console.log('addItem', img);

        const newCanvas = {
          ...canvasData,
          top,
          left
        };

        let newId = id || uuidv4();
        if (
          !DesignRoot.localData &&
          option !== 'ON-LOAD'
        ) {
          userData.orderSeq = orderObject('RETURN_NEXT_ORDER');
          if (type === 'TEXT') {
            const inputText = setupTexts({
              userData,
              designAttributes: newCanvas
            });
            const responseTextData = await createTextDesignRevisionData(
              DesignRoot.props.productDesign.id,
              1,
              inputText,
              DesignRoot._APP_TYPE === '2d-3d'
            );
            newId = responseTextData.data.createTextDesignRevision.id;
          } else if (type === 'LOGO') {
            const inputLogo = setupLogo(
              { userData, designAttributes: newCanvas },
              DesignRoot.ownedByOrganisationId
            );
            const responseLogoData = await createLogoDesignRevisionData(
              DesignRoot.props.productDesign.id,
              DesignRoot.ownedByOrganisationId,
              1,
              inputLogo,
              DesignRoot._APP_TYPE === '2d-3d'
            );
            newId = responseLogoData.data.createLogoDesignRevision.id;
          }
        }

        img.set({
          id: newId,
          name: name,
          left: left,
          top: top,
          padding: 10,
          // scaleX: 0.2,
          // scaleY: 0.2,
          // width: new_width,
          // height: new_height,
          originX: 'center',
          originY: 'center',
          cornerStrokeColor: '#fdcc00',
          _USER_DATA: userData,
          _OBJECT_TYPE: type,
          _DEFAULT_ITEM: defaultItem
        });

        if ( type === 'TEXT' || type === 'LOGO' ) {

          // attachPosition
          img._USER_DATA.attachPosition = [];
        }

        if ( userData.visible === undefined || userData.visible === null || userData.visible === true ) {

          img.set({visible: true});
        }
        else if (userData.visible === false) {

          img.set({visible: false});
        }

        img = assignCustomiseCornerIcons(img);

        // ITEM EVENT

        // canvas.on({'object:moved': function(evt) {

        // }});

        // ITEM EVENT

        if (defaultItem === true) {
          img.lockMovementX = true;
          img.lockMovementY = true;
          img.lockScalingX = true;
          img.lockScalingY = true;
          // img.lockUniScaling = true;
          img.lockRotation = true;

          img.hascontrols = false;

          img.setControlsVisibility({
            tl: false,
            tr: false,
            bl: false,
            br: false,
            mt: false,
            mb: false
          });
        }

        if (option && option === 'ON-LOAD') {
          img.set({
            // width: canvasData.width,
            // height: canvasData.height,
            scaleX: canvasData.scaleX,
            scaleY: canvasData.scaleY,
            angle: canvasData.angle,
            flipX: canvasData.flipX,
            flipY: canvasData.flipY
          });

          if (defaultItem === false && canvasData.lock === true) {
            img.lockMovementX = true;
            img.lockMovementY = true;
            img.lockScalingX = true;
            img.lockScalingY = true;
            img.lockUniScaling = true;
            img.lockRotation = true;
          }

          if (defaultItem === true) {
            img.set({
              left: userData.centerPoint.x,
              top: userData.centerPoint.y
              // originX: 'left',
              // originY: 'top'
            });

            img._KEEP_SCALE_W = img.width * canvasData.scaleX;
            img._KEEP_SCALE_H = img.height * canvasData.scaleX;
            img._KEEP_CENTER = img.getCenterPoint();
            img._BOUNDING_ID = userData.boundingId;
            img._BOUNDING_GROUP = userData.boundingGroup;

            if (img.width > img.height) {
              img.scaleToWidth(canvasData.width * canvasData.scaleX);
            } else {
              img.scaleToHeight(canvasData.height * canvasData.scaleX);
            }

            adjust_bounding_size(canvasData, img);
          }

          canvas.add(img);
          canvas.requestRenderAll();

          if (type === 'TEXT' || type === 'LOGO') {
            // orderObject('REMAP_ORDER');
            orderObject('REARRANGE_ORDER');
          }

          DesignRoot.tools.renderComponent('CONTROL_TOOLS');
          DesignRoot.tools.renderComponent('PatternStep');
        } else if (type === 'PATTERN') {
          // MAP SAME WITH TEMPLATE BASE

          if (DesignRoot._ACTIVE_COLOR_ZONE === true) {
            img.scaleToWidth(templateBase.width); // MAP ONLY WIDTH
            img.set({
              top: templateBase.top,
              left: templateBase.left,
              originX: 'left',
              originY: 'top'
            });
          } else {
            img.scaleToWidth(3000); // MAP ONLY WIDTH
            img.set({
              originX: 'left',
              originY: 'top'
            });
          }

          toggleLock(img);

          // MAP SAME WITH TEMPLATE BASE

          canvas.add(img);
          canvas.setActiveObject(img);
          canvas.requestRenderAll();

          DesignRoot.tools.renderComponent('PatternStep');

          DesignRoot.Loading.activeLoading(false);

        } else if (defaultItem === true) {
          // LOGIC OF MULTIPLE ITEM IN 1 ZONE

          var boundingId = userData.boundingId;
          var boundingGroup = userData.boundingGroup;

          var newCanvasData = bounding_multiple_item_process(
            type,
            boundingId,
            boundingGroup,
            'ADD'
          );

          console.log('newCanvasData', newCanvasData);

          if (newCanvasData !== false && newCanvasData !== undefined) {
            canvasData = newCanvasData;
            userData.centerPoint = canvasData.centerPoint;
          }

          // LOGIC OF MULTIPLE ITEM IN 1 ZONE

          // canvasData.centerPoint

          img.set({
            left: userData.centerPoint.x,
            top: userData.centerPoint.y
            // originX: 'left',
            // originY: 'top'
          });

          img._KEEP_SCALE_W = img.width * canvasData.scaleX;
          img._KEEP_SCALE_H = img.height * canvasData.scaleX;
          img._KEEP_CENTER = img.getCenterPoint();
          img._BOUNDING_ID = userData.boundingId;
          img._BOUNDING_GROUP = userData.boundingGroup;

          if (img.width > img.height) {
            img.scaleToWidth(canvasData.width * canvasData.scaleX);
          } else {
            img.scaleToHeight(canvasData.height * canvasData.scaleX);
          }

          adjust_bounding_size(canvasData, img);

          canvas.add(img);
          canvas.setActiveObject(img);
          DesignRoot.Selection.setState({
            selection: img.id,
            selectionObject: img
          });
          canvas.requestRenderAll();
          reArrageItem();
          DesignRoot.tools.renderComponent('CONTROL_TOOLS');
          DesignRoot.setState({render: true});
        } else {
          if (option && option === 'DUPLICATE') {
            img.set({
              width: canvasData.width,
              height: canvasData.height,
              scaleX: canvasData.scaleX,
              scaleY: canvasData.scaleY
            });
          } else {
            img.scaleToWidth(250); // DEFAULT
          }

          DesignRoot.onAddLogo = true;
          DesignRoot.onAddLogoObject = img;

          swal({
            icon: 'info',
            text:
              'Click the model position, where the ' +
              type +
              ' should be placed.',
            button: 'Gotcha!'
          });
        }

        // canvas.add(img);
        // updateTexture();

        reArrageItem();
      },
      { crossOrigin: 'Anonymous' }
    );
  } catch (e) {
    // statements
    console.error('fabric_function.addItem.error', e);
  }
}

export function updateItem(
  id,
  name,
  src,
  left,
  top,
  flipX,
  flipY,
  userData,
  type
) {
  try {
    // statements

    if (DesignRoot._2D_BUILD === false) {
      return;
    }

    var item = getObjectById(id);
    console.log('****getObjectById', item, id);

    if (item !== null) {
      if (type === 'LOGO' || type === 'PATTERN') {
        item.set({
          name: name,
          _USER_DATA: userData
        });
        if (flipX) {
          item.set({
            flipX: !item.flipX
          });
        }
        if (flipY) {
          item.set({
            flipY: !item.flipY
          });
        }
        DesignRoot.canvas.requestRenderAll();
        updateDimension(id, 'byID');
        DesignRoot.tools.renderComponent('LogoStep');
        DesignRoot.tools.renderComponent('PatternStep');

        if (DesignRoot.isSaveEnabled) {
          if (type === 'LOGO') {
            const inputLogo = setupLogo(
              {
                userData,
                designAttributes: setDesignAttributes(item)
              },
              DesignRoot.ownedByOrganisationId
            );
            updateLogoDesignRevisionData(
              id,
              DesignRoot.props.productDesign.id,
              DesignRoot.ownedByOrganisationId,
              1,
              inputLogo,
              DesignRoot._APP_TYPE === '2d-3d'
            );
          }
        }
      } else if (type === 'TEXT') {
        var image = new Image();
        image.onload = async function() {
          if (image.width < image.height) {
            var ratio = image.width / image.height;
          } else {
            var ratio = image.height / image.width;
          }

          var new_width = item.width; // KEEP BY WIDTH
          var new_height = type !== 'LOGO' ? ratio * new_width : item.height;

          item._element.src = src;
          item.set({
            name: name,
            width: image.width,
            height: image.height,
            _USER_DATA: userData
          });
          if (flipX) {
            item.set({
              flipX: !item.flipX
            });
          }
          if (flipY) {
            item.set({
              flipY: !item.flipY
            });
          }

          if (item._DEFAULT_ITEM && item._DEFAULT_ITEM === true) {
            var bounding = getObjectById(item._BOUNDING_ID);

            if (image.width > image.height) {
              item.scaleToWidth(bounding.width * bounding.scaleX);
            } else {
              item.scaleToHeight(bounding.height * bounding.scaleX);
            }

            adjust_bounding_size(bounding, item);

            // if (image.width > image.height) {
            //   var newScale = item._KEEP_SCALE_W / image.width;
            // } else {
            //   var newScale = item._KEEP_SCALE_H / image.height;
            // }

            // item.set({
            //   scaleX: newScale,
            //   scaleY: newScale
            // });
          }

          item.setCoords();

          DesignRoot.canvas.requestRenderAll();
          updateDimension(id, 'byID');
          DesignRoot.tools.renderComponent('TextStep');

          if (DesignRoot.isSaveEnabled) {
            const inputText = setupTexts({
              userData,
              designAttributes: setDesignAttributes(item)
            });
            const responseTextData = await updateTextDesignRevisionData(
              id,
              DesignRoot.props.productDesign.id,
              1,
              inputText,
              DesignRoot._APP_TYPE === '2d-3d'
            );
          }
        };
        image.src = src;
      } // ELSE IF TEXT
    }
  } catch (e) {
    // statements
    console.error('fabric_function.updateItem.error', e);
  }
}

export function removeItem(id) {
  try {
    // statements
    const item = getObjectById(id);

    if (item._DEFAULT_ITEM && item._DEFAULT_ITEM === true) {
      var boundingId = item._BOUNDING_ID;
      var boundingGroup = item._BOUNDING_GROUP;
      var type = item._OBJECT_TYPE;

      canvas.remove(item);

      bounding_multiple_item_process(type, boundingId, boundingGroup, 'REMOVE');
    } else {
      canvas.remove(item);
    }

    orderObject('ORDER_CHANGE');

    if (DesignRoot.isSaveEnabled) {
      if (item._OBJECT_TYPE === 'TEXT') {
        deleteTextDesignRevisionData(
          item.id,
          DesignRoot.props.productDesign.id,
          1,
          DesignRoot._APP_TYPE === '2d-3d'
        );
      } else if (item._OBJECT_TYPE === 'LOGO') {
        deleteLogoDesignRevisionData(
          item.id,
          DesignRoot.props.productDesign.id,
          DesignRoot.ownedByOrganisationId,
          1,
          DesignRoot._APP_TYPE === '2d-3d'
        );
      }
    }
  } catch (e) {
    // statements
    console.error('fabric_function.removeItem.error', e);
  }
}

export function rePosition(id) {
  try {
    // statements
    const item = getObjectById(id);
    // item.scaleToWidth(100); // DEFAULT

    DesignRoot.onAddLogo = true;
    DesignRoot.onAddLogoObject = item;

    swal({
      icon: 'info',
      text:
        'Click the model position, where the ' +
        item._OBJECT_TYPE +
        ' should be placed.',
      button: 'Gotcha!'
    });

    removeItem(id);
  } catch (e) {
    // statements
    console.error('fabric_function.rePosition.error', e);
  }
}

export function assignCustomiseCornerIcons(item, option) {
  try {
    // statements

    item.setControlsVisibility({
      tl: true,
      tr: true,
      bl: true,
      br: true
    });

    // overwrite the prototype object based
    item.customiseCornerIcons(
      {
        settings: {
          borderColor: '#fdcc00',
          cornerSize: 23,
          cornerShape: 'rect',
          cornerBackgroundColor: '#fdcc00',
          cornerPadding: 10
          // cornerStrokeColor: '#2f2f2f'
        },
        tl: {
          icon: rotate
        },
        tr: {
          icon: resize
        },
        bl: {
          icon: remove
        },
        br: {
          icon: lock
        },
        mt: {
          icon: up
        },
        mb: {
          icon: down
        }
      },
      function() {
        DesignRoot.canvas.requestRenderAll();
      }
    );

    if (option && option === 'MARKER_LAYOUT') {
      // item.setControlsVisibility({
      //   tr: false,
      //   bl: false,
      // });
    }

    return item;
  } catch (e) {
    // statements
    console.error('fabric_function.assignCustomiseCornerIcons.error', e);
  }
}

export function drawUVCoord(X, Y, option, event) {
  try {
    // statements

    if (DesignRoot._2D_BUILD === false) {
      return;
    }

    DesignRoot.rectPos.set({
      left: X,
      top: Y
    });

    // canvas.bringToFront(DesignRoot.rectPos);
    // canvas.requestRenderAll();
  } catch (e) {
    // statements
    console.error('fabric_function.drawUVCoord.error', e);
  }
}

export function drawMouseCoord(X, Y, option, event) {
  try {
    // statements

    if (DesignRoot._2D_BUILD === false) {
      return;
    }

    drawMouseMove.set({
      left: X,
      top: Y
    });

    if (DesignRoot._DOT_ACTIVE === true) {
      canvas.bringToFront(drawMouseMove);
      canvas.requestRenderAll();
    }




    // if ( DesignRoot.ColorPicker !== null && DesignRoot._COLOR_PICKER === true ) {

    //   if ( DesignRoot.ColorPicker['FillStep_COLORZONE'] ) {

    //     pickup_color_picker(DesignRoot.ColorPicker['FillStep_COLORZONE'], X, Y, option);
    //   }

    //   if ( DesignRoot.ColorPicker['FillStep_SVG'] ) {

    //     pickup_color_picker(DesignRoot.ColorPicker['FillStep_SVG'], X, Y, option);
    //   }

    //   if ( DesignRoot.ColorPicker['TextStep_COLOR'] ) {

    //     pickup_color_picker(DesignRoot.ColorPicker['TextStep_COLOR'], X, Y, option);
    //   }

    //   if ( DesignRoot.ColorPicker['TextStep_OUTLINECOLOR'] ) {

    //     pickup_color_picker(DesignRoot.ColorPicker['TextStep_OUTLINECOLOR'], X, Y, option);
    //   }

    // }

  } catch (e) {
    // statements
    console.error('fabric_function.drawUVCoord.error', e);
  }
}

export function pickup_color_picker(ColorPickerComponent, X, Y, option) {

  try {

    if ( ColorPickerComponent.state.colorPickerActive === true ) {

      var ctx = canvas.getContext("2d");
      var pxData = ctx.getImageData(X, Y, 1, 1);

      var hex = DesignRoot.canvas_coloring.rgbToHex(
        pxData.data[0],
        pxData.data[1],
        pxData.data[2]
      );

      ColorPickerComponent.setState({colorPreview: hex});

      if ( option === 'mouse:down' ) {

        var colorPicker = ColorPickerComponent.state.colorPicker;

        var index = colorPicker.indexOf(hex);
        if (index > -1) {
          colorPicker.splice(index, 1);
        }
        colorPicker.unshift(hex);
        colorPicker = colorPicker.slice(0,5);

        console.log(colorPicker);

        ColorPickerComponent.setState({colorPicker: colorPicker});
      }

    }

  } catch (e) {
    // statements
    console.error('fabric_function.pickup_color_picker.error', e);
  }
}

export function exportFabricCanvas() {
  // console.log(canvas.toDataURL('png'));

  try {
    // statements
    return canvas.toDataURL('png');
  } catch (e) {
    // statements
    console.error('fabric_function.exportFabricCanvas.error', e);
  }
}

export function updateTexture(option, x) {
  try {
    // statements

    if (DesignRoot._2D_BUILD === false) {
      return;
    }

    if (
      DesignRoot._3D_UPDATE === false ||
      DesignRoot.Main3D === null ||
      DesignRoot._UI_ONLY === true
    ) {
      return;
    }

    // DesignRoot._CANVAS_RENDER._objects = DesignRoot.canvas._objects;
    // DesignRoot._CANVAS_RENDER.lowerCanvasEl = _cloneDeep(DesignRoot.canvas.lowerCanvasEl);

    // if ( DesignRoot.canvas._activeObject ) {

    //   console.log('_activeObject', DesignRoot.canvas._activeObject);
    //   DesignRoot._CANVAS_RENDER._activeObject = _cloneDeep(DesignRoot.canvas._activeObject);

    //   var bl = DesignRoot._CANVAS_RENDER._activeObject.aCoords.bl;
    //   var br = DesignRoot._CANVAS_RENDER._activeObject.aCoords.br;
    //   var tl = DesignRoot._CANVAS_RENDER._activeObject.aCoords.tl;
    //   var tr = DesignRoot._CANVAS_RENDER._activeObject.aCoords.tr;

    //   DesignRoot._CANVAS_RENDER._activeObject.aCoords.bl = {x: bl.x * 2, y: bl.y * 2}
    //   DesignRoot._CANVAS_RENDER._activeObject.aCoords.br = {x: br.x * 2, y: br.y * 2}
    //   DesignRoot._CANVAS_RENDER._activeObject.aCoords.tl = {x: tl.x * 2, y: tl.y * 2}
    //   DesignRoot._CANVAS_RENDER._activeObject.aCoords.tr = {x: tr.x * 2, y: tr.y * 2}
    //   // DesignRoot._CANVAS_RENDER.setActiveObject(DesignRoot.canvas._activeObject);
    // }

    // DesignRoot._CANVAS_RENDER.requestRenderAll();

    // console.log('updateTexture', DesignRoot.canvas, DesignRoot._CANVAS_RENDER);

    // console.log('updateTexture Main3D.storeMappingChild', DesignRoot.Main3D.storeMappingChild);

    if (DesignRoot._TRIGGER_EXPORT === true) {
      if (DesignRoot._APP_TYPE === '2d-3d') {
        DesignRoot.template_control.export2DSceneTIFF_RealSize(
          DesignRoot._CANVAS_RENDER
        );
      } else {
        console.log(
          'CANVAS DATA',
          DesignRoot.canvas,
          DesignRoot.canvas.getZoom()
        );

        setTimeout(() => {
          DesignRoot.template_control.export2DSceneTIFF_RealSize(
            DesignRoot.canvas
          );
        }, 2000);
      }

      DesignRoot._TRIGGER_EXPORT = false;
    }

    var storeMappingChild = DesignRoot.Main3D.storeMappingChild;
    if (storeMappingChild.length) {
      for (var i = 0; i < storeMappingChild.length; i++) {
        // storeMappingChild[i].map.image = DesignRoot.template_control.export2DScene('RETURN');

        if (storeMappingChild[i].map.image.nodeName !== 'CANVAS') {
          console.log(
            'REMAP CANVAS',
            document.getElementById(DesignRoot.canvasMainDom)
          );

          storeMappingChild[i].map.image = document.getElementById(
            DesignRoot.canvasMainDom
          );
        }

        // EXCLUDE
        // 68211af4-a991-4e5e-8bd7-76740839e938 // BIB
        // 683cb3b3-2dd4-4b32-8d8e-fd0a7c24af05 // JERSEY

        // if (SHOP_ID === 'vermarc' && (DesignRoot.productID !== '68211af4-a991-4e5e-8bd7-76740839e938' && DesignRoot.productID !== '683cb3b3-2dd4-4b32-8d8e-fd0a7c24af05')) {
        //   storeMappingChild[i].map.needsUpdate = true;
        //   console.log('RENDER ALL', DesignRoot.productID);
        // }
      }

      // if (true) {
      //   storeMappingChild[0].map.needsUpdate = true; // ONE TIME RENDER
      // }
    }

    if ( DesignRoot.Main3D.storeMappingMaterialName !== null ) {

      for (var key in DesignRoot.Main3D.storeMappingMaterialName) {
        if (DesignRoot.Main3D.storeMappingMaterialName.hasOwnProperty(key)) {

            DesignRoot.Main3D.storeMappingMaterialName[key].map.needsUpdate = true;
        }
      }
    }

  } catch (e) {
    // statements
    console.error('fabric_function.updateTexture.error', e);
  }
}

var activeOBJ = null;

export function updateCanvas() {
  try {
    // statements

    if (DesignRoot._2D_BUILD === false) {
      return;
    }

    // console.log('updateCanvas', DesignRoot.canvas);
    DesignRoot._CANVAS_RENDER._objects = _cloneDeep(DesignRoot.canvas._objects);

    if (
      DesignRoot.canvas._activeObject &&
      (DesignRoot.canvas._activeObject._OBJECT_TYPE === 'TEXT' ||
        DesignRoot.canvas._activeObject._OBJECT_TYPE === 'LOGO')
    ) {
      if (
        activeOBJ !== null &&
        activeOBJ.id !== DesignRoot.canvas._activeObject.id
      ) {
        DesignRoot._CANVAS_RENDER.remove(activeOBJ);
      }

      activeOBJ = _cloneDeep(DesignRoot.canvas._activeObject);
      // DesignRoot._CANVAS_RENDER._activeObject = _cloneDeep(DesignRoot.canvas._activeObject);
      // console.log('_activeObject', DesignRoot._CANVAS_RENDER._activeObject);

      // activeOBJ.customiseCornerIcons(
      // {
      //   settings: {
      //     borderColor: '#fdcc00',
      //     cornerSize: 33,
      //     cornerShape: 'rect',
      //     cornerBackgroundColor: '#fdcc00',
      //     cornerPadding: 10
      //     // cornerStrokeColor: '#2f2f2f'
      //   },
      //   tl: {
      //     icon: rotate
      //   },
      //   tr: {
      //     icon: resize
      //   },
      //   bl: {
      //     icon: remove
      //   },
      //   br: {
      //     icon: lock
      //   }
      // },
      // function() {
      //   DesignRoot.canvas.requestRenderAll();
      // });

      console.log('activeOBJ', activeOBJ);

      activeOBJ.cornerSize = 40;
      activeOBJ.padding = 20;

      DesignRoot._CANVAS_RENDER.add(activeOBJ);
      DesignRoot._CANVAS_RENDER.setActiveObject(activeOBJ);

      // var aCoords = DesignRoot._CANVAS_RENDER._activeObject.aCoords;

      // for (var key in aCoords) {
      //   if (aCoords.hasOwnProperty(key)) {

      //       aCoords[key].x *= 2;
      //       aCoords[key].y *= 2;
      //   }
      // }

      // var oCoords = DesignRoot._CANVAS_RENDER._activeObject.oCoords;

      // for (var key in oCoords) {
      //   if (oCoords.hasOwnProperty(key)) {

      //       oCoords[key].x *= 2;
      //       oCoords[key].y *= 2;

      //       for (var key2 in oCoords[key].corner) {
      //         if (oCoords[key].corner.hasOwnProperty(key2)) {

      //             oCoords[key].corner[key2].x *= 2;
      //             oCoords[key].corner[key2].y *= 2;
      //         }
      //       }

      //   }
      // }
    } else {
      if (activeOBJ !== null) {
        DesignRoot._CANVAS_RENDER.remove(activeOBJ);
        delete DesignRoot._CANVAS_RENDER._activeObject;
      }
    }


    DesignRoot._CANVAS_RENDER.requestRenderAll();
  } catch (e) {
    // statements
    console.error('fabric_function.updateCanvas.error', e);
  }
}

export function updateDimension(e, option) {
  try {
    // statements

    if (DesignRoot._2D_BUILD === false) {
      return;
    }

    console.log('updateDimension', e, option);

    if (option && option === 'byID') {
      var target = getObjectById(e);
    } else {
      var target = e.target;
    }

    if (target) {
      if (
        target._OBJECT_TYPE === 'SVG' &&
        target._USER_DATA.fillType === 'AOP' &&
        target._USER_DATA.transform &&
        target.fill.img !== undefined
      ) {
        var transform = target._USER_DATA.transform;

        var rotation = transform.angle ? transform.angle : 0;
        var width = transform.scaleX * (target.scaleX * target.fill.img.width);
        var height =
          transform.scaleY * (target.scaleY * target.fill.img.height);

        console.log(width, height, target.scaleX);

        // TODO: CHECK: HAVE TO MULTIPLY WITH SVG SCALE OR NOT

        // var productDimension = DesignRoot.productDataSet.dimension;

        var approxWidth = width * DesignRoot._PXTOCM;
        var approxHeight = height * DesignRoot._PXTOCM;

        approxWidth = approxWidth.toFixed(2);
        approxHeight = approxHeight.toFixed(2);
        rotation = rotation.toFixed(0);
      } else if (target._OBJECT_TYPE !== 'SVG') {
        var rotation = target.angle ? target.angle : 0;
        // var width = target.width * target.scaleX;
        // var height = target.height * target.scaleY;

        var width = target.scaleX * target.width;
        var height = target.scaleY * target.height;

        console.log(width, height, target.scaleX);

        // width = width * DesignRoot._CANVAS_SIZE;
        // height = height * DesignRoot._CANVAS_SIZE;

        // var productDimension = DesignRoot.productDataSet.dimension;

        var approxWidth = width * DesignRoot._PXTOCM;
        var approxHeight = height * DesignRoot._PXTOCM;

        approxWidth = approxWidth.toFixed(2);
        approxHeight = approxHeight.toFixed(2);
        rotation = rotation.toFixed(0);

        if (target._DEFAULT_ITEM && target._DEFAULT_ITEM === true) {
          target.set({
            left: target._KEEP_CENTER.x,
            top: target._KEEP_CENTER.y
          });
          target._KEEP_SCALE_W = target.width * target.scaleX;
          target._KEEP_SCALE_H = target.height * target.scaleX;
        }
      }

      // DPI

      // console.log(target, width, approxWidth);

      if (target._OBJECT_TYPE === 'LOGO') {
        var DPI_WIDTH = target.width / (approxWidth / 2.54);
        var DPI_HEIGHT = target.height / (approxHeight / 2.54);
      } else if (
        target._OBJECT_TYPE === 'SVG' &&
        target.fill.img !== undefined
      ) {
        var DPI_WIDTH = target.fill.img.width / (approxWidth / 2.54);
        var DPI_HEIGHT = target.fill.img.height / (approxHeight / 2.54);
      } else {
        var DPI_WIDTH = null;
        var DPI_HEIGHT = null;
      }

      console.log(DPI_WIDTH, DPI_HEIGHT);

      // DPI

      DesignRoot.Dimension.setState({
        approxWidth: approxWidth,
        approxHeight: approxHeight,
        rotation: rotation,
        DPI_WIDTH: DPI_WIDTH,
        DPI_HEIGHT: DPI_HEIGHT
      });
    } else {
      DesignRoot.Dimension.setState({
        approxWidth: null,
        approxHeight: null,
        rotation: null,
        DPI_WIDTH: null,
        DPI_HEIGHT: null
      });
    }
  } catch (e) {
    // statements
    console.error('fabric_function.updateDimension.error', e);
  }
}

export function getObjectById(itemId) {
  try {
    // statements

    if (DesignRoot.canvas === null) {
      return null;
    }

    // console.log('getObjectById', itemId, DesignRoot.canvas.getObjects());
    var getObject = null;

    for (var i = 0; i < DesignRoot.canvas.getObjects().length; i++) {
      var object = DesignRoot.canvas.getObjects()[i];
      if (object.id === itemId) {
        getObject = object;
      }
    }

    return getObject;
  } catch (e) {
    // statements
    console.error('fabric_function.getObjectById.error', e);
  }
}

export function getObjectByType(type) {
  try {
    // statements

    if (DesignRoot.canvas === null) {
      return [];
    }

    // console.log('getObjectByType', type, DesignRoot.canvas);
    var getObject = [];

    for (var i = 0; i < DesignRoot.canvas.getObjects().length; i++) {
      var object = DesignRoot.canvas.getObjects()[i];
      if (object._OBJECT_TYPE && object._OBJECT_TYPE === type) {
        getObject.push(object);
      }
    }

    return getObject;
  } catch (e) {
    // statements
    console.error('fabric_function.getObjectByType.error', e);
  }
}

export function fitInScene() {
  try {
    // statements

    // ONE TIME EXE

    if (DesignRoot._CANVAS_RENDER !== null && fitInSceneInit === true) {
      var base_width = DesignRoot._CANVAS_RENDER.width;
      var plane_width = document.getElementById('PLANE_2D').offsetWidth;

      var diff = plane_width / base_width;

      console.log('fitInScene init', base_width, plane_width, diff);

      DesignRoot.canvas.setZoom(diff);

      DesignRoot._CANVAS_SIZE = diff;

      canvas.setDimensions({
        width: DesignRoot._CANVAS_RENDER.width * DesignRoot._CANVAS_SIZE,
        height: DesignRoot._CANVAS_RENDER.height * DesignRoot._CANVAS_SIZE
      });

      fitInSceneInit = false;

      fitInScene();
    }
    else if ( DesignRoot._CANVAS_RENDER !== null ) {

      canvas.setDimensions({
        width: DesignRoot._CANVAS_RENDER.width * DesignRoot._CANVAS_SIZE,
        height: DesignRoot._CANVAS_RENDER.height * DesignRoot._CANVAS_SIZE
      });

      console.log('fitInScene', DesignRoot._CANVAS_RENDER.width, DesignRoot._CANVAS_RENDER.height, DesignRoot._CANVAS_SIZE, DesignRoot.canvas);
    }


  } catch (e) {
    // statements
    console.error('fabric_function.fitInScene.error', e);
  }
}

export function moveTo(target, direction) {
  try {
    // moveUp // moveDown

    if (direction === 'moveUp') {
      target.bringForward();
      reArrageItem();
      orderObject('ORDER_CHANGE');
    } else if (direction === 'moveDown') {
      target.sendBackwards();
      reArrageItem();
      orderObject('ORDER_CHANGE');
    }

    // UPDATE ICON CONTROL
    DesignRoot.tools.renderComponent('CONTROL_TOOLS');
  } catch (e) {
    console.error('fabric_function.moveTo.error', e);
  }
}

export function reArrageItem() {
  try {
    // AFTER Move Up Down, then re arrage order

    var itemSET = DesignRoot.canvas.getObjects();

    // RE-ARRAGE ORDER TOP TO BOTTOM
    // 1. LOGO TEXT
    // 2. SVG BORDER
    // 3. PATTERN

    if (templateBase !== null) {
      canvas.bringToFront(templateBase);
    }

    if (DesignRoot._OVERLAY_BASE !== null) {
      canvas.bringToFront(DesignRoot._OVERLAY_BASE);
    }

    for (var i = 0; i < itemSET.length; i++) {
      var object = itemSET[i];

      if (object._OBJECT_TYPE && object._OBJECT_TYPE === 'SVG') {
        canvas.bringToFront(itemSET[i]);
      }
    }

    for (var i = 0; i < itemSET.length; i++) {
      var object = itemSET[i];

      if (object._OBJECT_TYPE && object._OBJECT_TYPE === 'PATTERN') {
        canvas.bringToFront(itemSET[i]);
      }
    }

    for (var i = 0; i < itemSET.length; i++) {
      var object = itemSET[i];

      if (
        object._OBJECT_TYPE &&
        (object._OBJECT_TYPE === 'LOGO' || object._OBJECT_TYPE === 'TEXT')
      ) {
        canvas.bringToFront(itemSET[i]);
      }
    }

    for (var i = 0; i < itemSET.length; i++) {
      var object = itemSET[i];

      if (
        object._OBJECT_TYPE &&
        (object._OBJECT_TYPE === 'LOGO' || object._OBJECT_TYPE === 'TEXT') &&
        object._DEFAULT_ITEM &&
        object._DEFAULT_ITEM === true
      ) {
        canvas.bringToFront(itemSET[i]);
      }
    }

    if (redlineBase !== null) {
      canvas.bringToFront(redlineBase);
    }

    for (var i = 0; i < itemSET.length; i++) {
      var object = itemSET[i];

      if (object._OBJECT_TYPE && object._OBJECT_TYPE === 'SVG_BORDER') {
        canvas.bringToFront(itemSET[i]);
      }
    }

    canvas.requestRenderAll();
  } catch (e) {
    console.error('fabric_function.reArrageItem.error', e);
  }
}

export function toggleLock(target) {
  try {
    target.lockMovementX = !target.lockMovementX;
    target.lockMovementY = !target.lockMovementY;
    target.lockScalingX = !target.lockScalingX;
    target.lockScalingY = !target.lockScalingY;
    target.lockUniScaling = !target.lockUniScaling;
    target.lockRotation = !target.lockRotation;
    canvas.requestRenderAll();

    // UPDATE ICON CONTROL
    DesignRoot.tools.renderComponent('CONTROL_TOOLS');
  } catch (e) {
    console.error('fabric_function.toggleLock.error', e);
  }
}

export async function toggleVisible(target) {
  try {
    target.visible = !target.visible;
    canvas.requestRenderAll();

    saveLogoOrText(target);

    // UPDATE ICON CONTROL
    DesignRoot.tools.renderComponent('CONTROL_TOOLS');
  } catch (e) {
    console.error('fabric_function.toggleVisible.error', e);
  }
}

export function mapSvgWith3DElm(w, h) {
  try {
    // if ( SHOP_ID !== 'bodywearlab' ) { return; }

    var allSVG = getObjectByType('SVG');
    var all3DELM = DesignRoot.Main3D.storeMappingObject;

    console.log('mapSvgWith3DElm', allSVG, all3DELM);

    for (var i = 0; i < all3DELM.length; i++) {

      var uvSet = all3DELM[i].geometry.attributes.uv.array;
      var uvLength = all3DELM[i].geometry.attributes.uv.array.length;

      var setX = [];
      var setY = [];

      for (var m = 0; m < uvSet.length; m++) {

        if ( m % 2 === 0 ) {

          setX.push(uvSet[m]);
        }
        else {

          setY.push(uvSet[m]);
        }
      }

      setX.sort(function(a, b){return a-b});
      setY.sort(function(a, b){return a-b});

      var x = setX[setX.length / 2];
      var y = setY[setY.length / 2];


      var uv = {
        x: x,
        y: y
      };

      var coord = DesignRoot.Main3D.ThreejsFunction.calculateUV(uv);


      if ( DesignRoot._SHOW_COORD === true ) {

        var intersectDraw = new window.fabric.Rect({
          left: coord.x,
          top: coord.y,
          width: 10,
          height: 10,
          fill: 'blue',
          originX: 'center',
          originY: 'center',
          opacity: 1,
          evented: false
        });
        intersectDraw.hascontrols = false;
        intersectDraw.hasBorders = false;
        intersectDraw.selectable = false;
        canvas.add(intersectDraw);
        intersectDraw.bringToFront();
      }

      intersectCheck.set({
        left: coord.x,
        top: coord.y
      });
      intersectCheck.bringToFront();
      intersectCheck.setCoords();
      canvas.requestRenderAll();

      console.log(all3DELM[i].name, uv, coord);

      for (var j = 0; j < allSVG.length; j++) {
        if (intersectCheck.intersectsWithObject(allSVG[j])) {
          console.log('intersectCheck', allSVG[j].id);

          if (DesignRoot._SPECIAL_ELM_SET.indexOf(all3DELM[i].name) !== -1) {
            allSVG[j]._SPECIAL_ELM = true;
          } else {
            allSVG[j]._SPECIAL_ELM = false;
          }

          if (allSVG[j]._BOUNDING_TYPE && allSVG[j]._BOUNDING_TYPE === 'FLAG') {
            allSVG[j]._USER_DATA.element3DName = all3DELM[i].name + '_FLAG';
            allSVG[j]._SPECIAL_ELM = true;
          } else {
            allSVG[j]._USER_DATA.element3DName = all3DELM[i].name;
          }
        }
      }
    } // END FOR all3DELM

    loadSVGColorAop(allSVG); // LOAD MAP SVG COLOR
  } catch (e) {
    console.error('fabric_function.mapSvgWith3DElm.error', e);
  }
}

export function loadSVGColorAop(allSVG) {
  try {
    if (DesignRoot.loadData.directElm !== null && (DesignRoot._ACTIVE_COLOR_ZONE === false || DesignRoot._DEFAULT_TEMPLATE_ACTIVE === true)) {
      for (var i = 0; i < allSVG.length; i++) {
        var element3DName = allSVG[i]._USER_DATA.element3DName;

        if (
          DesignRoot.loadData.directElm &&
          DesignRoot.loadData.directElm[element3DName] &&
          DesignRoot.loadData.directElm[element3DName] !== undefined &&
          DesignRoot.loadData.directElm[element3DName].color !== null
        ) {
          allSVG[i]._USER_DATA.id = DesignRoot.loadData.directElm[element3DName].id;
          allSVG[i].set({
            fill: DesignRoot.loadData.directElm[element3DName].color
          });
        }
        else if ( // FLAG LOAD
            DesignRoot.loadData.directElm &&
            DesignRoot.loadData.directElm[element3DName] &&
            DesignRoot.loadData.directElm[element3DName] !== undefined &&
            DesignRoot.loadData.directElm[element3DName].color === null &&
            DesignRoot.loadData.directElm[element3DName].countryFlag !== null
          ) {

            // LOAD FLAG INDIVIDUAL

            console.log('LOAD AOP', DesignRoot.loadData.directElm[element3DName]);

            var flagSrc = '/Flags/' + DesignRoot.loadData.directElm[element3DName].countryFlag + '.png';
            var _id = DesignRoot.loadData.directElm[element3DName].id;

            DesignRoot._DEFAULT_FLAG = DesignRoot.loadData.directElm[element3DName].countryFlag;

            var allFlagPlaceholder = get_flag_placeholder();

            if ( allFlagPlaceholder.length ) {

              console.log('APPLY FLAG AOP');

              for (var j = 0; j < allFlagPlaceholder.length; j++) {

                allFlagPlaceholder[j]._USER_DATA.id = _id;
                DesignRoot.change_color.changeAOPSelectedSVGELM(flagSrc, allFlagPlaceholder[j], null, false);
              }
            }

            // LOAD FLAG INDIVIDUAL

          }
          else if (
            DesignRoot.loadData.directElm &&
            DesignRoot.loadData.directElm[element3DName] &&
            DesignRoot.loadData.directElm[element3DName] !== undefined &&
            DesignRoot.loadData.directElm[element3DName].productDesignPrintId !== null &&
            DesignRoot.loadData.directElm[element3DName].productDesignPrintSrc !== null
          ) {

            // LOAD AOP

            var aopSrc = DesignRoot.loadData.directElm[element3DName].productDesignPrintSrc;
            var _id = DesignRoot.loadData.directElm[element3DName].id;
            var productDesignPrintId = DesignRoot.loadData.directElm[element3DName].productDesignPrintId
            var transform = (DesignRoot.loadData.directElm[element3DName].designAttributes) ? DesignRoot.loadData.directElm[element3DName].designAttributes : null ;

            // RECHECK DATA

            if ( transform !== null ) {

              transform.scaleX = ( transform.scaleX ) ? transform.scaleX : 1;
              transform.scaleY = ( transform.scaleY ) ? transform.scaleY : 1;
              transform.angle = ( transform.angle ) ? transform.angle : 0;
              transform.offsetX = ( transform.offsetX ) ? transform.offsetX : 0;
              transform.offsetY = ( transform.offsetY ) ? transform.offsetY : 0;
              transform.repeat = ( transform.repeat === undefined || transform.repeat === null ) ? true : transform.repeat;
            }

            // RECHECK DATA

            allSVG[i]._USER_DATA.id = DesignRoot.loadData.directElm[element3DName].id;
            DesignRoot.change_color.changeAOPSelectedSVGELM(aopSrc, allSVG[i], transform, false, productDesignPrintId);

          }
          
      }

      canvas.requestRenderAll();
    }
  } catch (e) {
    console.error('fabric_function.loadSVGColorAop.error', e);
  }
}

export function get_bounding_by_type(type) {
  try {
    var boundingSET = [];
    for (var i = 0; i < DesignRoot._SVG_BOUNDING_SET.length; i++) {
      if (
        type === DesignRoot._SVG_BOUNDING_SET[i]._BOUNDING_TYPE ||
        DesignRoot._SVG_BOUNDING_SET[i]._BOUNDING_TYPE === 'BOTH' || 
        type === 'ALL'
      ) {
        boundingSET.push(DesignRoot._SVG_BOUNDING_SET[i]);
      }
    }

    return boundingSET;
  } catch (e) {
    console.error('fabric_function.get_bounding_by_type.error', e);
  }
}

export function get_bounding_limit_item_by_group(type, zoneGroup) {
  try {
    var boundingSET = get_bounding_by_type(type);
    var max = 1;
    for (var i = 0; i < boundingSET.length; i++) {
      var _ZONE_LIMIT_ITEM = parseInt(boundingSET[i]._ZONE_LIMIT_ITEM);
      if (zoneGroup === boundingSET[i]._ZONE_GROUP && _ZONE_LIMIT_ITEM > max) {
        max = _ZONE_LIMIT_ITEM;
      }
    }

    return max;
  } catch (e) {
    console.error('fabric_function.get_bounding_limit_item_by_group.error', e);
  }
}

export function get_bounding_current_item_by_group(type, zoneGroup) {
  try {
    var numberOfItemPlace = 0;
    var items = DesignRoot.canvas.getObjects();

    for (var i = 0; i < items.length; i++) {
      if (items[i]._DEFAULT_ITEM && items[i]._DEFAULT_ITEM === true) {
        var boundingId = items[i]._BOUNDING_ID;
        var boundingData = getObjectById(boundingId);

        if (boundingData._ZONE_GROUP === zoneGroup && type === items[i]._OBJECT_TYPE) {
          numberOfItemPlace++;
        }
      }
    }

    return numberOfItemPlace;
  } catch (e) {
    console.error(
      'fabric_function.get_bounding_current_item_by_group.error',
      e
    );
  }
}

export function get_bounding_by_number_of_item(type, zoneGroup, numberOfItem) {
  try {
    console.log(
      'get_bounding_by_number_of_item',
      type,
      zoneGroup,
      numberOfItem
    );

    var boundingSET = get_bounding_by_type(type);
    var boundingItems = [];

    for (var i = 0; i < boundingSET.length; i++) {
      var _ZONE_LIMIT_ITEM = parseInt(boundingSET[i]._ZONE_LIMIT_ITEM);
      if (
        zoneGroup === boundingSET[i]._ZONE_GROUP &&
        _ZONE_LIMIT_ITEM === numberOfItem
      ) {
        boundingItems.push(boundingSET[i]);
      }
    }

    return boundingItems;
  } catch (e) {
    console.error('fabric_function.get_bounding_by_number_of_item.error', e);
  }
}

export function getAlreadyPlaceItems(boundingGroup, type) {
  try {
    var items = getObjectByType(type);
    var currentItems = [];
    for (var i = 0; i < items.length; i++) {
      if (items[i]._BOUNDING_GROUP === boundingGroup) {
        currentItems.push(items[i]);
      }
    }

    return currentItems;
  } catch (e) {
    console.error('fabric_function.getAlreadyPlaceItems.error', e);
  }
}

export function adjust_bounding_size(bounding, item) {
  console.log('adjust_bounding_size', bounding, item);

  try {
    for (var i = 0; i < 1; i++) {
      // BOUNDING SIZE CALCULATION & CHECK

      item.setCoords();

      var boundingWidth = bounding.width * bounding.scaleX;
      var boundingHeight = bounding.height * bounding.scaleX;

      item.scaleToWidth(boundingWidth);
      item.setCoords();

      var itemWidth = item.width * item.scaleX;
      var itemHeight = item.height * item.scaleY;

      if (itemHeight > boundingHeight) {
        item.scaleToHeight(boundingHeight);
        item.setCoords();
      }

      // BOUNDING SIZE CALCULATION & CHECK
    }
  } catch (e) {
    console.error('fabric_function.adjust_bounding_size.error', e);
  }
}

export function bounding_multiple_item_process(
  type,
  boundingId,
  boundingGroup,
  option
) {
  try {
    console.log(
      'bounding_multiple_item_process',
      type,
      boundingId,
      boundingGroup,
      option
    );

    // LOGIC OF MULTIPLE ITEM IN 1 ZONE

    var _ZONE_GROUP = boundingGroup;

    var maxItem = get_bounding_limit_item_by_group(type, _ZONE_GROUP);

    var numberOfItem = get_bounding_current_item_by_group(type, _ZONE_GROUP);

    var numberOfItemCheck = numberOfItem + 1;
    if (option === 'REMOVE') {
      numberOfItemCheck = numberOfItem;
    }

    if (numberOfItem >= 1) {
      var boundingSet = get_bounding_by_number_of_item(
        type,
        _ZONE_GROUP,
        numberOfItemCheck
      );

      var currentItemInZone = getAlreadyPlaceItems(boundingGroup, type);

      console.log(
        'get_bounding_by_number_of_item',
        boundingSet,
        currentItemInZone,
        numberOfItem,
        maxItem
      );

      // UPDATE CURRENT TO NEW BOUNDING

      for (var i = 0; i < currentItemInZone.length; i++) {
        // currentItemInZone[i];
        // boundingSet[i];

        currentItemInZone[i].set({
          left: boundingSet[i].getCenterPoint().x,
          top: boundingSet[i].getCenterPoint().y
        });

        currentItemInZone[i]._KEEP_SCALE_W =
          currentItemInZone[i].width * boundingSet[i].scaleX;
        currentItemInZone[i]._KEEP_SCALE_H =
          currentItemInZone[i].height * boundingSet[i].scaleX;
        currentItemInZone[i]._KEEP_CENTER = currentItemInZone[
          i
        ].getCenterPoint();
        currentItemInZone[i]._BOUNDING_ID = boundingSet[i].id;
        currentItemInZone[i]._BOUNDING_GROUP = _ZONE_GROUP;

        // if (currentItemInZone[i].width > currentItemInZone[i].height) {
        //   currentItemInZone[i].scaleToWidth(
        //     boundingSet[i].width * boundingSet[i].scaleX
        //   );
        // } else {
        //   currentItemInZone[i].scaleToHeight(
        //     boundingSet[i].height * boundingSet[i].scaleX
        //   );
        // }

        adjust_bounding_size(boundingSet[i], currentItemInZone[i]);
      }

      if (option === 'ADD') {
        // NEW CONFIG FOR LASTED ITEM

        var lastedBounding = boundingSet[boundingSet.length - 1];
        var canvasData = {
          left: lastedBounding.left,
          top: lastedBounding.top,
          scaleX: lastedBounding.scaleX,
          scaleY: lastedBounding.scaleY,
          width: lastedBounding.width,
          height: lastedBounding.height,
          centerPoint: lastedBounding.getCenterPoint(),
          boundingId: lastedBounding.id,
          boundingGroup: lastedBounding._ZONE_GROUP
        };

        // NEW CONFIG FOR LASTED ITEM

        return canvasData;
      }

      // UPDATE CURRENT TO NEW BOUNDING
    }

    return false;

    // LOGIC OF MULTIPLE ITEM IN 1 ZONE
  } catch (e) {
    console.error('fabric_function.bounding_multiple_item_process.error', e);
  }
}

export function toggle_bounding_stroke(type, _ZONE_GROUP) {
  try {
    var boundingSET = get_bounding_by_type(type);

    console.log('toggle_bounding_stroke', boundingSET, _ZONE_GROUP);

    for (var i = 0; i < boundingSET.length; i++) {
      if (boundingSET[i]._ZONE_GROUP === _ZONE_GROUP || _ZONE_GROUP === 'ALL') {
        console.log(boundingSET[i]);

        if (boundingSET[i].strokeWidth === 0) {
          boundingSET[i].set({ strokeWidth: 1 });
        } else {
          boundingSET[i].set({ strokeWidth: 0 });
        }
      }
    }

    DesignRoot.canvas.requestRenderAll();
  } catch (e) {
    console.error('fabric_function.toggle_bounding_stroke.error', e);
  }
}

export function hide_all_bounding_stroke(type) {
  try {
    var boundingSET = get_bounding_by_type(type);

    console.log('hide_all_bounding_stroke', boundingSET);

    for (var i = 0; i < boundingSET.length; i++) {
      boundingSET[i].set({ strokeWidth: 0 });
    }

    DesignRoot.canvas.requestRenderAll();
  } catch (e) {
    console.error('fabric_function.hide_all_bounding_stroke.error', e);
  }
}

export function orderObject(option) {
  try {
    var stackTextLogo = [];

    for (var i = 0; i < DesignRoot.canvas.getObjects().length; i++) {
      var object = DesignRoot.canvas.getObjects()[i];

      if (object._OBJECT_TYPE === 'TEXT' || object._OBJECT_TYPE === 'LOGO') {
        stackTextLogo.push(object);
      }
    }

    if (option === 'RETURN_NEXT_ORDER') {
      console.log('RETURN_NEXT_ORDER', stackTextLogo.length + 1);
      return stackTextLogo.length + 1;
    } else if (option === 'REMAP_ORDER') {
      for (var i = 0; i < stackTextLogo.length; i++) {
        var seq = i + 1;
        stackTextLogo[i]._USER_DATA.orderSeq = seq;
      }
    } else if (option === 'ORDER_CHANGE') {
      var changeLog = [];
      for (var i = 0; i < stackTextLogo.length; i++) {
        var seq = i + 1;
        if (
          stackTextLogo[i]._USER_DATA.orderSeq &&
          stackTextLogo[i]._USER_DATA.orderSeq !== seq
        ) {
          changeLog.push(stackTextLogo[i]);
        }

        stackTextLogo[i]._USER_DATA.orderSeq = seq;
      }

      console.log('changeLog', changeLog);
      if (changeLog.length) {
        console.log('MUTATION UPDATE ORDER', changeLog);

        if (DesignRoot.isSaveEnabled) {
          changeLog.forEach(async item => {
            if (item._OBJECT_TYPE === 'TEXT') {
              const inputText = setupTexts({
                userData: item._USER_DATA,
                designAttributes: setDesignAttributes(item)
              });
              await updateTextDesignRevisionData(
                item.id,
                DesignRoot.props.productDesign.id,
                1,
                inputText,
                DesignRoot._APP_TYPE === '2d-3d'
              );
            } else if (item._OBJECT_TYPE === 'LOGO') {
              const inputLogo = setupLogo(
                {
                  userData: item._USER_DATA,
                  designAttributes: setDesignAttributes(item)
                },
                DesignRoot.ownedByOrganisationId
              );
              await updateLogoDesignRevisionData(
                item.id,
                DesignRoot.props.productDesign.id,
                DesignRoot.ownedByOrganisationId,
                1,
                inputLogo,
                DesignRoot._APP_TYPE === '2d-3d'
              );
            }
          });
        }
      }
    } else if (option === 'REARRANGE_ORDER') {
      // RE-ARRANGE ORDER AFTER LOAD

      var byOrder = [];
      for (var i = 0; i < stackTextLogo.length; i++) {
        var orderSeq = stackTextLogo[i]._USER_DATA.orderSeq;
        byOrder[orderSeq] = stackTextLogo[i];
      }

      console.log('byOrder', byOrder);

      for (var i = 0; i < byOrder.length; i++) {
        if (byOrder[i]) {
          console.log('byOrder[i]', byOrder[i]);
          byOrder[i].bringToFront();
        }
      }

      reArrageItem();
    }

    console.log('stackTextLogo', stackTextLogo, option);
  } catch (e) {
    console.error('fabric_function.toggle_bounding_stroke.error', e);
  }
}

export function get_item_in_placeholder(boundingId, type, returnType) {
  try {
    var items = getObjectByType(type);
    var currentItems = null;
    for (var i = 0; i < items.length; i++) {
      if (items[i]._BOUNDING_ID === boundingId) {
        currentItems = items[i];
      }
    }

    if ( returnType === 'BASE64' && currentItems !== null ) {

      return currentItems._USER_DATA.base64;
    }
    else {

      return currentItems;
    }

    
  } catch (e) {
    console.error('fabric_function.get_item_in_placeholder.error', e);
  }
}

export function get_flag_placeholder() {
  
  try {

    var boundingSET = [];
    for (var i = 0; i < DesignRoot._SVG_BOUNDING_SET.length; i++) {
      if (
        'FLAG' === DesignRoot._SVG_BOUNDING_SET[i]._BOUNDING_TYPE
      ) {
        boundingSET.push(DesignRoot._SVG_BOUNDING_SET[i]);
      }
    }

    return boundingSET;

  } catch (e) {
    console.error('fabric_function.get_flag_placeholder.error', e);
  }
}


export async function saveLogoOrText(target) {
  try {
    target._USER_DATA.visible = target.visible;
    if (!DesignRoot.localData) {
      if (target._OBJECT_TYPE === 'TEXT') {
        const inputText = setupTexts({
          userData: target._USER_DATA,
          designAttributes: setDesignAttributes(target)
        });
        await updateTextDesignRevisionData(
          target.id,
          DesignRoot.props.productDesign.id,
          1,
          inputText,
          DesignRoot._APP_TYPE === '2d-3d'
        );
      } else if (target._OBJECT_TYPE === 'LOGO') {
        const inputLogo = setupLogo(
          {
            userData: target._USER_DATA,
            designAttributes: setDesignAttributes(target)
          },
          DesignRoot.ownedByOrganisationId
        );
        await updateLogoDesignRevisionData(
          target.id,
          DesignRoot.props.productDesign.id,
          DesignRoot.ownedByOrganisationId,
          1,
          inputLogo,
          DesignRoot._APP_TYPE === '2d-3d'
        );
      }
    }
    
  } catch (e) {
    // statements
    console.error('fabric_function.saveLogoOrText.error', e);
  }
}

export function find_attach_position_element(object) {
  
  // GET CENTER TO FIND POSITION ON ELEMENT NAME

  try {

    // console.log('find_attach_position_element', object);

    if ( object._OBJECT_TYPE && (object._OBJECT_TYPE === 'LOGO' || object._OBJECT_TYPE === 'TEXT') ) {

      var center = object.getCenterPoint();

      // console.log('object center', object._OBJECT_TYPE, center);
      var allSVG = getObjectByType('SVG');

      var checkIntersect = false;

      object._USER_DATA.attachPosition = [];

      for (var j = 0; j < allSVG.length; j++) {
        if (object.intersectsWithObject(allSVG[j])) {

          // console.log('intersectCheck', allSVG[j]);

          if ( allSVG[j]._USER_DATA.element3DName ) {

            for (var i = 0; i < object._USER_DATA.attachPosition.length; i++) {
              
              if ( object._USER_DATA.attachPosition[i] !== allSVG[j]._USER_DATA.element3DName ) {

                object._USER_DATA.attachPosition.push(allSVG[j]._USER_DATA.element3DName);
              }

            }

            if ( object._USER_DATA.attachPosition.length <= 0 ) {

              object._USER_DATA.attachPosition.push(allSVG[j]._USER_DATA.element3DName);
            }

            checkIntersect = true;
            
          }

        }
      } // end for

      object._USER_DATA.attachPosition = object._USER_DATA.attachPosition.filter((v, i, a) => a.indexOf(v) === i); 
      console.log("find_attach_position_element", checkIntersect, object, object._USER_DATA.attachPosition);

    }

  } catch (e) {
    // statements
    console.error('fabric_function.find_attach_position_element.error', e);
  }
}