import React from 'react'

interface Props {
    designTemplate: any; 
    latestRevisionData: any;
}

const Texts: React.FunctionComponent<Props> = props => {

    const { designTemplate, latestRevisionData } = props

    const pxToCm = (latestRevisionData.pxToCm) ? latestRevisionData.pxToCm : 1

    return (
        <>
            <h2>Text</h2>
            <table className="table">
              <thead>
                <tr style={{ fontWeight: 'bold' }}>
                  <th>Content</th>
                  <th>Font</th>
                  <th>Width</th>
                  <th>Height</th>
                  <th>Color</th>
                  <th>Printing</th>
                </tr>
              </thead>
              <tbody>
              {latestRevisionData && latestRevisionData.textDesigns
                ? latestRevisionData.textDesigns.map((text: any, key: number) => (
                    <tr key={`text-${key}`} style={{ textAlign: 'center' }}>
                      <td>{text.textDesign.content}</td>
                      <td>{text.textDesign.fontFamily}</td>
                      <td>{(text.textDesign.designAttributes.width * pxToCm).toFixed(2)} {pxToCm == 1 ? 'px' : 'cm'}</td>
                      <td>{(text.textDesign.designAttributes.height * pxToCm).toFixed(2)} {pxToCm == 1 ? 'px' : 'cm'}</td>
                      <td>{text.textDesign.colorHex}</td>
                      <td>-</td>
                    </tr>
                  ))
                : null}
              </tbody>
            </table>
        </>
    )
}

export default Texts