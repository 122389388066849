import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  createStyles,
  withStyles,
  WithStyles
} from '@material-ui/core';

import AddMenuLinkFields from './AddMenuLinkFields';
import AddMenuLinkPage from './AddMenuLinkPage';
import AddMenuLinkProduct from './AddMenuLinkProduct';
import AddMenuLinkCollection from './AddMenuLinkCollection';
import AddMenuLinkModule from './AddMenuLinkModule';

const styles = () => createStyles({});

interface Props extends WithStyles<typeof styles> {
  item: any | null;
  collections: any[];
  modules: any[];
  navigationLinks: any[];
  open: boolean;
  pages: any[];
  parentItems: any[];
  products: any[];
  onClose(): void;
  onSave(data: any, isEdit?: boolean): void;
}

const AddMenuLink: React.FunctionComponent<Props> = props => {
  const {
    classes,
    item,
    collections,
    modules,
    languages,
    navigationLinks,
    open,
    pages,
    parentItems,
    products,
    onClose,
    onSave
  } = props;

  const emptyData = {
    collectionId: null,
    externalLinks: [],
    linkTitle: {
      text: []
    },
    moduleId: null,
    pageId: null,
    parentId: null,
    productId: null
  };
  const initialData = !item ? emptyData : item;
  const [data, setData] = useState(initialData);
  const [isEditMode, setIsEditMode] = useState(!!item);

  useEffect(() => {
    if (item && item.id !== data.id) {
      setData(item);
      setIsEditMode(true);
    }
    if (!item && data.id) {
      setData(initialData);
      setIsEditMode(false);
    }
  }, [item, data.id, initialData]);

  const handleSave = (): void => {
    onSave(data, isEditMode);
    setData(initialData);
    onClose();
  };

  const handleClose = (): void => {
    setData(initialData);
    onClose();
  };

  const handleChangePage = (
    pageId: string | null,
    parentId: string | null
  ): void => {
    setData({ ...data, pageId, parentId });
  };

  const handleChangeCollection = (collectionId: string | null): void => {
    setData({ ...data, collectionId });
  };

  const handleChangeProduct = (productId: string | null): void => {
    setData({ ...data, productId });
  };

  const handleChangeModule = (moduleId: string | null): void => {
    setData({ ...data, moduleId });
  };

  const handleChangeLink = (
    value: string,
    language: string,
    fieldName: string
  ): void => {
    let t = data[fieldName].hasOwnProperty('text')
      ? data[fieldName].text
      : data[fieldName];
    t = t.map(text => {
      return {
        ...text,
        content: language === text.langCode ? value : text.content
      };
    });
    if (!t.find(e => e.langCode === language)) {
      t.push({
        content: value,
        langCode: language,
        __typename: 'TextContent'
      });
    }
    if (value === '' && t.find(e => e.langCode === language)) {
      t = t.filter(e => e.langCode !== language);
    }
    setData({
      ...data,
      [fieldName]: data[fieldName].hasOwnProperty('text')
        ? { id: data[fieldName].id, text: t, __typename: 'Text' }
        : t
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ width: 400 }} id="form-dialog-title">
        {isEditMode ? 'Edit' : 'Add'} Link
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <form autoComplete="off">
          <Grid container={true} justify="center" alignItems="center">
            <Grid item={true} xs={12} direction="column">
              <AddMenuLinkFields
                languages={languages}
                data={data}
                onChange={handleChangeLink}
              />
            </Grid>

            <Grid
              container={true}
              alignItems="center"
              style={{ margin: '8px 0px 32px' }}
            >
              <Grid
                item={true}
                xs={5}
                style={{ background: '#eee', height: 2 }}
              >
                &nbsp;
              </Grid>
              <Grid item={true} xs={2}>
                <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                  Or
                </Typography>
              </Grid>
              <Grid
                item={true}
                xs={5}
                style={{ background: '#eee', height: 2 }}
              >
                &nbsp;
              </Grid>
            </Grid>

            <Grid item={true} xs={12} style={{ margin: '8px 0px 24px' }}>
              <Typography variant="subtitle1">
                Select from existing pages
              </Typography>
            </Grid>

            <Grid item={true} xs={12} direction="column">
              <AddMenuLinkPage
                data={data}
                navigationLinks={navigationLinks}
                pages={pages}
                parentPages={parentItems}
                onChange={handleChangePage}
              />
            </Grid>

            <Grid item={true} xs={12} direction="column">
              <AddMenuLinkCollection
                data={data}
                collections={collections}
                onChange={handleChangeCollection}
              />
            </Grid>

            <Grid item={true} xs={12} direction="column">
              <AddMenuLinkProduct
                data={data}
                products={products}
                onChange={handleChangeProduct}
              />
            </Grid>

            <Grid item={true} xs={12} direction="column">
              <AddMenuLinkModule
                data={data}
                modules={modules}
                onChange={handleChangeModule}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="secondary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles, { withTheme: true })(AddMenuLink);
