import React, { Fragment } from 'react';
import { Typography, Grid } from '@material-ui/core';
import GlobalStyles from '../../../globalStyles';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ThemeProvider } from '@material-ui/styles';
import MenuContainer from './Menu/MenuContainer';
import AddMenuContainer from './Menu/AddMenuContainer';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  ADMIN_GET_TEAMSTORE_MENU,
  ADMIN_GET_CONTENT_MENU,
  GET_PAGES,
  GET_CONTENT_MENU_LOCATION
} from '../../gql/admin-queries';
import Header from '../../../store/header/Header';
import Footer from '../../../store/footer/Footer';
import LanguageSwitch from '../content/LanguageSwitch';
import { theme } from '../../../common/Theme';

interface TabContainerProps {
  children?: React.ReactNode;
}

function TabContainer(props: TabContainerProps) {
  return (
    <Grid container={true} style={{ maxWidth: '100%', margin: 'auto' }}>
      {props.children}
    </Grid>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '8px 0px'
    },
    preview: {
      background: 'white',
      padding: '20px',
      marginTop: 24
    },
    btn: {
      display: 'flex',
      flex: 1,
      maxWidth: '100%',
      border: '1px solid #000'
    },
    containerFlex: {
      width: '100%'
    },
    root: {
      width: '100%'
    },
    indicator: {
      backgroundColor: 'transparent'
    },
    selected: {}
  })
);

function getFooter(menus: any[], index: number): any | null {
  const footers = menus.filter(o => o.menuLocation === 'FOOTER');
  return footers.length > 0 ? footers[index].contentMenu : null;
}

function cleanData(data: any): any {
  const newData = JSON.parse(JSON.stringify(data));
  try {
    delete newData.shop.customerContactEmail;
    delete newData.shop.shippingSettings;
    delete newData.shop.socialLinks;
    delete newData.shop.paymentOptions;
  } catch (error) {}
  return newData;
}

export default function Menu({
  shop,
  shopId,
  languages,
  defaultLanguage,
  teamstoreId,
  modules
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [language, setLanguage] = React.useState('en_UK');

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }

  function handleLanguageSwitch(newLanguage: string) {
    setLanguage(newLanguage);
  }

  let dataMenu = {};
  let menus = [];
  let headers: any[] = [];
  let footers: any[] = [];
  let teamstore;
  return (
    <Query
      query={teamstoreId ? ADMIN_GET_TEAMSTORE_MENU : ADMIN_GET_CONTENT_MENU}
      variables={{ id: teamstoreId ? teamstoreId : shopId }}
    >
      {({ loading, error, data }) => {
        if (loading) return 'Loading...';
        if (error) {
          console.error(error);
          return `Error! `;
        }

        try {
          teamstore = data.teamstore;
          dataMenu = teamstoreId
            ? {
                shop
              }
            : data;
          menus = teamstoreId
            ? data.teamstore.menus
            : dataMenu.shop &&
              dataMenu.shop.contentSettings &&
              dataMenu.shop.contentSettings.menus
            ? dataMenu.shop.contentSettings.menus
            : [];
          headers = menus.filter(o => o.menuLocation === 'HEADER');
          footers = menus.filter(o => o.menuLocation === 'FOOTER');
        } catch (error) {}

        return (
          <Grid container={true} justify="center" style={{ padding: 24 }}>
            <Grid container={true} style={{ maxWidth: '1000px', padding: 0 }}>
              <GlobalStyles />
              <Grid item={true} xs={12} style={{ marginTop: 24 }}>
                <Typography data-cy="Header-Menu" variant="h1">
                  Navigation settings
                </Typography>
              </Grid>
              <Grid item={true} xs={12} style={{ marginTop: 24 }}>
                <Typography variant="body1">
                  Add or modify pages of your Header and Footer.
                </Typography>
              </Grid>
              <Grid container={true} xs={12} style={{ marginTop: 24 }}>
                <Grid item={true} xs={10}>
                  <AddMenuContainer
                    menus={menus}
                    data={dataMenu}
                    teamstoreId={teamstoreId}
                  />
                </Grid>
                <Grid item={true} xs={2}>
                  <LanguageSwitch
                    shopId={shopId}
                    shop={shop}
                    defaultLanguage={defaultLanguage}
                    languages={languages}
                    onSwitch={handleLanguageSwitch}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container={true}
              style={{ marginTop: 24 }}
              alignItems="center"
              justify="center"
            >
              <Grid container={true} style={{ maxWidth: 1000 }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  classes={{
                    flexContainer: classes.containerFlex,
                    root: classes.root,
                    indicator: classes.indicator
                  }}
                >
                  <Tab
                    label="Header"
                    classes={{ root: classes.btn, selected: classes.selected }}
                  />
                  <Tab
                    label="Footer"
                    classes={{ root: classes.btn, selected: classes.selected }}
                  />
                </Tabs>
              </Grid>

              <Query query={GET_PAGES} variables={{ id: shopId }}>
                {({ loading, error, data }) => {
                  if (loading) return 'Loading...';
                  if (error) return `Error! ${error.message}`;

                  return (
                    <Fragment>
                      {value === 0 && (
                        <TabContainer>
                          <Grid
                            container={true}
                            direction="row"
                            justify="space-between"
                            spacing={2}
                            style={{
                              margin: 'auto',
                              maxWidth: '1000px'
                            }}
                          >
                            {headers && headers.length > 0 ? (
                              headers.map((nav, index) => (
                                <Query
                                  key={'header1-' + index}
                                  query={GET_CONTENT_MENU_LOCATION}
                                  variables={{ id: nav.id }}
                                >
                                  {({
                                    loading,
                                    error,
                                    data: { contentMenuLocation }
                                  }) => {
                                    if (error) return `Error! ${error.message}`;
                                    return (
                                      <MenuContainer
                                        teamstore={teamstore}
                                        loading={loading}
                                        shopId={shopId}
                                        counter={index + 1}
                                        data={cleanData(dataMenu)}
                                        nav={contentMenuLocation}
                                        pages={data.shop.pages}
                                        language={language}
                                        languages={languages}
                                      />
                                    );
                                  }}
                                </Query>
                              ))
                            ) : (
                              <div>Please add a menu first</div>
                            )}
                          </Grid>

                          {headers && headers.length > 0 ? (
                            <Grid
                              container={true}
                              className={classes.preview}
                              style={{ minHeight: 250 }}
                            >
                              <Typography
                                variant="h4"
                                align="center"
                                style={{ padding: '20px 0' }}
                              >
                                Preview
                              </Typography>

                              <ThemeProvider theme={theme}>
                                <Header
                                  teamstore={teamstore}
                                  languages={languages}
                                  slugs={[]}
                                  menus={headers}
                                  isEditMode={true}
                                  language={language}
                                  urlPrefix={language.slice(0, 2) + '/'}
                                  shop={dataMenu.shop}
                                />
                              </ThemeProvider>
                            </Grid>
                          ) : null}
                        </TabContainer>
                      )}
                      {value === 1 && (
                        <TabContainer>
                          <Grid
                            container={true}
                            direction="row"
                            justify="space-between"
                            spacing={2}
                            style={{
                              maxWidth: '1000px',
                              margin: 'auto'
                            }}
                          >
                            {footers && footers.length > 0 ? (
                              footers.map((nav, index) => (
                                <Query
                                  key={'footer1-' + index}
                                  query={GET_CONTENT_MENU_LOCATION}
                                  variables={{ id: nav.id }}
                                >
                                  {({
                                    loading,
                                    error,
                                    data: { contentMenuLocation }
                                  }) => {
                                    if (error) return `Error! ${error.message}`;
                                    return (
                                      <MenuContainer
                                        teamstore={teamstore}
                                        loading={loading}
                                        shopId={shopId}
                                        key={index}
                                        counter={index + 1}
                                        data={cleanData(dataMenu)}
                                        nav={contentMenuLocation}
                                        pages={data.shop.pages}
                                        language={language}
                                        languages={languages}
                                      />
                                    );
                                  }}
                                </Query>
                              ))
                            ) : (
                              <div>Please add a menu first</div>
                            )}
                          </Grid>

                          {footers && footers.length > 0 ? (
                            <Grid container={true} className={classes.preview}>
                              <Typography
                                variant="h4"
                                align="center"
                                style={{ padding: '20px 0' }}
                              >
                                Preview
                              </Typography>
                              <ThemeProvider theme={theme}>
                                {footers.map((nav, index) => (
                                  <Footer
                                    teamstore={teamstore}
                                    key={index}
                                    menu={getFooter(footers, index)}
                                    shop={dataMenu.shop}
                                    isEditMode={true}
                                    language={language}
                                    urlPrefix={language.slice(0, 2) + '/'}
                                  />
                                ))}
                              </ThemeProvider>
                            </Grid>
                          ) : null}
                        </TabContainer>
                      )}
                    </Fragment>
                  );
                }}
              </Query>
            </Grid>
          </Grid>
        );
      }}
    </Query>
  );
}
