import React, { Component } from 'react';
import * as template_control from '../template_control';
import Grid from '@material-ui/core/Grid';

class LayoutStep extends Component {
  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot;
    DesignRoot.LayoutStep = this;
    DesignRoot.setState({ update: true });
    // PROVIDE COMPONENT TO MAIN
  }

  listTemplateLayout() {

    try {

      const { DesignRoot, productDataSet } = this.props;
      if (!productDataSet) return null;
      var renderDom = [];
      for (var i = 0; i < productDataSet.TEMPLATE_SET.length; i++) {

        var selected = null;
        if ( DesignRoot._ACTIVE_TEMPLATE_ID === productDataSet.TEMPLATE_SET[i].templateId ) {

          selected = {border: '2px solid #f00'};
        }

        renderDom.push(
          <Grid
            item
            xs={4}
            key={'listTemplateLayout' + i}
            title={productDataSet.TEMPLATE_SET[i].templateName}
            onClick={template_control.changeActiveTemplate.bind(
              this,
              productDataSet.TEMPLATE_SET[i].templateId,
              i,
              productDataSet.TEMPLATE_SET[i].templateSrc
            )}
          >
            <img
              src={productDataSet.TEMPLATE_SET[i].templatePreview}
              className="templateListImg"
              alt=""
              style={selected}
            />
          </Grid>
        );
      }

      return (
        <Grid container spacing={2} style={{ padding: 8 }}>
          {renderDom}
        </Grid>
      );

    } catch (e) {

      console.error('LayoutStep.listTemplateLayout.error', e);
    }

    
  }

  render() {
    return <div className="LayoutStep">{this.listTemplateLayout()}</div>;
  }
}

export default LayoutStep;
