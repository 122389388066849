import * as THREE from 'three'

import { MTLLoader, OBJLoader } from 'three-obj-mtl-loader'
import _uniq from 'lodash/uniq'
import { API_STAGE } from '../../../../brikl-config'
import transparent from '../TEMPLATE_ASSET/transparent.png'
import cotton_normal_map from '../TEMPLATE_ASSET/NORMAL_MAP/polyester.jpg'

import * as tools from '../tools/tools'
let SHOP_ID = tools.getQueryStringValue('s')

try {
  if (window !== `undefined`) {
    if (!window.THREE) window.THREE = THREE
  }
} catch (error) {}

try {
  if (window !== `undefined`) {
    const fpsmeter = require('fpsmeter')
    if (!window.THREE) window.THREE = THREE
  }
} catch (error) {}

var OrbitControls = require('three-orbit-controls')(THREE)
let objLoader = new OBJLoader()
let mtlLoader = new MTLLoader()
objLoader.crossOrigin = 'anonymous'
mtlLoader.crossOrigin = 'anonymous'

var DesignRoot = null
var Main3D = null
var appType = null

export function initComponent(_Main3D) {
  Main3D = _Main3D
  DesignRoot = Main3D.props.DesignRoot
  DesignRoot.Main3D = Main3D

  if (!SHOP_ID) {
    SHOP_ID = DesignRoot.SHOP_ID
  }
}

export function declareVar() {
  try {
    // statements

    console.log(
      'declareVar',
      document.querySelector(Main3D.getBounding).offsetHeight,
      document.querySelector(Main3D.getBounding).offsetWidth
    )

    Main3D.THREEJS_WIDTH = document.querySelector(
      Main3D.getBounding
    ).offsetWidth
    Main3D.THREEJS_HEIGHT = document.querySelector(
      Main3D.getBounding
    ).offsetHeight

    Main3D.stitchURL = '/STITCH'

    Main3D.container = null
    Main3D.camera = null
    Main3D.scene = null
    Main3D.renderer = null
    Main3D.renderer_virtual = null
    Main3D.controls = null

    Main3D.main_object = null

    Main3D.manager = null
    Main3D.hoverCanvas = null
    Main3D.texture_front = new THREE.Texture()
    Main3D.textureLoader = null

    Main3D.raycaster = new THREE.Raycaster()
    Main3D.mouse = new THREE.Vector2()
    Main3D.canvasXY = document.createElement('canvas')

    Main3D.patternMaterialName = []
    Main3D.collectUrl = []
    Main3D.selectionObjectELM = null
  } catch (e) {
    // statements
    console.error('ThreejsFunction.declareVar.error', e)
  }
}

export function threejsINIT() {
  try {
    // statements

    Main3D.container = document.getElementById('threejs_container')
    Main3D.camera = new THREE.PerspectiveCamera(
      15,
      Main3D.THREEJS_WIDTH / Main3D.THREEJS_HEIGHT,
      0.1,
      2000
    )

    // SIZE FOR YOGA

    Main3D.camera.position.z = 2 // 1.5 for crop

    Main3D.scene = new THREE.Scene()
    // Main3D.scene.background = new THREE.WebGLRenderer({ alpha: true });
    // Main3D.scene.background = new THREE.Color(0xf5f5f5);
    // Main3D.scene.background = new THREE.Color('rgba(245, 245, 245)');

    Main3D.renderer = new THREE.WebGLRenderer({
      antialias: DesignRoot._ANTIALIAS,
      preserveDrawingBuffer: true,
      alpha: true
    })

    Main3D.renderer.setClearColor(0x000000, 0) //default
    Main3D.renderer.setPixelRatio(window.devicePixelRatio)
    Main3D.renderer.setSize(Main3D.THREEJS_WIDTH, Main3D.THREEJS_HEIGHT)
    Main3D.renderer.domElement.id = 'THREEJS'
    Main3D.renderer.domElement.style.backgroundColor = '#f5f5f5'
    Main3D.container.appendChild(Main3D.renderer.domElement)

    console.log('getMaxAnisotropy', Main3D.renderer.capabilities.getMaxAnisotropy());

    // RENDERER VIRTUAL
    Main3D.renderer_virtual = new THREE.WebGLRenderer({
      antialias: DesignRoot._ANTIALIAS,
      preserveDrawingBuffer: true,
      alpha: true
    })
    Main3D.renderer_virtual.setClearColor(0x000000, 0) //default
    Main3D.renderer_virtual.setPixelRatio(window.devicePixelRatio)
    Main3D.renderer_virtual.setSize(Main3D.THREEJS_WIDTH, Main3D.THREEJS_HEIGHT)
    Main3D.renderer_virtual.domElement.id = 'THREEJS_VIRTUAL'
    Main3D.renderer_virtual.shadowMap.enabled = true
    // Main3D.renderer_virtual.domElement.style.display = 'none';
    // Main3D.container.appendChild(Main3D.renderer_virtual);
    // RENDERER VIRTUAL

    Main3D.storeMappingChild = []
    Main3D.storeMappingMaterialName = []
    Main3D.storeMappingObject = []
    Main3D.storeMappingObjectGroup = []
    Main3D.storeMappingObjectGroupArray = []
    Main3D.storeMappingObjectNoTemplate = []
    Main3D.storeMappingObjectNoTemplateGroup = []
    Main3D.storeMappingObjectNoTemplateGroupArray = []

    // CONTROL POS

    Main3D.lastX = 0
    Main3D.lastY = 0

    // CONTROL POS

    Main3D.onControl = false
    Main3D.directELM = null

    Main3D.meter = null

    Main3D.export3D = false
    Main3D.export3DTransparent = false

    Main3D.normalMapTexture = null

    if (API_STAGE === 'staging' || DesignRoot._DEV_TOOL === true) {
      Main3D.meter = new window.FPSMeter({
        smoothing: 1,
        graph: 1,
        heat: 0,
        decimals: 0,
        maxFps: 60,
        theme: 'dark',
        position: 'fixed',
        top: '-1',
        left: '0',
        bottom: '5px',
        right: '-1',
        padding: '0',
        margin: '0 0 0 0'
      })
    }

    lightSetup()
    helperSetup()
    toolsSetup()

    loadModel()

    eventSetup()

    start()
  } catch (e) {
    // statements
    console.error('ThreejsFunction.threejsINIT.error', e)
  }
}

export function start() {
  try {
    // statements
    if (!Main3D.frameId) {
      Main3D.frameId = requestAnimationFrame(animate.bind(Main3D))
    }
  } catch (e) {
    // statements
    console.error('ThreejsFunction.start.error', e)
  }
}

export function stop() {
  try {
    // statements
    cancelAnimationFrame(Main3D.frameId)
  } catch (e) {
    // statements
    console.error('ThreejsFunction.stop.error', e)
  }
}

export function animate() {
  try {
    // statements
    renderScene()
    Main3D.frameId = window.requestAnimationFrame(animate.bind(Main3D))
  } catch (e) {
    // statements
    console.error('ThreejsFunction.animate.error', e)
  }
}

export function renderScene() {
  try {
    // statements

    if (Main3D.capture3D === true) {
      // CHECK CAPTURE SET LOOP
      let view = '0'

      if (Main3D.captureSET['0'] === null) {
        view = '0'
      } else if (Main3D.captureSET['180'] === null) {
        view = '180'
      } else if (Main3D.captureSET['90'] === null) {
        view = '90'
      } else if (Main3D.captureSET['270'] === null) {
        view = '270'
      }

      // CHECK CAPTURE SET LOOP

      Main3D.scene.background = new THREE.Color(0xffffff)
      Main3D.captureDirection = view
      DesignRoot.control_view.changeView(view)
      Main3D.renderer_virtual.render(Main3D.scene, Main3D.camera)
      DesignRoot.template_control.makeCapture()

      if (
        Main3D.captureSET['0'] !== null &&
        Main3D.captureSET['180'] !== null &&
        Main3D.captureSET['90'] !== null &&
        Main3D.captureSET['270'] !== null
      ) {
        // console.log(' Main3D.captureSET', Main3D.captureSET);

        Main3D.camera.position.set(
          Main3D.currentView.x,
          Main3D.currentView.y,
          Main3D.currentView.z
        )

        Main3D.controls.target.x = Main3D.currentPan.x
        Main3D.controls.target.y = Main3D.currentPan.y
        Main3D.controls.target.z = Main3D.currentPan.z

        Main3D.controls.update()
        Main3D.scene.background = null
        Main3D.capture3D = false

        // STATEMENT HERE AFTER AL CAPTURE
      }
    } else {
      if (Main3D.export3D === true) {
        Main3D.scene.background = new THREE.Color(0xffffff)
        Main3D.renderer.render(Main3D.scene, Main3D.camera)
        DesignRoot.template_control.export3DScene()
        Main3D.scene.background = new THREE.Color('rgba(245, 245, 245)')
        Main3D.export3D = false
      } else if (Main3D.export3DTransparent === true) {
        Main3D.renderer_virtual.render(Main3D.scene, Main3D.camera)
        DesignRoot.template_control.export3DScene('transparent')
        Main3D.export3DTransparent = false
      } else {
        // Main3D.renderer_virtual.render(Main3D.scene, Main3D.camera);
        Main3D.renderer.render(Main3D.scene, Main3D.camera)
      }
    }

    if (Main3D.meter !== null) {
      Main3D.meter.tick()
    }

    if (Main3D.controls.autoRotate === true) {
      Main3D.controls.update()
    }
  } catch (e) {
    // statements
    console.error('ThreejsFunction.renderScene.error', e)
  }
}

export function eventSetup() {
  try {
    // statements

    window.addEventListener('resize', Main3D.onWindowResize.bind(Main3D), false)
    document
      .querySelector('#THREEJS')
      .addEventListener('click', onDocumentClick, false)

    document
      .querySelector('#THREEJS')
      .addEventListener('mousemove', onDocumentMouseMove, false)
    document
      .querySelector('#THREEJS')
      .addEventListener('mousedown', onDocumentMouseDown, false)
    document
      .querySelector('#THREEJS')
      .addEventListener('mouseup', onDocumentMouseUp, false)
  } catch (e) {
    // statements
    console.error('ThreejsFunction.eventSetup.error', e)
  }
}

export function lightSetup() {
  try {
    // statements

    const ambient = new THREE.AmbientLight(0xffffff, 0.6)
    Main3D.scene.add(ambient)

    const spotLightTwo = new THREE.SpotLight(0xffffff, 0.6)
    // camera.add(spotLightTwo);
    spotLightTwo.position.set(0, 500, 500)
    spotLightTwo.angle = Math.PI / 2
    spotLightTwo.castShadow = true
    spotLightTwo.penumbra = 0.1
    spotLightTwo.decay = 2
    spotLightTwo.distance = 4000
    spotLightTwo.shadow.mapSize.width = 1000
    spotLightTwo.shadow.mapSize.height = 1000
    Main3D.camera.add(spotLightTwo)
    Main3D.scene.add(Main3D.camera)

    const HemisphereLight = new THREE.HemisphereLight(0xffffff, 0xbbbbbb, 0.1)
    Main3D.scene.add(HemisphereLight)
  } catch (e) {
    // statements
    console.error('ThreejsFunction.lightSetup.error', e)
  }
}

export function helperSetup() {
  try {
    // statements

    Main3D.controls = new OrbitControls(
      Main3D.camera,
      Main3D.renderer.domElement
    )

    Main3D.controls.enableKeys = false
    Main3D.controls.autoRotate = false
    Main3D.controls.autoRotateSpeed = 5

    Main3D.controls.addEventListener(
      'change',
      onControlsChange.bind(this),
      false
    )
    Main3D.controls.addEventListener('end', onControlsEnd.bind(this), false)

    const axeHelper = new THREE.AxesHelper(10)
    // Main3D.scene.add(axeHelper);
  } catch (e) {
    // statements
    console.error('ThreejsFunction.helperSetup.error', e)
  }
}

export function toolsSetup() {
  try {
    // statements

    Main3D.manager = new THREE.LoadingManager()
    Main3D.textureLoader = new THREE.TextureLoader(Main3D.manager) // USE FOR LOAD ANY TEXTURE

    Main3D.normalMapTexture = new THREE.TextureLoader().load(cotton_normal_map)
    Main3D.normalMapTexture.wrapS = Main3D.normalMapTexture.wrapT =
      THREE.RepeatWrapping

    Main3D.manager.onStart = function(url, itemsLoaded, itemsTotal) {
      console.log('LoadingManager')
      console.log(
        'Started loading texture file: ' +
          url +
          '.\nLoaded ' +
          itemsLoaded +
          ' of ' +
          itemsTotal +
          ' files.'
      )
    }

    Main3D.manager.onLoad = function() {
      console.log('Loading texture complete!')
    }

    Main3D.manager.onProgress = function(url, itemsLoaded, itemsTotal) {
      console.log(
        'Loading texture file: ' +
          url +
          '.\nLoaded ' +
          itemsLoaded +
          ' of ' +
          itemsTotal +
          ' files.'
      )
    }
  } catch (e) {
    // statements
    console.error('ThreejsFunction.toolsSetup.error', e)
  }
}

export function loadModel() {
  try {
    // statements

    console.log('Main3D', Main3D)
    const { productDataSet } = Main3D.props
    const model_obj = productDataSet.OBJ
    const model_mtl = productDataSet.MTL
    const MTL_PATTERN_PREFIX = productDataSet.MTL_PATTERN_PREFIX

    if (
      DesignRoot._ACTIVE_COLOR_ZONE === true &&
      DesignRoot._ACTIVE_TEMPLATE_ID !== 'BLANK'
    ) {
      var local_pattern =
        productDataSet.TEMPLATE_SET_BY_ID[DesignRoot._ACTIVE_TEMPLATE_ID]
          .templateSrc
    } else {
      var local_pattern = transparent
    }

    var patternNameCheck = MTL_PATTERN_PREFIX
    var stitchRoot = Main3D.stitchURL

    mtlLoader.load(model_mtl, materials => {
      console.log('materials', materials)

      // GET PATTERN MATERIAL

      // EXAMPLE RPED-Wider-Basic_Offset_48741

      for (var key in materials.materialsInfo) {
        if (materials.materialsInfo.hasOwnProperty(key)) {
          var materialsInfo = materials.materialsInfo[key]

          if (materialsInfo['map_bump']) {
            var urlSplit = materialsInfo['map_bump'].split('/')
            var pureUrl = urlSplit[urlSplit.length - 1]
            materialsInfo['map_bump'] = stitchRoot + '/' + pureUrl
            // collectUrl[pureUrl] =  stitchRoot+"/"+pureUrl;
            // Main3D.collectUrl.push(materialsInfo['map_bump']);
          }

          if (materialsInfo['map_ka']) {
            var urlSplit = materialsInfo['map_ka'].split('/')
            var pureUrl = urlSplit[urlSplit.length - 1]
            materialsInfo['map_ka'] = stitchRoot + '/' + pureUrl
            // collectUrl[pureUrl] =  stitchRoot+"/"+pureUrl;
            // Main3D.collectUrl.push(materialsInfo['map_ka']);

            // GET MATERAIL PATTERN NAME
            if (materialsInfo['map_ka'].indexOf(patternNameCheck) !== -1) {
              Main3D.patternMaterialName.push(key)
              materialsInfo['map_ka'] = local_pattern
            } else {
              pureUrl = regexStitchName(pureUrl)
              materialsInfo['map_ka'] = stitchRoot + '/' + pureUrl
            }
          }

          if (materialsInfo['map_kd']) {
            var urlSplit = materialsInfo['map_kd'].split('/')
            var pureUrl = urlSplit[urlSplit.length - 1]
            materialsInfo['map_kd'] = stitchRoot + '/' + pureUrl
            // collectUrl[pureUrl] =  stitchRoot+"/"+pureUrl;
            // Main3D.collectUrl.push(materialsInfo['map_kd']);

            // GET MATERAIL PATTERN NAME
            if (materialsInfo['map_kd'].indexOf(patternNameCheck) !== -1) {
              Main3D.patternMaterialName.push(key)
              materialsInfo['map_kd'] = local_pattern
            } else {
              pureUrl = regexStitchName(pureUrl)
              materialsInfo['map_kd'] = stitchRoot + '/' + pureUrl
            }
          }
        }
      }

      Main3D.patternMaterialName = _uniq(Main3D.patternMaterialName)
      Main3D.collectUrl = _uniq(Main3D.collectUrl)

      console.log('Main3D.patternMaterialName', Main3D.patternMaterialName)

      // GET PATTERN MATERIAL

      materials.preload()
      objLoader.setMaterials(materials)

      objLoader.load(model_obj, object => {
        console.log('object', object, object.children)
        loadModelProcess(object)
      })
    })
  } catch (e) {
    // statements
    console.error('ThreejsFunction.loadModel.error', e)
  }
}

export function loadModelProcess(object) {
  try {
    // statements

    var boxScale = new THREE.Box3().setFromObject(object)

    var xLength = boxScale.max.x - boxScale.min.x
    var yLength = boxScale.max.y - boxScale.min.y

    console.log('boxScale', boxScale, xLength, yLength)

    if (xLength > yLength) {
      var modelScale = 0.35 / xLength
    } else {
      var modelScale = 0.35 / yLength
    }

    console.log(modelScale)

    for (var c = 0; c < object.children.length; c++) {
      var child = object.children[c]

      var checkMapMaterial = false

      if (child.material.constructor === Array) {
        for (var i = 0; i < child.material.length; i++) {
          if (
            Main3D.patternMaterialName.indexOf(child.material[i].name) !== -1
          ) {
            // child.material[i].map = mapOverlay;
            Main3D.storeMappingChild.push(child.material[i])
            var materialName = child.material[i].name
            Main3D.storeMappingMaterialName[materialName] = child.material[i]
            child.material[i].map.image = document.getElementById(
              DesignRoot.canvasMainDom
            )
            checkMapMaterial = true
            // console.log('map array', child);
            child.material[i].transparent = true
            child.material[i].needsUpdate = true
            additionalMap(child.material[i])
            normalMap(child.material[i])
          } else if (child.material[i].map && child.material[i].map.image) {
            child.material[i].transparent = true
            // child.material[i].color.set('#FFF');
            child.material[i].userData.defaultColor = child.material[
              i
            ].color.getHexString()
            child.material[i].needsUpdate = true
            additionalMap(child.material[i])
            normalMap(child.material[i])
          } else {
            child.material[i].userData.defaultColor = child.material[
              i
            ].color.getHexString()
            child.material[i].needsUpdate = true
            child.material[i].transparent = true
            additionalMap(child.material[i])
            normalMap(child.material[i], 'MAP')
          }

          // child.material[i].map = mapOverlay;
          // console.log('map array', child);
        }

        // var insideMat = child.material[1].clone();
        // insideMat.color.set('#969696');
        // insideMat.emissive.set("#d1d1d1");
        // insideMat.emissiveIntensity = 0.5;
        // insideMat.shininess = 0;
        // insideMat.lights = false;
        // insideMat.opacity = 0.2;
        // child.material[1] = insideMat;
      } else if (
        Main3D.patternMaterialName.indexOf(child.material.name) !== -1
      ) {
        // child.material.map = mapOverlay;
        // console.log('map', child);
        Main3D.storeMappingChild.push(child.material)
        var materialName = child.material.name
        Main3D.storeMappingMaterialName[materialName] = child.material
        child.material.map.image = document.getElementById(
          DesignRoot.canvasMainDom
        )
        checkMapMaterial = true
        child.material.transparent = true
        child.material.needsUpdate = true
        additionalMap(child.material)
        normalMap(child.material)
      } else if (child.material.map && child.material.map.image) {
        child.material.transparent = true
        // child.material.color.set('#FFF');
        child.material.userData.defaultColor = child.material.color.getHexString()
        child.material.needsUpdate = true
        additionalMap(child.material)
        normalMap(child.material)
      } else {
        child.material.userData.defaultColor = child.material.color.getHexString()
        child.material.transparent = true
        child.material.needsUpdate = true
        additionalMap(child.material)
        normalMap(child.material, 'MAP')
      }

      child.scale.set(modelScale, modelScale, modelScale)

      if (checkMapMaterial === true) {
        Main3D.storeMappingObject.push(child)
      } else {
        child.userData.hasChange = false
        Main3D.storeMappingObjectNoTemplate.push(child)
      }
    }

    var box = new THREE.Box3().setFromObject(object)
    box.getCenter(object.position) // Main3D re-sets the object position
    object.position.multiplyScalar(-1)

    Main3D.main_object = object
    Main3D.scene.add(Main3D.main_object)

    console.log('Main3D.storeMappingChild', Main3D.storeMappingChild)
    console.log('Main3D.storeMappingObject', Main3D.storeMappingObject)
    console.log(
      'Main3D.storeMappingObjectNoTemplate',
      Main3D.storeMappingObjectNoTemplate
    )
    console.log(
      'Main3D.storeMappingMaterialName',
      Main3D.storeMappingMaterialName
    )

    buildGroupELM()

    if (DesignRoot.onLoad === true) {
      // LOAD DIRECT COLOR

      console.log(
        'LOAD DIRECT COLOR IN',
        DesignRoot.onLoad,
        DesignRoot.loadData.directElm,
        DesignRoot.loadData
      )

      for (var i = 0; i < Main3D.storeMappingObjectNoTemplate.length; i++) {
        var child = Main3D.storeMappingObjectNoTemplate[i]

        if (
          DesignRoot.loadData.directElm &&
          DesignRoot.loadData.directElm[child.name] &&
          DesignRoot.loadData.directElm[child.name] !== undefined &&
          DesignRoot.loadData.directElm[child.name].color !== null
        ) {
          console.log('FILL', DesignRoot.loadData.directElm[child.name])

          DesignRoot.change_color.changeColorPickerSelectedDirectELM(
            DesignRoot.loadData.directElm[child.name].color,
            child,
            DesignRoot.loadData.directElm[child.name].id
          )
        }
      }
    }

    // AFTER LOAD MODEL FINISH

    initComponent(Main3D)
    DesignRoot.design_template.canvascolor_fabricjs_init()

    // AFTER LOAD MODEL FINISH
  } catch (e) {
    // statements
    console.error('ThreejsFunction.loadModelProcess.error', e)
  }
}

export function normalMap(material, option) {
  try {
    // statements

    if (material.bumpMap !== null) {
      // material.normalMap = material.bumpMap;
      // var normalScale = new THREE.Vector2();
      // normalScale.x = 0.1;
      // normalScale.y = 0.1;
      // material.normalScale = normalScale;

      if (option && option === 'MAP') {
        // material.bumpScale = 0.5;
      } else {
        material.bumpMap = null
      }
    }

    // TEST NORMAL MAP

    if (DesignRoot._NORMAL_MAP === true) {
      material.onBeforeCompile = function(shader) {
        console.log('onBeforeCompile', shader)

        // materialShader = shader;

        shader.fragmentShader = shader.fragmentShader.replace(
          '#include <normalmap_pars_fragment>',
          [
            `#ifdef USE_NORMALMAP
              uniform sampler2D normalMap;
              uniform vec2 normalScale;
              vec3 perturbNormal2Arb( vec3 eye_pos, vec3 surf_norm ) {
                vec3 q0 = vec3( dFdx( eye_pos.x ), dFdx( eye_pos.y ), dFdx( eye_pos.z ) );
                vec3 q1 = vec3( dFdy( eye_pos.x ), dFdy( eye_pos.y ), dFdy( eye_pos.z ) );
                vec2 st0 = dFdx( vUv.st );
                vec2 st1 = dFdy( vUv.st );
                float scale = sign( st1.t * st0.s - st0.t * st1.s );
                vec3 S = normalize( ( q0 * st1.t - q1 * st0.t ) * scale );
                vec3 T = normalize( ( - q0 * st1.s + q1 * st0.s ) * scale );
                vec3 N = normalize( surf_norm );
                mat3 tsn = mat3( S, T, N );
                vec3 mapN = texture2D( normalMap, vUv * 30.0 ).xyz * 2.0 - 1.0; // REPEAT NORMAL MAP
                mapN.xy *= (normalScale * 2.0); // NORMAL MAP INTENSITY
                mapN.xy *= ( float( gl_FrontFacing ) * 2.0 - 1.0 );
                return normalize( tsn * mapN );
              }
            #endif`
          ].join('\n')
        )
      }

      material.normalMap = Main3D.normalMapTexture
    }

    // TEST NORMAL MAP
  } catch (e) {
    // statements
    console.error('ThreejsFunction.normalMap.error', e)
  }
}

export function additionalMap(material) {
  try {
    // statements

    if ( material.map && Main3D.renderer.capabilities.getMaxAnisotropy() ) {

      material.map.anisotropy = Main3D.renderer.capabilities.getMaxAnisotropy() / 2;
    }

    if (SHOP_ID === 'bodywearlab') {
      material.side = THREE.DoubleSide
    } else if (SHOP_ID === 'demo') {
      material.color.set('#ffffff')
    }
  } catch (e) {
    // statements
    console.error('ThreejsFunction.additionalMap.error', e)
  }
}

export function onControlsChange(event) {
  Main3D.onControl = true
}

export function onControlsEnd(event) {
  Main3D.onControl = false
}

export function onDocumentClick(event) {
  // console.log('onDocumentClick', onControl);
  checkIntersect('click', event)
}

export function onDocumentMouseMove(event) {
  // if ( DesignRoot.mouseDown === true ) {

  // Main3D.controls.enabled = false;
  //   }
  //   else {

  //     Main3D.controls.enabled = true;
  //   }

  // Main3D.controls.enabled = false;

  // console.log('mouse move', event);
  // var hoverCanvas = event.target.id;

  // if ( hoverCanvas === 'THREEJS' ) {

  //   Main3D.controls.enabled = true;
  //   checkIntersect('mousemove', event);
  // }
  // else {

  //   Main3D.controls.enabled = false;
  // }

  if (Main3D.onControl === false) {
    checkIntersect('mousemove', event)
  }
}

export function onDocumentMouseDown(event) {
  checkIntersect('mousedown', event)
}

export function onDocumentMouseUp(event) {
  DesignRoot.mouseDown = false
  checkIntersect('mouseup', event)
}

export function checkIntersect(option, event) {
  try {
    // statements

    // console.log('checkIntersect', option);

    // document.body.style.cursor = 'default';

    if (DesignRoot._DISPATCH_EVENT === false) {
      return
    }

    // GET MOUSE POSITION

    var rect = Main3D.renderer.domElement.getBoundingClientRect()

    // Main3D.mouse.x =
    //   ((event.clientX - rect.left) / (rect.width - rect.left)) * 2 - 1;
    // Main3D.mouse.y =
    //   -((event.clientY - rect.top + 0) / (rect.bottom - rect.top + 0)) * 2 + 1;

    Main3D.mouse.x = (event.offsetX / Main3D.THREEJS_WIDTH) * 2 - 1
    Main3D.mouse.y = -(event.offsetY / Main3D.THREEJS_HEIGHT) * 2 + 1

    // GET MOUSE POSITION

    Main3D.raycaster.setFromCamera(Main3D.mouse, Main3D.camera)

    var intersects = Main3D.raycaster.intersectObjects(
      Main3D.main_object.children,
      true
    )

    var intersectsOBJ = null

    // console.log("checkIntersect", intersects, rect, Main3D.mouse);

    if (intersects.length) {
      if (DesignRoot.bucketStatus === true) {
        document.body.style.cursor = "url('TEMPLATE/bucket.png') 18 20, auto"
      } else {
        // document.body.style.cursor = 'crosshair';
      }

      intersectsOBJ = intersects[0]
    } else if (option === 'mousemove') {
      // NO INTERSECT
      DesignRoot.svg_control.svg_border_default_set()
    }

    if (intersectsOBJ !== null) {
      // CHECK OUTBOUND HOVER

      // DEBUG UV IN ELM
      // intersectsOBJ.uv.x = 0.5135080218315125;
      // intersectsOBJ.uv.y = -0.8589289784431458;
      // DEBUG UV IN ELM

      if (option === 'click' || option === 'mousedown') {
        // GET ELM NAME EVERY CLICK
        Main3D.selectionObjectELM = intersectsOBJ.object
      }

      var checkHoverBounce = false
      for (var i = 0; i < Main3D.storeMappingObject.length; i++) {
        if (intersectsOBJ.object.name === Main3D.storeMappingObject[i].name) {
          checkHoverBounce = true
        }
      }

      if (checkHoverBounce === false) {
        // CHECK AREA OUT OF CANVAS (STITCH)
        var pixelX = Main3D.lastX
        var pixelY = Main3D.lastY
      } else {
        var pixel = calculateUV(intersectsOBJ.uv)
        var pixelX = pixel.x
        var pixelY = pixel.y
      }

      // CHECK OUTBOUND HOVER
      // console.log('pixelXY FIRST (', pixelX, ',', pixelY, ')', intersectsOBJ.uv);

      Main3D.lastX = pixelX
      Main3D.lastY = pixelY

      if (option === 'click') {
        console.log('intersectsOBJ', intersectsOBJ)

        if (checkOutOfTemplateElement(intersectsOBJ.object)) {
          Main3D.directELM = intersectsOBJ.object
          DesignRoot.Selection.setState({
            selectDirectELM: Main3D.directELM.name,
            selectDirectELMObject: Main3D.directELM
          })

          if (DesignRoot._ACTIVE_COLOR_ZONE === true) {
            DesignRoot.FillStep.setState({
              selectColor: Main3D.directELM.name + '_ELM_',
              selectType: 'ELM',
              color: DesignRoot.change_color.returnCurrentColor(
                Main3D.directELM,
                'ELM'
              )
            })
          } else {
            DesignRoot.FillStep.setState({
              colorSVG: DesignRoot.change_color.returnCurrentColor(
                Main3D.directELM,
                'ELM'
              )
            })
          }

          return false
        } else {
          Main3D.directELM = null
          DesignRoot.Selection.setState({
            selectDirectELM: null,
            selectDirectELMObject: null
          })
        }

        console.log('pixelXY (', pixelX, ',', pixelY, ')', intersectsOBJ.uv)

        if (DesignRoot._ACTIVE_DRAW_XY === true) {
          // drawCanvasXY(pixelX, pixelY, option, event);
        }

        // DesignRoot.fabric_function.drawUVCoord(pixelX, pixelY, option, event);
      } // END option === 'click'

      // [0.5, 0, 0, 0.5, 0, 0]
      // [0.5, 0, 0, 0.5, 11, 0]

      if (option === 'mousemove') {
        DesignRoot.fabric_function.drawUVCoord(pixelX, pixelY, option, event)

        var rect = document
          .querySelector('.upper-canvas')
          .getBoundingClientRect()
        // console.log('getBoundingClientRect', rect);

        // CONTROL ClientRect

        if (DesignRoot._APP_TYPE === '2d-3d') {
          if (rect.top < 0) {
            DesignRoot._GAP_Y = rect.top
          } else {
            DesignRoot._GAP_Y = rect.top
          }

          if (rect.left < 0) {
            DesignRoot._GAP_X = rect.left
          } else {
            DesignRoot._GAP_X = rect.left
          }
        }

        if (DesignRoot.canvas.viewportTransform) {
          DesignRoot._GAP_X += DesignRoot.canvas.viewportTransform[4]
          DesignRoot._GAP_Y += DesignRoot.canvas.viewportTransform[5]
        }

        // CONTROL ClientRect

        if (DesignRoot.mouseDown === true) {
          console.log('targetObjectHover', DesignRoot.targetObjectHover)

          if (
            DesignRoot.targetObjectHover !== null &&
            (DesignRoot.targetObjectHover.id === 'template' ||
              DesignRoot.targetObjectHover._OBJECT_TYPE === 'SVG' ||
              (DesignRoot.targetObjectHover._OBJECT_TYPE === 'LOGO' &&
                DesignRoot.targetObjectHover.lockMovementX === true) ||
              (DesignRoot.targetObjectHover._OBJECT_TYPE === 'TEXT' &&
                DesignRoot.targetObjectHover.lockMovementX === true) ||
              (DesignRoot.targetObjectHover._OBJECT_TYPE === 'PATTERN' &&
                DesignRoot.targetObjectHover.lockMovementX === true) ||
              DesignRoot.targetObjectHover._DEFAULT_ITEM) &&
            DesignRoot.onAddLogo === false
          ) {
            Main3D.controls.enabled = true
            return false
          } else {
            Main3D.controls.enabled = false
          }

          console.log('targetControl', DesignRoot.targetControl)

          if (
            DesignRoot.targetControl !== null &&
            DesignRoot.targetControl !== 0 &&
            DesignRoot.targetControl !== undefined
          ) {
            console.log('virtual mousedown', event)
            var evt = new MouseEvent('mousedown', {
              clientX:
                DesignRoot.targetObject.oCoords[DesignRoot.targetControl].x +
                DesignRoot._GAP_X -
                DesignRoot.canvas.viewportTransform[4],
              clientY:
                DesignRoot.targetObject.oCoords[DesignRoot.targetControl].y +
                DesignRoot._GAP_Y -
                DesignRoot.canvas.viewportTransform[5],
              bubbles: true
            })
          } else if (DesignRoot.targetObject !== null) {
            console.log('virtual mousedown', event)
            console.log('targetObjectPos', DesignRoot.targetObjectPos)
            var evt = new MouseEvent('mousedown', {
              clientX: DesignRoot.targetObjectPos.left,
              clientY: DesignRoot.targetObjectPos.top,
              bubbles: true
            })
          } else {
            console.log('virtual mousedown', event)
            var evt = new MouseEvent('mousedown', {
              clientX: pixelX * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_X,
              clientY: pixelY * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_Y,
              bubbles: true
            })
          }

          document.querySelector('.upper-canvas').dispatchEvent(evt)

          var evt = new MouseEvent('mousemove', {
            clientX: pixelX * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_X,
            clientY: pixelY * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_Y,
            bubbles: true
          })
          document.querySelector('.upper-canvas').dispatchEvent(evt)

          console.log('virtual mouseup', event)
          var evt = new MouseEvent('mouseup', {
            bubbles: true
          })
          document.querySelector('.upper-canvas').dispatchEvent(evt)
        } // END (DesignRoot.mouseDown === true)
        else {
          // MOUSE MOVE ONLY
          // console.log('MOUSE MOVE ONLY');

          var evt = new MouseEvent('mouseup', {
            bubbles: true
          })
          document.querySelector('#THREEJS').dispatchEvent(evt)

          var evt = new MouseEvent('mousemove', {
            clientX: pixelX * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_X,
            clientY: pixelY * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_Y,
            bubbles: true
          })
          document.querySelector('.upper-canvas').dispatchEvent(evt)
        } // END // MOUSE MOVE ONLY
      } // END (option === 'mousemove')
      else if (option === 'mousedown') {
        console.log('virtual mousedown 1', event)
        var evt = new MouseEvent('mousedown', {
          clientX: pixelX * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_X,
          clientY: pixelY * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_Y
          // bubbles: true,
          // cancelable: true,
          // buttons: 1,
          // view: window,
          // composed: false
        })

        // DesignRoot.canvas.upperCanvasEl.dispatchEvent(evt);
        document.querySelector('.upper-canvas').dispatchEvent(evt)

        DesignRoot.mouseDown = true

        DesignRoot.fabric_function.updateTexture()
      } // END (option === 'mousedown')
    } // END  (intersectsOBJ !== null)
    else {
      // NO INTERSECT
      // mouseLeave();
      // DesignRoot.mouseDown = false;

      if (DesignRoot.mouseDown === false) {
        Main3D.controls.enabled = true
      }

      if (
        document.body.style.cursor !== 'default' &&
        DesignRoot._DEV_TOOL === false
      ) {
        document.body.style.cursor = 'default'
      }
    }
  } catch (e) {
    // statements
    console.error('ThreejsFunction.checkIntersect.error', e)
  }
}

export function checkOutOfTemplateElement(object) {
  try {
    // statements

    var check = false
    for (var i = 0; i < Main3D.storeMappingObjectNoTemplate.length; i++) {
      if (Main3D.storeMappingObjectNoTemplate[i].name === object.name) {
        check = true
      }
    }

    console.log(
      'checkOutOfTemplateElement',
      check,
      object,
      Main3D.storeMappingObjectNoTemplate
    )

    return check
  } catch (e) {
    // statements
    console.error('ThreejsFunction.checkOutOfTemplateElement.error', e)
  }
}

export function drawCanvasXY(X, Y, option, event) {
  try {
    // statements

    if (DesignRoot._ACTIVE_COLOR_ZONE === false) {
      return
    }

    // canvasXY
    var context = Main3D.canvasXY.getContext('2d')
    Main3D.canvasXY.width = DesignRoot.imageLoad.width
    Main3D.canvasXY.height = DesignRoot.imageLoad.height
    context.clearRect(0, 0, Main3D.canvasXY.width, Main3D.canvasXY.height)
    context.drawImage(DesignRoot.imageLoad, 0, 0)

    var getColorDot = context.getImageData(X, Y, 1, 1)
    var getColorDotCheck = context.getImageData(1, 1, 1, 1)

    console.log('getColorDotCheck 1 1', getColorDotCheck)
    console.log('getColorDot', getColorDot)

    var colorHOVER = DesignRoot.canvas_coloring.rgbToHex(
      getColorDot.data[0],
      getColorDot.data[1],
      getColorDot.data[2]
    )

    console.log('colorHOVER', colorHOVER)

    if (option === 'click') {
      DesignRoot.FillStep.setState({
        selectColor: colorHOVER,
        selectType: 'COLOR',
        color: DesignRoot.change_color.returnCurrentColor(colorHOVER, 'COLOR')
      })
      DesignRoot.template_control.setSelectColorZone(colorHOVER)
    }

    // DEBUG POSITION

    // context.beginPath();
    // context.rect(X, Y, 20, 20); // POINTER
    // context.strokeStyle = "red";
    // context.stroke();

    // console.log(Main3D.canvasXY.toDataURL('image/png'))

    // DEBUG POSITION
  } catch (e) {
    // statements
    console.error('ThreejsFunction.drawCanvasXY.error', e)
  }
}

export function getELMByName(name) {
  try {
    // statements

    for (var i = 0; i < Main3D.storeMappingObjectNoTemplate.length; i++) {
      if (Main3D.storeMappingObjectNoTemplate[i].name === name) {
        Main3D.directELM = Main3D.storeMappingObjectNoTemplate[i]
        break
      }
    }

    return Main3D.directELM
  } catch (e) {
    // statements
    console.error('ThreejsFunction.getELMByName.error', e)
  }
}

export function getOneELMByName(name) {
  try {
    // statements

    var elm = null

    for (var i = 0; i < Main3D.main_object.children.length; i++) {
      if (Main3D.main_object.children[i].name === name) {
        elm = Main3D.main_object.children[i]
        break
      }
    }

    console.log('getOneELMByName', elm)

    return elm
  } catch (e) {
    // statements
    console.error('ThreejsFunction.getELMByName.error', e)
  }
}

export function calculateUV(uv) {
  try {
    var baseWidth = DesignRoot.imageLoad.width
    var baseHeight = DesignRoot.imageLoad.height

    var pixelX = Math.round(uv.x * baseWidth)

    if (uv.y > 1) {
      var pixelY = Math.round(
        (uv.y - Math.round(uv.y)) * baseHeight - baseHeight
      ) // Fix pixelY
    } else {
      var pixelY = Math.round(uv.y * baseHeight - baseHeight) // Fix pixelY
    }

    // // Fix pixelY
    while (pixelY <= -0.1) {
      pixelY = pixelY * -1
    }
    while (pixelX <= -0.1) {
      pixelX = pixelX * -1
      pixelX = baseWidth - pixelX
    }
    // If texture have repeat setting
    while (pixelY > baseHeight) {
      pixelY = pixelY - baseHeight
    }
    while (pixelX > baseWidth) {
      pixelX = pixelX - baseWidth
    }

    // console.log('pixelX', pixelX);
    // console.log('pixelY', pixelY);

    return { x: pixelX, y: pixelY }
  } catch (e) {
    // statements
    console.error('ThreejsFunction.calculateUV.error', e)
  }
}

export function regexStitchName(fileName) {
  try {
    if (
      fileName.indexOf('RPEDButtonHole_L500_S0_T1500') !== -1 ||
      fileName.indexOf('RPEDZigzag_L200_S0_T3000') !== -1
    ) {
      return fileName
    } else {
      return fileName.replace(/(_\d+)/g, '')
    }
  } catch (e) {
    // statements
    console.error('ThreejsFunction.regexStitchName.error', e)
  }
}

export function buildGroupELM() {
  try {
    console.log('buildGroupELM')

    if (Main3D.storeMappingObjectNoTemplate.length) {
      for (var i = 0; i < Main3D.storeMappingObjectNoTemplate.length; i++) {
        var fullname = Main3D.storeMappingObjectNoTemplate[i].name

        if (fullname.indexOf('MatShape') !== -1) {
          var key = 'MatShape'
        } else {
          var split = fullname.split('-')
          var key = split[0]
        }

        console.log(key)
        Main3D.storeMappingObjectNoTemplate[i].userData.elmGroup = key

        if (Main3D.storeMappingObjectNoTemplateGroup[key] !== undefined) {
          Main3D.storeMappingObjectNoTemplateGroup[key].push(
            Main3D.storeMappingObjectNoTemplate[i]
          )
        } else {
          Main3D.storeMappingObjectNoTemplateGroup[key] = []
          Main3D.storeMappingObjectNoTemplateGroup[key].push(
            Main3D.storeMappingObjectNoTemplate[i]
          )
          Main3D.storeMappingObjectNoTemplateGroupArray.push(
            Main3D.storeMappingObjectNoTemplate[i]
          )
        }
      }

      console.log(Main3D.storeMappingObjectNoTemplateGroup)
    }

    if (Main3D.storeMappingObject.length) {
      for (var i = 0; i < Main3D.storeMappingObject.length; i++) {
        var fullname = Main3D.storeMappingObject[i].name

        if (fullname.indexOf('MatShape') !== -1) {
          var key = 'MatShape'
        } else {
          var split = fullname.split('-')
          var key = split[0]
        }

        console.log(key)
        Main3D.storeMappingObject[i].userData.elmGroup = key

        if (Main3D.storeMappingObjectGroup[key] !== undefined) {
          Main3D.storeMappingObjectGroup[key].push(Main3D.storeMappingObject[i])
        } else {
          Main3D.storeMappingObjectGroup[key] = []
          Main3D.storeMappingObjectGroup[key].push(Main3D.storeMappingObject[i])
          Main3D.storeMappingObjectGroupArray.push(Main3D.storeMappingObject[i])
        }
      }

      console.log(Main3D.storeMappingObjectGroup)
    }
  } catch (e) {
    console.error('ThreejsFunction.buildGroupELM.error', e)
  }
}

export function updateNormalMap(normalMapFile) {
  try {
    for (var i = 0; i < Main3D.storeMappingChild.length; i++) {
      Main3D.storeMappingChild[i].onBeforeCompile = function(shader) {
        console.log('onBeforeCompile', shader)

        // materialShader = shader;

        shader.fragmentShader = shader.fragmentShader.replace(
          '#include <normalmap_pars_fragment>',
          [
            `#ifdef USE_NORMALMAP
              uniform sampler2D normalMap;
              uniform vec2 normalScale;
              vec3 perturbNormal2Arb( vec3 eye_pos, vec3 surf_norm ) {
                vec3 q0 = vec3( dFdx( eye_pos.x ), dFdx( eye_pos.y ), dFdx( eye_pos.z ) );
                vec3 q1 = vec3( dFdy( eye_pos.x ), dFdy( eye_pos.y ), dFdy( eye_pos.z ) );
                vec2 st0 = dFdx( vUv.st );
                vec2 st1 = dFdy( vUv.st );
                float scale = sign( st1.t * st0.s - st0.t * st1.s );
                vec3 S = normalize( ( q0 * st1.t - q1 * st0.t ) * scale );
                vec3 T = normalize( ( - q0 * st1.s + q1 * st0.s ) * scale );
                vec3 N = normalize( surf_norm );
                mat3 tsn = mat3( S, T, N );
                vec3 mapN = texture2D( normalMap, vUv * 50.0 ).xyz * 2.0 - 1.0; // REPEAT NORMAL MAP
                mapN.xy *= (normalScale * 4.0); // NORMAL MAP INTENSITY
                mapN.xy *= ( float( gl_FrontFacing ) * 2.0 - 1.0 );
                return normalize( tsn * mapN );
              }
            #endif`
          ].join('\n')
        )
      }

      Main3D.normalMapTexture = new THREE.TextureLoader().load(normalMapFile)
      Main3D.normalMapTexture.wrapS = Main3D.normalMapTexture.wrapT =
        THREE.RepeatWrapping

      Main3D.storeMappingChild[i].normalMap = Main3D.normalMapTexture
      Main3D.storeMappingChild[i].needsUpdate = true
    }
  } catch (e) {
    console.error('ThreejsFunction.updateNormalMap.error', e)
  }
}
