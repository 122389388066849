import React from 'react'
import {
  withStyles,
  Theme,
  createStyles, WithStyles, ListItem
} from '@material-ui/core'


const styles = (themeStyle: Theme) => createStyles({
  ColumnTitle: {
    fontSize: '16px'
  },
  list: {
    fontWeight: '500',
    padding: 0
  }
})


interface Props extends WithStyles<typeof styles> {
  data: any;
  linkColor?: string;
}


const Contacts: React.FunctionComponent<Props> = (props) => {
  const { classes, data, linkColor } = props

  return (
    <>
      <ListItem
        className={classes.list}
        style={{ color: linkColor, cursor: 'auto' }}
      >
        {data.address1}
        <br/>
        {data.address2}
        <br /> {data.zip?data.zip:''} {data.city}
        {data.country}
      </ListItem>
      {
        data.phone ?
        <ListItem className={classes.list} style={{color: linkColor, cursor: 'auto'}}>
        T: {data.phone}
      </ListItem>
      :null
      }
      {
        data.email ?
        <ListItem className={classes.list} style={{color: linkColor, cursor: 'auto'}}>
        E: {data.email}
      </ListItem> :null
      }
    </>
  )
}


export default withStyles(styles, { withTheme: true })(Contacts)
