import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, Input, InputLabel } from '@material-ui/core';
import { Slider } from '@material-ui/core';
import Select from '../../common/Select';
// import Input from '../../common/Input';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

import * as tools from '../tools/tools';
import * as text_function from './appFunc/text_function';
import { CirclePicker } from 'react-color';
import LayerControl from './LayerControl';
import ControlTools from './ControlTools';
import { Localization } from '../../../lang/localization';
import { API_STAGE } from '~/brikl-config';
import Divider from '@material-ui/core/Divider';
import { wait } from '../../Utils';
import { Delete, Add, KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import ColorPicker from './ColorPicker';

let SHOP_ID = tools.getQueryStringValue('s');

const styles = theme => ({
  colorSwatch: {
    background: '#BBBBBB',
    width: 27,
    height: 27,
    border: '3px solid #BBBBBB',
    borderRadius: '100%',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer'
    }
  },
  logoTumbnail: {
    background: '#BBBBBB',
    width: 46,
    height: 46,
    border: '3px solid #BBBBBB',
    borderRadius: '6px',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer'
    }
  },
  root: {
    width: '100%',
    boxShadow: 'none',
    border: '1px solid #e5e5e5'
  },
  elevation: {
    boxShadow: 'none',
    border: '1px solid #e5e5e5'
  },
  track: {
    backgroundColor: '#000'
  },
  thumb: {
    backgroundColor: '#000'
  },
  inputHeight: {
    height: 14
  },
  rail:{
    backgroundColor: '#e5e5e5'
  }
});

class Text extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      selectTextId: null,
      content: '',
      fontStyle: 'Arial',
      fontStyleSET: [
        'Arial',
        'Serif',
        'Sans-Serif',
        'Biome',
        'Helvetica',
        'Tahoma',
        'Verdana',
        'Impact',
        'Nexa',
        'Oswald',
        'Poppins',
        'Roboto Slab',
        'Caveat Brush',
        'Fargo Faro NF',
        'Gota Light',
        'Yesteryear',
        'Henny Penny',
        'Comfortaa',
        'Black Ops One',
        'Montserrat'
      ],
      textColor: '#FFFFFF',
      orderSeq: null,
      outlineColor: '#FFFFFF',
      outlineSize: 0,
      lineSpacing: 0,
      triggerDidUpdate: false,
      rotate: 0,
      scale: 1,
      expanded: null,
      triggerAddText: false
    };

    if (this.props.DesignRoot._ACTIVE_PERSONALIZATION === true) {
      this.initState.fontStyleSET = ['Montserrat'];
      this.initState.fontStyle = 'Montserrat';
      this.initState.textColor = '#000000';
      this.initState.outlineColor = '#000000';
    }

    this.state = this.initState;

    if ( !SHOP_ID ) {

      SHOP_ID = this.props.DesignRoot.SHOP_ID;
    }
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });

    this.initState.expanded = expanded ? panel : false;

  };

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot;
    DesignRoot.TextStep = this;
    DesignRoot.setState({ update: true });
    // PROVIDE COMPONENT TO MAIN
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectTextId !== null &&
      this.state.triggerDidUpdate === true
    ) {
      console.log('componentDidUpdate', prevState, this.state);

      this.applyText(); // INSTANT UPDATE
      this.setState({ triggerDidUpdate: false });
    }

    var DesignRoot = this.props.DesignRoot;

    if (DesignRoot._TEXT_LOAD === true && DesignRoot._TEXT_LIB_READY === true) {
      await this.loadText();
    }

    if ( this.state.triggerAddText === true ) {

      this.setState({ triggerAddText: false });



    }

  }

  async loadText() {

    try {

      var DesignRoot = this.props.DesignRoot;

      var allItemList = DesignRoot._LOAD_DATA.allItem;

      DesignRoot._TEXT_LOAD = false;
      DesignRoot._TEXT_LIB_READY = false;

      var allItem = [];
      for (var i = 0; i < allItemList.length; i++) {
        if (allItemList[i].objectType === 'TEXT') {
          allItem.push(allItemList[i]);
        }
      }

      i = 0;
      while (i < allItem.length) {
        // for (var i = 0; i < allItem.length; i++) {
        if (allItem[i].canvasData === null) {
          console.log('canvasData NULL', allItem[i]);
          continue;
        }

        if (allItem[i].objectType === 'TEXT') {
          var userData = allItem[i].userData;

          DesignRoot.Loading.activeLoading(true);
          console.log('TEXT LOAD START', allItem[i], userData);

          const src = await this.getTextCanvas(userData, i);

          console.log('TEXT LOAD END', i, src);

          userData.base64 = src;

          if (userData.defaultItem === true || userData.boundingId !== null) {
            // DEFAULT ITEM BOUNDING LOAD

            // DEMO LOAD DATA

            var boundingId = userData.boundingId;
            var boundingGroup = userData.boundingGroup;

            var boundingData = DesignRoot.fabric_function.getObjectById(
              boundingId
            );

            var left = boundingData.left;
            var top = boundingData.top;
            var isBouding = true;
            var canvasData = {
              left: left,
              top: top,
              scaleX: boundingData.scaleX,
              scaleY: boundingData.scaleY,
              width: boundingData.width,
              height: boundingData.height,
              flipX: allItem[i].canvasData.flipX,
              flipY: allItem[i].canvasData.flipY
            };

            userData.centerPoint = boundingData.getCenterPoint();
            userData.boundingId = boundingData.id;
            userData.boundingGroup = boundingData._ZONE_GROUP;

            DesignRoot.fabric_function.addItem(
              allItem[i].id || userData.id,
              allItem[i].name,
              src,
              canvasData.left,
              canvasData.top,
              allItem[i].userData,
              allItem[i].objectType,
              'ON-LOAD',
              canvasData,
              isBouding
            );

            // DEMO LOAD DATA
          } else if (DesignRoot._ACTIVE_PERSONALIZATION === false) {
            DesignRoot.fabric_function.addItem(
              allItem[i].id || userData.id,
              allItem[i].name,
              src,
              allItem[i].canvasData.left,
              allItem[i].canvasData.top,
              allItem[i].userData,
              allItem[i].objectType,
              'ON-LOAD',
              allItem[i].canvasData
            );
          }
        }

        i++;
        DesignRoot.Loading.activeLoading(false);
        DesignRoot.setState({render: true});
      } // END WHILE

    } catch (e) {

      console.error('TextStep.loadText.error', e);
    }

  }

  async getTextCanvas(userData, i) {
    try {
      text_function.textGenerateToCanvas(
        userData.content,
        userData.textColor,
        userData.fontStyle,
        userData.outlineColor,
        userData.outlineSize,
        userData.lineSpacing,
        i
      );

      await wait(1000);

      return text_function.textGenerateToCanvas(
        userData.content,
        userData.textColor,
        userData.fontStyle,
        userData.outlineColor,
        userData.outlineSize,
        userData.lineSpacing,
        i
      );
    } catch (e) {
      // statements
      console.error('fabric_function.getTextCanvas.error', e);
    }
  }

  async applyText(option, boundingData) {
    try {
      // statements

      const { content, selectTextId } = this.state;
      var DesignRoot = this.props.DesignRoot;

      if (content === '') {
        return false;
      }

      // ADD TO ZONE LOGIC

      var isBouding = false;
      var canvasData = null;
      let boundingUserData = {};
      var left = 0;
      var top = 0;
      if (option && option === 'ADD_TO_ZONE') {
        left = boundingData.left;
        top = boundingData.top;
        isBouding = true;
        canvasData = {
          left: left,
          top: top,
          scaleX: boundingData.scaleX,
          scaleY: boundingData.scaleY,
          width: boundingData.width,
          height: boundingData.height
        };
        boundingUserData = {
          centerPoint: boundingData.getCenterPoint(),
          boundingId: boundingData.id,
          boundingGroup: boundingData._ZONE_GROUP
        };
      }

      // ADD TO ZONE LOGIC

      console.log('applyText', content, this.state, selectTextId);
      const textBase64 = await this._getTextBase64(selectTextId);

      if (selectTextId === null) {
        if (content) {
          DesignRoot.fabric_function.addItem(
            tools.uuidv4(),
            content,
            textBase64,
            left,
            top,
            { ...this.state, ...boundingUserData, base64: textBase64 },
            'TEXT',
            null,
            canvasData,
            isBouding
          );
        }
      } else {
        // UPDATE SELECT TEXT
        if (content) {
          DesignRoot.fabric_function.updateItem(
            selectTextId,
            content,
            textBase64,
            0,
            0,
            false,
            false,
            { ...this.state, ...boundingUserData, base64: textBase64 },
            'TEXT'
          );

          // var item = DesignRoot.fabric_function.getObjectById(selectTextId);

          // console.log('getObjectById', item);

          // if ( item !== null ) {

          //   var img = new Image();
          //   img.onload=function(){
          //       item.setElement(img);
          //       DesignRoot.canvas.renderAll();
          //   }
          //   img.src=textBase64;
          // }
        }
      }
    } catch (e) {
      // statements
      console.error('TextStep.applyText.error', e);
    }
  }

  async _getTextBase64(selectTextId) {
    try {
      // statements

      const DesignRoot = this.props.DesignRoot;

      const {
        content,
        fontStyle,
        textColor,
        outlineColor,
        outlineSize,
        lineSpacing
      } = this.state;

      if (selectTextId !== null) {
        return text_function.textGenerateToCanvas(
          content,
          textColor,
          fontStyle,
          outlineColor,
          outlineSize,
          lineSpacing
        );
      } else {
        text_function.textGenerateToCanvas(
          content,
          textColor,
          fontStyle,
          outlineColor,
          outlineSize,
          lineSpacing
        );

        DesignRoot.Loading.activeLoading(true);

        await wait(500);

        DesignRoot.Loading.activeLoading(false);

        return text_function.textGenerateToCanvas(
          content,
          textColor,
          fontStyle,
          outlineColor,
          outlineSize,
          lineSpacing
        );
      }
    } catch (e) {
      // statements
      console.error('TextStep._getTextBase64.error', e);
    }
  }









  async applyTextByData(option, boundingData, textData) {
    try {
      // statements

      const { content, selectTextId } = textData;
      var DesignRoot = this.props.DesignRoot;

      if (content === '') {
        // return false;
      }

      // ADD TO ZONE LOGIC

      var isBouding = false;
      var canvasData = null;
      let boundingUserData = {};
      var left = 0;
      var top = 0;
      if (option && option === 'ADD_TO_ZONE') {
        left = boundingData.left;
        top = boundingData.top;
        isBouding = true;
        canvasData = {
          left: left,
          top: top,
          scaleX: boundingData.scaleX,
          scaleY: boundingData.scaleY,
          width: boundingData.width,
          height: boundingData.height
        };
        boundingUserData = {
          centerPoint: boundingData.getCenterPoint(),
          boundingId: boundingData.id,
          boundingGroup: boundingData._ZONE_GROUP
        };
      }

      // ADD TO ZONE LOGIC

      console.log('applyTextByData', content, textData, selectTextId);
      const textBase64 = await this._getTextBase64ByData(selectTextId, textData);

      if (selectTextId === null) {
        if (content) {
          DesignRoot.fabric_function.addItem(
            tools.uuidv4(),
            content,
            textBase64,
            left,
            top,
            { ...textData, ...boundingUserData, base64: textBase64 },
            'TEXT',
            null,
            canvasData,
            isBouding
          );
        }
      } else {
        // UPDATE SELECT TEXT
        if (content) {
          DesignRoot.fabric_function.updateItem(
            selectTextId,
            content,
            textBase64,
            0,
            0,
            false,
            false,
            { ...textData, ...boundingUserData, base64: textBase64 },
            'TEXT'
          );

          // var item = DesignRoot.fabric_function.getObjectById(selectTextId);

          // console.log('getObjectById', item);

          // if ( item !== null ) {

          //   var img = new Image();
          //   img.onload=function(){
          //       item.setElement(img);
          //       DesignRoot.canvas.renderAll();
          //   }
          //   img.src=textBase64;
          // }
        }
      }
    } catch (e) {
      // statements
      console.error('TextStep.applyTextByData.error', e);
    }
  }

  async _getTextBase64ByData(selectTextId, textData) {
    try {
      // statements

      const DesignRoot = this.props.DesignRoot;

      const {
        content,
        fontStyle,
        textColor,
        outlineColor,
        outlineSize,
        lineSpacing
      } = textData;

      if (selectTextId !== null) {
        return text_function.textGenerateToCanvas(
          content,
          textColor,
          fontStyle,
          outlineColor,
          outlineSize,
          lineSpacing
        );
      } else {
        text_function.textGenerateToCanvas(
          content,
          textColor,
          fontStyle,
          outlineColor,
          outlineSize,
          lineSpacing
        );

        DesignRoot.Loading.activeLoading(true);

        await wait(500);

        DesignRoot.Loading.activeLoading(false);

        return text_function.textGenerateToCanvas(
          content,
          textColor,
          fontStyle,
          outlineColor,
          outlineSize,
          lineSpacing
        );
      }
    } catch (e) {
      // statements
      console.error('TextStep._getTextBase64ByData.error', e);
    }
  }












  _handleInputChange(type, evt) {
    try {
      // statements
      evt.persist();
      console.log('_handleInputChange', type, evt.target.value);

      this.setState({ [type]: evt.target.value, triggerDidUpdate: true });
    } catch (e) {
      // statements
      console.error('TextStep._handleInputChange.error', e);
    }
  }

  _handleSliderChange(type, evt, value) {
    try {
      // statements
      console.log('_handleSliderChange', type, evt, value);
      this.setState({ [type]: value, triggerDidUpdate: true });
    } catch (e) {
      // statements
      console.error('TextStep._handleSliderChange.error', e);
    }
  }

  _handleChangeColor(type, color) {
    try {
      // statements
      console.log('_handleChangeColor', type, color);
      this.setState({ [type]: color.hex, triggerDidUpdate: true });
    } catch (e) {
      // statements
      console.error('TextStep._handleChangeColor.error', e);
    }
  }

  fontStyleBuild(fontStyle, fontStyleSET) {
    try {
      // statements

      var fontStyleOPTION = [];
      for (var i = 0; i < fontStyleSET.length; i++) {
        fontStyleOPTION.push(
          <option key={'fontStyle' + i} value={fontStyleSET[i]}>
            {fontStyleSET[i]}
          </option>
        );
      }

      return (
        <Select
          onChange={this._handleInputChange.bind(this, 'fontStyle')}
          value={fontStyle}
        >
          {fontStyleOPTION}
        </Select>
      );
    } catch (e) {
      // statements
      console.error('TextStep.fontStyleBuild.error', e);
    }
  }

  renderTextSet() {
    try {
      // statements
      var self = this;
      var DesignRoot = this.props.DesignRoot;

      if (DesignRoot.canvas === null) {
        return false;
      }

      var textSet = [];
      DesignRoot.canvas.getObjects().forEach(function(object) {
        if (object._OBJECT_TYPE && object._OBJECT_TYPE === 'TEXT') {
          textSet.push(<div key={object.id}>{object.name}</div>);
        }
      });

      return textSet;
    } catch (e) {
      // statements
      console.error('TextStep.renderTextSet.error', e);
    }
  }

  handleRotate = (event, rotate) => {
    this.setState({ rotate });
  };

  handleScale = (event, scale) => {
    this.setState({ scale });
  };

  mapAvailableColor() {
    const { classes, DesignRoot, productDataSet } = this.props;

    if (DesignRoot._ACTIVE_PERSONALIZATION === true) {
      return ['#000000', '#FFFFFF'];
    }

    // LOCAL TEST
    if (DesignRoot.props.localData === true) {
      return ['#FFFFFF', '#FF0000', '#00FF00', '#0000FF', '#000000'];
    }
    else if ( DesignRoot._LOCAL_COLOR === true ) {
      return ['#FF0000', '#be2137', '#ff7525', '#ffa100', '#ffcb00', '#fdf900', '#00752c', '#00e430', '#002bff', '#00a9ff', '#701f85', '#873cbe', '#c87aff', '#cccccc', '#f7f7f7', '#000000'];
    }

    let colors = [];
    try {
      const selectedProductElement = productDataSet.elements.find(element =>
        element.fullCode.includes('E1-')
      );
      colors = [];
      if (selectedProductElement && selectedProductElement.fabricCompositions) {
        selectedProductElement.fabricCompositions.map(fC => {
          try {
            fC.colors.forEach(color => {
              if (color.hex) {
                colors.push(color.hex);
              }
            });
          } catch (error) {
            console.error('TextStep.selectedProductElement.fC.error', error);
          }
        });
      }

      let uniqueColors = colors.filter((v, i, a) => a.indexOf(v) === i);

      return uniqueColors;
    } catch (error) {
      console.error('TextStep.selectedProductElement.error', error);
    }
  }

  SLTDefaultText() {

    var DesignRoot = this.props.DesignRoot;

    try {
      if (DesignRoot._ACTIVE_PERSONALIZATION === false) {
        return null;
      }

      // GEN ZONE LIST FROM SVG

      var boundingSET = DesignRoot.fabric_function.get_bounding_by_type('TEXT');

      console.log('_SVG_BOUNDING_SET TEXT', boundingSET);

      var zoneList = [];
      var lastZone = null;

      for (var i = 0; i < boundingSET.length; i++) {
        var _BOUNDING_TYPE = boundingSET[i]._BOUNDING_TYPE;
        var _ZONE_NUMBER = boundingSET[i]._ZONE_NUMBER;
        var _ZONE_LIMIT_ITEM = boundingSET[i]._ZONE_LIMIT_ITEM;
        var _ZONE_GROUP = boundingSET[i]._ZONE_GROUP;
        var _ZONE_ANGLE = boundingSET[i]._ZONE_ANGLE
          ? boundingSET[i]._ZONE_ANGLE
          : 0;
        var _ZONE_NAME = boundingSET[i]._ZONE_NAME;

        if (_ZONE_GROUP !== lastZone) {
          var maxItem = DesignRoot.fabric_function.get_bounding_limit_item_by_group(
            'TEXT',
            _ZONE_GROUP
          );

          var numberOfItem = DesignRoot.fabric_function.get_bounding_current_item_by_group(
            'TEXT',
            _ZONE_GROUP
          );

          var addItem = (
            <Button
              variant="contained"
              style={{
                backgroundColor: '#171717',
                color: '#FFFFFF',
                marginTop: 10,
                marginBottom: 10
              }}
              component="span"
              onClick={this.applyText.bind(this, 'ADD_TO_ZONE', boundingSET[i])}
            >
              ADD TEXT TO {_ZONE_NAME}
            </Button>
          );

          if (numberOfItem >= maxItem) {
            addItem = (
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#171717',
                  color: '#FFFFFF',
                  marginTop: 10,
                  marginBottom: 10
                }}
                component="span"
              >
                ALREADY ADDED TO {_ZONE_NAME}
              </Button>
            );
          }

          var toggleZone = null;

          if (true) {
            toggleZone = (
              <Button
                variant="outlined"
                style={{
                  marginTop: 10,
                  marginBottom: 10
                }}
                component="span"
                onClick={DesignRoot.fabric_function.toggle_bounding_stroke.bind(
                  DesignRoot,
                  'TEXT',
                  _ZONE_GROUP
                )}
              >
                TOGGLE ZONE
              </Button>
            );
          }

          zoneList.push(
            <Grid container justify="center" style={{ margin: 0 }}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {_ZONE_NAME} (Max Item: {maxItem} ({numberOfItem}) )
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {addItem}:{toggleZone}
              </Grid>
            </Grid>
          );

          lastZone = _ZONE_GROUP;
        }
      }

      // GEN ZONE LIST FROM SVG

      return (
        <Grid container justify="center" style={{ margin: 0 }}>
          <Divider
            light
            style={{ width: 180, marginTop: 40, marginBottom: 40 }}
          />

          {zoneList}
        </Grid>
      );
    } catch (e) {
      // statements
      console.error('TextStep.SLTDefaultText.error', e);
    }
  }

  textTransformTool() {

    try {

      const {
        classes,
        DesignRoot,
        productDataSet,
        langCode,
        textConfiguration
      } = this.props;

      const {
        content,
        fontStyle,
        fontStyleSET,
        textColor,
        outlineColor,
        outlineSize,
        lineSpacing,
        selectTextId,
        rotate,
        scale,
        expanded
      } = this.state;

      if (DesignRoot._ACTIVE_PERSONALIZATION === true) {
        return null;
      }

      var approxWidth = 0;
      var approxHeight = 0;

      if ( DesignRoot.Dimension !== null ) {

        approxWidth = DesignRoot.Dimension.state.approxWidth ? DesignRoot.Dimension.state.approxWidth : 0;
        approxHeight = DesignRoot.Dimension.state.approxHeight ? DesignRoot.Dimension.state.approxHeight : 0;
      }

      return (
        <div>
          <Grid item xs={12}>
            <Grid
              container
              justify="center"
              style={{ margin: 0, marginTop: 40, textAlign: 'center' }}
            >
              <Typography variant="h5">
                {Localization.get('beCreative_transform_label')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={1}
              style={{ margin: 0, marginTop: 16 }}
            >
              <Typography id="label" style={{ marginBottom: 5 }}>
                {Localization.get('beCreative_outline_size')} : {outlineSize}
              </Typography>
              <Slider
                value={outlineSize}
                aria-labelledby="label"
                step={1}
                min={0}
                max={35}
                classes={{
                  track: classes.track,
                  thumb: classes.thumb,
                  rail: classes.rail
                }}
                onChange={this._handleSliderChange.bind(this, 'outlineSize')}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={1}
              style={{ margin: 0, marginTop: 16 }}
            >
              <Typography id="label" style={{ marginBottom: 5 }}>
                {Localization.get('beCreative_line_spacing')} : {lineSpacing}
              </Typography>
              <Slider
                value={lineSpacing}
                aria-labelledby="label"
                step={1}
                min={0}
                max={100}
                classes={{
                  track: classes.track,
                  thumb: classes.thumb,
                  rail: classes.rail
                }}
                onChange={this._handleSliderChange.bind(this, 'lineSpacing')}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            spacing={1}
            style={{ margin: 0, marginTop: 16 }}
          >
            <Typography id="label">Rotate {rotate}&deg;</Typography>
            <Grid container spacing={2} alignItems='center'>
                <Grid item xs={10}>
                  <Slider
                    value={rotate}
                    aria-labelledby="label"
                    step={1}
                    min={-360}
                    max={360}
                    onChange={this.handleTransformChange.bind(this, 'rotate')}
                    classes={{
                      track: classes.track,
                      thumb: classes.thumb,
                      rail: classes.rail
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button style={{ marginBottom: 2, minWidth: 0 }} variant="outlined" onClick={(e)=> { this.handleTransformChange('rotate', e, '+')}}><KeyboardArrowUp style={{ fontSize: 14 }} /></Button>
                  <Button style={{ marginBottom: 2, minWidth: 0 }} variant="outlined" onClick={(e)=> { this.handleTransformChange('rotate', e, '-')}}><KeyboardArrowDown style={{ fontSize: 14 }} /></Button>
                </Grid>
              </Grid>
          </Grid>

          <Grid
              container
              justify="flex-start"
              alignItems="center"
              spacing={1}
              style={{ margin: 0, marginTop: 16 }}
            >
              <Typography id="label" style={{marginBottom: 8}}>Resize {approxWidth} x {approxHeight} CM</Typography>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={10}>
                  <Slider
                  value={scale}
                  aria-labelledby="label"
                  step={0.01}
                  min={0.01}
                  max={5}
                  onChange={this.handleTransformChange.bind(this, 'scale')}
                  classes={{
                    track: classes.track,
                    thumb: classes.thumb,
                    rail: classes.rail
                  }}
                />
                </Grid>
                <Grid item xs={2}>
                  <Grid container direction={'column'}>
                  <Button style={{ marginBottom: 2, minWidth: 0 }} variant="outlined" onClick={(e)=> { this.handleTransformChange('scale', e, '+')}}><KeyboardArrowUp style={{ fontSize: 14 }} /></Button>
                    <Button style={{ marginBottom: 2, minWidth: 0 }} variant="outlined" onClick={(e)=> { this.handleTransformChange('scale', e, '-')}}><KeyboardArrowDown style={{ fontSize: 14 }} /></Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        </div>
      );

    } catch (e) {

      console.error('TextStep.textTransformTool.error', e);
    }

    
  }

  handleTransformChange(type, evt, value) {

    try {

      const { classes, DesignRoot, logoConfiguration, langCode } = this.props;

      // console.log('handleTransformChange', type, value);

      var valueNumber = this.state[type];

      if ( value === '+' ) {
        if ( type === 'rotate' ) { valueNumber = parseInt(valueNumber) + 1; }
        else if ( type === 'scale' ) { valueNumber = parseFloat(valueNumber) + 0.01; }
      }
      else if ( value === '-' ) {
        if ( type === 'rotate' ) { valueNumber = parseInt(valueNumber) - 1; }
        else if ( type === 'scale' ) { valueNumber = parseFloat(valueNumber) - 0.01; }
      }
      else {

        valueNumber = value;
      }

      if ( type === 'rotate' ) {

        valueNumber = valueNumber.toFixed(0);
        valueNumber = parseInt(valueNumber);
      }
      else if ( type === 'scale' ) {

        valueNumber = valueNumber.toFixed(2);
        valueNumber = parseFloat(valueNumber);
      }

      var activeObject = DesignRoot.canvas.getActiveObject();

      if (
        activeObject &&
        activeObject._OBJECT_TYPE === 'TEXT'
      ) {

        var item = DesignRoot.fabric_function.getObjectById(activeObject.id);

        if ( type === 'rotate' ) {

          item.set({angle: valueNumber});

          item.canvas.requestRenderAll();
          DesignRoot.fabric_function.mutate_object_modify(item);
          DesignRoot.fabric_function.updateDimension(item.id, 'byID');
        }
        else if ( type === 'scale' ) {

          item.set({
            scaleX: valueNumber,
            scaleY: valueNumber
          });

          item.canvas.requestRenderAll();
          DesignRoot.fabric_function.mutate_object_modify(item);
          DesignRoot.fabric_function.updateDimension(item.id, 'byID');
        }

      }

      this.setState({[type]: valueNumber});

    } catch (e) {

      console.error('TextStep.handleTransformChange.error', e);
    }

    

  }

  render() {
    const {
      classes,
      DesignRoot,
      productDataSet,
      langCode,
      textConfiguration
    } = this.props;

    const control_tools_event = DesignRoot.control_tools_event;

    const {
      content,
      fontStyle,
      fontStyleSET,
      textColor,
      outlineColor,
      outlineSize,
      lineSpacing,
      selectTextId,
      rotate,
      scale,
      expanded
    } = this.state;

    const items = DesignRoot.fabric_function.getObjectByType('TEXT');
    const item = DesignRoot.fabric_function.getObjectById(selectTextId);

    var btnApply = `${Localization.get('beCreative_add_text')}`;
    if (item !== null) {
      btnApply = `${Localization.get('beCreative_apply_change_text')}`;
    }

    let colors = this.mapAvailableColor();

    // CONTROL TOOL

    var controlToolDom = (
      <Button
        style={{
          backgroundColor: '#171717',
          color: '#FFFFFF',
          marginTop: 10
        }}
        variant="outlined"
        onClick={this.applyText.bind(this)}
      >
        {btnApply}
      </Button>
    );

    if (DesignRoot._ACTIVE_PERSONALIZATION === true) {
      controlToolDom = null;
    }

    if (item && item._DEFAULT_ITEM && item._DEFAULT_ITEM === true) {
      controlToolDom = (
        <ControlTools
          selectId={selectTextId}
          visible={item.visible}
          onMirror={control_tools_event.__mirror.bind(this)}
          onRemove={control_tools_event.__remove.bind(this)}
        />
      );
    } else if (item) {
      controlToolDom = (
        <ControlTools
          selectId={selectTextId}
          lock={item.lockMovementX}
          visible={item.visible}
          onDuplicate={control_tools_event.__duplicate.bind(this)}
          onMirror={control_tools_event.__mirror.bind(this)}
          onRemove={control_tools_event.__remove.bind(this)}
          onRePosition={control_tools_event.__rePosition.bind(this)}
          onMoveTo={control_tools_event.__moveTo.bind(this)}
          onToggleLock={control_tools_event.__toggleLock.bind(this)}
          // onToggleVisible={control_tools_event.__toggleVisible.bind(this)}
        />
      );
    }

    // CONTROL TOOL

    return (
      <Grid
        container
        style={{
          padding: 24,
          overflowY: 'auto',
          height: '100%',
          overflowX: 'hidden',
          width: '100%',
          overflowX: 'hidden'
        }}
        spacing={2}
      >
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid xs={12} style={{ margin: 0, textAlign: 'center' }}>
              <Typography variant="h5">
                {Localization.get('beCreative_text_input_label')}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            style={{ margin: 0, marginTop: 4, textAlign: 'center' }}
            alignItems="center"
            justify="center"
            spacing={1}
          >
            <Grid item xs={12} style={{ flex: 1, padding: '0px 8px 8px 0px' }}>
              <Input
                placeholder={`${Localization.get(
                  'beCreative_text_input_placeholder'
                )}`}
                value={content}
                onChange={this._handleInputChange.bind(this, 'content')}
              />
            </Grid>
            <Grid item xs={12}>
              {controlToolDom}
            </Grid>
          </Grid>

          {DesignRoot._ACTIVE_PERSONALIZATION === false && (
            <div>
              <Grid item xs={12}>
                <Grid
                  container
                  justify="center"
                  style={{ margin: 0, marginTop: 40, textAlign: 'center' }}
                >
                  <Typography variant="h5">
                    {Localization.get('beCreative_select_fonts')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ margin: 0, marginTop: 10 }}>
                {this.fontStyleBuild(fontStyle, fontStyleSET)}
              </Grid>
            </div>
          )}

          

          <Grid item xs={12}>
            <Grid
              container
              justify="center"
              style={{ margin: 0, marginTop: 40, textAlign: 'center' }}
            >
              <Typography variant="h5">
                {Localization.get('beCreative_color')}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Grid item xs={12}>
              <ExpansionPanel
                expanded={expanded === 'panel1'}
                onChange={this.handleChange('panel1')}
                elevation={0}
                style={{
                  border: '1px solid #e5e5e5',
                  marginTop: 16,
                  minHeight: 35
                }}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1" style={{ fontSize: 14 }}>
                    {Localization.get('beCreative_font_color')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={1}
                    style={{ margin: 0, marginTop: 16 }}
                  >
                    {DesignRoot._COLOR_PALETTE === true && (

                      <CirclePicker
                        colors={colors}
                        color={textColor}
                        triangle="hide"
                        onChangeComplete={this._handleChangeColor.bind(
                          this,
                          'textColor'
                        )}
                      />

                    )}
                    

                    <ColorPicker DesignRoot={DesignRoot} mainFillComponent={this} componentType="TextStep_COLOR" />

                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>

            {DesignRoot._ACTIVE_PERSONALIZATION === false && (
              <Grid item xs={12}>
                <ExpansionPanel
                  expanded={expanded === 'panel2'}
                  onChange={this.handleChange('panel2')}
                  elevation={0}
                  style={{
                    border: '1px solid #e5e5e5',
                    marginTop: 16,
                    minHeight: 35
                  }}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1" style={{ fontSize: 14 }}>
                      {Localization.get('beCreative_outline_color')}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid
                      container
                      justify="flex-start"
                      alignItems="center"
                      spacing={1}
                      style={{ margin: 0, marginTop: 16 }}
                    >

                      {DesignRoot._COLOR_PALETTE === true && (

                        <CirclePicker
                          colors={colors}
                          color={outlineColor}
                          triangle="hide"
                          onChangeComplete={this._handleChangeColor.bind(
                            this,
                            'outlineColor'
                          )}
                        />
                        
                      )}

                      

                      <ColorPicker DesignRoot={DesignRoot} mainFillComponent={this} componentType="TextStep_OUTLINECOLOR" />

                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            )}

            <Grid item xs={12} style={{ marginTop: 16 }}>
              {this.textTransformTool()}
            </Grid>
            
            <Grid item xs={12} style={{ marginTop: 16 }}>
              <LayerControl
                type="TEXT"
                DesignRoot={DesignRoot}
                textConfiguration={textConfiguration}
                langCode={langCode}
              />
            </Grid>

            {this.SLTDefaultText()}

            <canvas className="textCanvasClass" id="textCanvas" />
            <canvas className="textCanvasClass" id="textCanvas_0" />
            <canvas className="textCanvasClass" id="textCanvas_1" />
            <canvas className="textCanvasClass" id="textCanvas_2" />
            <canvas className="textCanvasClass" id="textCanvas_3" />
            <canvas className="textCanvasClass" id="textCanvas_4" />
            <canvas className="textCanvasClass" id="textCanvas_5" />
            <canvas className="textCanvasClass" id="textCanvas_6" />
            <canvas className="textCanvasClass" id="textCanvas_7" />
            <canvas className="textCanvasClass" id="textCanvas_8" />
            <canvas className="textCanvasClass" id="textCanvas_9" />
            <canvas className="textCanvasClass" id="textCanvas_10" />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

Text.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Text);
