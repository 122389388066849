import React, { Component } from 'react';
import * as text_function from '../step/appFunc/text_function';

class FontLoad extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // this.buildFontPreload();
    const { DesignRoot } = this.props;
    DesignRoot._TEXT_LIB_READY = true;
  }

  buildFontPreload() {
    const { DesignRoot } = this.props;

    var fontStyleSET = [
      'Arial',
      'Serif',
      'Sans-Serif',
      'Biome',
      'Helvetica',
      'Tahoma',
      'Verdana',
      'Impact',
      'Nexa',
      'Oswald',
      'Poppins',
      'Roboto Slab',
      'Caveat Brush',
      'Fargo Faro NF',
      'Gota Light',
      'Yesteryear',
      'Henny Penny',
      'Comfortaa',
      'Black Ops One',
      'Montserrat'
    ];

    for (var i = 0; i < fontStyleSET.length; i++) {
      text_function.textGenerateToCanvas(
        'S',
        '#F00',
        fontStyleSET[i],
        '#FFFFFF',
        0,
        0
      );
    }
  }

  render() {
    return (
      <div className="FontLoad">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Oswald:400,700%7CPoppins:400,700%7CRoboto+Slab:400,700%7CCaveat+Brush:400%7CYesteryear:400%7CHenny+Penny:400%7CComfortaa:400%7CBlack+Ops+One:400%7CMontserrat:400"
          media="all"
        />
        <link rel="stylesheet" href="/font.css" media="all" />

        <div className="loadFont" style={{ fontFamily: 'Arial' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Serif' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Sans-Serif' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Biome' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Helvetica' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Tahoma' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Verdana' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Impact' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Nexa' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Oswald' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Poppins' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Roboto Slab' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Caveat Brush' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Fargo Faro NF' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Gota Light' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Yesteryear' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Henny Penny' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Comfortaa' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Black Ops One' }}>
          TEST
        </div>
        <div className="loadFont" style={{ fontFamily: 'Montserrat' }}>
          TEST
        </div>
      </div>
    );
  }
}

export default FontLoad;
