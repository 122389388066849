import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approxWidth: null,
      approxHeight: null,
      rotation: null,
      DPI_WIDTH: null,
      DPI_HEIGHT: null
    };
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot;
    DesignRoot.Dimension = this;
    DesignRoot.setState({ update: true });
    // PROVIDE COMPONENT TO MAIN
  }

  DPIStatus(DPI_WIDTH, DPI_HEIGHT) {

    try {

      if (DPI_WIDTH === null || DPI_HEIGHT === null) {
        return;
      }

      var baseDPICheck = DPI_WIDTH;

      if (DPI_HEIGHT < DPI_WIDTH) {
        baseDPICheck = DPI_HEIGHT;
      }

      var dpiWarning = '';
      var statusColor = '#FFF';
      if (baseDPICheck < 150) {
        dpiWarning = 'VERY LOW';
        statusColor = '#BC1F1F';
      } else if (baseDPICheck >= 150 && baseDPICheck < 300) {
        dpiWarning = 'LOW';
        statusColor = '#FF8D07';
      } else if (baseDPICheck >= 300) {
        dpiWarning = 'OK';
        statusColor = '#60A763';
      }

      return (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              background: statusColor,
              height: 18,
              width: 18,
              borderRadius: '100%',
              marginRight: 8
            }}
          />
          {dpiWarning}
        </span>
      );

    } catch (e) {

      console.error('Dimension.DPIStatus.error', e);
    }

    
  }

  render() {
    const {
      approxWidth,
      approxHeight,
      rotation,
      DPI_WIDTH,
      DPI_HEIGHT
    } = this.state;

    if (approxWidth === null || approxHeight === null || rotation === null) {
      return false;
    }

    return (
      <Paper
        style={{
          position: 'absolute',
          color: '#000',
          padding: '10px',
          fontSize: '14px',
          zIndex: 1,
          marginTop: 8,
          top: 8,
          right: 8,
          borderRadius: 4,
          textAlign: 'center'
        }}
      >
        <Typography variant="subtitle1" style={{ fontWeight: 800 }}>
          Approx size (cm)
        </Typography>
        <Typography variant="subtitle1">
          {approxWidth} x {approxHeight}
        </Typography>

        <br />
        <Typography variant="subtitle1" style={{ fontWeight: 800 }}>
          Rotation (°)
        </Typography>
        <Typography variant="subtitle1">{rotation}</Typography>
        <br />
        {DPI_WIDTH === null ? null : (
          <div>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 800, marginBottom: 8 }}
            >
              DPI
            </Typography>
            <Typography variant="subtitle1" style={{ fontSize: 12 }}>
              {this.DPIStatus(DPI_WIDTH, DPI_HEIGHT)}
            </Typography>
          </div>
        )}
      </Paper>
    );
  }
}

export default Dimension;
