import { API_STAGE } from '../../../../../brikl-config';

var DesignRoot = null;

export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot;
}

export function changeView(direction) {

  try {

    switch (direction) {
      case '180':
        DesignRoot.Main3D.controls.reset();
        DesignRoot.Main3D.camera.position.set(
          DesignRoot.Main3D.camera.position.x,
          DesignRoot.Main3D.camera.position.y,
          DesignRoot.Main3D.camera.position.z * -1
        );
        DesignRoot.Main3D.controls.update();
        break;
      case '90':
        DesignRoot.Main3D.controls.reset();
        DesignRoot.Main3D.camera.position.set(
          DesignRoot.Main3D.camera.position.z,
          DesignRoot.Main3D.camera.position.y,
          0
        );
        DesignRoot.Main3D.controls.update();
        break;
      case '270':
        DesignRoot.Main3D.controls.reset();
        DesignRoot.Main3D.camera.position.set(
          DesignRoot.Main3D.camera.position.z * -1,
          DesignRoot.Main3D.camera.position.y,
          0
        );
        DesignRoot.Main3D.controls.update();
        break;
      default:
        DesignRoot.Main3D.controls.reset();
        break;
    }

  } catch (e) {
    // statements
    console.error('control_view.changeView.error', e);
  }

  
}
