import { save } from './Functions/save_load'
import {
  createElementDesignRevisionData,
  updateElementDesignRevisionData,
  setupElement,
  setDesignAttributes
} from './Functions/save'

var DesignRoot = null

export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot
}

export function changeColorPickerSelected(color) {
  try {
    // statements

    if (color === null) {
      return false
    }

    var canvas_coloring = DesignRoot.canvas_coloring
    var _SELECT_COLOR_ZONE = DesignRoot._SELECT_COLOR_ZONE

    console.log(
      'changeColorPickerSelected',
      color,
      DesignRoot,
      canvas_coloring,
      _SELECT_COLOR_ZONE,
      DesignRoot.defaultZoneColor
    )

    if (_SELECT_COLOR_ZONE === null) {
      return false
    }

    var indexColor = null

    for (var i = 0; i < DesignRoot.defaultZoneColor.length; i++) {
      if (
        _SELECT_COLOR_ZONE.toLowerCase() ===
        DesignRoot.defaultZoneColor[i].toLowerCase()
      ) {
        indexColor = i
      }
    }

    console.log(indexColor)

    if (indexColor === null) {
      return false
    }

    console.log('changeColorPickerSelected', indexColor, color)
    DesignRoot.currentChangeColor[indexColor] = color

    // COLLECT SAVE DATA
    DesignRoot.productDataSet.TEMPLATE_SET_BY_ID[
      DesignRoot._ACTIVE_TEMPLATE_ID
    ].currentChangeColor = DesignRoot.currentChangeColor
    // COLLECT SAVE DATA

    canvas_coloring.recolorImage('change')
    // canvas_coloring.updateTexture('QUALITY');

    DesignRoot.mutate.mutate_colorzone_update(color)

    if (DesignRoot.isSaveEnabled) {
      save()
    }
  } catch (e) {
    // statements
    console.error('change_color.changeColorPickerSelected.error', e)
  }
}

export async function changeColorPickerSelectedDirectELM(
  color,
  selectDirectELMObject,
  _id
) {
  try {
    // statements

    if (selectDirectELMObject !== null) {
      // FOR CHECK SAVE TO DB
      await saveELMToDB(_id, selectDirectELMObject, color)
      selectDirectELMObject.userData.hasChange = true
      selectDirectELMObject.userData.color = color
      if (_id) {
        selectDirectELMObject.userData.id = _id
      }
      // FOR CHECK SAVE TO DB

      var elmGroup = selectDirectELMObject.userData.elmGroup
      var elmGroupList =
        DesignRoot.Main3D.storeMappingObjectNoTemplateGroup[elmGroup]

      for (var i = 0; i < elmGroupList.length; i++) {
        // FOR CHECK SAVE TO DB
        elmGroupList[i].userData.hasChange = true
        elmGroupList[i].userData.color = color
        // FOR CHECK SAVE TO DB

        try {
          if (elmGroupList[i].material.constructor === Array) {
            for (var j = 0; j < elmGroupList[j].material.length; j++) {
              if (color === 'RESET') {
                color = '#' + elmGroupList[i].material[j].userData.defaultColor
              }

              elmGroupList[i].material[j].color.set(color)
            }
          } else {
            if (color === 'RESET') {
              color = '#' + elmGroupList[i].material.userData.defaultColor
            }

            elmGroupList[i].material.color.set(color)
          }
        } catch (error) {
          console.error(
            'change_color.changeColorPickerSelectedDirectELM.error1',
            error
          )
        }
      }
    } // END IF selectDirectELMObject
  } catch (e) {
    // statements
    console.error('change_color.changeColorPickerSelectedDirectELM.error2', e)
  }
}

export async function changeColorPickerSelectedSVGELM(color, selectionObject) {
  try {
    // statements

    console.log('***changeColorPickerSelectedSVGELM', color, selectionObject)

    if (selectionObject !== null && selectionObject._OBJECT_TYPE === 'SVG') {
      if (color === 'RESET') {
        // FOR CHECK SAVE TO DB
        selectionObject._USER_DATA.hasChange = false
        selectionObject._USER_DATA.color = null
        selectionObject._USER_DATA.fillType = null
        // FOR CHECK SAVE TO DB

        if (
          selectionObject._BOUNDING_TYPE &&
          selectionObject._BOUNDING_TYPE === 'FLAG'
        ) {
          selectionObject.set({
            fill: 'transparent'
          })
        } else if (DesignRoot._ACTIVE_COLOR_ZONE === false) {
          selectionObject.set({
            fill: '#FFF'
          })
        } else {
          selectionObject.set({
            fill: 'transparent'
          })
        }

        selectionObject.canvas.renderAll()
      } else {
        // FOR CHECK SAVE TO DB
        const _id = selectionObject._USER_DATA.id || null
        await saveELMToDB(_id, selectionObject, color)
        selectionObject._USER_DATA.hasChange = true
        selectionObject._USER_DATA.color = color
        selectionObject._USER_DATA.fillType = 'COLOR'
        // FOR CHECK SAVE TO DB

        selectionObject.set({
          fill: color
        })
        selectionObject.canvas.renderAll()
      }
    }
  } catch (e) {
    // statements
    console.error('change_color.changeColorPickerSelectedSVGELM.error', e)
  }
}

export function changeAOPSelectedSVGELM(
  src,
  selectionObject,
  transform,
  SAVE,
  productDesignPrintId
) {
  try {
    // statements

    console.log(
      'changeAOPSelectedSVGELM',
      src,
      selectionObject,
      transform,
      SAVE
    )

    if (selectionObject !== null && selectionObject._OBJECT_TYPE === 'SVG') {
      if (src.indexOf('s3-') !== -1) {
        src = src + '?t=' + new Date().getDay()
      }
      // BUILD CANVAS IMAGE ROTATE

      var imgCanvas = new Image()
      imgCanvas.crossOrigin = 'anonymous'
      imgCanvas.onload = function() {
        console.log(
          'imgCanvas',
          imgCanvas,
          imgCanvas.naturalWidth,
          imgCanvas.naturalHeight,
          imgCanvas.width,
          imgCanvas.height
        )

        var canvas = document.createElement('canvas')
        var ctx = canvas.getContext('2d')

        // ANGLE CHECK FOR SLT

        var angle = 0
        if (
          selectionObject._ZONE_ANGLE &&
          parseInt(selectionObject._ZONE_ANGLE) !== 0
        ) {
          angle = parseInt(selectionObject._ZONE_ANGLE)
          // angle = 90; // TEST ONLY
        }

        if (transform) {
          // transform.angle = angle;
          angle = transform.angle
        }

        // ANGLE CHECK FOR SLT

        if (angle !== 0 && DesignRoot._ACTIVE_PERSONALIZATION === true) {
          canvas.width = imgCanvas.naturalHeight
          canvas.height = imgCanvas.naturalWidth

          // translate to center-canvas
          // the origin [0,0] is now center-canvas
          ctx.translate(canvas.width / 2, canvas.height / 2)

          // roate the canvas by +90% (==Math.PI/2)
          ctx.rotate(Math.PI / 2)

          // draw the signature
          // since images draw from top-left offset the draw by 1/2 width & height
          ctx.drawImage(
            imgCanvas,
            -imgCanvas.naturalWidth / 2,
            -imgCanvas.naturalHeight / 2
          )

          // un-rotate the canvas by -90% (== -Math.PI/2)
          ctx.rotate(-Math.PI / 2)

          // un-translate the canvas back to origin==top-left canvas
          ctx.translate(-canvas.width / 2, -canvas.height / 2)

          // testing...just draw a rect top-left
          // ctx.fillRect(0, 0, 25, 10);
        } else {
          canvas.width = imgCanvas.naturalWidth
          canvas.height = imgCanvas.naturalHeight
          ctx.drawImage(imgCanvas, 0, 0)
        }

        var imgBuild = canvas.toDataURL()

        console.log('AOP DEBUG')
        console.log(
          'imgCanvas',
          imgCanvas.naturalWidth,
          imgCanvas.naturalHeight
        )
        console.log('canvas', canvas.width, canvas.height)
        console.log(imgBuild)

        window.fabric.Image.fromURL(
          imgBuild,
          function(img) {
            if (DesignRoot._ACTIVE_PERSONALIZATION === true) {
              img.set({
                scaleX: 1.11 / window.devicePixelRatio,
                scaleY: 1.11 / window.devicePixelRatio
                // originX: 'center',
                // originY: 'center',
              })
            }

            // img.set({
            //   originX: 'center',
            //   originY: 'center',
            // });

            if (!transform || transform === null) {
              selectionObject._USER_DATA.transform = {
                // RECORD TRANSFORM
                scaleX: 1,
                scaleY: 1,
                angle: angle,
                repeat: true,
                offsetX: 0,
                offsetY: 0
              }
            } else {
              selectionObject._USER_DATA.transform = {
                // RECORD TRANSFORM
                scaleX: transform.scaleX,
                scaleY: transform.scaleY,
                angle: transform.angle,
                repeat: transform.repeat,
                offsetX: transform.offsetX,
                offsetY: transform.offsetY
              }
            }

            // FOR CHECK SAVE TO DB

            if (SAVE === false) {
              console.log('NO SAVE AOP')
            } else {
              const id = selectionObject._USER_DATA.id || null
              const countryFlag =
                src.toLowerCase().indexOf('flags') !== -1
                  ? src.split('/')[2].replace('.png', '')
                  : null
              saveELMToDB(
                id,
                selectionObject,
                null,
                countryFlag,
                productDesignPrintId
              )
            }

            selectionObject._USER_DATA.hasChange = true
            selectionObject._USER_DATA.fillType = 'AOP'
            selectionObject._USER_DATA.aopSrc = src
            selectionObject._USER_DATA.productDesignPrintId = productDesignPrintId
            // FOR CHECK SAVE TO DB

            // img.scaleToWidth(img.width); // DEFAULT

            if (DesignRoot._ACTIVE_PERSONALIZATION === true) {
              var w = selectionObject.width * selectionObject.scaleX
              var h = selectionObject.height * selectionObject.scaleY

              selectionObject._USER_DATA.transform.offsetX =
                w / 2 - img.width / 2
              selectionObject._USER_DATA.transform.offsetY =
                h / 2 - img.height / 2
            }

            selectionObject.set({
              fill: updateFillPattern(
                img,
                null,
                null,
                selectionObject,
                transform
              )
            })

            selectionObject.canvas.renderAll()
            DesignRoot.fabric_function.updateDimension(
              selectionObject.id,
              'byID'
            )
          },
          { crossOrigin: 'Anonymous' }
        )
      }
      imgCanvas.src = src

      // BUILD CANVAS IMAGE ROTATE
    }
  } catch (e) {
    // statements
    console.error('change_color.changeAOPSelectedSVGELM.error', e)
  }
}

export function updateFillPattern(
  img,
  type,
  value,
  selectionObject,
  transform,
  SAVE
) {
  try {
    // statements

    console.log(
      'updateFillPattern',
      img,
      type,
      value,
      selectionObject,
      transform,
      SAVE
    )

    if (type === 'scale') {
      var widthAfterScale = img.width * parseFloat(value)
      img.scaleToWidth(widthAfterScale)
      selectionObject._USER_DATA.transform.scaleX = img.scaleX
      selectionObject._USER_DATA.transform.scaleY = img.scaleY
    } else if (type === 'angle') {
      // img.set({ angle: parseFloat(value) });
      selectionObject._USER_DATA.transform.angle = parseFloat(value)
    } else if (type === 'width') {
      img.scaleToWidth(value)
      selectionObject._USER_DATA.transform.scaleX = img.scaleX
      selectionObject._USER_DATA.transform.scaleY = img.scaleY
    } else if (type === 'height') {
      img.scaleToHeight(value)
      selectionObject._USER_DATA.transform.scaleX = img.scaleX
      selectionObject._USER_DATA.transform.scaleY = img.scaleY
    } else if (type === 'repeat') {
      selectionObject._USER_DATA.transform.repeat =
        value === 'true' ? true : false
    }

    var patternSourceCanvas = new window.fabric.StaticCanvas()
    patternSourceCanvas.add(img)
    patternSourceCanvas.renderAll()

    if (transform) {
      // LOAD TRANSFROM

      var widthAfterScale = img.width * parseFloat(transform.scaleX)
      img.scaleToWidth(widthAfterScale)
    }

    if (DesignRoot._ACTIVE_PERSONALIZATION === true) {
      var repeat = 'no-repeat'
    } else {
      var repeat = selectionObject._USER_DATA.transform.repeat
        ? 'repeat'
        : 'no-repeat'
    }

    var pattern = new window.fabric.Pattern({
      // source: textureIMG,
      img: img,
      source: function() {
        patternSourceCanvas.setDimensions({
          width: img.getScaledWidth(),
          height: img.getScaledHeight()
        })
        patternSourceCanvas.renderAll()
        return patternSourceCanvas.getElement()
      },
      repeat: repeat
    })

    // ANGLE

    if (DesignRoot._ACTIVE_PERSONALIZATION === false) {
      var angle = selectionObject._USER_DATA.transform.angle
        ? selectionObject._USER_DATA.transform.angle
        : 0
      var svgServe = document.getElementById('svgServe')
      var matrix = svgServe.createSVGMatrix()
      var matrixAngle = matrix.rotate(angle)
      console.log('matrixAngle', matrixAngle)
      pattern.patternTransform = [
        matrixAngle.a,
        matrixAngle.b,
        matrixAngle.c,
        matrixAngle.d,
        matrixAngle.e,
        matrixAngle.f
      ]
    }

    // ANGLE

    // OFFSET HAVE TO SET ON PATTERN

    pattern.offsetX = selectionObject._USER_DATA.transform.offsetX
    pattern.offsetY = selectionObject._USER_DATA.transform.offsetY

    if (type === 'offsetX') {
      pattern.offsetX = value
      selectionObject._USER_DATA.transform.offsetX = value
    } else if (type === 'offsetY') {
      pattern.offsetY = value
      selectionObject._USER_DATA.transform.offsetY = value
    }

    if (transform) {
      // LOAD TRANSFROM

      selectionObject._USER_DATA.transform = transform
      // pattern.angle = transform.angle;
      pattern.offsetX = transform.offsetX
      pattern.offsetY = transform.offsetY
    } else {
      if (DesignRoot.AopStep !== null) {
        DesignRoot.AopStep.setState({
          scale: selectionObject._USER_DATA.transform.scaleX,
          angle: selectionObject._USER_DATA.transform.angle,
          offsetX: selectionObject._USER_DATA.transform.offsetX,
          offsetY: selectionObject._USER_DATA.transform.offsetY,
          width: selectionObject._USER_DATA.transform.scaleX * img.width,
          height: selectionObject._USER_DATA.transform.scaleX * img.height,
          repeat: selectionObject._USER_DATA.transform.repeat
        })
      }

      DesignRoot.fabric_function.updateDimension(selectionObject.id, 'byID')
    }

    if (!(SAVE === undefined || SAVE === null || SAVE === false)) {
      // RECORD TRANSFORM CHANGE

      const id = selectionObject._USER_DATA.id || null
      saveELMToDB(
        id,
        selectionObject,
        null,
        null,
        selectionObject._USER_DATA.productDesignPrintId
      )

      // RECORD TRANSFORM CHANGE
    }

    // OFFSET HAVE TO SET ON PATTERN

    console.log('pattern', pattern)

    return pattern
  } catch (e) {
    // statements
    console.error('change_color.updateFillPattern.error', e)
  }
}

export function returnCurrentColor(dataCheck, type) {
  // FOR COLOR ZONE, ELM

  try {
    // statements

    if (type === 'COLOR') {
      for (var i = 0; i < DesignRoot.defaultZoneColor.length; i++) {
        if (DesignRoot.defaultZoneColor[i] === dataCheck) {
          return DesignRoot.currentChangeColor[i]
          break
        }
      }
    } else if (type === 'ELM') {
      if (dataCheck.userData.hasChange === true) {
        return dataCheck.userData.color
      }
    } else {
      return null
    }
  } catch (e) {
    // statements
    console.error('change_color.returnCurrentColor.error', e)
  }
}

const saveELMToDB = async (
  _id,
  selectDirectELMObject,
  color,
  countryFlag,
  productDesignPrintId
) => {
  try {
    const userData =
      selectDirectELMObject.userData || selectDirectELMObject._USER_DATA
    const designAttributes = userData.transform
      ? setDesignAttributes(userData.transform)
      : null
    const input = setupElement(
      selectDirectELMObject,
      designAttributes,
      color,
      countryFlag,
      productDesignPrintId
    )
    if (
      DesignRoot.onLoad ||
      !DesignRoot.isSaveEnabled ||
      !input.baseElement.name
    ) {
      return
    }

    console.log(
      'saveELMToDB',
      selectDirectELMObject,
      designAttributes,
      _id,
      color,
      countryFlag,
      productDesignPrintId
    )

    if (userData && _id) {
      console.log('saveELMToDB', 'updateElementDesignRevisionData', input)

      updateElementDesignRevisionData(
        _id || userData.id,
        DesignRoot.props.productDesign.id,
        1,
        input,
        DesignRoot._APP_TYPE === '2d-3d'
      )
    } else {
      console.log('saveELMToDB', 'createElementDesignRevisionData', input)

      const responseCreate = await createElementDesignRevisionData(
        DesignRoot.props.productDesign.id,
        1,
        input,
        DesignRoot._APP_TYPE === '2d-3d'
      )
      userData.id = responseCreate.data.createElementDesignRevision.id
    }
  } catch (e) {
    // statements
    console.error('change_color.saveELMToDB.error', e)
  }
}
