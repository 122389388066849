import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import SliderInput from './SliderInput'
import Select from '../../common/Select'
import Input from '../../common/Input'

import * as template_control from '../template_control'

import Camouflage from '../TEMPLATE_ASSET/AOP/Camouflage.png'
import UV_Grid_Sm from '../TEMPLATE_ASSET/AOP/UV_Grid_Sm.jpg'

import aop1 from '../TEMPLATE_ASSET/AOP/aop (1).jpg'
import aop2 from '../TEMPLATE_ASSET/AOP/aop (1).png'
import aop3 from '../TEMPLATE_ASSET/AOP/aop (2).jpg'
import aop4 from '../TEMPLATE_ASSET/AOP/aop (2).png'
import aop5 from '../TEMPLATE_ASSET/AOP/aop (3).jpg'
import aop6 from '../TEMPLATE_ASSET/AOP/aop (4).jpg'

import * as tools from '../tools/tools'
import flagsList from '../TEMPLATE_ASSET/flags.json'

import { API_STAGE } from '~/brikl-config'
import {
  appendToFilename,
  createPrintDesignRevisionData
} from '../tools/Functions/save'
import shortid from 'shortid'

let SHOP_ID = tools.getQueryStringValue('s')

const styles = theme => ({
  colorSwatch: {
    background: '#BBBBBB',
    width: 27,
    height: 27,
    border: '3px solid #BBBBBB',
    borderRadius: '100%',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer'
    }
  },
  printThumbnail: {
    background: '#BBBBBB',
    width: 46,
    height: 46,
    border: '3px solid #BBBBBB',
    borderRadius: '6px',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer'
    }
  },
  slider: {
    padding: '8px 0px',
    width: '100%'
  },
  btn: {
    minHeight: 12,
    minWidth: 12,
    height: 12,
    width: 12
  },
  track: {
    backgroundColor: '#000'
  },
  thumb: {
    backgroundColor: '#000'
  }
})

class AopStep extends Component {
  constructor(props) {
    super(props)

    this.aopList = []

    this.state = {
      scale: 1,
      width: 0,
      height: 0,
      angle: 0,
      offsetX: 0,
      offsetY: 0,
      repeat: true,
      SPECIAL_ELM: false
    }

    if (!SHOP_ID) {
      SHOP_ID = this.props.DesignRoot.SHOP_ID
    }
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot
    DesignRoot.AopStep = this
    DesignRoot.setState({ update: true })
    // PROVIDE COMPONENT TO MAIN
  }

  handleTransform(type, e) {
    try {
      // statements
      e.persist()
      console.log('handleTransform', type, e)

      var actualValue = e.target.value

      this.setState({ [type]: actualValue })
      this._transform(type, actualValue)
    } catch (e) {
      // statements
      console.error('AopStep.handleTransform.error', e)
    }
  }

  async handleChangeSVGElementAOP(src, FILE_DATA, productDesignPrintId) {
    try {
      // statements
      const { DesignRoot } = this.props
      var selectionObject = DesignRoot.Selection.state.selectionObject

      var baseElement3DName = selectionObject._USER_DATA.element3DName
      var baseELM = baseElement3DName.split('-')

      if (baseELM[0]) {
        var baseELMName = baseELM[0]
        var allSVG = DesignRoot.fabric_function.getObjectByType('SVG')

        for (var i = 0; i < allSVG.length; i++) {
          var element3DName = allSVG[i]._USER_DATA.element3DName

          if (element3DName && element3DName.indexOf(baseELMName) !== -1) {
            DesignRoot.change_color.changeAOPSelectedSVGELM(
              src,
              allSVG[i],
              null,
              true,
              productDesignPrintId
            )
          }
        }
      } else {
        DesignRoot.change_color.changeAOPSelectedSVGELM(
          src,
          selectionObject,
          null,
          true,
          productDesignPrintId
        )
      }

      //
      // if (
      //   DesignRoot._S3_AOP_SRC[selectionObject.id] === undefined &&
      //   FILE_DATA !== null
      // ) {
      //   // UPLOAD TO S3 IF USE AOP
      //
      // }
    } catch (e) {
      // statements
      console.error('AopStep.handleChangeSVGElementAOP.error', e)
    }
  }

  handleSliderChange(type, SAVE, e, value) {
    try {
      // statements
      this.setState({ [type]: value })
      this._transform(type, value, SAVE)
    } catch (e) {
      // statements
      console.error('AopStep.handleSliderChange.error', e)
    }
  }

  _transform(type, actualValue, SAVE) {
    try {
      console.log('_transform', type, actualValue)
      // statements
      const { DesignRoot } = this.props
      var selectionObject = DesignRoot.Selection.state.selectionObject

      console.log('selectionObject', selectionObject)

      if (
        selectionObject &&
        selectionObject._USER_DATA.fillType &&
        selectionObject._USER_DATA.fillType === 'AOP'
      ) {
        var baseElement3DName = selectionObject._USER_DATA.element3DName
        var baseELM = baseElement3DName.split('-')

        if (baseELM[0]) {
          var baseELMName = baseELM[0]
          var allSVG = DesignRoot.fabric_function.getObjectByType('SVG')

          for (var i = 0; i < allSVG.length; i++) {
            var element3DName = allSVG[i]._USER_DATA.element3DName

            if (element3DName && element3DName.indexOf(baseELMName) !== -1) {
              if (
                allSVG[i] &&
                allSVG[i]._USER_DATA.fillType &&
                allSVG[i]._USER_DATA.fillType === 'AOP'
              ) {
                var img = allSVG[i].fill.img
                allSVG[i].set({
                  fill: DesignRoot.change_color.updateFillPattern(
                    img,
                    type,
                    actualValue,
                    allSVG[i],
                    null,
                    SAVE
                  )
                })
              }
            }
          }

          DesignRoot.canvas.renderAll()
        } else {
          var img = selectionObject.fill.img
          selectionObject.set({
            fill: DesignRoot.change_color.updateFillPattern(
              img,
              type,
              actualValue,
              selectionObject,
              null,
              SAVE
            )
          })
          selectionObject.canvas.renderAll()
        }
      }
    } catch (e) {
      // statements
      console.error('AopStep._transform.error', e)
    }
  }

  handleRotate = (event, rotate) => {
    try {
      // statements
      this.setState({ rotate })
    } catch (e) {
      // statements
      console.error('AopStep.handleRotate.error', e)
    }
  }

  async uploadAOP() {
    try {
      // statements
      var DesignRoot = this.props.DesignRoot

      var file_upload = document.getElementById('file-upload-aop')
      if (file_upload.files[0]) {
        var FILE_DATA = file_upload.files[0]
      } else {
        alert('PLEASE SELECT FILE')
        return
      }

      var file_name = FILE_DATA.name
      var file_size = FILE_DATA.size
      var file_type = FILE_DATA.type
      const gen_filename = appendToFilename(file_name, '-' + shortid.generate())

      const self = this

      tools.getBase64(FILE_DATA).then(async data => {
        tools.uploadFileWithPresignedUrl(
          FILE_DATA,
          gen_filename,
          DesignRoot.ownedByOrganisationId,
          'PRODUCTDESIGN_PRINT',
          DesignRoot._APP_TYPE === '2d-3d'
        )

        const response = await createPrintDesignRevisionData(
          DesignRoot.productDesignId,
          DesignRoot.ownedByOrganisationId,
          1,
          {
            src: gen_filename
          },
          DesignRoot._APP_TYPE === '2d-3d'
        )
        const productDesignPrintId = response.data.createPrintDesignRevision.id

        console.log('***print upload', productDesignPrintId)
        self.aopList.push({
          file_name: gen_filename,
          file_size: file_size,
          file_type: file_type,
          FILE_DATA: FILE_DATA,
          base64: data,
          productDesignPrintId: productDesignPrintId
        })
        self.setState({ render: true })
      })
    } catch (e) {
      // statements
      console.error('AopStep.uploadAOP.error', e)
    }
  }

  renderAOPList() {
    try {
      var DesignRoot = this.props.DesignRoot

      if (true) {
        var productDesignPrintId = null
        var selectionObject = DesignRoot.Selection.state.selectionObject
        if (
          selectionObject &&
          selectionObject._USER_DATA.fillType &&
          selectionObject._USER_DATA.fillType === 'AOP'
        ) {
          productDesignPrintId = selectionObject._USER_DATA.productDesignPrintId
        }

        var borderColor = '#fff'
        var domRender = []
        for (var i = 0; i < this.aopList.length; i++) {
          if (productDesignPrintId === this.aopList[i].productDesignPrintId) {
            borderColor = '#f00'
          } else {
            borderColor = '#fff'
          }

          domRender.push(
            <Grid
              item
              xs={4}
              key={'AOP_LIST_' + this.aopList[i].productDesignPrintId}
            >
              <img
                src={this.aopList[i].base64}
                style={{
                  width: '100%',
                  height: '80px',
                  objectFit: 'contain',
                  margin: '3px',
                  border: '2px dashed ' + borderColor
                }}
                onClick={this.handleChangeSVGElementAOP.bind(
                  this,
                  this.aopList[i].base64,
                  this.aopList[i].FILE_DATA,
                  this.aopList[i].productDesignPrintId
                )}
              />
            </Grid>
          )
        }

        if (DesignRoot.loadData && DesignRoot.loadData.printDesigns) {
          for (var i = 0; i < DesignRoot.loadData.printDesigns.length; i++) {
            if (
              productDesignPrintId === DesignRoot.loadData.printDesigns[i].id
            ) {
              borderColor = '#f00'
            } else {
              borderColor = '#fff'
            }

            domRender.push(
              <Grid
                item
                xs={4}
                key={'AOP_LIST_' + DesignRoot.loadData.printDesigns[i].id}
              >
                <img
                  src={DesignRoot.loadData.printDesigns[i].src}
                  style={{
                    width: '100%',
                    height: '80px',
                    objectFit: 'contain',
                    margin: '3px',
                    border: '2px dashed ' + borderColor
                  }}
                  onClick={this.handleChangeSVGElementAOP.bind(
                    this,
                    DesignRoot.loadData.printDesigns[i].src,
                    null,
                    DesignRoot.loadData.printDesigns[i].id
                  )}
                />
              </Grid>
            )
          }
        }

        return domRender
      }
    } catch (e) {
      // statements
      console.error('AopStep.renderAOPList.error', e)
    }
  }

  specialAOPList() {

    try {

      const { classes, DesignRoot, FillStep } = this.props;
      const { SPECIAL_ELM } = this.state;

      if ( DesignRoot._DEFAULT_ITEM === false ) {

        return null;
      }

      if (SPECIAL_ELM === false) {
        return (
          <div
            style={{
              textAlign: 'center',
              fontStyle: 'italic',
              paddingTop: '10px'
            }}
          >
            This element not available for custom printing.
          </div>
        );
      }

      console.warn('flagsList', flagsList);

      var flagsDom = [];
      for (var i = 0; i < flagsList.length; i++) {
        var flag = '/Flags/' + flagsList[i].imageSrc;
        var flagThumb = '/Flags/thumb/' + flagsList[i].imageSrc;
        flagsDom.push(
          <Grid item xs={4} key={'flags_' + i}>
            <img
              src={flagThumb}
              style={{ width: '50px', cursor: 'pointer' }}
              onClick={this.handleChangeSVGElementAOP.bind(this, flag, null)}
            />
          </Grid>
        );
      }

      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={2}
          style={{ margin: 0, marginTop: 16 }}
        >
          {flagsDom}
        </Grid>
      );

    } catch (e) {

      console.error('AopStep.renderAOPList.error', e);
    }

    
  }

  transformAOP() {

    try {

      const {
        scale,
        width,
        height,
        angle,
        offsetX,
        offsetY,
        repeat,
        SPECIAL_ELM
      } = this.state;

      // if (scale === 0) {
      //   return null;
      // }

      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: 'center', marginTop: 20 }}
            >
              Repeat setting
            </Typography>
          </Grid>

          <Grid container justify="center">
            <Grid item xs={12}>
              <Select
                value={repeat}
                onChange={this.handleTransform.bind(this, 'repeat')}
              >
                <option value={true}>Repeat</option>
                <option value={false}>Non-repeat</option>
              </Select>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={1}
            style={{ margin: 0, marginTop: 16 }}
          >
            <Grid item xs={12}>
              <SliderInput
                aria-labelledby="label"
                title="Scale"
                value={scale}
                valueNum={scale}
                step={0.01}
                min={0.01}
                max={5}
                input={false}
                onChange={this.handleSliderChange.bind(this, 'scale', false)}
                onChangeCommitted={this.handleSliderChange.bind(this, 'scale', true)}
                valueLabelDisplay="auto"
              />
            </Grid>

            <Grid item xs={12}>
              <SliderInput
                aria-labelledby="angle"
                title="Rotate"
                value={angle}
                valueNum={angle}
                step={1}
                min={-180}
                max={180}
                input={false}
                onChange={this.handleSliderChange.bind(this, 'angle', false)}
                onChangeCommitted={this.handleSliderChange.bind(this, 'angle', true)}
                valueLabelDisplay="auto"
              />
            </Grid>

            <Grid item xs={12}>
              <SliderInput
                aria-labelledby="label"
                title="X-Axis Offset"
                value={offsetX}
                valueNum={offsetX.toFixed(2)}
                step={1}
                min={-1000}
                max={1000}
                input={false}
                onChange={this.handleSliderChange.bind(this, 'offsetX', false)}
                onChangeCommitted={this.handleSliderChange.bind(this, 'offsetX', true)}
                valueLabelDisplay="auto"
              />
            </Grid>

            <Grid item xs={12}>
              <SliderInput
                title="Y-Axis Offset"
                aria-labelledby="label"
                value={offsetY}
                valueNum={offsetY.toFixed(2)}
                step={1}
                min={-1000}
                max={1000}
                input={false}
                onChange={this.handleSliderChange.bind(this, 'offsetY', false)}
                onChangeCommitted={this.handleSliderChange.bind(this, 'offsetY', true)}
                valueLabelDisplay="auto"
              />
            </Grid>
          </Grid>
        </Grid>
      );

    } catch (e) {

      console.error('AopStep.transformAOP.error', e);
    }

    
  }

  uploadAOPBTN() {

    try {

      const DesignRoot = this.props.DesignRoot;

      if (DesignRoot._SVG_BOUNDING === true) {
        return null;
      }

      return (
        <Grid item style={{ flex: 1, padding: '5px 8px 0px 0px' }}>
          <input
            type="file"
            id="file-upload-aop"
            style={{ display: 'none' }}
            onChange={this.uploadAOP.bind(this)}
          />
          <label htmlFor="file-upload-aop">
            <Grid container justify="center">
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#171717', color: '#FFFFFF' }}
                  component="span"
                >
                  Upload
                </Button>
              </Grid>
            </Grid>
          </label>
        </Grid>
      );
      
    } catch (e) {

      console.error('AopStep.uploadAOPBTN.error', e);
    }

  }

  render() {
    const { classes, DesignRoot, FillStep } = this.props
    const {
      scale,
      width,
      height,
      angle,
      offsetX,
      offsetY,
      repeat,
      SPECIAL_ELM
    } = this.state

    var printWording = 'Select a print :'
    if (DesignRoot._SVG_BOUNDING === true) {
      printWording = 'Select a flag :'
    }

    return (
      <div className="AopStep" style={{ minHeight: 300, paddingBottom: 24 }}>
        {DesignRoot._SVG_BOUNDING === false && (
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{ margin: 0 }}
          >
            <Divider
              light
              style={{ width: 180, marginTop: 40, marginBottom: 40 }}
            />
            <Typography variant="h5" style={{ textAlign: 'center' }}>
              Print Fill
            </Typography>
          </Grid>
        )}

        {this.transformAOP()}

        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={1}
          style={{ margin: 0, marginTop: 16 }}
        >
          <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
            {printWording}
          </Typography>

          {DesignRoot._SVG_BOUNDING === true && (
            <Grid
              container
              style={{ margin: 0, marginTop: 20 }}
              justify="center"
            >
              <Grid item xs={12}>
                {FillStep.genSVGList()}{' '}
              </Grid>
            </Grid>
          )}

          <Grid container style={{ padding: '16px 0px' }}>
            {this.renderAOPList()}
          </Grid>
          {this.specialAOPList()}

          {this.uploadAOPBTN()}
        </Grid>
        {/*<div>
          WIDTH :
          <input
            type="number"
            step="1"
            value={width}
            onChange={this.handleTransform.bind(this, 'width')}
          />
        </div>

        <div>
          HEIGHT :
          <input
            type="number"
            step="1"
            value={height}
            onChange={this.handleTransform.bind(this, 'height')}
          />
        </div>*/}

        <svg id="svgServe" style={{ display: 'none' }}></svg>
      </div>
    )
  }
}

AopStep.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AopStep)
