import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { ADMIN_GET_SHOP_SETTINGS } from '../../gql/admin-queries'
import { ADMIN_UPDATE_SHOP_SHIPPING_SETTINGS } from '../../gql/admin-mutations'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import { countries } from '../../../../helper/countryList'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '../../../common/Input'
import Select from '../../../common/Select'
import ShippingCard from './ShippingCard'

import PackageCard from './PackageCard'
import ShippingZones from './ShippingZones'
import _cloneDeep from 'lodash/cloneDeep'
import omitDeep from 'omit-deep'
import uuid from 'uuid'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  }
})

class ShippingSettings extends Component {
  state = {
    editShippingFrom: false,
    shippingFrom: {},
    addShippingZone: false
  }
  render() {
    const { classes, shop, shopId } = this.props
    return (
      <Query query={ADMIN_GET_SHOP_SETTINGS} variables={{ id: shopId }}>
        {({ loading, error, data: { shop } }) => {
          if (loading) return <CircularProgress />
          if (error) return <div>Error :(</div>
          return (
            <Mutation
              update={(store, { data: { updateShopShippingSettings } }) => {}}
              mutation={ADMIN_UPDATE_SHOP_SHIPPING_SETTINGS}
            >
              {(updateShopShippingSettings, { data, loading, error }) => {
                if (!shop) return null
                return (
                  <Grid container className={classes.root}>
                    <Grid
                      container
                      justify="flex-start"
                      direction="row"
                      alignItems="center"
                      style={{ height: 60, marginBottom: 20 }}
                    >
                      <Typography
                        style={{
                          fontSize: 28,
                          fontWeight: 400,
                          color: '#424242'
                        }}
                      >
                        Shipping
                      </Typography>
                      <div style={{ flex: 1 }} />
                    </Grid>

                    <Grid container style={{ marginTop: 24 }}>
                      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                        <Grid
                          container
                          style={{ padding: '24px 24px 24px 0px' }}
                          spacing={1}
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              style={{ marginRight: 16 }}
                            >
                              Shipping Origin
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              The address used to calculate shipping rates
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                        <Paper elevation={0} style={{ padding: 24 }}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                              <Typography variant="subtitle1">
                                Shipping from
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} lg={9} xl={9}>
                              <Grid container justify="flex-end">
                                <Button
                                  onClick={() => {
                                    this.setState({
                                      editShippingFrom: true,
                                      shippingFrom: shop.shippingSettings
                                        .shippingFrom
                                        ? {
                                            ...shop.shippingSettings
                                              .shippingFrom
                                          }
                                        : {}
                                    })
                                  }}
                                  variant="text"
                                  color="primary"
                                  style={{ fontWeight: 400 }}
                                >
                                  Change shipping origin
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <ShippingCard
                                countries={countries}
                                shopId={shopId}
                                shippingFrom={
                                  shop && shop.shippingSettings
                                    ? shop.shippingSettings.shippingFrom
                                    : {}
                                }
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>

                    {this.state.editShippingFrom ? (
                      <Grid container>
                        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                          <Grid
                            container
                            style={{ padding: '24px 24px 24px 0px' }}
                            spacing={1}
                            alignItems="center"
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant="subtitle1"
                                style={{ marginRight: 16 }}
                              >
                                Shipping
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                Provide shipping information about your business
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                          <Paper elevation={0} style={{ padding: 24 }}>
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Input
                                    label="Legal name"
                                    placeholder="Enter legal name here"
                                    onChange={e => {
                                      updateShopShippingSettings({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateShopShippingSettings: {
                                            ...shop,
                                            shippingSettings: shop.shippingSettings
                                              ? {
                                                  ...shop.shippingSettings,
                                                  shippingFrom: {
                                                    ...shop.shippingSettings
                                                      .shippingFrom,
                                                    company: e.target.value
                                                  }
                                                }
                                              : {
                                                  __typename:
                                                    'ShopShippingSettings',
                                                  shippingFrom: {
                                                    __typename: 'ShopAddress',
                                                    company: e.target.value
                                                  }
                                                }
                                          }
                                        },
                                        variables: {
                                          id: shopId,
                                          input: {
                                            shippingSettings: {
                                              shippingFrom: {
                                                company: e.target.value
                                              }
                                            }
                                          }
                                        }
                                      })
                                    }}
                                    value={_get(
                                      shop,
                                      'shippingSettings.shippingFrom.company',
                                      ''
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <Input
                                    label="Street"
                                    placeholder="Enter your street here"
                                    onChange={e => {
                                      updateShopShippingSettings({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateShopShippingSettings: {
                                            ...shop,
                                            shippingSettings: shop.shippingSettings
                                              ? {
                                                  ...shop.shippingSettings,
                                                  shippingFrom: {
                                                    ...shop.shippingSettings
                                                      .shippingFrom,
                                                    address1: e.target.value
                                                  }
                                                }
                                              : {
                                                  __typename:
                                                    'ShopShippingSettings',
                                                  shippingFrom: {
                                                    __typename: 'ShopAddress',
                                                    address1: e.target.value
                                                  }
                                                }
                                          }
                                        },
                                        variables: {
                                          id: shopId,
                                          input: {
                                            shippingSettings: {
                                              shippingFrom: {
                                                address1: e.target.value
                                              }
                                            }
                                          }
                                        }
                                      })
                                    }}
                                    value={_get(
                                      shop,
                                      'shippingSettings.shippingFrom.address1',
                                      ''
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <Input
                                    label="City"
                                    onChange={e => {
                                      updateShopShippingSettings({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateShopShippingSettings: {
                                            ...shop,
                                            shippingSettings: shop.shippingSettings
                                              ? {
                                                  ...shop.shippingSettings,
                                                  shippingFrom: {
                                                    ...shop.shippingSettings
                                                      .shippingFrom,
                                                    city: e.target.value
                                                  }
                                                }
                                              : {
                                                  __typename:
                                                    'ShopShippingSettings',
                                                  shippingFrom: {
                                                    __typename: 'ShopAddress',
                                                    city: e.target.value
                                                  }
                                                }
                                          }
                                        },
                                        variables: {
                                          id: shopId,
                                          input: {
                                            shippingSettings: {
                                              shippingFrom: {
                                                city: e.target.value
                                              }
                                            }
                                          }
                                        }
                                      })
                                    }}
                                    value={_get(
                                      shop,
                                      'shippingSettings.shippingFrom.city',
                                      ''
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <Input
                                    label="District/State"
                                    onChange={e => {
                                      updateShopShippingSettings({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateShopShippingSettings: {
                                            ...shop,
                                            shippingSettings: shop.shippingSettings
                                              ? {
                                                  ...shop.shippingSettings,
                                                  shippingFrom: {
                                                    ...shop.shippingSettings
                                                      .shippingFrom,
                                                    address2: e.target.value
                                                  }
                                                }
                                              : {
                                                  __typename:
                                                    'ShopShippingSettings',
                                                  shippingFrom: {
                                                    __typename: 'ShopAddress',
                                                    address2: e.target.value
                                                  }
                                                }
                                          }
                                        },
                                        variables: {
                                          id: shopId,
                                          input: {
                                            shippingSettings: {
                                              shippingFrom: {
                                                address2: e.target.value
                                              }
                                            }
                                          }
                                        }
                                      })
                                    }}
                                    value={_get(
                                      shop,
                                      'shippingSettings.shippingFrom.address2',
                                      ''
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <Input
                                    label="Postcode"
                                    onChange={e => {
                                      updateShopShippingSettings({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateShopShippingSettings: {
                                            ...shop,
                                            shippingSettings: shop.shippingSettings
                                              ? {
                                                  ...shop.shippingSettings,
                                                  shippingFrom: {
                                                    ...shop.shippingSettings
                                                      .shippingFrom,
                                                    zip: e.target.value
                                                  }
                                                }
                                              : {
                                                  __typename:
                                                    'ShopShippingSettings',
                                                  shippingFrom: {
                                                    __typename: 'ShopAddress',
                                                    zip: e.target.value
                                                  }
                                                }
                                          }
                                        },
                                        variables: {
                                          id: shopId,
                                          input: {
                                            shippingSettings: {
                                              shippingFrom: {
                                                zip: e.target.value
                                              }
                                            }
                                          }
                                        }
                                      })
                                    }}
                                    value={_get(
                                      shop,
                                      'shippingSettings.shippingFrom.zip',
                                      ''
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <Select
                                    label="Country"
                                    className={classes.select}
                                    onChange={e => {
                                      updateShopShippingSettings({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateShopShippingSettings: {
                                            ...shop,
                                            shippingSettings: shop.shippingSettings
                                              ? {
                                                  ...shop.shippingSettings,
                                                  shippingFrom: {
                                                    ...shop.shippingSettings
                                                      .shippingFrom,
                                                    countryCode: e.target.value
                                                  }
                                                }
                                              : {
                                                  __typename:
                                                    'ShopShippingSettings',
                                                  shippingFrom: {
                                                    __typename: 'ShopAddress',
                                                    countryCode: e.target.value
                                                  }
                                                }
                                          }
                                        },
                                        variables: {
                                          id: shopId,
                                          input: {
                                            shippingSettings: {
                                              shippingFrom: {
                                                countryCode: e.target.value
                                              }
                                            }
                                          }
                                        }
                                      })
                                    }}
                                    id="outlined-logo"
                                    value={_get(
                                      shop,
                                      'shippingSettings.shippingFrom.countryCode',
                                      ''
                                    )}
                                  >
                                    {_sortBy(countries, ['name']).map(
                                      country => {
                                        return (
                                          <option
                                            key={'country-menu-' + country.code}
                                            value={country.code}
                                          >
                                            {country.name}
                                          </option>
                                        )
                                      }
                                    )}
                                  </Select>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                    ) : null}
                    <Grid container style={{ marginTop: 24 }}>
                      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                        <Grid
                          container
                          style={{ padding: '24px 24px 24px 0px' }}
                          spacing={1}
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              style={{ marginRight: 16 }}
                            >
                              Shipping rates at checkout
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              Use shipping zones to define regions where you'll
                              ship.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                        <Paper elevation={0} style={{ padding: 24 }}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                              <Typography variant="subtitle1">
                                Shipping zone
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} lg={9} xl={9}>
                              <Grid container justify="flex-end">
                                <Button
                                  onClick={() => {
                                    let newShippingZone = {
                                      __typename: 'ShippingZone',
                                      id: uuid.v4(),
                                      name: 'Zone name',
                                      applyToAllCountries: false,
                                      countryCodes: [],
                                      regionCodes: [],
                                      shippingRates: [
                                        {
                                          __typename: 'ShippingRate',
                                          id: uuid.v4(),
                                          priority: 0,
                                          type: 'PRICE',
                                          minimumTotal: {
                                            __typename: 'Price',
                                            value: 0,
                                            currency: shop.defaultCurrency
                                          },
                                          minimumWeight: 0,
                                          weightUnit: null,
                                          rate: {
                                            __typename: 'Price',
                                            value: 0,
                                            currency: shop.defaultCurrency
                                          }
                                        }
                                      ]
                                    }
                                    const shippingZoneCopy = _cloneDeep(
                                      newShippingZone
                                    )
                                    updateShopShippingSettings({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateShopShippingSettings: {
                                          ...shop,
                                          shippingSettings: shop.shippingSettings
                                            ? {
                                                ...shop.shippingSettings,
                                                shippingZones: shop
                                                  .shippingSettings
                                                  .shippingZones
                                                  ? [
                                                      ...shop.shippingSettings
                                                        .shippingZones,
                                                      newShippingZone
                                                    ]
                                                  : [newShippingZone]
                                              }
                                            : {
                                                shippingZones: [newShippingZone]
                                              }
                                        }
                                      },
                                      variables: {
                                        id: shopId,
                                        input: {
                                          shippingSettings: {
                                            shippingZones: shop.shippingSettings
                                              ? shop.shippingSettings
                                                  .shippingZones
                                                ? [
                                                    ..._cloneDeep(
                                                      shop.shippingSettings
                                                        .shippingZones
                                                    ).map(tempShippingZone => {
                                                      return omitDeep(
                                                        tempShippingZone,
                                                        ['__typename']
                                                      )
                                                    }),
                                                    omitDeep(shippingZoneCopy, [
                                                      '__typename'
                                                    ])
                                                  ]
                                                : [
                                                    omitDeep(shippingZoneCopy, [
                                                      '__typename'
                                                    ])
                                                  ]
                                              : [
                                                  omitDeep(shippingZoneCopy, [
                                                    '__typename'
                                                  ])
                                                ]
                                          }
                                        }
                                      }
                                    })
                                  }}
                                  variant="text"
                                  color="primary"
                                  style={{ fontWeight: 400 }}
                                >
                                  Add shipping zone
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              {/*   ingRateCard /> */}
                              {/* {this.state.addShippingZone ? ( */}
                              <ShippingZones
                                defaultCurrency={shop.defaultCurrency}
                                shop={shop}
                                shopId={shopId}
                                updateShopShippingSettings={
                                  updateShopShippingSettings
                                }
                              />
                              {/* ) : null} */}
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 24, display: 'none' }}>
                      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                        <Grid
                          container
                          style={{ padding: '24px 24px 24px 0px' }}
                          spacing={1}
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              style={{ marginRight: 16 }}
                            >
                              Packages
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              The package sizes you use to ship your products.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                        <Paper elevation={0} style={{ padding: 24 }}>
                          <Grid container spacing={3}>
                            <Grid item xs={5} sm={4} md={4} lg={3} xl={3}>
                              <Typography variant="subtitle1">
                                Shipping zone
                              </Typography>
                            </Grid>
                            <Grid item xs={7} sm={8} md={8} lg={9} xl={9}>
                              <Grid container justify="flex-end">
                                <Button
                                  variant="text"
                                  color="primary"
                                  style={{ fontWeight: 400 }}
                                >
                                  Add package details
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <PackageCard />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

export default withStyles(styles)(ShippingSettings)
