import { Link } from 'gatsby';
import React from 'react';
import {
  Card,
  Grid,
  createStyles,
  withStyles,
  WithStyles
} from '@material-ui/core';

import BoardProductPreview from '../common/BoardProductPreview';

const styles = () =>
  createStyles({
    designCard: {
      background: '#ffffff',
      borderRadius: '4px',
      color: '#000000',
      padding: '8px',
      position: 'relative',
      width: '100%'
    },
    preview: {
      background: '#000',
      height: ' 180px',
      marginBottom: '35px',
      marginLeft: '-15px',
      marginTop: '-15px',
      position: 'relative',
      width: 'calc(100% + 30px)'
    },
    root: {
      height: '100%',
      padding: '8px'
    }
  });

interface Props extends WithStyles<typeof styles> {
  isAdmin: boolean;
  item: any | null;
  urlPrefix: string;
}

const ProductDesign: React.FunctionComponent<Props> = props => {
  const { classes, item, urlPrefix, isAdmin } = props;

  const link = isAdmin
    ? `/${urlPrefix}admin/vermarc/designroom/room/?s=${item.shopId}&p=${item.product.id}&pd=${item.id}&apptype=2d-3d&o=${item.shopId}-ADMIN`
    : `/${urlPrefix}be-creative/?s=${item.shopId}&p=${item.product.id}`

  return (
    <Grid
      item={true}
      xs={6}
      sm={6}
      md={4}
      lg={3}
      xl={3}
      style={{ padding: '5px', marginBottom: '16px' }}
    >
      <Card data-cy="product-card" className={classes.designCard}>
        <Grid
          container={true}
          data-cy="product-preview"
          className={classes.preview}
        >
          <BoardProductPreview
            ownedByOrganisationId={item.ownedByOrganisationId}
            productDesignId={item.id}
            shopId={item.shopId}
          />
        </Grid>
        <Grid>
          <Link to={link}>
            Edit
          </Link>
        </Grid>
      </Card>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(ProductDesign);
