import React, { Component } from 'react'
import { Typography, Grid, Button } from '@material-ui/core'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import { MEDIA_CDN_URL, IMGIX_URL } from '../../../../../brikl-config'
import Imgix from 'react-imgix'
import BoardProductPreviews from '../../../common/BoardProductPreviews'
const OrderItem = ({
  orderItem,
  defaultLanguage,
  handleShowCustom,
  shopId
}) => {
  let title = ''
  try {
    title = `${orderItem.productTitle}${
      orderItem.productOptionValueTitle
        ? ' - ' + orderItem.productOptionValueTitle
        : ''
    }`
  } catch (error) {}
  let img = null
  try {
    img = orderItem.product.media
      .find(m => m && m.isThumbnail)
      .image.replace(MEDIA_CDN_URL, IMGIX_URL)
  } catch (error) {}
  try {
    if (orderItem.productOptionValue) {
      orderItem.productOptionValue.selectedVariants.forEach(
        selectedVariantOption => {
          const tempImage = orderItem.product.media.find(vo => {
            return (
              vo &&
              vo.variantOptionId === selectedVariantOption.selectedOption.id
            )
          })
          if (tempImage) {
            img = tempImage.image.replace(MEDIA_CDN_URL, IMGIX_URL)
          }
        }
      )
    }
  } catch (error) {
    console.error('variantimage.error', error)
  }
  let discounts = []
  try {
    orderItem.appliedDiscounts.forEach(
      (appliedDiscount, appliedDiscountIndex) => {
        let appliedDiscountNote =
          appliedDiscount.coupon && appliedDiscount.coupon.couponCode
            ? `Discount (${appliedDiscount.coupon.couponCode}):`
            : 'Discount:'
        if (
          appliedDiscount.linkedDiscount &&
          appliedDiscount.linkedDiscount.discountType === 'FREE_SHIPPING'
        ) {
          appliedDiscountNote = appliedDiscountNote + ` Free Shipping`
        } else {
          if (
            appliedDiscount.linkedDiscount.discountType ===
            'FIXED_TOTAL_DISCOUNT'
          ) {
            appliedDiscountNote =
              appliedDiscountNote +
              ` -${appliedDiscount.linkedDiscount.discountValue}`
          } else {
            appliedDiscountNote =
              appliedDiscountNote +
              ` -${appliedDiscount.linkedDiscount.discountValue}%`
          }
        }

        discounts.push(
          <Grid
            key={'appliedDiscountIndex-' + appliedDiscountIndex}
            container
            style={{ padding: '16px 0 0' }}
          >
            <Grid style={{}} item={true} xs={3}>
              <Typography variant="h7" component="span">
                {appliedDiscountNote}
              </Typography>
            </Grid>
            <Grid style={{}} item={true} xs={3}>
              <Typography variant="h7" component="span">
                -{' '}
                {appliedDiscount.discountDeduction.value
                  .toFixed(2)
                  .toLocaleString()}{' '}
                {appliedDiscount.discountDeduction.currency}
              </Typography>
            </Grid>

            <Button
              variant={'outlined'}
              color={'primary'}
              style={{ position: 'absolute', bottom: 0, right: 0 }}
              onClick={() => {
                window.open(
                  '/admin/' +
                    shopId +
                    '/settings/discounts/' +
                    appliedDiscount.linkedDiscount.id
                )
              }}
            >
              View Discount
            </Button>
          </Grid>
        )
      }
    )
  } catch (error) {}
  let packageDetails = []
  try {
    orderItem.productPackageProductOptionValues.forEach((value, i) => {
      packageDetails.push(
        <Grid key={'pi-' + i} container style={{ padding: '.5em 0 0' }}>
          <Grid style={{}} item xs={12}>
            <Typography variant="body1" component="span">
              {value.productTitle}
              {' - '}
              {value.productAttributeTitle}
              {' - '}
              {value.productOptionValueTitle}
            </Typography>
          </Grid>
        </Grid>
      )
    })
  } catch (error) {}
  let personalization = []
  try {
    if (orderItem.customizationPrice) {
      personalization.push(
        <Typography variant="body1" component="div" key={'ocp-' + orderItem.id}>
          Cost:{' '}
          {orderItem.customizationPrice
            ? orderItem.customizationPrice.value +
              ' ' +
              orderItem.customizationPrice.currency
            : ''}
        </Typography>
      )
    }

    orderItem.customizationOptions.forEach(group => {
      group.options.forEach(customizationOptionValue => {
        let optionTitle = customizationOptionValue.value
        try {
          let groups =
            orderItem.salesChannel.customizationSettings
              .customizationOptionGroups
          optionTitle = customizationOptionValue.value
          try {
            if (orderItem.product.customizationSettings) {
              groups = [
                ...groups,
                ...orderItem.product.customizationSettings
                  .customizationOptionGroups
              ]
            }
          } catch (error) {}

          const customOptionGroup = groups.find(c =>
            c.options.find(co => {
              return (
                co.id === customizationOptionValue.productCustomizationOptionId
              )
            })
          )

          const customOption = customOptionGroup.options.find(x => {
            return (
              x.id === customizationOptionValue.productCustomizationOptionId
            )
          })
          try {
            if (customOptionGroup.logic === 'SELECT') {
              optionTitle =
                getTextFromLanguage(
                  customOptionGroup.title.text,
                  defaultLanguage
                ) +
                ': ' +
                getTextFromLanguage(customOption.title.text, defaultLanguage)
            } else {
              optionTitle =
                getTextFromLanguage(customOption.title.text, defaultLanguage) +
                ': ' +
                optionTitle
            }
          } catch (error) {}
          // '(' +
          // customOption.pricingRules[0].formula.price.value +
          // showCurrencySymbol(
          //   customOption.pricingRules[0].formula.price.currency
          // ) +
          // ')';
        } catch (error) {}

        personalization.push(
          <Typography
            variant="body1"
            component="div"
            key={'ocp-' + customizationOptionValue.id}
            style={{
              display: 'block',
              marginRight: '1em'
            }}
          >
            <span
              style={{
                display: 'inline-block',
                minWidth: '50px'
              }}
            >
              {optionTitle}
            </span>{' '}
          </Typography>
        )
      })
    })
  } catch (error) {}
  return orderItem ? (
    <Grid container spacing={1} style={{ position: 'relative' }}>
      <Grid item xs={2}>
        {orderItem.productDesignId ? (
          <BoardProductPreviews
            shopId={orderItem.shopId}
            ownedByOrganisationId={orderItem.ownedByOrganisationId}
            boardProductId={orderItem.productDesignId}
            techpack
          />
        ) : img ? (
          <Imgix
            width={100}
            height={100}
            htmlAttributes={{
              alt: title,
              title: title,
              style: {
                objectFit: 'contain',
                objectPosition: 'top',
                display: 'block',
                borderRadius: 5,
                padding: 5
              }
            }}
            src={img}
            imgixParams={{
              auto: 'compress'
            }}
          />
        ) : null}
      </Grid>
      <Grid item xs={8}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Order Item ID:{' '}
              <span style={{ fontWeight: 300 }}>{orderItem.id}</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Type:{' '}
              <span style={{ fontWeight: 300 }}>
                {orderItem.productDesignId
                  ? `CUSTOM (Design ID: ${orderItem.productDesignId})`
                  : 'STOCK'}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Saleschannel:{' '}
              <span style={{ fontWeight: 300 }}>
                {orderItem.salesChannelTitle || orderItem.salesChannelId}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Quantity:{' '}
              <span style={{ fontWeight: 300 }}>{orderItem.quantity}</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Product {orderItem.productPackageId ? ' package' : ''}:{' '}
              <span style={{ fontWeight: 300 }}>{title}</span>
            </Typography>
            {orderItem.productPackageId ? packageDetails : ''}
            <br />
            {orderItem.shopId === 'vermarc' &&
            (orderItem.productOptionAccountingId ||
              orderItem.productAccountingId) ? (
              <Typography variant="subtitle1">
                Product - Wings ID:{' '}
                {orderItem.productOptionAccountingId
                  ? orderItem.productOptionAccountingId
                  : orderItem.productAccountingId}
              </Typography>
            ) : null}
            {orderItem.salesChannelGroupId ? (
              <Typography variant="subtitle1">
                Division:{' '}
                <span style={{ fontWeight: 300 }}>
                  {orderItem.salesChannelGroupTitle ||
                    orderItem.salesChannelGroupId}
                </span>
              </Typography>
            ) : null}

            {orderItem.salesChannelTargetCategoryId ? (
              <Typography variant="subtitle1">
                Activity:{' '}
                <span style={{ fontWeight: 300 }}>
                  {orderItem.salesChannelTargetCategoryTitle ||
                    orderItem.salesChannelTargetCategoryId}
                </span>
              </Typography>
            ) : null}
            {orderItem.customizationOptions ? (
              <Typography variant="subtitle1">
                Personalization:{' '}
                <span style={{ fontWeight: 300 }}>{personalization}</span>
              </Typography>
            ) : null}

            <Typography variant="subtitle1">Discounts: {discounts}</Typography>
          </Grid>
        </Grid>
      </Grid>

      {orderItem.productDesignId ? (
        <Button
          onClick={() => handleShowCustom()}
          variant={'contained'}
          color={'primary'}
          style={{ position: 'absolute', top: 0, right: 0 }}
        >
          Show Custom Order Details
        </Button>
      ) : null}
    </Grid>
  ) : null
}
export default OrderItem
