import React from 'react'
import {
  createStyles,
  Grid,
  Theme,
  Toolbar,
  WithStyles,
  Button,
  withStyles
} from '@material-ui/core'

import Menu from './Menu'
import { logout } from '../../store/ObsHeader'
import { remapAlignmentForLayout } from '../../admin/components/content/utils'

const styles = (themeStyle: Theme) =>
  createStyles({
    direction: {
      [themeStyle.breakpoints.down('sm')]: {
        flexDirection: 'column',
        width: '100%'
      }
    },
    responsive: {
      height: 50,
      minHeight: 50,
      width: '100%',
      [themeStyle.breakpoints.down('sm')]: {
        background: 'transparent !important',
        color: '#000000',
        height: 'auto',
        width: '100%'
      }
    },
    directionResponsive: {
      display: 'flex',
      flexDirection: 'row',
      // maxWidth: 1000,
      margin: 'auto',
      [themeStyle.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    menuMob: {
      color: '#fff',
      [themeStyle.breakpoints.down('sm')]: {
        color: '#000000',
        fontSize: '1.2rem',
        width: '100%'
      }
    }
  })

interface Props extends WithStyles<typeof styles> {
  contentMenu: any
  currentUser: any
  elements: any[]
  isEditMode?: boolean
  language: string
  languages?: [string]
  urlPrefix: string | null
  showHomeMobile?: boolean
  shop: any
  slugs: any[]
}

const SecondaryHeader: React.FunctionComponent<Props> = props => {
  const {
    classes,
    contentMenu,
    elements,
    isEditMode,
    language,
    languages,
    urlPrefix,
    shop,
    currentUser,
    slugs
  } = props

  return (
    <Toolbar
      className={classes.responsive}
      style={{
        backgroundColor: contentMenu.backgroundColor,
        height:
          contentMenu.styling && contentMenu.styling.height
            ? contentMenu.styling.height
            : 'auto'
      }}
    >
      <Grid container={true}>
        <Grid
          container={true}
          className={classes.direction}
          spacing={2}
          justify={remapAlignmentForLayout(contentMenu.alignment)}
          alignItems={remapAlignmentForLayout(contentMenu.verticalAlign)}
        >
          <Menu
            slugs={slugs}
            currentUser={currentUser}
            language={language}
            languages={languages}
            urlPrefix={urlPrefix}
            isEditMode={isEditMode}
            linkColor={contentMenu.linkColor}
            styling={contentMenu.styling || {}}
            elements={elements}
            shop={shop}
          />
        </Grid>
      </Grid>
      {/* TODO better logged in handling */}
      {currentUser && currentUser.id ? (
        <Grid item={true} className={classes.menuMob}>
          <Button
            className={classes.menuMob}
            onClick={() => logout(currentUser)}
          >
            Logout
          </Button>
        </Grid>
      ) : null}
    </Toolbar>
  )
}

export default withStyles(styles, { withTheme: true })(SecondaryHeader)
