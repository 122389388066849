import gql from 'graphql-tag'
import {
  Board,
  PrintingDetailFragment,
  PricingDetailFragment,
  TextFragment
} from './fragments'
import getShopBrand from './queries/GET_SHOP_BRAND.graphql'

export const GET_SHOP_BRAND = getShopBrand

export const BoardItemDetailFragment = gql`
  ${Board.fragments.logoFields}
  ${Board.fragments.decalAttr}
  ${Board.fragments.textFields}
  ${Board.fragments.decalValues}
  fragment boardItemDetails on BoardProductDesign {
    boardTexts {
      id
      type
      createdAt
      updatedAt
      ...textFields
      decal {
        ...decalAttr
      }
      decalValues {
        ...decalValues
      }
    }
    boardLogos {
      id
      type
      createdAt
      updatedAt
      ...logoFields
      decal {
        ...decalAttr
      }
      decalValues {
        ...decalValues
      }
    }
    boardPrints {
      id
      fileName
      src
      preview
      createdAt
      updatedAt
    }
  }
`

export const GET_CUSTOMER_ADDRESS = gql`
  query customerAddress($ownedByOrganisationId: ID!) {
    customerAddress(ownedByOrganisationId: $ownedByOrganisationId) {
      id
      ownedByOrganisationId
      address1
      city
      company
      vatNumber
      countryCode
      firstName
      lastName
      email
      phone
      province
      zip
      isValidAddress
    }
  }
`

export const GET_TEAMSTORE = gql`
  query teamstore($id: ID!, $first: Int, $after: String) {
    teamstore(id: $id) {
      id
      enabled
      hasOutOfStock
      type
      privacyType
      name
      logo
      mainBanner
      brandColor
      availableFrom
      availableUntil
      contactPerson
      contactEmail
      contactPhone
      hasPassword
      hasPersonalization
      purchaseLimitPerCustomer
      title {
        id
        text {
          langCode
          content
        }
      }
      introductionText {
        id
        text {
          langCode
          content
        }
      }

      slugs {
        langCode
        content
      }
      groups {
        id
        name
        title {
          id
          text {
            langCode
            content
          }
        }
      }
      targetCategories {
        id
        name
        title {
          id
          text {
            langCode
            content
          }
        }
      }
      customizationSettings {
        customizationOptionGroups {
          id
          options {
            id
            type
            title {
              id
              text {
                langCode
                content
              }
            }
            pricingRules {
              id
              linkedType
              minOrderQuantity
              calculationType
              formula {
                priority
                price {
                  currency
                  value
                }
                qty
                sqm
              }
            }
          }
        }
      }
      productPackages {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            featured
            sortOrder
            productPackageId
            salesChannelId
            productPackage {
              id
              status
              price {
                currency
                value
              }
              slugs {
                langCode
                content
              }
              title {
                id
                text {
                  langCode
                  content
                }
              }
              media {
                id
                variantId
                variantOptionId
                optionValue
                sortOrder
                isThumbnail
                isBanner
                image
              }
              discounts {
                discount {
                  id
                  canBeCombinedWithOtherDiscounts
                  availableFrom
                  availableUntil
                  enabled
                  hasCoupon
                  discountType
                  discountValue
                  discountCurrency
                  discountRules {
                    id
                    operator
                    discountRuleObjectType
                    discountRuleCondition
                  }
                }
              }
              products {
                id
                assignedProductAttributes {
                  attribute {
                    id
                    title {
                      id
                      text {
                        langCode
                        content
                      }
                    }
                    description {
                      id
                      text {
                        langCode
                        content
                      }
                    }
                    no
                    sortOrder
                    isFeatured
                    isFeaturedAttributeTitle
                    image
                    icon
                  }
                }
              }
            }
          }
        }
      }

      products(first: $first, after: $after) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        edges {
          node {
            featured
            sortOrder
            productId
            salesChannelId
            product {
              id
              status
              isCustomProduct
              hasPersonalization
              inventory
              sku
              price {
                currency
                value
              }
              slugs {
                langCode
                content
              }
              title {
                id
                text {
                  langCode
                  content
                }
              }
              media {
                id
                variantId
                variantOptionId
                optionValue
                sortOrder
                isThumbnail
                isBanner
                image
              }
              assignedProductAttributes {
                id
                attribute {
                  id
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                  description {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                  no
                  image
                  icon
                  sortOrder
                  isFeatured
                  isFeaturedAttributeTitle
                }
              }
              variants {
                id
                options
                variant {
                  id
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                  options {
                    id
                    value
                    sortOrder
                    title {
                      id
                      text {
                        langCode
                        content
                      }
                    }
                  }
                }
              }
              optionValues {
                id
                isEnabled
                sku
                inventory
                barcode
                sortOrder
                optionValues
                variantIds
                optionValueIds
                modifiers {
                  id
                  type
                  stringModifier
                  priceChange {
                    currency
                    value
                  }
                }
                media {
                  id
                  sortOrder
                  isThumbnail
                  isBanner
                  image
                }
              }
            }
          }
        }
      }
      collections {
        edges {
          node {
            id
            featured
            sortOrder
            collectionId
            salesChannelId
            collection {
              id
              no
              thumbnail
              slugs {
                langCode
                content
              }
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
          }
        }
      }
    }
  }
`

export const SEARCH_PRODUCT = gql`
  query searchProducts(
    $price: PriceFilter
    $name: String
    $productAttributeIds: [ID]
    $salesChannelIds: [ID]
    $collectionIds: [ID]
  ) {
    searchProducts(
      price: $price
      name: $name
      productAttributeIds: $productAttributeIds
      salesChannelIds: $salesChannelIds
      collectionIds: $collectionIds
    ) {
      id
      shopId
      name
      active
      categoryName
      slug
      no
      accountingId
      dummyStyles
      isCustomProduct
      hasPersonalization
      inventory
      sku
      slugs {
        langCode
        content
      }
      title {
        id
        text {
          langCode
          content
        }
      }
      media {
        id
        productId
        variantId
        variantOptionId
        optionValue
        sortOrder
        isThumbnail
        isBanner
        image
      }
      price {
        currency
        value
      }
      assignedProductAttributes {
        attribute {
          id
          title {
            id
            text {
              langCode
              content
            }
          }
          description {
            id
            text {
              langCode
              content
            }
          }
          no
          sortOrder
          isFeatured
          isFeaturedAttributeTitle
          image
          icon
        }
      }
    }
  }
`

export const ELEMENTS = gql`
  query getProductDesign($id: ID!, $ownedByOrganisationId: ID!) {
    getProductDesign(id: $id, ownedByOrganisationId: $ownedByOrganisationId) {
      id
      modelRevision
      name
      ownedByOrganisationId
      latestRevisionNo
      product {
        id
        designTemplates {
          id
          lastTemplateRevision
          templateFileSrc
          logobaseFileSrc
          previewImageSrc
          colors {
            id
            name
            coordinates {
              x
              y
            }
            colorId
            customColorHex
            color {
              id
              hex
              code
              name
            }
          }
        }
      }
      latestRevision {
        id
        revisionNo
        revisionName
        backgroundColorHex
        selectedDesignTemplateId
        pxToCm
        zoneColors {
          colorHex
          colorId
          color {
            id
            internalCode
            code
            hex
            internalName
            name
          }
          zoneId
        }
        textDesigns {
          id
          textDesign {
            content
            fontFamily
            colorHex
            colorId
            width
            height
            lineSpacing
            outlineSize
            outlineHex
            orderSeq
            defaultItem
            boundingId
            boundingGroup
            designAttributes {
              top
              left
              width
              height
              scaleX
              scaleY
              angle
              flipX
              flipY
              lock
            }
          }
        }
        logoDesigns {
          id
          logoDesign {
            colorHex
            colorId
            colorType
            printingTechniqueId
            name
            src
            orderSeq
            defaultItem
            boundingId
            boundingGroup
            designAttributes {
              top
              left
              width
              height
              scaleX
              scaleY
              angle
              flipX
              flipY
              lock
            }
          }
        }
        designElements {
          id
          colorHex
          colorId
          countryFlag
          baseElement {
            name
          }
          productDesignPrint {
            src
          }
          designAttributes {
            top
            left
            width
            height
            scaleX
            scaleY
            angle
            flipX
            flipY
            lock
            offsetX
            offsetY
            repeat
          }
        }
      }
    }
  }
`

export const GET_SHOP_CONFIGURATION = gql`
  query shopConfiguration($id: ID!) {
    shopConfiguration(id: $id) {
      name
      designStudioSettings {
        designSteps {
          stepSortOrder
          type
          ... on LabelStep {
            defaultCareLabelId
          }
          ... on FabricStep {
            showTooltip
          }
          ... on ColorStep {
            defaultPrintingTechnique
            colorPrintingTechniques {
              ...printingTechniqueDetail
            }
            printLibrary {
              id
              name
              preview
              src
            }
          }
          ... on TextStep {
            fonts {
              id
              name
              weights
              source
            }
            defaultPrintingTechnique
            textPrintingTechniques {
              ...printingTechniqueDetail
            }
          }
          ... on LogoStep {
            maxLogos
            defaultPrintingTechnique
            logoPrintingTechniques {
              ...printingTechniqueDetail
            }
          }
        }
      }
    }
  }
  ${TextFragment}
  ${PricingDetailFragment}
  ${PrintingDetailFragment}
`

export const GET_CUSTOMER_BOARD_FOLDERS = gql`
  query customerBoardFolders($ownedByOrganisationId: ID!, $parentId: ID!) {
    customerBoardFolders(
      ownedByOrganisationId: $ownedByOrganisationId
      parentId: $parentId
    ) {
      id
      name
      parentId
      ownedByOrganisationId
    }
  }
`
export const GET_CUSTOMER_ORDERS = gql`
  query customerOrders($ownedByOrganisationId: ID!) {
    customerOrders(ownedByOrganisationId: $ownedByOrganisationId) {
      id
      no
      ownedByOrganisationId
      name
      submittedAt
      dueAt
      customer {
        id
        shopId
        email
        members {
          userId
          user {
            id
            email
            firstName
            lastName
            vermarcPassportNumber
          }
        }
      }
      shippingAddress {
        firstName
        lastName
        company
        vatNumber
        phone
        email
        address1
        address2
        city
        zip
        countryCode
        country
      }
      billingAddress {
        firstName
        lastName
        company
        vatNumber
        phone
        email
        address1
        address2
        city
        zip
        countryCode
        country
      }
      total {
        currency
        value
        includesTax
        taxPercent
      }
      shippingCost {
        currency
        value
      }
      status
      appliedCoupons {
        discountDeduction {
          currency
          value
          includesTax
          taxPercent
        }
        linkedCoupon {
          id
          couponCode
        }
      }
      appliedDiscounts {
        coupon {
          id
          couponCode
        }
        discountDeduction {
          currency
          value
          includesTax
          taxPercent
        }
        linkedDiscount {
          id
          accountingId
          title {
            id
            text {
              langCode
              content
            }
          }
          priority
          enabled
          hasCoupon
          enablesFreeShipping
          canBeCombinedWithOtherDiscounts
          discountType
          productsGetForFree
          freeProducts {
            id
            media {
              id
              sortOrder
              isThumbnail
              isBanner
              image
            }
            title {
              id
              text {
                content
                langCode
              }
            }
          }
          discountValue
          discountCurrency
          availableFrom
          availableUntil
          discountRules {
            id
            discountId
            operator
            discountRuleValue
            discountRuleCurrency
            discountRuleObjects {
              id
              type
              objectId
            }
            discountRuleObjectType
            discountRuleCondition
          }
          internalNote
        }
      }
      orderItems {
        id
        productDesignId
        orderId
        salesChannelId
        salesChannelTitle
        salesChannelGroupId
        salesChannelGroupTitle
        salesChannelTargetCategoryId
        salesChannelTargetCategoryTitle
        customizationOptions {
          options {
            value
          }
        }
        quantity
        productId
        orderType
        productId
        total {
          currency
          value
        }
        itemTotalBeforeDiscount {
          currency
          value
          includesTax
          taxPercent
        }
        totalBeforeDiscount {
          currency
          value
          includesTax
          taxPercent
        }
        appliedCoupons {
          discountDeduction {
            currency
            value
            includesTax
            taxPercent
          }
          linkedCoupon {
            id
            couponCode
          }
        }
        appliedDiscounts {
          coupon {
            id
            couponCode
          }
          discountDeduction {
            currency
            value
            includesTax
            taxPercent
          }
          discountQuantity
          linkedDiscount {
            id
            accountingId
            title {
              id
              text {
                langCode
                content
              }
            }
            priority
            enabled
            hasCoupon
            enablesFreeShipping
            canBeCombinedWithOtherDiscounts
            discountType
            productsGetForFree
            freeProducts {
              id
              media {
                id
                sortOrder
                isThumbnail
                isBanner
                image
              }
              title {
                id
                text {
                  content
                  langCode
                }
              }
            }
            discountValue
            discountCurrency
            availableFrom
            availableUntil
            discountRules {
              id
              discountId
              operator
              discountRuleValue
              discountRuleCurrency
              discountRuleObjects {
                id
                type
                objectId
              }
              discountRuleObjectType
              discountRuleCondition
            }
            internalNote
          }
        }
        productTitle
        productAttributeTitle
        productOptionValueTitle
        productOptionValueId
        productOptionValue {
          id
          sku
          selectedVariants {
            id
            selectedOption {
              id
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
            title {
              id
              text {
                langCode
                content
              }
            }
          }
        }
        product {
          id
          isCustomProduct
          hasPersonalization
          inventory
          sku
          careLabels {
            id
            name
            src
          }
          title {
            id
            text {
              content
              langCode
            }
          }
          price {
            currency
            value
            includesTax
            taxPercent
          }
          media {
            id
            productId
            variantOptionId
            optionValue
            isThumbnail
            image
          }
          collections {
            collectionId
            productId
            collection {
              id
              slugs {
                langCode
                content
              }
              title {
                id
                text {
                  content
                  langCode
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_BOARD_PRODUCT = gql`
  query boardProduct($id: ID!, $ownedByOrganisationId: ID!) {
    boardProduct(id: $id, ownedByOrganisationId: $ownedByOrganisationId) {
      id
      name
      comment
      ownedByOrganisationId
      backgroundColorHex
      isOrdered
      orderId
      decalOrder {
        sequence
        boardItemId
      }
      createdAt
      updatedAt
      baseProductId
    }
  }
`
// @Deprecated
// export const GET_BOARDPRODUCT_COMMENTS = gql`
//   query boardProductComments($boardProductId: ID!) {
//     boardProductComments(boardProductId: $boardProductId) {
//       id
//       boardProductId
//       content
//       createdAt
//       createdBy
//     }
//   }
// `;
export const GET_PRODUCT_DESIGN = gql`
  query productDesign($boardProductId: ID!) {
    productDesign(boardProductId: $boardProductId) {
      id
      boardProductId # equals boardProductId
      ownedByOrganisationId
      elementRevision
      ...boardItemDetails
      elementDesignData {
        ...elementDesignDataDetails
      }
      careLabelId
      careLabelLogoId
    }
  }
  ${BoardItemDetailFragment}
  ${Board.fragments.elementDesignDataDetails}
`

export const GET_CUSTOMER_CART = gql`
  query getCustomerCart($id: ID!) {
    customerCart(id: $id) {
      id
      cart {
        id
        couponCode
        shippingAddressId
        billingAddressId
        cartItems {
          id
          productOptionValueId
          productOptionValues
          productOptionValue {
            id
            sku
            selectedVariants {
              id
              selectedOption {
                id
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
              }
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
          }
          productId
          quantity
          product {
            id
            price {
              currency
              value
              includesTax
              taxPercent
            }
            title {
              id
              text {
                langCode
                content
              }
            }
            media {
              id
              productId
              variantId
              variantOptionId
              optionValue
              sortOrder
              isThumbnail
              isBanner
              image
            }
            customizationSettings {
              customizationOptionGroups {
                id
                logic
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
                options {
                  id
                  type
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                  pricingRules {
                    id
                    linkedType
                    minOrderQuantity
                    calculationType
                    formula {
                      priority
                      price {
                        currency
                        value
                      }
                      qty
                      sqm
                    }
                  }
                }
              }
            }
          }
          shopId
          boardProductId
          productDesignId
          designStudioVersion
          salesChannelId
          salesChannel {
            customizationSettings {
              customizationOptionGroups {
                id
                options {
                  id
                  type
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                  pricingRules {
                    id
                    linkedType
                    minOrderQuantity
                    calculationType
                    formula {
                      priority
                      price {
                        currency
                        value
                      }
                      qty
                      sqm
                    }
                  }
                }
              }
            }
          }
          orderType
          sizeQuantities {
            quantity
            size
          }
          productPackageId
          productPackageProductOptionValues {
            id
            productId
            product {
              id
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
            productOptionValueId
            productOptionValues
            productOptionValue {
              id
              sku
              selectedVariants {
                id
                selectedOption {
                  id
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                }
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
              }
            }
          }
          salesChannelTargetCategoryId
          salesChannelGroupId
          customizationOptions {
            id
            customizationGroupId
            options {
              id
              productCustomizationOptionId
              value
            }
          }
        }
      }
    }
  }
`
export const GET_CUSTOMER_CART_DETAIL = gql`
  query getCustomerCartDetail($id: ID!, $currency: Currency) {
    customerCart(id: $id, currency: $currency) {
      id
      cart {
        id
        couponCode
        lastAddToCartUrl
        paymentSourceId
        paymentMethod
        userReferences
        shopId
        seasonName
        collectionName
        shippingAddressId
        billingAddressId
        ownedByOrganisationId
        shopId
        seasonName
        collectionName
        shippingMethodId
        ownedByOrganisationId
        totalCalculated {
          appliedCoupons {
            discountDeduction {
              currency
              value
              includesTax
              taxPercent
            }
            linkedCoupon {
              id
              couponCode
            }
          }
          appliedDiscounts {
            coupon {
              id
              couponCode
            }
            discountDeduction {
              currency
              value
              includesTax
              taxPercent
            }
            linkedDiscount {
              id
              accountingId
              title {
                id
                text {
                  langCode
                  content
                }
              }
              priority
              enabled
              hasCoupon
              enablesFreeShipping
              canBeCombinedWithOtherDiscounts
              discountType
              productsGetForFree
              freeProducts {
                id
                media {
                  id
                  sortOrder
                  isThumbnail
                  isBanner
                  image
                }
                title {
                  id
                  text {
                    content
                    langCode
                  }
                }
              }
              discountValue
              discountCurrency
              availableFrom
              availableUntil
              discountRules {
                id
                discountId
                operator
                discountRuleValue
                discountRuleCurrency
                discountRuleObjects {
                  id
                  type
                  objectId
                }
                discountRuleObjectType
                discountRuleCondition
              }
              internalNote
            }
          }
          cartTotalPrice {
            currency
            value
            includesTax
            taxPercent
          }
          cartTotalinCurrency(currency: $currency) {
            currency
            value
            includesTax
            taxPercent
          }
          cartTotalString
          cartTotalGroups {
            finalTotal {
              currency
              value
              includesTax
              taxPercent
            }
            finalTotalinCurrency(currency: $currency) {
              currency
              value
              includesTax
              taxPercent
            }
            totalPieces
            discountRate
            discountNote
            shippingCost {
              shippingProviderName
              cost {
                currency
                value
                includesTax
                taxPercent
              }
            }
            cartItemTotals {
              id
              qty
              productId
              productOptionValueId
              productOptionValues
              boardProductId
              productDesignId
              baseProductId
              salesChannelId

              fixPrice {
                currency
                value
                includesTax
                taxPercent
              }
              priceNote
              itemTotalBeforeDiscount {
                currency
                value
                includesTax
                taxPercent
              }
              totalBeforeDiscount {
                currency
                value
                includesTax
                taxPercent
              }
              total {
                currency
                value
                includesTax
                taxPercent
              }
              appliedCoupons {
                discountDeduction {
                  currency
                  value
                  includesTax
                  taxPercent
                }
                linkedCoupon {
                  id
                  couponCode
                }
              }
              appliedDiscounts {
                coupon {
                  id
                  couponCode
                }
                discountDeduction {
                  currency
                  value
                  includesTax
                  taxPercent
                }
                discountQuantity
                linkedDiscount {
                  id
                  accountingId
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                  priority
                  enabled
                  hasCoupon
                  enablesFreeShipping
                  canBeCombinedWithOtherDiscounts
                  discountType
                  productsGetForFree
                  freeProducts {
                    id
                    media {
                      id
                      sortOrder
                      isThumbnail
                      isBanner
                      image
                    }
                    title {
                      id
                      text {
                        content
                        langCode
                      }
                    }
                  }
                  discountValue
                  discountCurrency
                  availableFrom
                  availableUntil
                  discountRules {
                    id
                    discountId
                    operator
                    discountRuleValue
                    discountRuleCurrency
                    discountRuleObjects {
                      id
                      type
                      objectId
                    }
                    discountRuleObjectType
                    discountRuleCondition
                  }
                  internalNote
                }
              }
            }
          }
        }
        cartItems {
          id
          productOptionValueId
          productOptionValues
          productOptionValue {
            id
            sku
            selectedVariants {
              id
              selectedOption {
                id
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
              }
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
          }
          productId
          quantity
          product {
            id
            isCustomProduct
            hasPersonalization
            price {
              currency
              value
              includesTax
              taxPercent
            }
            title {
              id
              text {
                langCode
                content
              }
            }
            media {
              id
              productId
              variantId
              variantOptionId
              optionValue
              sortOrder
              isThumbnail
              isBanner
              image
            }
            variants {
              id
              options
              variant {
                id
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
                options {
                  id
                  value
                  sortOrder
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                }
              }
            }
            optionValues {
              id
              isEnabled
              sku
              inventory
              barcode
              sortOrder
              optionValues
              variantIds
              optionValueIds
              modifiers {
                id
                type
                stringModifier
                priceChange {
                  currency
                  value
                }
              }
              media {
                id
                sortOrder
                isThumbnail
                isBanner
                image
              }
            }
            assignedProductAttributes {
              id
              attribute {
                id
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
                description {
                  id
                  text {
                    langCode
                    content
                  }
                }
                no
                image
                icon
                sortOrder
                isFeatured
                isFeaturedAttributeTitle
              }
            }
            customizationSettings {
              customizationOptionGroups {
                id
                logic
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
                options {
                  id
                  type
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                  pricingRules {
                    id
                    linkedType
                    minOrderQuantity
                    calculationType
                    formula {
                      priority
                      price {
                        currency
                        value
                      }
                      qty
                      sqm
                    }
                  }
                }
              }
            }
          }
          shopId
          boardProductId
          productDesignId
          designStudioVersion
          salesChannelId
          salesChannel {
            hasOutOfStock
            customizationSettings {
              customizationOptionGroups {
                id
                options {
                  id
                  type
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                  pricingRules {
                    id
                    linkedType
                    minOrderQuantity
                    calculationType
                    formula {
                      priority
                      price {
                        currency
                        value
                      }
                      qty
                      sqm
                    }
                  }
                }
              }
            }
          }
          productPackageId
          productPackageProductOptionValues {
            id
            productId
            product {
              id
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
            productOptionValueId
            productOptionValues
            productOptionValue {
              id
              sku
              selectedVariants {
                id
                selectedOption {
                  id
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                }
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
              }
            }
          }
          orderType
          salesChannelTargetCategoryId
          salesChannelGroupId
          customizationOptions {
            id
            customizationGroupId
            options {
              id
              productCustomizationOptionId
              value
            }
          }
          boardProduct {
            id
            name
            shopId
            comment
            baseProduct {
              id
              no
            }
          }
          sizeQuantities {
            quantity
            size
          }
          design {
            elementRevision
            ...boardItemDetails
            elementDesignData {
              ...elementDesignDataDetails
              colorHex
            }
          }
        }
      }
    }
  }
  ${BoardItemDetailFragment}
  ${Board.fragments.elementDesignDataDetails}
`

export const GET_CUSTOMER_BOARDPRODUCTS = gql`
  query customerBoardProducts($ownedByOrganisationId: ID!) {
    customerProductDesigns(ownedByOrganisationId: $ownedByOrganisationId) {
      edges {
        node {
          id
          shopId
          createdAt
          updatedAt
          ownedByOrganisationId
          product {
            id
            designStudioVersion
          }
          latestRevision {
            id
            revisionName
          }
        }
      }
    }
    customerBoardProducts(ownedByOrganisationId: $ownedByOrganisationId) {
      edges {
        node {
          id
          shopId
          ownedByOrganisationId
          baseProductId
          name
          updatedAt
          createdAt
          lastPrice {
            value
            currency
            includesTax
            taxPercent
          }
          isOrdered
          orderId
        }
      }
    }
  }
`

export const GET_ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      shopId
      cartId
      ownedByOrganisationId
      name
      submittedAt
      shippedAt
      dueAt
      total {
        currency
        value
        includesTax
        taxPercent
      }
      status
      createdAt
      updateAt
    }
  }
`

export const GET_COLOR = gql`
  query color($id: ID!) {
    color(id: $id) {
      id
      name
      colorRGB {
        r
        g
        b
      }
      hex
    }
  }
`

export const GET_PRINTINGTECHNIQUE = gql`
  query printingTechnique($id: ID!) {
    printingTechnique(id: $id) {
      ...printingTechniqueDetail
    }
  }
  ${TextFragment}
  ${PricingDetailFragment}
  ${PrintingDetailFragment}
`
export const GET_CART = gql`
  query cart($id: ID!, $currency: Currency!) {
    cart(id: $id) {
      id
      couponCode
      lastAddToCartUrl
      paymentSourceId
      paymentMethod
      userReferences
      shopId
      seasonName
      collectionName
      shippingAddressId
      shippingAddress {
        id
        ownedByOrganisationId
        address1
        city
        company
        vatNumber
        countryCode
        firstName
        lastName
        email
        phone
        province
        zip
        isValidAddress
      }
      billingAddressId
      billingAddress {
        id
        ownedByOrganisationId
        address1
        city
        company
        vatNumber
        countryCode
        firstName
        lastName
        email
        phone
        province
        zip
        isValidAddress
      }
      ownedByOrganisationId
      shopId
      seasonName
      collectionName
      shippingMethodId
      ownedByOrganisationId
      totalCalculated {
        appliedCoupons {
          discountDeduction {
            currency
            value
            includesTax
            taxPercent
          }
          linkedCoupon {
            id
            couponCode
          }
        }
        appliedDiscounts {
          coupon {
            id
            couponCode
          }
          discountDeduction {
            currency
            value
            includesTax
            taxPercent
          }
          linkedDiscount {
            id
            accountingId
            title {
              id
              text {
                langCode
                content
              }
            }
            priority
            enabled
            hasCoupon
            enablesFreeShipping
            canBeCombinedWithOtherDiscounts
            discountType
            productsGetForFree
            freeProducts {
              id
              media {
                id
                sortOrder
                isThumbnail
                isBanner
                image
              }
              title {
                id
                text {
                  content
                  langCode
                }
              }
            }
            discountValue
            discountCurrency
            availableFrom
            availableUntil
            discountRules {
              id
              discountId
              operator
              discountRuleValue
              discountRuleCurrency
              discountRuleObjects {
                id
                type
                objectId
              }
              discountRuleObjectType
              discountRuleCondition
            }
            internalNote
          }
        }
        cartTotalPrice {
          currency
          value
          includesTax
          taxPercent
        }
        cartTotalinCurrency(currency: $currency) {
          currency
          value
          includesTax
          taxPercent
        }
        cartTotalString
        cartTotalGroups {
          finalTotal {
            currency
            value
            includesTax
            taxPercent
          }
          finalTotalinCurrency(currency: $currency) {
            currency
            value
            includesTax
            taxPercent
          }
          totalPieces
          discountRate
          discountNote
          shippingCost {
            shippingProviderName
            cost {
              currency
              value
              includesTax
              taxPercent
            }
          }
          cartItemTotals {
            id
            qty
            productId
            productOptionValueId
            productOptionValues
            boardProductId
            baseProductId
            fixPrice {
              currency
              value
              includesTax
              taxPercent
            }
            priceNote
            itemTotalBeforeDiscount {
              currency
              value
              includesTax
              taxPercent
            }
            totalBeforeDiscount {
              currency
              value
              includesTax
              taxPercent
            }
            total {
              currency
              value
              includesTax
              taxPercent
            }
            textCost {
              name
              type
              total {
                currency
                value
                includesTax
                taxPercent
              }
            }
            aopCost {
              name
              type
              total {
                currency
                value
                includesTax
                taxPercent
              }
            }
            colorCost {
              name
              type
              total {
                currency
                value
                includesTax
                taxPercent
              }
            }
            logoCost {
              name
              type
              total {
                currency
                value
                includesTax
                taxPercent
              }
            }
            fabricCost {
              name
              type
              total {
                currency
                value
                includesTax
                taxPercent
              }
            }
          }
        }
      }
      cartItems {
        id
        productOptionValueId
        productOptionValues
        productOptionValue {
          id
          sku
          selectedVariants {
            id
            selectedOption {
              id
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
            title {
              id
              text {
                langCode
                content
              }
            }
          }
        }
        productId
        quantity
        product {
          id
          price {
            currency
            value
            includesTax
            taxPercent
          }
          title {
            id
            text {
              langCode
              content
            }
          }
          media {
            id
            productId
            variantId
            variantOptionId
            optionValue
            sortOrder
            isThumbnail
            isBanner
            image
          }
          customizationSettings {
            customizationOptionGroups {
              id
              logic
              title {
                id
                text {
                  langCode
                  content
                }
              }
              options {
                id
                type
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
                pricingRules {
                  id
                  linkedType
                  minOrderQuantity
                  calculationType
                  formula {
                    priority
                    price {
                      currency
                      value
                    }
                    qty
                    sqm
                  }
                }
              }
            }
          }
        }
        boardProductId
        productDesignId
        designStudioVersion
        shopId
        shop {
          tempDiscount {
            orderType
            pricingNote
            leadTimeNote
            leadTimeDays
            minQuantity
            maxQuantity
            multiplier
          }
        }
        productPackageId
        productPackageProductOptionValues {
          id
          productId
          product {
            id
            title {
              id
              text {
                langCode
                content
              }
            }
          }
          productOptionValueId
          productOptionValues
          productOptionValue {
            id
            sku
            selectedVariants {
              id
              selectedOption {
                id
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
              }
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
          }
        }
        orderType
        salesChannelTargetCategoryId
        salesChannelGroupId
        customizationOptions {
          id
          customizationGroupId
          options {
            id
            productCustomizationOptionId
            value
          }
        }
        salesChannelId
        salesChannel {
          customizationSettings {
            customizationOptionGroups {
              id
              options {
                id
                type
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
                pricingRules {
                  id
                  linkedType
                  minOrderQuantity
                  calculationType
                  formula {
                    priority
                    price {
                      currency
                      value
                    }
                    qty
                    sqm
                  }
                }
              }
            }
          }
        }
        boardProduct {
          id
          name
          shopId
          comment
          lastPrice {
            value
            currency
            includesTax
            taxPercent
          }
          baseProduct {
            id
            no
          }
        }
        sizeQuantities {
          quantity
          size
        }
        design {
          elementRevision
          ...boardItemDetails
          elementDesignData {
            ...elementDesignDataDetails
            colorHex
            selectedFabric {
              id
              code
              name
              summary
              fabricTypes {
                id
                name
                fabricConstructions
                fabricContentIds
                fabricContents {
                  id
                  percent
                  textileId
                  textile {
                    id
                    code
                    name
                    textileMaterial
                  }
                }
              }
              weights {
                weight
                weightUnit
              }
            }
          }
          careLabelId
          careLabelLogoId
        }
      }
    }
  }
  ${BoardItemDetailFragment}
  ${Board.fragments.elementDesignDataDetails}
`

export const GET_ORGANISATION = gql`
  query organisation($id: ID!) {
    organisation(id: $id) {
      id
      name
      vatNumber
      organisationType
      defaultShippingAddressId
      defaultBillingAddressId
    }
  }
`
export const GET_USER = gql`
  query user {
    user {
      id
      email
      firstName
      lastName
      phone
      acceptedCookieConsent
      statisticsConsent
      marketingConsent
      vermarcPassportNumber
      settings {
        defaultLanguage
        defaultCountry
        defaultCurrency
        defaultWeightUnit
        defaultLengthUnit
        defaultShippingAddressId
        defaultBillingAddressId
      }
      organisationMemberships {
        id
        organisationId
        userId
        role
      }
    }
  }
`
export const GET_START_PRICE = gql`
  query calculateStartPrice($productId: ID!, $total: Float!) {
    calculateStartPrice(productId: $productId, total: $total) {
      currency
      value
      includesTax
      taxPercent
    }
  }
`
export const GET_PRODUCT_FINISHED_DESIGNS = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      finishedDesigns {
        designs {
          salesChannelType
          boardProduct {
            id
          }
        }
      }
    }
  }
`
export const GET_PRODUCT_CONFIGURATION = gql`
  query productConfiguration($id: ID!, $revision: Int!) {
    productConfiguration(id: $id) {
      id
      name
      no
      slug
      updatedAt
      objSrc
      mtlSrc
      labelPosition
      manualAttatchObject
      manualAttatchPosition {
        x
        y
        z
      }
      tempCustomPriceAdditions {
        id
        minOrderQuantity
        calculationType
        formula {
          priority
          price {
            currency
            value
            includesTax
            taxPercent
          }
          qty
          sqm
        }
      }
      measurementTableSrc
      measurementDiagramFront
      measurementDiagramBack
      careLabels {
        id
      }
      modelConfiguration {
        modelRatio
        modelTextureRatio
        modelSide
        modelRotation {
          x
          y
          z
        }
        actualWidthCm
        actualHeightCm
        actualWidthELM
      }
      elements(productId: $id, revision: $revision) {
        id
        code
        defaultName
        name
        sqm
        defaultFabricId
        fabricGroupId
        fabricGroup {
          id
          name
        }
        fabricCompositions {
          id
          code
          printable
          printableColors
          name
          summary
          fabricCost {
            id
            minOrderQuantity
            calculationType
            formula {
              priority
              price {
                currency
                value
                includesTax
                taxPercent
              }
              qty
              sqm
            }
          }
          swatchPreviewUrl
          defaultColorId
          colors {
            id
            name
            colorRGB {
              r
              g
              b
            }
            hex
          }
          fabricTypes {
            id
            name
            fabricConstructions
            fabricContentIds
            fabricContents {
              id
              percent
              textileId
              textile {
                id
                code
                name
                textileMaterial
              }
            }
          }
          weights {
            weight
            weightUnit
          }
        }
      }
    }
  }
`

export const PRODUCT_DESIGN = gql`
  query getProductDesign($shopId: ID!, $id: ID!, $ownedByOrganisationId: ID!) {
    shopConfiguration(id: $shopId) {
      name
      designStudioSettings {
        designSteps {
          stepSortOrder
          type
          ... on LabelStep {
            defaultCareLabelId
          }
          ... on FabricStep {
            showTooltip
          }
          ... on ColorStep {
            defaultPrintingTechnique
            colorPrintingTechniques {
              ...printingTechniqueDetail
            }
            printLibrary {
              id
              name
              preview
              src
            }
          }
          ... on TextStep {
            fonts {
              id
              name
              weights
              source
            }
            defaultPrintingTechnique
            textPrintingTechniques {
              ...printingTechniqueDetail
            }
          }
          ... on LogoStep {
            maxLogos
            defaultPrintingTechnique
            logoPrintingTechniques {
              ...printingTechniqueDetail
            }
          }
        }
      }
    }
    getProductDesign(id: $id, ownedByOrganisationId: $ownedByOrganisationId) {
      id
      modelRevision
      name
      ownedByOrganisationId
      productElements {
        id
        elementRevision
        code
        fullCode
        defaultName
        name
        fabricCompositions {
          id
          name
          colors {
            id
            name
            code
            hex
            CMYK {
              C
              M
              Y
              K
            }
          }
        }
        fabricGroupId
        fabricGroup {
          id
          name
        }
      }
      product {
        id
        title {
          id
          text {
            content
            langCode
          }
        }
        description {
          id
          text {
            content
            langCode
          }
        }
        lastElementRevision
        objSrc
        mtlSrc
        seamSrc
        baseTemplateSrc
        defaultTemplateSrc
        modelConfiguration {
          dimension {
            baseTemplate
            baseSize
            pxToCm
          }
        }
        designTemplates {
          id
          lastTemplateRevision
          templateFileSrc
          logobaseFileSrc
          previewImageSrc
          colors {
            id
            name
            coordinates {
              x
              y
            }
            colorId
            customColorHex
            color {
              id
              hex
              code
              name
            }
          }
        }
      }
      latestRevisionNo
      latestRevision {
        id
        revisionNo
        revisionName
        backgroundColorHex
        selectedDesignTemplateId
        pxToCm
        zoneColors {
          colorHex
          colorId
          color {
            id
            internalCode
            code
            hex
            internalName
            name
          }
          zoneId
        }
        textDesigns {
          id
          isVisible
          textDesign {
            content
            fontFamily
            colorHex
            colorId
            width
            height
            lineSpacing
            outlineSize
            outlineHex
            orderSeq
            defaultItem
            boundingId
            boundingGroup
            designAttributes {
              top
              left
              width
              height
              scaleX
              scaleY
              angle
              flipX
              flipY
              lock
            }
          }
        }
        logoDesigns {
          id
          isVisible
          logoDesign {
            name
            src
            orderSeq
            defaultItem
            boundingId
            boundingGroup
            designAttributes {
              top
              left
              width
              height
              scaleX
              scaleY
              angle
              flipX
              flipY
              lock
            }
          }
        }
        designElements {
          id
          colorHex
          colorId
          baseElement {
            name
          }
          revisionNo
          productDesignId
          productDesignPrintId
          productDesignPrint {
            id
            name
            src
          }
          countryFlag
          designAttributes {
            scaleX
            scaleY
            angle
            offsetX
            offsetY
            repeat
          }
        }
        printDesigns {
          id
          name
          src
        }
      }
    }
  }
  ${TextFragment}
  ${PricingDetailFragment}
  ${PrintingDetailFragment}
`

export const CUSTOM_PRODUCTS = gql`
  query searchCustomProducts(
    $name: String
    $collectionIds: [ID]
    $salesChannelIds: [ID]
  ) {
    searchCustomProducts(
      name: $name
      collectionIds: $collectionIds
      salesChannelIds: $salesChannelIds
    ) {
      id
      no
      slugs {
        langCode
        content
      }
      media {
        id
        productId
        variantId
        variantOptionId
        optionValue
        image
        isThumbnail
      }
      title {
        id
        text {
          langCode
          content
        }
      }
      collections {
        collection {
          id
          no
          slugs {
            langCode
            content
          }
          title {
            id
            text {
              langCode
              content
            }
          }
        }
      }
      assignedProductAttributes {
        attribute {
          id
          title {
            id
            text {
              langCode
              content
            }
          }
          description {
            id
            text {
              langCode
              content
            }
          }
          no
          sortOrder
          isFeatured
          isFeaturedAttributeTitle
          image
          icon
        }
      }
    }
  }
`
export const GET_PRODUCT = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      isCustomProduct
      hasPersonalization
      inventory
      sku
      slugs {
        langCode
        content
      }
      title {
        id
        text {
          langCode
          content
        }
      }
      description {
        id
        text {
          langCode
          content
        }
      }
      price {
        currency
        value
      }
      media {
        id
        variantId
        variantOptionId
        optionValue
        sortOrder
        isThumbnail
        isBanner
        image
      }
      assignedProductAttributes {
        id
        attribute {
          id
          title {
            id
            text {
              langCode
              content
            }
          }
          description {
            id
            text {
              langCode
              content
            }
          }
          no
          image
          icon
          sortOrder
          isFeatured
          isFeaturedAttributeTitle
        }
      }
      variants {
        id
        options
        variant {
          id
          title {
            id
            text {
              langCode
              content
            }
          }
          options {
            id
            value
            sortOrder
            title {
              id
              text {
                langCode
                content
              }
            }
          }
        }
      }
      optionValues {
        id
        isEnabled
        sku
        inventory
        barcode
        sortOrder
        optionValues
        variantIds
        optionValueIds
        modifiers {
          id
          type
          stringModifier
          priceChange {
            currency
            value
          }
        }
        media {
          id
          sortOrder
          isThumbnail
          isBanner
          image
        }
      }
      discounts {
        discount {
          id
          canBeCombinedWithOtherDiscounts
          availableFrom
          availableUntil
          enabled
          hasCoupon
          discountType
          discountValue
          discountCurrency
          discountRules {
            id
            operator
            discountRuleObjectType
            discountRuleCondition
          }
        }
      }
      customizationSettings {
        customizationOptionGroups {
          id
          logic
          title {
            id
            text {
              langCode
              content
            }
          }
          options {
            id
            type
            title {
              id
              text {
                langCode
                content
              }
            }
            pricingRules {
              id
              linkedType
              minOrderQuantity
              calculationType
              formula {
                priority
                price {
                  currency
                  value
                }
                qty
                sqm
              }
            }
          }
        }
      }
    }
  }
`
export const GET_CUSTOM_PRODUCT = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      isCustomProduct
      hasPersonalization
      inventory
      sku
      slugs {
        langCode
        content
      }
      title {
        id
        text {
          langCode
          content
        }
      }
      description {
        id
        text {
          langCode
          content
        }
      }
      price {
        currency
        value
      }
      media {
        id
        variantId
        variantOptionId
        optionValue
        sortOrder
        isThumbnail
        isBanner
        image
      }
      assignedProductAttributes {
        id
        attribute {
          id
          title {
            id
            text {
              langCode
              content
            }
          }
          description {
            id
            text {
              langCode
              content
            }
          }
          no
          image
          icon
          sortOrder
          isFeatured
          isFeaturedAttributeTitle
        }
      }
      variants {
        id
        options
        variant {
          id
          title {
            id
            text {
              langCode
              content
            }
          }
          options {
            id
            value
            sortOrder
            title {
              id
              text {
                langCode
                content
              }
            }
          }
        }
      }
      optionValues {
        id
        isEnabled
        sku
        inventory
        barcode
        sortOrder
        optionValues
        variantIds
        optionValueIds
        modifiers {
          id
          type
          stringModifier
          priceChange {
            currency
            value
          }
        }
        media {
          id
          sortOrder
          isThumbnail
          isBanner
          image
        }
      }
      discounts {
        discount {
          id
          canBeCombinedWithOtherDiscounts
          availableFrom
          availableUntil
          enabled
          hasCoupon
          discountType
          discountValue
          discountCurrency
          discountRules {
            id
            operator
            discountRuleObjectType
            discountRuleCondition
          }
        }
      }
    }
  }
`
export const RECOMMENDED_PRODUCTS = gql`
  query recommendedProducts(
    $productId: ID!
    $salesChannelId: ID!
    $limit: Int!
  ) {
    recommendedProducts(
      productId: $productId
      salesChannelId: $salesChannelId
      limit: $limit
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          no
          slugs {
            langCode
            content
          }
          media {
            id
            productId
            variantId
            variantOptionId
            optionValue
            image
            isThumbnail
          }
          title {
            id
            text {
              langCode
              content
            }
          }
        }
      }
    }
  }
`

export const GET_PRODUCT_PACKAGE_PRICE_VARIANTS = gql`
  query productPackagePriceVariants($id: ID!) {
    productPackage(id: $id) {
      id
      accountingId
      productIds
      products {
        id
        no
        media {
          id
          productId
          variantId
          variantOptionId
          optionValue
          sortOrder
          isThumbnail
          isBanner
          image
        }
        title {
          id
          text {
            langCode
            content
          }
        }
        assignedProductAttributes {
          attribute {
            id
            title {
              id
              text {
                langCode
                content
              }
            }
            description {
              id
              text {
                langCode
                content
              }
            }
            no
            sortOrder
            isFeatured
            isFeaturedAttributeTitle
            image
            icon
          }
        }
        variants {
          id
          options
          variant {
            id
            title {
              id
              text {
                langCode
                content
              }
            }
            options {
              id
              value
              sortOrder
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
          }
        }
        optionValues {
          id
          isEnabled
          sku
          inventory
          barcode
          sortOrder
          optionValues
          variantIds
          optionValueIds
          modifiers {
            id
            type
            stringModifier
            priceChange {
              currency
              value
            }
          }
          media {
            id
            sortOrder
            isThumbnail
            isBanner
            image
          }
        }
      }
      price {
        currency
        value
        includesTax
        taxPercent
      }
      accountingId
      slugs {
        content
        langCode
      }
      status
      media {
        id
        productId
        variantId
        variantOptionId
        optionValue
        sortOrder
        isThumbnail
        isBanner
        image
      }
      title {
        id
        text {
          langCode
          content
        }
      }
      description {
        id
        text {
          langCode
          content
        }
      }
      salesChannels {
        productPackageId
        salesChannelId
        salesChannel {
          id
          name
          title {
            id
            text {
              langCode
              content
            }
          }
        }
      }
      collections {
        collection {
          id
          no
          slugs {
            langCode
            content
          }
          title {
            id
            text {
              langCode
              content
            }
          }
        }
      }
      discounts {
        discount {
          id
          canBeCombinedWithOtherDiscounts
          availableFrom
          availableUntil
          enabled
          hasCoupon
          discountType
          discountValue
          discountCurrency
          discountRules {
            id
            operator
            discountRuleObjectType
            discountRuleCondition
          }
        }
      }
    }
  }
`
export const GET_PRODUCT_PRICE_VARIANTS = gql`
  query productPriceVariants($id: ID!) {
    product(id: $id) {
      id
      inventory
      sku
      price {
        currency
        value
      }
      variants {
        id
        options
        variant {
          id
          title {
            id
            text {
              langCode
              content
            }
          }
          options {
            id
            value
            sortOrder
            title {
              id
              text {
                langCode
                content
              }
            }
          }
        }
      }
      optionValues {
        id
        isEnabled
        sku
        inventory
        barcode
        sortOrder
        optionValues
        variantIds
        optionValueIds
        modifiers {
          id
          type
          stringModifier
          priceChange {
            currency
            value
          }
        }
        media {
          id
          sortOrder
          isThumbnail
          isBanner
          image
        }
      }
      discounts {
        discount {
          id
          canBeCombinedWithOtherDiscounts
          availableFrom
          availableUntil
          enabled
          hasCoupon
          discountType
          discountValue
          discountCurrency
          discountRules {
            id
            operator
            discountRuleObjectType
            discountRuleCondition
          }
        }
      }
      customizationSettings {
        customizationOptionGroups {
          id
          logic
          title {
            id
            text {
              langCode
              content
            }
          }
          options {
            id
            type
            title {
              id
              text {
                langCode
                content
              }
            }
            pricingRules {
              id
              linkedType
              minOrderQuantity
              calculationType
              formula {
                priority
                price {
                  currency
                  value
                }
                qty
                sqm
              }
            }
          }
        }
      }
    }
  }
`
