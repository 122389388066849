import React, { useState } from 'react';
import {
  createStyles,
  withStyles,
  WithStyles,
  Typography,
  Button,
  Grid
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { Query } from 'react-apollo';
import _ from 'lodash';

import client from '../../../gql/admin-apollo-config';
import {
  ADMIN_UPDATE_SHOP_CONTENT_SETTINGS,
  CONTENT_UPDATE_MENU_LOCATION,
  CONTENT_DELETE_MENU_LOCATION
} from '../../../gql/admin-mutations';
import {
  ADMIN_SHOP_COLLECTIONS,
  ADMIN_SHOP_PRODUCTS,
  ADMIN_GET_CONTENT_MENU
} from '../../../gql/admin-queries';
import { NAVIGATION_LINK_INPUTS } from './mutationInputs';
import { generateSubIds } from '../../content/utils';
import {
  recalculateSorts,
  removeKeysBeforeMutation
} from '../../../../../helper/menuHelper';
import AddMenuLink from './AddMenuLink';
import EditMenu from './EditMenu';
import EditMenuSettings from './EditMenuSettings';
import EditMenuItemStyling from './EditMenuItemStyling';
import DeleteMenu from './DeleteMenu';

const styles = () =>
  createStyles({
    button: {
      margin: '0 10px'
    },
    menuContainer: {
      background: 'white',
      padding: '20px'
    }
  });

interface Props extends WithStyles<typeof styles> {
  counter: number;
  data: any;
  nav: any;
  language: string;
  languages: [string];
  pages: any[];
}

const MenuContainer: React.FunctionComponent<Props> = props => {
  const {
    classes,
    counter,
    data,
    language,
    languages,
    loading,
    nav,
    pages,
    shopId
  } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogStylingOpen, setDialogStylingOpen] = useState(false);
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);
  const [itemEditing, setItemEditing] = useState(null);
  const [itemStyling, setItemStyling] = useState(null);

  if (loading)
    return (
      <Grid item={true} xs={6}>
        <Grid
          container={true}
          justify="flex-start"
          alignContent="flex-start"
          className={classes.menuContainer}
        />
      </Grid>
    );

  const save = async (
    clonedData: any,
    clonedNav: any,
    input: any
  ): Promise<any> => {
    let newData = _.cloneDeep(input);

    await client.mutate({
      mutation: CONTENT_UPDATE_MENU_LOCATION,
      optimisticResponse: {
        __typename: 'Mutation',
        updateContentMenuLocation: {
          id: clonedNav.id,
          ...input
        }
      },
      variables: {
        id: clonedNav.id,
        input: removeKeysBeforeMutation(newData)
      },
      update: (cache, { data: { updateContentMenuLocation } }) => {
        // const data = cache.readQuery({
        //   query: ADMIN_GET_CONTENT_MENU,
        //   variables: {
        //     id: shopId
        //   }
        // });
        // data.shop.contentSettings.menus = data.shop.contentSettings.menus.filter(
        //   m => {
        //     return m.id !== clonedNav.id;
        //   }
        // );
        // cache.writeQuery({
        //   query: ADMIN_GET_CONTENT_MENU,
        //   data,
        //   variables: {
        //     id: shopId
        //   }
        // });
      }
    });
  };

  const handleSaveLink = async (
    linkData: any,
    isEdit?: boolean
  ): Promise<any> => {
    if (isEdit) {
      return editLink(linkData);
    } else {
      return addLink(linkData);
    }
  };

  const addLink = async (linkData: any): Promise<any> => {
    let navInput = _.cloneDeep(NAVIGATION_LINK_INPUTS);
    navInput.id = uuid();
    navInput = generateSubIds(navInput, ['linkTitle']);
    navInput.sortOrder = nav.length + 1;
    if (linkData.pageId) {
      navInput.pageId = linkData.pageId;
    }
    if (linkData.parentId) {
      navInput.parentId = linkData.parentId;
    }
    if (linkData.collectionId) {
      navInput.collectionId = linkData.collectionId;
    }
    if (linkData.productId) {
      navInput.productId = linkData.productId;
    }
    if (linkData.moduleId) {
      navInput.moduleId = linkData.moduleId;
    }
    if (linkData.linkTitle.text && linkData.linkTitle.text.length > 0) {
      navInput.linkTitle.text = linkData.linkTitle.text;
    }
    if (linkData.externalLinks && linkData.externalLinks.length > 0) {
      navInput.externalLinks = linkData.externalLinks;
    }

    const input = {
      __typename: 'ContentMenuLocation',
      menuLocation: nav.menuLocation,
      salesChannelId: nav.salesChannelId,
      sortOrder: nav.sortOrder,
      contentMenu: {
        ...nav.contentMenu,
        navigationLinks: recalculateSorts(
          nav.contentMenu && nav.contentMenu.navigationLinks
            ? [...nav.contentMenu.navigationLinks, navInput]
            : [navInput]
        )
      }
    };

    await save(linkData, nav, input);
  };

  const editLink = async (linkData: any): Promise<any> => {
    const input = {
      __typename: 'ContentMenuLocation',
      menuLocation: nav.menuLocation,
      salesChannelId: nav.salesChannelId,
      sortOrder: nav.sortOrder,
      contentMenu: {
        ...nav.contentMenu,
        navigationLinks: nav.contentMenu.navigationLinks.map(link => {
          return linkData.id === link.id ? linkData : link;
        })
      }
    };

    await save(linkData, nav, input);
  };

  const handleEditSettings = async (
    value: string,
    fieldName: string
  ): Promise<any> => {
    const clonedNav = JSON.parse(JSON.stringify(nav));
    const clonedData = JSON.parse(JSON.stringify(data));

    const input = {
      __typename: 'ContentMenuLocation',
      menuLocation: clonedNav.menuLocation,
      salesChannelId: clonedNav.salesChannelId,
      sortOrder: clonedNav.sortOrder,
      contentMenu: {
        ...clonedNav.contentMenu,
        [fieldName]: value
      }
    };

    await save(clonedData, clonedNav, input);
  };

  const handleEdit = async (
    deletedId: string | null,
    swapped: any[]
  ): Promise<any> => {
    const clonedNav = JSON.parse(JSON.stringify(nav));
    const clonedData = JSON.parse(JSON.stringify(data));

    const prevNavLinks =
      clonedNav.contentMenu && clonedNav.contentMenu.navigationLinks
        ? clonedNav.contentMenu.navigationLinks
        : [];
    let newNavLinks = prevNavLinks;
    if (deletedId) {
      newNavLinks = newNavLinks.filter(o => deletedId !== o.id);
    }

    if (swapped && swapped.length > 0) {
      swapped.forEach(swap => {
        const newSortOrders = { 0: null, 1: null };
        newNavLinks.forEach((link: any) => {
          if (link.id === swap[0]) {
            newSortOrders[0] = newNavLinks.find(
              o => o.id === swap[0]
            ).sortOrder;
          } else if (link.id === swap[1]) {
            newSortOrders[1] = newNavLinks.find(
              o => o.id === swap[1]
            ).sortOrder;
          }
        });
        if (newSortOrders[0] && newSortOrders[1]) {
          newNavLinks.find(o => o.id === swap[0]).sortOrder = newSortOrders[1];
          newNavLinks.find(o => o.id === swap[1]).sortOrder = newSortOrders[0];
        }
      });
    }

    const sortedNavLinks = recalculateSorts(newNavLinks);

    const input = {
      __typename: 'ContentMenuLocation',
      menuLocation: clonedNav.menuLocation,
      salesChannelId: clonedNav.salesChannelId,
      sortOrder: clonedNav.sortOrder,
      contentMenu: {
        ...clonedNav.contentMenu,
        navigationLinks: sortedNavLinks
      }
    };

    await save(clonedData, clonedNav, input);
  };

  const handleItemAlign = async (
    id: string,
    value: string | null
  ): Promise<any> => {
    const clonedNav = JSON.parse(JSON.stringify(nav));
    const clonedData = JSON.parse(JSON.stringify(data));

    const prevNavLinks =
      clonedNav.contentMenu && clonedNav.contentMenu.navigationLinks
        ? clonedNav.contentMenu.navigationLinks
        : [];
    const newNavLinks = prevNavLinks.map(o => {
      o.alignment =
        o.id === id
          ? value
          : o.alignment && o.alignment !== value
          ? o.alignment
          : null;
      return o;
    });

    const input = {
      __typename: 'ContentMenuLocation',
      menuLocation: clonedNav.menuLocation,
      salesChannelId: clonedNav.salesChannelId,
      sortOrder: clonedNav.sortOrder,
      contentMenu: {
        ...clonedNav.contentMenu,
        navigationLinks: newNavLinks
      }
    };

    await save(clonedData, clonedNav, input);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setItemEditing(null);
  };

  const handleEditOpen = (item: any) => {
    setItemEditing(item);
    setDialogOpen(true);
  };

  const handleStylingOpen = (item: any) => {
    setItemStyling(item);
    setDialogStylingOpen(true);
  };

  const handleDialogStylingClose = () => {
    setDialogStylingOpen(false);
    setItemStyling(null);
  };

  const handleSaveStyling = async (
    id: string,
    dataStyling: any
  ): Promise<any> => {
    const clonedNav = _.cloneDeep(nav);
    const clonedData = _.cloneDeep(data);

    const prevNavLinks =
      clonedNav.contentMenu && clonedNav.contentMenu.navigationLinks
        ? clonedNav.contentMenu.navigationLinks
        : [];
    const navInputStyling = _.cloneDeep(dataStyling);

    const newNavLinks = prevNavLinks.map(o => {
      if (o.id === id) {
        o.styling = navInputStyling;
      }
      return o;
    });

    const input = {
      __typename: 'ContentMenuLocation',
      menuLocation: clonedNav.menuLocation,
      salesChannelId: clonedNav.salesChannelId,
      sortOrder: clonedNav.sortOrder,
      contentMenu: {
        ...clonedNav.contentMenu,
        navigationLinks: newNavLinks
      }
    };

    await save(clonedData, clonedNav, input);
  };

  const handleDialogDeleteOpen = () => {
    setDialogDeleteOpen(true);
  };

  const handleDialogDeleteClose = () => {
    setDialogDeleteOpen(false);
  };

  const handleDelete = async (): Promise<any> => {
    const clonedNav = _.cloneDeep(nav);

    await client.mutate({
      mutation: CONTENT_DELETE_MENU_LOCATION,
      variables: {
        id: clonedNav.id
      },
      update: (cache, { data: { deleteContentMenuLocation } }) => {
        // const data = cache.readQuery({
        //   query: ADMIN_GET_CONTENT_MENU,
        //   variables: {
        //     id: shopId
        //   }
        // });
        // data.shop.contentSettings.menus = data.shop.contentSettings.menus.filter(
        //   m => {
        //     return m.id === clonedNav.id ? false : true;
        //   }
        // );
        // cache.writeQuery({
        //   query: ADMIN_GET_CONTENT_MENU,
        //   data,
        //   variables: {
        //     id: shopId
        //   }
        // });
      }
    });
    window.location.reload();
  };

  return (
    <Grid item={true} xs={6}>
      <Grid
        container={true}
        justify="flex-start"
        alignContent="flex-start"
        className={classes.menuContainer}
      >
        <Typography variant="h4">
          {nav.menuLocation} {counter}
        </Typography>

        {/* TODO merge into 1 query to speed up */}
        <Query
          query={ADMIN_SHOP_COLLECTIONS}
          variables={{
            id: shopId
          }}
        >
          {responseCollections => {
            return (
              <Query
                query={ADMIN_SHOP_PRODUCTS}
                variables={{
                  id: shopId
                }}
              >
                {responseProducts => {
                  return (
                    <AddMenuLink
                      languages={languages}
                      item={itemEditing}
                      pages={pages}
                      navigationLinks={nav.contentMenu.navigationLinks}
                      modules={data && data.shop ? data.shop.modules : []}
                      parentItems={nav.contentMenu.navigationLinks.filter(
                        (o: any) => !o.parentId
                      )}
                      collections={
                        responseCollections.data &&
                        responseCollections.data.shop
                          ? responseCollections.data.shop.collections
                          : []
                      }
                      products={
                        responseProducts.data && responseProducts.data.shop
                          ? responseProducts.data.shop.products
                          : []
                      }
                      open={dialogOpen}
                      onClose={handleDialogClose}
                      onSave={handleSaveLink}
                    />
                  );
                }}
              </Query>
            );
          }}
        </Query>

        <EditMenuSettings
          contentMenu={nav.contentMenu}
          onSave={handleEditSettings}
        />

        <EditMenu
          elements={nav.contentMenu.navigationLinks}
          alignment={nav.contentMenu.alignment}
          onAlign={handleItemAlign}
          onEditOpen={handleEditOpen}
          onStylingOpen={handleStylingOpen}
          onSave={handleEdit}
          language={language}
        />

        <EditMenuItemStyling
          item={itemStyling}
          open={dialogStylingOpen}
          menuStyling={nav.contentMenu.styling || {}}
          onClose={handleDialogStylingClose}
          onSave={handleSaveStyling}
        />

        <DeleteMenu
          open={dialogDeleteOpen}
          onClose={handleDialogDeleteClose}
          onSave={handleDelete}
        />

        <div style={{ textAlign: 'center', width: '100%' }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleDialogOpen}
          >
            Add Link
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleDialogDeleteOpen}
          >
            Delete Menu
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(MenuContainer);
