import React, { Component } from 'react';
import * as ThreejsFunction from './ThreejsFunction';
import { Grid } from '@material-ui/core';

class Main3D extends Component {
  constructor(props) {
    super(props);

    // CLASS FOR SHOP DIFFERENT
    this.class3D = 'Main3D';
    this.getBounding = '.Main3D';
    if (props.DesignRoot._APP_TYPE === '2d-3d') {
      this.class3D = '';
      this.getBounding = '#PLANE_3D';
    }

    this.capture3D = false;
  }

  componentDidMount() {
    // AFTER 1ST RENDER

    if (
      this.props.DesignRoot._UI_ONLY === true ||
      this.props.DesignRoot._3D_BUILD === false
    ) {
      // this.props.DesignRoot.canvasInit();
      this.props.DesignRoot.Loading.activeLoading(false);
      this.props.DesignRoot.tools.renderComponent('DesignRoot');
      this.props.DesignRoot.props.MainTemplate.setState({ loading: false });
      return;
    }

    ThreejsFunction.initComponent(this);
    ThreejsFunction.declareVar();
    ThreejsFunction.threejsINIT();
    ThreejsFunction.initComponent(this);
    this.ThreejsFunction = ThreejsFunction;
  }

  onWindowResize() {
    try {
      // statements

      this.THREEJS_WIDTH = document.querySelector(this.getBounding).offsetWidth;
      this.THREEJS_HEIGHT = document.querySelector(
        this.getBounding
      ).offsetHeight;

      this.camera.aspect = this.THREEJS_WIDTH / this.THREEJS_HEIGHT;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.THREEJS_WIDTH, this.THREEJS_HEIGHT);
      this.renderer_virtual.setSize(this.THREEJS_WIDTH, this.THREEJS_HEIGHT);
    } catch (e) {
      // statements
      console.error('Main3D.onWindowResize.error', e);
    }
  }

  render() {
    return (
      <div className={this.class3D}>
        <div id="threejs_container" />
      </div>
    );
  }
}

export default Main3D;
