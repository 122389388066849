import React, { Component } from 'react';
import ControlTools from './ControlTools';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import Select from '../../common/Select';
import { API_STAGE } from '~/brikl-config';

class LayerControl extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot;
    DesignRoot.LayerControl = this;
    DesignRoot.setState({ update: true });
    // PROVIDE COMPONENT TO MAIN
  }

  selectItem(item) {

    try {

      console.log('selectItem', item);
      var DesignRoot = this.props.DesignRoot;

      if (item._DEFAULT_ITEM && item._DEFAULT_ITEM === true) {
        item.setControlsVisibility({
          tl: false,
          tr: false,
          bl: false,
          br: false,
          mt: false,
          mb: false
        });
      } else {
        item.setControlsVisibility({
          tl: true,
          tr: true,
          bl: true,
          br: false,
          mt: false,
          mb: false
        });
      }

      DesignRoot.canvas.setActiveObject(item);
      DesignRoot.canvas.requestRenderAll();
      DesignRoot.Selection.setState({
        selection: item.id,
        selectionObject: item
      });

    } catch (e) {

      console.error('LayerControl.selectItem.error', e);
    }

    
  }

  itemsList(type) {
    try {
      // statements

      const DesignRoot = this.props.DesignRoot;
      const control_tools_event = DesignRoot.control_tools_event;

      if (
        DesignRoot.canvas === null ||
        DesignRoot.LogoStep === null ||
        DesignRoot.TextStep === null
      ) {
        return;
      }

      if (type && (type === 'LOGO' || type === 'TEXT')) {
        var itemSET = DesignRoot.fabric_function.getObjectByType(type);
      } else {
        var itemSET = DesignRoot.canvas.getObjects();
      }

      var activeObject = DesignRoot.canvas.getActiveObject();
      var activeObjectId = null;

      if ( activeObject ) { activeObjectId = activeObject.id; }

      var itemDOM = [];

      let hasPrintingTechniques = false;

      for (var i = itemSET.length - 1; i >= 0; i--) {
        // REVERT LOOP FOR CORRECT STACK ITEM

        if (itemSET[i]._OBJECT_TYPE && itemSET[i]._OBJECT_TYPE === 'LOGO') {
          let logoPrintingTechniques = [];
          try {
            this.props.logoConfiguration.logoPrintingTechniques.forEach(
              printingTechnique => {
                if (printingTechnique.id) {
                  hasPrintingTechniques = true;
                  logoPrintingTechniques.push(
                    <option>
                      {
                        printingTechnique.customName.text.find(
                          text => text.langCode === this.props.langCode
                        ).content
                      }
                    </option>
                  );
                }
              }
            );
          } catch (error) {}

          var selectionBorder = "1px solid transparent";
          if ( activeObjectId === itemSET[i].id ) {

            selectionBorder = "1px solid #000";
          }

          itemDOM.push(
            <span key={itemSET[i].id}>
              <Grid
                container
                style={{
                  width: '100%',
                  overflow: 'hidden',
                  height: '100%',
                  cursor: 'pointer',
                  marginBottom: 32,
                }}
                spacing={2}
                alignItems="center"
                onClick={this.selectItem.bind(this, itemSET[i])}
              >
                <Grid
                  item
                  xs={4}
                  style={{
                    flex: 1,
                    padding: '8px',
                    height: 66,
                    border: '1px solid #E5E5E5',
                    borderRadius: '4px',
                    padding: 1,
                    border: selectionBorder
                  }}

                >
                  <img
                    src={itemSET[i]._USER_DATA.base64}
                    alt=""
                    style={{
                      objectFit: 'contain',
                      height: '100%',
                      width: '100%'
                    }}
                  />
                </Grid>
                <Grid item xs={8} style={{padding: '0px 0px 0px 8px'}}>
                  {itemSET[i]._DEFAULT_ITEM &&
                  itemSET[i]._DEFAULT_ITEM === true ? (
                    <ControlTools
                      selectId={itemSET[i].id}
                      visible={itemSET[i].visible}
                      onRemove={control_tools_event.__remove.bind(this)}
                      onMirror={control_tools_event.__mirror.bind(this)}
                    />
                  ) : (
                    <ControlTools
                      selectId={itemSET[i].id}
                      lock={itemSET[i].lockMovementX}
                      visible={itemSET[i].visible}
                      onDuplicate={control_tools_event.__duplicate.bind(this)}
                      onMirror={control_tools_event.__mirror.bind(this)}
                      onRemove={control_tools_event.__remove.bind(this)}
                      onRePosition={control_tools_event.__rePosition.bind(this)}
                      onMoveTo={control_tools_event.__moveTo.bind(this)}
                      onToggleLock={control_tools_event.__toggleLock.bind(this)}
                      // onToggleVisible={control_tools_event.__toggleVisible.bind(
                      //   this
                      // )}
                    />
                  )}
                </Grid>

                {hasPrintingTechniques === true ? (
                  <div>
                    <Grid item xs={7}>
                      <h3>Printing technique</h3>
                    </Grid>
                    <Grid item xs={12}>
                      <Select>{logoPrintingTechniques}</Select>
                    </Grid>
                  </div>
                ) : null}
              </Grid>
            </span>
          );
        } else if (
          itemSET[i]._OBJECT_TYPE &&
          itemSET[i]._OBJECT_TYPE === 'TEXT'
        ) {
          let textPrintingTechniques = [];
          try {
            this.props.textConfiguration.textPrintingTechniques.forEach(
              printingTechnique => {
                textPrintingTechniques.push(
                  <option>
                    {
                      printingTechnique.customName.text.find(
                        text => text.langCode === this.props.langCode
                      ).content
                    }
                  </option>
                );
              }
            );
          } catch (error) {}

          var selectionBorder = "1px solid transparent";
          if ( activeObjectId === itemSET[i].id ) {

            selectionBorder = "1px solid #000";
          }

          // WHITE TEXT PREVIEW
          var bgPreview = '#FFFFFF';

          if (
            itemSET[i]._USER_DATA.outlineSize === 0 &&
            itemSET[i]._USER_DATA.textColor.toUpperCase() === '#FFFFFF'
          ) {
            bgPreview = '#000000';
          } else if (
            itemSET[i]._USER_DATA.outlineColor.toUpperCase() === '#FFFFFF' &&
            itemSET[i]._USER_DATA.outlineSize !== 0
          ) {
            bgPreview = '#000000';
          }

          itemDOM.push(
            <span key={itemSET[i].id}>
              <Grid
                container
                style={{
                  width: '100%',
                  overflowX: 'hidden',
                  height: 'fit-content',
                  borderBottom: '1px solid #e5e5e5',
                  paddingBottom: '16px',
                  paddingTop: '16px',
                  cursor: 'pointer',
                  marginBottom: 32,
                }}
                alignItems="center"
                spacing={2}
                onClick={this.selectItem.bind(this, itemSET[i])}
              >
                <Grid
                  item
                  xs={4}
                  style={{
                    flex: 1,
                    padding: '8px',
                    height: 66,
                    border: '1px solid #E5E5E5',
                    borderRadius: '4px',
                    padding: 1,
                    backgroundColor: bgPreview,
                    border: selectionBorder
                  }}
                >
                  <img
                    src={itemSET[i]._USER_DATA.base64}
                    alt=""
                    style={{
                      objectFit: 'contain',
                      height: '100%',
                      width: '100%',

                    }}
                  />
                </Grid>
                <Grid item xs={8} style={{padding: '0px 0px 0px 8px'}}>
                  {itemSET[i]._DEFAULT_ITEM &&
                  itemSET[i]._DEFAULT_ITEM === true ? (
                    <ControlTools
                      selectId={itemSET[i].id}
                      visible={itemSET[i].visible}
                      onRemove={control_tools_event.__remove.bind(this)}
                      onMirror={control_tools_event.__mirror.bind(this)}
                    />
                  ) : (
                    <ControlTools
                      selectId={itemSET[i].id}
                      lock={itemSET[i].lockMovementX}
                      visible={itemSET[i].visible}
                      onDuplicate={control_tools_event.__duplicate.bind(this)}
                      onMirror={control_tools_event.__mirror.bind(this)}
                      onRemove={control_tools_event.__remove.bind(this)}
                      onRePosition={control_tools_event.__rePosition.bind(this)}
                      onMoveTo={control_tools_event.__moveTo.bind(this)}
                      onToggleLock={control_tools_event.__toggleLock.bind(this)}
                      // onToggleVisible={control_tools_event.__toggleVisible.bind(
                      //   this
                      // )}
                    />
                  )}
                </Grid>

                {hasPrintingTechniques === true ? (
                  <div>
                    <Grid item xs={7}>
                      <h3>Printing technique</h3>
                    </Grid>
                    <Grid item xs={12}>
                      <Select>{textPrintingTechniques}</Select>
                    </Grid>
                  </div>
                ) : null}
              </Grid>
            </span>
          );
        }
      } // END FOR

      return itemDOM;
    } catch (e) {
      // statements
      console.error('LayerControl.itemsList.error', e);
    }
  }

  render() {
    const { type } = this.props;

    if (type) {
      var renderDom = this.itemsList(type);
    } else {
      var renderDom = (
        <Grid
          container
          style={{
            paddingTop: 24,
            paddingLeft: 24,
            paddingRight: 24,
            overflowY: 'auto'
          }}
        >
          <Grid container style={{ marginBottom: 20 }}>
            <Typography variant="subtitle1">Layer Controls</Typography>
          </Grid>

          {this.itemsList()}
        </Grid>
      );
    }

    return <div className="LayerControl">{renderDom}</div>;
  }
}

LayerControl.propTypes = {
  classes: PropTypes.object.isRequired
};

export default LayerControl;
