import { createMuiTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import Input from '@material-ui/core/Input';
import StepButton from '@material-ui/core/StepButton';
import { Stepper, Grid } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { SketchPicker } from 'react-color';
import './Admin.css';
import { SingleSelect } from 'react-select-material-ui';

//Color set here
export const primary = '#B23566';
export const secondary = '#62082b';

export const adminTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h1: {
      letterSpacing: 2.4,
      fontFamily: 'Roboto',
      fontSize: '45px'
    },
    h2: {
      letterSpacing: 2.4,
      fontFamily: 'Roboto'
    },
    h3: {
      letterSpacing: 2.4,
      fontFamily: 'Roboto'
    },
    h4: {
      fontFamily: 'Roboto',
      fontSize: '18px',
      lineHeight: '19px',
      letterSpacing: '1px',
      textTransform: 'uppercase'
    },
    h5: {
      letterSpacing: 2.4,
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '19px',
      textTransform: 'uppercase'
    },
    h6: {
      letterSpacing: 2.4,
      fontFamily: 'Roboto'
    },
    subtitle1: {
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 400,
      fontFamily: 'Roboto'
    },
    body1: {
      fontSize: '14px',
      fontWeight: 300,
      fontFamily: 'Roboto'
    }
  },
  palette: {
    primary: {
      main: primary
    },
    secondary: {
      main: secondary
    }
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none'
      }
    },
    MuiButton: {
      outlinedPrimary: {
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Roboto'
      },
      text: {
        fontSize: '14px',
        fontWeight: 300,
        fontFamily: 'Roboto',
        textTransform: 'none'
      }
    },
    MuiInputBase: {
      root: {
        'label + &': {
          marginTop: 8
        },
        width: '100%'
      },
      input: {
        borderRadius: 4,
        position: 'relative',
        border: '1px solid #ced4da',
        width: '100%',
        padding: '10px 24px 10px 8px !important',
        '&:before': {
          display: 'none'
        },
        '&:after': {
          display: 'none'
        }
      },
      multiline: {
        padding: 0
      }
    },
    MuiSlider: {
      root: {
        width: 160,
        marginTop: 20,
        borderRadius: 10
      },
      thumb: {
        backgroundColor: '#000000',
        height: 12,
        width: 12
      },
      track: {
        backgroundColor: '#E0E0E0'
      }
    },
    MuiSelect:{
      root:{
        borderRadius: '5px !important',
        fontFamily: 'Roboto',
      }
    },
    MuiNativeSelect: {
      root: {
        'label + &': {
          marginTop: 8
        },
        '&:before': {
          display: 'none'
        },
        '&:after': {
          display: 'none'
        },
        width: '100%',
        paddingRight: 16
      },
      select: {
        borderRadius: 4,
        position: 'relative',
        border: 'none',
        fontSize: 14,
        padding: '8px',
        transition: 'none',
        fontFamily: 'Roboto',
        width: '100%',
        '&:focus': {
          borderRadius: 4
        },
        '&:before': {
          display: 'none'
        },
        '&:after': {
          display: 'none'
        }
      }
    },
    MuiTextField: {
      root: {
        'label + &': {
          marginTop: 8
        },
        width: '100%',
        paddingRight: 0
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 14,
        fontWeight: 300 + '!important'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        fontWeight: 300,
        fontFamily: '"Roboto", Helvetica, Arial, sans-serif',
        minHeight: '40px',
        height: '40px',
        flex: '1 !important',
        '&:focus': {
          color: '#ffffff',
          background: '#2E2E31'
        },
        '&$selected': {
          background: '#2E2E31',
          color: '#ffffff',
          fontWeight: 700
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        fontFamily: 'Roboto'
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        fontFamily: 'Roboto'
        // backgroundColor: lightBlue.A200,
        // color: "white",
      }
    },
    MuiPickersDay: {
      day: {
        color: '#111'
      },
      isSelected: {
        backgroundColor: '#B23566'
      },
      current: {
        color: '#111'
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#111'
      }
    },
    MuiInput: {
      root: {
        width: '100%'
      },
      input: {
        width: '100%',
        fontSize: 12
      },
      underline: {
        width: '100%',
        '&:before': {
          display: 'none'
        },
        '&:after': {
          display: 'none'
        }
      }
    },
    // MuiTable: {},
    MuiTableCell: {
      body: {
        height: '49px',
        fontWeight: 300
      }
    },
    MuiTableRow: {
      root: {
        height: '49px',
        fontWeight: 300
      }
    },
    MuiList: {
      root: {
        width: '100%'
      }
    }
  }
});

export const MuiSelect = styled(SingleSelect)`
&& {
  div{
    border: none !important;
    background: #ffffff;
    padding-bottom: 0px;
    padding-top: 0px;
  }
  div:hover {
    border-bottom: 0px
  }
  div:focus{
    border-bottom: 0px
  }
  font-family: 'Roboto';
  border: none;
  background: transparent;
  height: 37px;
  font-weight: 400;
  line-height: 16px;
  fontSize: 11px;
  width: 100%;
  border-bottom: 0px;
  margin-left: 4px;
  &:hover {
    border-bottom: 0px;
  }
  input:before: {
    border-bottom: 0px;
  }
  input:after {
    border-bottom: 0px;
  }
  .control:hover
    border-bottom: 0px;
  }
}
`
