import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import Select from '../../common/Select';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
const styles = theme => ({
  colorSwatch: {
    background: '#BBBBBB',
    width: 27,
    height: 27,
    border: '3px solid #BBBBBB',
    borderRadius: '100%',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer'
    }
  },
  printThumbnail: {
    background: '#BBBBBB',
    width: 46,
    height: 46,
    border: '3px solid #BBBBBB',
    borderRadius: '6px',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer'
    }
  },
  slider: {
    padding: '8px 0px',
    width: '100%'
  }
});

class FabricStep extends Component {
  state = {};

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot;
    DesignRoot.FabricStep = this;
    DesignRoot.setState({ update: true });
    // PROVIDE COMPONENT TO MAIN
  }

  render() {
    const { classes, DesignRoot, productDataSet } = this.props;
    let fabrics = [];
    let fabricGroups = {};
    let fabricOptions = {};
    let productFabricGroup = {};
    try {
      productDataSet.elements.map(element => {
        console.log('element', element);
        if (element && element.fabricGroup) {
          const elementId = element.id;
          // TODO use filter instead
          if (element.fabricCompositions) {
            for (
              let index = 0;
              index < element.fabricCompositions.length;
              index++
            ) {
              const fabricComposition = element.fabricCompositions[index];
              var contents = '';
              var contentsLong = '';
              if (
                fabricComposition.fabricTypes &&
                fabricComposition.fabricTypes[0]
              ) {
                _sortBy(fabricComposition.fabricTypes[0].fabricContents, [
                  'percent'
                ])
                  .reverse()
                  // eslint-disable-next-line
                  .forEach(fabricContent => {
                    if (fabricContent) {
                      const myFabricContentLong =
                        fabricContent.percent +
                        '%' +
                        (fabricContent.textile
                          ? fabricContent.textile.textileMaterial
                          : 'N/A');
                      contentsLong =
                        contentsLong === ''
                          ? myFabricContentLong
                          : contentsLong + ' ' + myFabricContentLong;
                      const myFabricContent =
                        fabricContent.percent +
                        '% ' +
                        (fabricContent.textile
                          ? fabricContent.textile.code
                          : 'N/A');
                      contents =
                        contents === ''
                          ? myFabricContent
                          : contents + ' ' + myFabricContent;
                    }
                  });
              }
              const fabric = {
                id: fabricComposition.id,
                summary: fabricComposition.summary,
                colors: fabricComposition.colors,
                src: fabricComposition.swatchPreviewUrl,
                groupName: element.fabricGroup.name,
                name: fabricComposition.name,
                code: fabricComposition.code,
                content: contents,
                contentLong: contentsLong,
                construction: _get(
                  fabricComposition,
                  'fabricTypes[0].fabricConstructions[0]',
                  'N/A'
                ),
                weight: `${_get(
                  fabricComposition,
                  'weights[0].weight',
                  ''
                )}${_get(fabricComposition, 'weights[0].weightUnit', '')}`
              };
              if (!fabricOptions[element.fabricGroup.id]) {
                fabricOptions[element.fabricGroup.id] = {};
              }
              fabricOptions[element.fabricGroup.id][fabric.id] = fabric;

              if (!fabricGroups[element.fabricGroup.id]) {
                fabricGroups[element.fabricGroup.id] = {
                  elements: [],
                  elementsData: [],
                  fabricOptions: [fabric],
                  name: element.fabricGroup.name,
                  defaultFabric: null
                };
              } else {
                const currentFabric = _find(
                  fabricGroups[element.fabricGroup.id].fabricOptions,
                  o => {
                    return o.id === fabric.id;
                  }
                );
                if (!currentFabric) {
                  fabricGroups[element.fabricGroup.id].fabricOptions.push(
                    fabric
                  );
                }
              }
              if (fabric.id === element.defaultFabricId) {
                fabricGroups[element.fabricGroup.id].defaultFabric = fabric;
                activeColors.updateElementColor(
                  element.id,
                  fabricComposition.colors
                );
              }
            }
          } else {
            console.error(
              'elementId ' + elementId + ' has no fabricCompositions'
            );
          }

          if (fabricGroups[element.fabricGroup.id]) {
            fabricGroups[element.fabricGroup.id].elements.push(
              <option data-cy="fabric-overview" key={'li-' + elementId}>
                {element.name}
              </option>
            );
            fabricGroups[element.fabricGroup.id].elementsData.push(element);
          }
          console.log('fabricGroups', fabricGroups);

          //load selected fabric here
          if (element.selectedFabricId) {
            // ASSIGN MESH
            try {
              if (element.selectedFabricId === 'FCMP-62583981') {
                // this.props.DesignBoard.changeMESH(
                //   'https://d24ektica6xifs.cloudfront.net/fabric/mesh3.png',
                //   'Mesh 1',
                //   element.baseElementFullCode
                // );
              }
              if (element.selectedFabricId === 'FCMP-62584803') {
                // this.props.DesignBoard.changeMESH(
                //   'https://d24ektica6xifs.cloudfront.net/fabric/mesh3.png',
                //   'Mesh 2',
                //   element.baseElementFullCode
                // );
              }
            } catch (error) {
              console.error('StepFabric.initAll', error);
            }

            // END OF ASSIGN MESH

            productFabricGroup[element.fabricGroup.id] =
              fabricOptions[element.fabricGroup.id][element.selectedFabricId];

            try {
              activeColors.updateElementColor(
                element.id,
                fabricOptions[element.fabricGroup.id][element.selectedFabricId]
                  .colors
              );
            } catch (error) {
              console.error(error);
            }
          } else {
            if (element.defaultFabricId === 'FCMP-62583981') {
              // this.props.DesignBoard.changeMESH(
              //   'https://d24ektica6xifs.cloudfront.net/fabric/mesh3.png',
              //   'Mesh 1',
              //   element.baseElementFullCode
              // );
            }
            if (element.defaultFabricId === 'FCMP-62584803') {
              // this.props.DesignBoard.changeMESH(
              //   'https://d24ektica6xifs.cloudfront.net/fabric/mesh3.png',
              //   'Mesh 2',
              //   element.baseElementFullCode
              // );
            }
          }
        } else {
          console.error(
            'element ' +
              (element ? JSON.stringify(element.id) : '') +
              ' is undefined',
            error
          );
        }
      });
    } catch (error) {}

    // console.log('fabrics', fabrics);
    // console.log('fabricGroups', fabricGroups);
    // console.log('fabricOptions', fabricOptions);
    let fabricGroupList = [];
    let fabricCompositionGroups = {};
    try {
      if (fabricOptions) {
        try {
          Object.keys(
            fabricOptions[Object.keys(fabricOptions)[0]]
            // eslint-disable-next-line
          ).map((fabricOption, j) => {
            // console.log('fabricOption', fabricOption)
            const option =
              fabricOptions[Object.keys(fabricOptions)[0]][fabricOption];
            if (!fabricCompositionGroups[option.groupName]) {
              fabricCompositionGroups[option.groupName] = [];
            }
            console.log('option', option);

            fabricCompositionGroups[option.groupName].push(
              <option key={'fc-' + option.id} value={option.id}>
                {option.name}
              </option>
            );
          });
        } catch (error) {}
      }
    } catch (error) {}
    try {
      Object.keys(fabricCompositionGroups).map((fabricGroupName, j) => {
        fabricGroupList.push(
          <Grid item xs={12}>
            <Select label={fabricGroupName}>
              {fabricCompositionGroups[fabricGroupName].map(value => value)}
            </Select>
          </Grid>
        );
      });
    } catch (error) {}
    // console.log('fabricCompositionGroups', fabricCompositionGroups);
    return (
      <Grid container style={{ padding: 24, height: '100%' }}>
        <Grid item xs={12}>
          <Grid container style={{ margin: 0 }}>
            <Typography variant="subtitle1">Select a material</Typography>
          </Grid>
          <span>
            <Grid container style={{ margin: 0, marginTop: 16 }}>
              <Typography variant="subtitle1" />
            </Grid>
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              spacing={2}
              style={{ margin: 0, marginTop: 16, width: '100%' }}
            >
              {fabricGroupList}
            </Grid>
          </span>
        </Grid>
      </Grid>
    );
  }
}

FabricStep.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FabricStep);
