import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import {
  Grid,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core'
import Input from '../../common/Input'
import { CirclePicker } from 'react-color'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiExpansionPanelDetails)

interface Props {
  title: string
  inputLabel: string
  inputPlaceholder: string
  imgURL: string
}

const PersonalizationContainer: React.FunctionComponent<Props> = props => {
  const {
    title,
    inputLabel,
    inputPlaceholder,
    imgURL,
    DesignRoot,
    boundingData,
    radioActive,
    BOUNDING_TYPE,
    logoPreview,
    currentLogo,
    currentText
  } = props
  const [value, setValue] = React.useState(radioActive)
  const [content, setContent] = React.useState('')
  const [textColor, setColor] = React.useState('#000000')
  const [textBTN, setTextBTN] = React.useState('ADD')

  React.useEffect(() => {
    console.log('useEffect', radioActive, boundingData)
    setValue(radioActive)
    // changeLogoTextProcess(radioActive);
    console.log('currentText', currentText)

    if (currentText !== null) {
      setContent(currentText._USER_DATA.content)
      setColor(currentText._USER_DATA.textColor)
    }
  }, [boundingData, currentText, radioActive])

  function handleChange(event: React.ChangeEvent<unknown>) {

    try {

      event.persist()
      setValue((event.target as HTMLInputElement).value)
      changeLogoTextProcess(event.target.value)

    } catch (e) {

      console.error('PersonalizationContainer.handleChange.error', e);
    }

    
  }

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex'
      },
      formControl: {
        margin: theme.spacing(3)
      },
      group: {
        margin: theme.spacing(1, 0)
      },
      disabledItem: {
        height: '100%',
        width: '100%',
        background: '#fff',
        opacity: '0.5',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        borderRadius: '4px'
      },
      blurText: {
        filter: value === 'logo' ? 'blur(2px)' : null
      },
      blurLogo: {
        filter: value === 'text' ? 'blur(2px)' : null
      }
    })
  )

  const classes = useStyles()

  // FUNCTION

  const onClickRadio = option => {
    // console.log('onClickRadio', option, DesignRoot);
    // if ( option === value ) {
    //    var change = "logo";
    //   if ( option === 'logo' ) {
    //     change = "text";
    //   }
    //   changeLogoTextProcess(change);
    //   setValue(null);
    // }
  }

  const onUploadLogo = props => {
    console.log('onUploadLogo', props)

    try {
      const DesignRoot = props.DesignRoot
      const boundingData = props.boundingData

      var file_upload = document.getElementById(
        'file-upload-slt-' + boundingData.id
      )
      if (file_upload.files[0]) {
        var FILE_DATA = file_upload.files[0]
      } else {
        alert('Upload your logo')
        return
      }

      var file_name = FILE_DATA.name
      var file_size = FILE_DATA.size
      var file_type = FILE_DATA.type

      DesignRoot.tools.getBase64(FILE_DATA).then(data => {
        console.log(data)
        DesignRoot.LogoStep.uploadLogo(
          'file-upload-slt-' + boundingData.id,
          'LOGO',
          'ADD_TO_ZONE',
          boundingData
        )
      })
    } catch (e) {
      // statements
      console.error('PersonalizationContainer.onUploadLogo.error', e)
    }
  }

  const changeLogoTextProcess = type => {
    try {
      console.log('changeLogoTextProcess', type)

      const DesignRoot = props.DesignRoot
      const boundingData = props.boundingData

      const currentLogo = DesignRoot.fabric_function.get_item_in_placeholder(
        boundingData.id,
        'LOGO',
        'ITEM'
      )
      const currentText = DesignRoot.fabric_function.get_item_in_placeholder(
        boundingData.id,
        'TEXT',
        'ITEM'
      )

      if (type === 'logo') {
        if (currentLogo !== null) {
          currentLogo.set({ visible: true })
          DesignRoot.fabric_function.saveLogoOrText(currentLogo)
        }

        if (currentText !== null) {
          currentText.set({ visible: false })
          DesignRoot.fabric_function.saveLogoOrText(currentText)
        }

        DesignRoot.canvas.requestRenderAll()
        // UPDATE ICON CONTROL
        DesignRoot.tools.renderComponent('CONTROL_TOOLS')
      } else if (type === 'text') {
        if (currentLogo !== null) {
          currentLogo.set({ visible: false })
          DesignRoot.fabric_function.saveLogoOrText(currentLogo)
        }

        if (currentText !== null) {
          currentText.set({ visible: true })
          DesignRoot.fabric_function.saveLogoOrText(currentText)
          setTextBTN('REMOVE')
        } else {
          setTextBTN('ADD')

          // var initTextState = DesignRoot.TextStep.initState;
          // initTextState.content = "Sample";
          // DesignRoot.TextStep.applyTextByData('ADD_TO_ZONE', boundingData, initTextState);
        }

        DesignRoot.canvas.requestRenderAll()
        // UPDATE ICON CONTROL
        DesignRoot.tools.renderComponent('CONTROL_TOOLS')
      }
    } catch (e) {
      // statements
      console.error('PersonalizationContainer.changeLogoTextProcess.error', e)
    }
  }

  const onTextChange = e => {

    try {

      e.persist()

      console.log('onTextChange', e, e.target.value)

      setContent(e.target.value)

      const DesignRoot = props.DesignRoot
      const boundingData = props.boundingData
      const currentText = DesignRoot.fabric_function.get_item_in_placeholder(
        boundingData.id,
        'TEXT',
        'ITEM'
      )

      if (currentText !== null) {
        var textState = currentText._USER_DATA
        textState.content = e.target.value
        textState.selectTextId = currentText.id
        DesignRoot.TextStep.applyTextByData(
          'ADD_TO_ZONE',
          boundingData,
          textState
        )
      } else {
        // var initTextState = DesignRoot.TextStep.initState;
        // initTextState.content = "Sample";
        // DesignRoot.TextStep.applyTextByData('ADD_TO_ZONE', boundingData, initTextState);
        // DesignRoot.canvas.requestRenderAll();
        // // UPDATE ICON CONTROL
        // DesignRoot.tools.renderComponent('CONTROL_TOOLS');
      }

    } catch (e) {

      console.error('PersonalizationContainer.onTextChange.error', e);
    }

    
  }

  const changeTextColor = color => {

    try {

      console.log('changeTextColor', color)
      setColor(color.hex)
      const DesignRoot = props.DesignRoot
      const boundingData = props.boundingData
      const currentText = DesignRoot.fabric_function.get_item_in_placeholder(
        boundingData.id,
        'TEXT',
        'ITEM'
      )

      if (currentText !== null) {
        var textState = currentText._USER_DATA
        textState.textColor = color.hex
        textState.selectTextId = currentText.id
        DesignRoot.TextStep.applyTextByData(
          'ADD_TO_ZONE',
          boundingData,
          textState
        )
      }

    } catch (e) {

      console.error('PersonalizationContainer.changeTextColor.error', e);
    }


  }

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleExpansion = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false)
  }

  var logoStep = true
  var textStep = true
  if (BOUNDING_TYPE === 'LOGO') {
    textStep = false
  } else if (BOUNDING_TYPE === 'TEXT') {
    logoStep = false
  }

  const tabToggle = () => {

    try {

      console.log('tabToggle', currentText)

      if (currentText !== null) {
        setContent(currentText._USER_DATA.content)
        setColor(currentText._USER_DATA.textColor)
        setTextBTN('REMOVE')
      } else {
        setTextBTN('ADD')
      }

    } catch (e) {

      console.error('PersonalizationContainer.tabToggle.error', e);
    }

  }

  const onBTNClick = () => {

    try {

      if (currentText === null) {
        var initTextState = DesignRoot.TextStep.initState
        initTextState.content = content
        initTextState.textColor = textColor
        DesignRoot.TextStep.applyTextByData(
          'ADD_TO_ZONE',
          boundingData,
          initTextState
        )

        setTextBTN('REMOVE')
      } else if (currentText !== null) {
        // REMOVE TEXT

        DesignRoot.control_tools_event.__remove(currentText.id)
        setTextBTN('ADD')
        setContent('')

        if (currentLogo !== null) {
          changeLogoTextProcess('logo')
        }
      }

    } catch (e) {

      console.error('PersonalizationContainer.onBTNClick.error', e);
    }

    
  }

  // FUNCTION

  // DesignRoot.productName
  // Trisuit
  // SLT Product
  // Tri Suit - No Legs

  var textMsg = 'Personalize your text'
  var textPlaceholder = 'Enter your text here'
  var textType = 'text'

  if (
    DesignRoot.productName &&
    (DesignRoot.productName.indexOf('Trisuit') !== -1 ||
      DesignRoot.productName.indexOf('Tri Suit - No Legs') !== -1 ||
      DesignRoot.productName.indexOf('SLT Product') !== -1)
  ) {
    if (title.indexOf('Top Back Panel') !== -1) {
      textMsg = 'Add your name'
      textPlaceholder = 'Enter your name here'
      textType = 'text'
    } else {
      textMsg = 'Add your number'
      textPlaceholder = 'Enter your number here'
      textType = 'number'
    }
  }

  return (
    <ExpansionPanel
      square={true}
      expanded={expanded === 'panel'}
      onChange={handleExpansion('panel')}
    >
      <ExpansionPanelSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ExpandMoreIcon />}
        onClick={() => tabToggle()}
      >
        <Grid container={true}>
          <Typography variant={'subtitle1'}>{title}</Typography>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          className={classes.group}
          value={value}
          onChange={e => handleChange(e)}
        >
          <Grid container>
            {logoStep && (
              <Grid
                item={true}
                xs={12}
                alignItems="center"
                style={{ position: 'relative' }}
              >
                {textStep && logoStep ? (
                  <Grid container={true}>
                    <FormControlLabel
                      value="logo"
                      control={<Radio onClick={() => onClickRadio('logo')} />}
                      label="Upload your logo"
                    />
                  </Grid>
                ) : (
                  <Grid container={true}>Upload your logo</Grid>
                )}

                <Grid container={true} style={{ position: 'relative' }}>
                  {value === 'logo' ? null : (
                    <div className={classes.disabledItem} />
                  )}
                  <Grid
                    container={true}
                    alignItems="center"
                    style={{ height: '90px' }}
                  >
                    <Grid item={true} xs={7} className={classes.blurLogo}>
                      <input
                        type="file"
                        id={'file-upload-slt-' + boundingData.id}
                        style={{ display: 'none' }}
                        onChange={() => onUploadLogo(props)}
                      />
                      <label htmlFor={'file-upload-slt-' + boundingData.id}>
                        <Button variant="outlined" component="span">
                          upload
                        </Button>
                      </label>
                    </Grid>

                    <Grid
                      item={true}
                      xs={5}
                      style={{ padding: 0 }}
                      className={classes.blurLogo}
                    >
                      {logoPreview ? (
                        <Grid container={true}>
                          <div
                            style={{
                              width: '100%',
                              height: '40px',
                              border: '1px solid #e5e5e5',
                              padding: 8,
                              margin: '0px 0px 8px'
                            }}
                          >
                            <img
                              src={logoPreview}
                              alt={title}
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain'
                              }}
                            />
                          </div>
                          <Button
                            style={{ width: '100%' }}
                            variant="outlined"
                            onClick={() =>
                              DesignRoot.control_tools_event.__remove(
                                currentLogo.id
                              )
                            }
                          >
                            Delete
                          </Button>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {textStep && logoStep && (
              <Grid item={true} xs={12} style={{ margin: '24px 0' }}>
                <Grid container justify="center" alignItems="center">
                  <Typography>- OR -</Typography>
                </Grid>
              </Grid>
            )}

            {textStep && (
              <Grid item={true} xs={12}>
                {textStep && logoStep ? (
                  <Grid container={true}>
                    <FormControlLabel
                      value="text"
                      control={<Radio onClick={() => onClickRadio('text')} />}
                      label={textMsg}
                    />
                  </Grid>
                ) : (
                  <Grid container={true}>{textMsg}</Grid>
                )}

                <Grid container={true} style={{ position: 'relative' }}>
                  {value === 'text' ? null : (
                    <div className={classes.disabledItem} />
                  )}
                  <Grid
                    container={true}
                    className={classes.blurText}
                    alignItems={'flex-end'}
                  >
                    <Grid item={true} xs={8}>
                      <Input
                        value={content}
                        placeholder={textPlaceholder}
                        padding="4px 8px"
                        borderRadius="3px"
                        width="100%"
                        onChange={e => onTextChange(e)}
                        type={textType}
                      />
                    </Grid>
                    <Grid item={true} xs={4} style={{ padding: '0px 0px 4px' }}>
                      <Button
                        variant={'outlined'}
                        style={{ height: '38px', width: '100%' }}
                        onClick={e => onBTNClick(e)}
                      >
                        {textBTN}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    container={true}
                    justify="center"
                    alignItems="center"
                    spacing={1}
                    style={{
                      margin: 0,
                      marginTop: 16,
                      padding: ' 8px 0px',
                      background: '#f2f2f2'
                    }}
                    className={classes.blurText}
                  >
                    <CirclePicker
                      triangle="hide"
                      colors={['#ffffff', '#000000']}
                      color={textColor}
                      onChangeComplete={color => changeTextColor(color)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </RadioGroup>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default PersonalizationContainer
