import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Input from '../../../common/Input'
import TextField from '../../../common/TextField'
import { Grid, Button, Typography, Paper, Checkbox } from '@material-ui/core'
import { Mutation } from 'react-apollo'
import { SEND_CONTACT_FORM } from '../../../../gql/mutations'
import { Localization } from '../../../../lang/localization'

const styles = theme => ({
  root: {
    flex: 1
  }
})

class ContactForm extends React.Component {
  state = {
    email: null,
    message: null,
    name: null,
    field: '',
    termsAccepted: false,
    sending: false
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  render() {
    const {
      classes,
      title,
      hasTerms,
      langCode,
      nameLabel,
      namePlaceholder,
      emailLabel,
      emailPlaceholder,
      contentLabel,
      contentPlaceholder,
      shopId
    } = this.props
    if (this.state.sent === true) {
      return <div>Sent</div>
    }
    return (
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12}>
          <Paper
            style={{ padding: 24, border: '1px solid #E5E5E5', height: '100%' }}
            elevation={0}
          >
            <Grid container alignItems="center" style={{ height: '100%' }}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="none"
                    value={this.state.name}
                    onChange={e =>
                      this.setState({
                        name: e.target.value
                      })
                    }
                    disableUnderline
                    label={nameLabel}
                    placeholder={namePlaceholder}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    autoComplete="none"
                    value={this.state.email}
                    onChange={e =>
                      this.setState({
                        email: e.target.value
                      })
                    }
                    disableUnderline
                    label={emailLabel}
                    placeholder={emailPlaceholder}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="none"
                    value={this.state.message}
                    onChange={e =>
                      this.setState({
                        message: e.target.value
                      })
                    }
                    rows={4}
                    label={contentLabel}
                    placeholder={contentPlaceholder}
                  />
                </Grid>
                <Grid
                  style={{
                    display: 'none'
                  }}
                  item
                  xs={12}
                >
                  <Input
                    autoComplete="none"
                    value={this.state.field}
                    onChange={e =>
                      this.setState({
                        field: e.target.value
                      })
                    }
                    type="email"
                    disableUnderline
                    label={emailLabel}
                    placeholder={emailPlaceholder}
                  />
                </Grid>
                {hasTerms ? (
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Checkbox
                        checked={this.state.termsAccepted}
                        onChange={e => {
                          this.setState({
                            termsAccepted: e.target.checked
                          })
                        }}
                        style={{
                          color: '#3f3f3f',
                          padding: '0px',
                          marginRight: 8
                        }}
                      />
                      <Typography variant="body1" style={{ fontSize: '14px' }}>
                        <a
                          target="_blank"
                          href={shopId === 'vermarc' ? '/en/privacy' : ''}
                          style={{ color: '#356998' }}
                        >
                          {Localization.get(
                            'i_have_read_and_have_approved_the_privacy_policy'
                          )}
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                ) : null}
                <Grid item xs={12} style={{ width: '100%' }}>
                  <Grid container justify="flex-end" alignItems="center">
                    <Mutation mutation={SEND_CONTACT_FORM}>
                      {sendContactForm => (
                        <Button
                          disabled={this.state.sending === true ? true : false}
                          onClick={async () => {
                            if (
                              this.state.email &&
                              this.state.email !== '' &&
                              this.state.name &&
                              this.state.name !== '' &&
                              this.state.message &&
                              this.state.message !== '' &&
                              ((hasTerms && this.state.termsAccepted) ||
                                !hasTerms) &&
                              this.state.field === ''
                            ) {
                              this.setState({
                                sending: true
                              })
                              const sendResult = await sendContactForm({
                                variables: {
                                  email: this.state.email,
                                  name: this.state.name,
                                  message: this.state.message,
                                  url: window.location.href,
                                  userAgent: navigator.userAgent,
                                  langCode: langCode
                                }
                              })

                              this.setState({
                                sent: sendResult.data.sendContactForm,
                                sending: false
                              })
                            } else {
                              alert('Fill all fields and accept the terms')
                              this.setState({
                                sending: false
                              })
                            }
                          }}
                          color="primary"
                          variant="contained"
                        >
                          {this.state.sending === true ? '...' : 'Send >'}
                        </Button>
                      )}
                    </Mutation>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}
ContactForm.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(ContactForm)
