import ws from 'ws'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { split, ApolloLink } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import fetch from 'isomorphic-fetch'
import { API_URL } from '../../../helper/config'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionQueryResultData from '../../../gql/fragmentTypes.json'
import { getBearer } from '../../../helper/getToken'
import { WebSocketLink } from 'apollo-link-ws'
import DebounceLink from 'apollo-link-debounce'

var jwtDecode = require('jwt-decode')

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})
const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: object => {
    if (object) {
      switch (object.__typename) {
        // case 'BoardProductDesign':
        // return object.__typename + ':' + object.boardProductId; // use `boardProductId` as the primary key for BoardProductDesign which equals id
        // case 'bar': return `bar:${object.blah}`; // use `bar` prefix and `blah` as the primary key
        default:
          return defaultDataIdFromObject(object) // fall back to default handling
      }
    } else {
      return null
    }
  }
})

const authLink = new setContext(async (_, sth) => {
  let headers = sth.headers
  try {
    const token = await getBearer()
    const shopId = window.location.pathname.split('/')[2]
    if (shopId !== 'inventory') {
      return {
        headers: {
          ...headers,
          'x-brikl-shop-id': shopId,
          authorization: token ? token : ''
          // 'Cache-Control': _.operationName === 'publicUser' || _.operationName === 'publicOrganisation' ? 'public, max-age=31536000' : null
        }
      }
    } else {
      throw new Error('Invalid shopId')
    }
  } catch (error) {
    throw new Error(error)
  }
})

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    try {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
          if (window && window.Sentry) {
            const screenId = Math.random().toString(36)
            // window.Sentry.captureException(message,{
            //   tags:{
            //     screenId: screenId,
            //     component: 'app',
            //     errortype: 'graphQLErrors',
            //     operation: operation && operation.operationName ? operation.operationName : operation ? JSON.stringify(operation) : '',
            //     response: response ? JSON.stringify(response) : ''
            //   }
            // });

            //take screenshot
            try {
              // takeScreenShotToS3(
              //   screenId,
              //   operation && operation.operationName ? '-graphQLErrors-'+operation.operationName : '-graphQLErrors',
              //   window.document.body
              // )
            } catch (error) {}
          }
        })
      if (networkError) {
        const screenId2 = Math.random().toString(36)
        if (window && window.Sentry) {
          // window.Raven.captureException(networkError,{
          //   tags:{
          //     screenId: screenId2,
          //     component: 'app',
          //     errortype: 'networkError',
          //     operation: operation && operation.operationName ? operation.operationName : operation ? JSON.stringify(operation) : '',
          //     response: response ? JSON.stringify(response) : ''
          //   }
          // });
        }
        //take screenshot
        try {
          // takeScreenShotToS3(
          //   screenId2,
          //   operation && operation.operationName ? '-networkError-'+operation.operationName : '-networkError',
          //   window.document.body
          // )
        } catch (error) {}
        console.error(`[Network error]:,`, networkError)
      }
    } catch (error) {
      console.error(`[onError error]: ${error}`)
    }
  }
)

const httpLink = createHttpLink({
  uri: API_URL,
  fetch
})

// Create a WebSocket link:
const wsLink =
  typeof window !== `undefined`
    ? new WebSocketLink({
        // uri: 'wss://staging-brikl-hasura.herokuapp.com/v1/graphql', //WS_URL
        // uri: 'wss://dby9o3hyj9.execute-api.ap-southeast-1.amazonaws.com/dev',
        uri:
          process.env.GATSBY_API_STAGE === 'production'
            ? 'wss://events.mybrikl.com/v1/graphql'
            : process.env.GATSBY_API_STAGE === 'staging'
            ? 'wss://staging.events.mybrikl.com/v1/graphql'
            : 'wss://dev.events.mybrikl.com/v1/graphql',
        options: {
          reconnectionAttempts: 5,
          reconnect: true,
          timeout: 30000,
          async connectionParams() {
            // console.log('sth', sth)
            // get the authentication token from local storage if it exists
            const token = await getBearer()
            console.log('token', token)
            return {
              headers: {
                Authorization: token
              }
            }
          },
          lazy: true
        },
        ws
      })
    : null

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const wsAndHttpLinks =
  typeof window !== `undefined`
    ? split(
        // split based on operation type
        ({ query }) => {
          const { kind, operation } = getMainDefinition(query)
          // console.log('operation', operation)
          return kind === 'OperationDefinition' && operation === 'subscription'
        },
        wsLink,
        httpLink
      )
    : httpLink
const DEFAULT_DEBOUNCE_TIMEOUT = 100
const debounceLink = new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT)
const client = new ApolloClient({
  cache,
  ssrMode: !typeof window !== `undefined`,
  link: debounceLink.concat(authLink.concat(errorLink.concat(wsAndHttpLinks))),
  name: 'ADMIN',
  version: 'v1'
})

export default client
