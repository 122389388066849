import { API_STAGE } from '~/brikl-config'

import * as template_control from '../../template_control'
import * as canvas_coloring from '../canvas_coloring'
import * as change_color from '../change_color'
import * as tools from '../tools'
import * as fabric_function from '../../FABRIC/fabric_function'
import * as svg_control from '../../FABRIC/svg_control'
import * as marker_layout_control from '../../FABRIC/marker_layout_control'
import * as keyboard_event from './keyboard_event'
import * as control_tools_event from './control_tools_event'
import * as save_load from './save_load'
import * as control_view from './control_view'
import * as mutate from '../../mutation_query/mutate'
import * as query from '../../mutation_query/query'
import * as local from '../../mutation_query/local'
import { getTextFromLanguage } from '../../../../helper/languageHelper'

let SHOP_ID = tools.getQueryStringValue('s')
let isViewMode = tools.getQueryStringValue('view')

var DesignRoot = null

export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot

  if (!SHOP_ID) {
    SHOP_ID = DesignRoot.SHOP_ID
  }

  console.log('API_STAGE', API_STAGE)
  console.log('SHOP_ID', SHOP_ID)
}

export function declare_var(props) {
  DesignRoot.productDesignId = props.productDesignId
  DesignRoot.ownedByOrganisationId = props.ownedByOrganisationId
  DesignRoot.productID = props.productID
  DesignRoot.onLoad = props.onLoad
  DesignRoot.loadData = props.loadData
  DesignRoot.templateID = props.templateID
  DesignRoot.productName = getTextFromLanguage(
    props.productDesign.product.title.text,
    props.langCode
  )

  DesignRoot.localData = props.localData
  DesignRoot.uiOnly = props.uiOnly
  DesignRoot.isSaveEnabled = !!!props.localData && !isViewMode

  console.log(
    'DesignTemplate constructor',
    props,
    DesignRoot.productDesignId,
    DesignRoot.ownedByOrganisationId,
    DesignRoot.productID,
    DesignRoot.onLoad,
    DesignRoot.loadData
  ) // proID

  // CONDITION INIT

  DesignRoot._ACTIVE_COLOR_ZONE = false
  DesignRoot._SVG_ACTIVE = true
  DesignRoot._SVG_FILL = false

  DesignRoot._DEFAULT_ITEM = false
  DesignRoot._DEFAULT_ITEM_SET = []
  DesignRoot._SPECIAL_ELM = false
  DesignRoot._SPECIAL_ELM_SET = []
  DesignRoot._SVG_BOUNDING = false
  DesignRoot._SVG_BOUNDING_SET = []
  DesignRoot._DEFAULT_TEMPLATE_ACTIVE = false
  DesignRoot._DEFAULT_TEMPLATE_SRC = null

  DesignRoot._BLANK_TEMPLATE_ACTIVE = false

  DesignRoot._LOGO_TOGGLE = false
  DesignRoot._SEAM_TOGGLE = false

  DesignRoot._ACTIVE_PERSONALIZATION = false
  DesignRoot._ACTIVE_LAYOUT = true
  DesignRoot._ACTIVE_FILL_STEP = true
  DesignRoot._ACTIVE_LOGO_STEP = true
  DesignRoot._ACTIVE_TEXT_STEP = true
  DesignRoot._ACTIVE_EXPORT_STEP = false

  DesignRoot._COLOR_PICKER = false
  DesignRoot._COLOR_PALETTE = true
  DesignRoot._DEFAULT_FLAG = null

  DesignRoot._SHOW_AOP_FLAG = false

  DesignRoot._FABRIC_STEP = false

  // CONDITION INIT

  // PERFORMANCE DEBUG

  DesignRoot._ACTIVE_DRAW_XY = true
  DesignRoot._3D_UPDATE = true
  DesignRoot._DISPATCH_EVENT = true
  DesignRoot._3D_BUILD = true
  DesignRoot._2D_BUILD = true

  // ADDITIONAL
  DesignRoot._SHOW_2D = false
  DesignRoot._UI_ONLY = false
  DesignRoot._DOT_ACTIVE = false
  DesignRoot._DEV_TOOL = false
  DesignRoot._TEXT_LOAD = false
  DesignRoot._TEXT_LIB_READY = false

  // PERFORMANCE DEBUG

  DesignRoot._GAP_Y = 0
  DesignRoot._GAP_X = 0
  DesignRoot._CANVAS_SIZE = 1

  DesignRoot._PXTOCM = 0
  DesignRoot._PXBASE = 0
  DesignRoot._CMBASE = 0

  DesignRoot._MAP_OVERLAY = null // DesignRoot IS FOR CANVAS DRAW TO MODEL (CANVAS TYPE)
  DesignRoot._SELECT_COLOR_ZONE = null
  DesignRoot._HOVER_COLOR_ZONE = null
  DesignRoot._ACTIVE_TEMPLATE_ID = null
  DesignRoot._SAVE_DATA = null
  DesignRoot._LOAD_DATA = null
  DesignRoot._ALL_LOAD_TEXT = false
  DesignRoot._ALL_LOAD_LOGO = false

  DesignRoot._STACK = []

  DesignRoot._CURRENT_TEMPLATE_SRC = null
  DesignRoot._CURRENT_LOGO_SRC = null
  DesignRoot._CURRENT_SEAM_SRC = null
  DesignRoot._CURRENT_SVG_SRC = null
  // SELECTION + GLOBAL DRAW RENDER VAR

  // STORE DATA
  DesignRoot.canvas = null
  DesignRoot._CANVAS_RENDER = null
  DesignRoot._OVERLAY_BASE = null
  DesignRoot._TRIGGER_EXPORT = false
  DesignRoot._TRIGGER_EXPORT_TYPE = 'TIFF'
  DesignRoot._TEXT_SET = []
  DesignRoot._LOGO_SET = []
  DesignRoot._S3_LOGO_SRC = {}
  DesignRoot._S3_AOP_SRC = {}
  DesignRoot._SAVE_ID = props.SAVE_ID
  // STORE DATA

  // CONTROL CONDITION DATA
  DesignRoot._NOTIFICATION_ALERT = []
  DesignRoot._NOTIFICATION_ALERT['ADD_LOGO_TEXT'] = false
  // CONTROL CONDITION DATA

  condition_by_apptype()
  condition_by_shop()
  component_init()
  function_init()
  hacking()
}

export function condition_by_shop() {
  // DEFAULT
  // DesignRoot._ACTIVE_COLOR_ZONE = false;
  // DesignRoot._SVG_ACTIVE = true;

  // DesignRoot._DEFAULT_ITEM = false;
  // DesignRoot._DEFAULT_ITEM_SET = [];
  // DesignRoot._SPECIAL_ELM = false;
  // DesignRoot._SPECIAL_ELM_SET = [];
  // DesignRoot._SVG_BOUNDING = false;
  // DesignRoot._SVG_BOUNDING_SET = [];
  // DesignRoot._DEFAULT_TEMPLATE_ACTIVE = false;
  // DesignRoot._DEFAULT_TEMPLATE_SRC = null;

  // DesignRoot._BLANK_TEMPLATE_ACTIVE = false;

  // DesignRoot._LOGO_TOGGLE = false;
  // DesignRoot._SEAM_TOGGLE = false;

  // DesignRoot._ACTIVE_PERSONALIZATION = false;
  // DesignRoot._ACTIVE_LAYOUT = true;
  // DesignRoot._ACTIVE_FILL_STEP = true;
  // DesignRoot._ACTIVE_LOGO_STEP = true;
  // DesignRoot._ACTIVE_TEXT_STEP = true;
  // DesignRoot._ACTIVE_EXPORT_STEP = false;
  // DEFAULT

  console.log('condition_by_shop', SHOP_ID)

  if (SHOP_ID === 'vermarc') {
    DesignRoot._LOGO_TOGGLE = true
    DesignRoot._ACTIVE_COLOR_ZONE = true

    if (DesignRoot._APP_TYPE === '2d-3d') {
      DesignRoot._BLANK_TEMPLATE_ACTIVE = true;
    }
  } else if (SHOP_ID === 'bodywearlab') {
    DesignRoot._ACTIVE_COLOR_ZONE = true
    DesignRoot._ACTIVE_LOGO_STEP = false
    DesignRoot._ACTIVE_TEXT_STEP = false

    if (DesignRoot._APP_TYPE === '2d-3d') {
      DesignRoot._BLANK_TEMPLATE_ACTIVE = true;
    }
    
  } else if (SHOP_ID === 'OBS' || SHOP_ID === 'obs') {

    if ( DesignRoot.props.productDesign.product.defaultTemplateSrc !== null && DesignRoot.productID === 'e53381a1-8afe-4aef-a17c-c407431fbd61' ) {

      DesignRoot._ACTIVE_COLOR_ZONE = true
      DesignRoot._LOGO_TOGGLE = true

      DesignRoot._DEFAULT_ITEM = true
      DesignRoot._SPECIAL_ELM = true
      DesignRoot._SVG_BOUNDING = true
      DesignRoot._DEFAULT_TEMPLATE_ACTIVE = true
      DesignRoot._ACTIVE_PERSONALIZATION = true
      DesignRoot._ACTIVE_LAYOUT = false
      DesignRoot._ACTIVE_FILL_STEP = false
      DesignRoot._ACTIVE_LOGO_STEP = false
      DesignRoot._ACTIVE_TEXT_STEP = false

    }
    else {

      DesignRoot._ACTIVE_COLOR_ZONE = true
      DesignRoot._BLANK_TEMPLATE_ACTIVE = true
    }

    if (DesignRoot._APP_TYPE === '2d-3d') {
      DesignRoot._BLANK_TEMPLATE_ACTIVE = true;
    }

  } else if (SHOP_ID === 'xceed') {


    console.log("xceed shop load", DesignRoot.props.productDesign);


    if ( (process.env.GATSBY_BRIKL_SALESCHANNEL_ID && (process.env.GATSBY_BRIKL_SALESCHANNEL_ID === '372107d4-d535-4381-98fb-23b4ac9d7a1e' || process.env.GATSBY_BRIKL_SALESCHANNEL_ID === 'slt')) || DesignRoot.props.productDesign.product.defaultTemplateSrc !== null ) {
      // SLT
      console.log(
        'SHOP_CHECK SLT',
        SHOP_ID,
        process.env.GATSBY_BRIKL_SALESCHANNEL_ID
      )

      DesignRoot._SHOW_AOP_FLAG =
        [
          '6ac619c6-e140-4ce5-9597-83553e4d2156',
          '7b896c32-0c4d-465b-bb5a-46416841d09f'
        ].indexOf(DesignRoot.productID) !== -1

      if (
        DesignRoot.productName &&
        (DesignRoot.productName.indexOf('Trisuit') !== -1 ||
          DesignRoot.productName.indexOf('Tri Suit - No Legs') !== -1 ||
          DesignRoot.productName.indexOf('SLT Product') !== -1)
      ) {
        DesignRoot._ACTIVE_COLOR_ZONE = true
        DesignRoot._LOGO_TOGGLE = true
        DesignRoot._SHOW_AOP_FLAG = true
      }

      DesignRoot._DEFAULT_ITEM = true
      DesignRoot._SPECIAL_ELM = true
      DesignRoot._SVG_BOUNDING = true
      DesignRoot._DEFAULT_TEMPLATE_ACTIVE = true
      DesignRoot._ACTIVE_PERSONALIZATION = true
      DesignRoot._ACTIVE_LAYOUT = false
      DesignRoot._ACTIVE_FILL_STEP = false
      DesignRoot._ACTIVE_LOGO_STEP = false
      DesignRoot._ACTIVE_TEXT_STEP = false
      // DesignRoot._COLOR_PICKER = true;
      // DesignRoot._COLOR_PALETTE = false;
    } else {





      // XCEED
      console.log(
        'SHOP_CHECK XCEED',
        SHOP_ID,
        process.env.GATSBY_BRIKL_SALESCHANNEL_ID
      )
      DesignRoot._LOGO_TOGGLE = false
      DesignRoot._ACTIVE_COLOR_ZONE = true

      if (DesignRoot._APP_TYPE === '2d-3d') {
        DesignRoot._BLANK_TEMPLATE_ACTIVE = true;
      }
    }





  } else if (SHOP_ID === 'hdfast') {
    DesignRoot._ACTIVE_COLOR_ZONE = true
    DesignRoot._COLOR_PICKER = true
    DesignRoot._COLOR_PALETTE = false
    DesignRoot._BLANK_TEMPLATE_ACTIVE = true

    if (DesignRoot._APP_TYPE === '2d-3d') {
      DesignRoot._BLANK_TEMPLATE_ACTIVE = true;
    }
  } else if (SHOP_ID === 'gkm') {
    DesignRoot._ACTIVE_COLOR_ZONE = true
    DesignRoot._COLOR_PICKER = true
    DesignRoot._COLOR_PALETTE = false
    DesignRoot._BLANK_TEMPLATE_ACTIVE = true

    if (DesignRoot._APP_TYPE === '2d-3d') {
      DesignRoot._BLANK_TEMPLATE_ACTIVE = true;
    }
  } else if (SHOP_ID === 'demo') {
    DesignRoot._LOGO_TOGGLE = true
    DesignRoot._ACTIVE_COLOR_ZONE = true

    if (DesignRoot._APP_TYPE === '2d-3d') {
      DesignRoot._BLANK_TEMPLATE_ACTIVE = true;
    }
  } else if (SHOP_ID === 'zoot') {

    DesignRoot._ACTIVE_COLOR_ZONE = true

    if (DesignRoot._APP_TYPE === '2d-3d') {
      DesignRoot._BLANK_TEMPLATE_ACTIVE = true;
    }
  } else if (SHOP_ID === 'hydrodynamic') {

    DesignRoot._ACTIVE_COLOR_ZONE = true

    if (DesignRoot._APP_TYPE === '2d-3d') {
      DesignRoot._BLANK_TEMPLATE_ACTIVE = true;
    }
  } else if ( SHOP_ID === 'assos' ) {

    DesignRoot._ACTIVE_COLOR_ZONE = true
    DesignRoot._COLOR_PICKER = true;

    if (DesignRoot._APP_TYPE === '2d-3d') {
      DesignRoot._BLANK_TEMPLATE_ACTIVE = true;
    }
  } else {

    DesignRoot._ACTIVE_COLOR_ZONE = true

    if (DesignRoot._APP_TYPE === '2d-3d') {
      DesignRoot._BLANK_TEMPLATE_ACTIVE = true;
    }
  }
}

export function condition_by_apptype() {
  var appTypeQuery = tools.getQueryStringValue('apptype')
  DesignRoot._APP_TYPE = appTypeQuery // be-cretive // 2d-3d // obs

  if (DesignRoot._APP_TYPE === '2d-3d') {
    DesignRoot.canvasMainDom = 'canvasRender'
    DesignRoot._ACTIVE_EXPORT_STEP = true
  } else {
    DesignRoot.canvasMainDom = 'canvasEditor'
  }

  if (DesignRoot.localData === true && DesignRoot._APP_TYPE === '2d-3d') {
    require('../byPass.css')
  }
}

export function component_init() {
  // REACT COMPONENT
  DesignRoot.Main3D = null
  DesignRoot.FillStep = null
  DesignRoot.LayoutStep = null
  DesignRoot.Loading = null
  DesignRoot.Selection = null
  DesignRoot.TextStep = null
  DesignRoot.LogoStep = null
  DesignRoot.PatternStep = null
  DesignRoot.Dimension = null
  DesignRoot.AopStep = null
  DesignRoot.StackLegend = null
  DesignRoot.BottomBar = null
  DesignRoot.MarkerLayout = null
  DesignRoot.MarkerLayoutPlane = null
  DesignRoot.LayerControl = null
  DesignRoot.ViewControl = null
  DesignRoot.MenuStep = null
  DesignRoot.MenuDesignRoomStep = null
  DesignRoot.PersonalizationStep = null
  // REACT COMPONENT
}

export function function_init() {
  // FUNCTION COMPONENT
  DesignRoot.template_control = template_control // CALL ANYWHERE
  DesignRoot.canvas_coloring = canvas_coloring // CALL ANYWHERE
  DesignRoot.change_color = change_color // CALL ANYWHERE
  DesignRoot.tools = tools // CALL ANYWHERE
  DesignRoot.fabric_function = fabric_function // CALL ANYWHERE
  DesignRoot.svg_control = svg_control // CALL ANYWHERE
  DesignRoot.marker_layout_control = marker_layout_control
  DesignRoot.keyboard_event = keyboard_event
  DesignRoot.control_tools_event = control_tools_event
  DesignRoot.save_load = save_load
  DesignRoot.control_view = control_view
  DesignRoot.mutate = mutate
  // FUNCTION COMPONENT

  function_init_call()
  keyboard_event.initKeyEvent()
}

export function function_init_call() {
  template_control.initComponent(DesignRoot)
  canvas_coloring.initComponent(DesignRoot)
  change_color.initComponent(DesignRoot)
  tools.initComponent(DesignRoot)
  fabric_function.initComponent(DesignRoot)
  svg_control.initComponent(DesignRoot)
  marker_layout_control.initComponent(DesignRoot)
  keyboard_event.initComponent(DesignRoot)
  control_tools_event.initComponent(DesignRoot)
  save_load.initComponent(DesignRoot)
  control_view.initComponent(DesignRoot)
  mutate.initComponent(DesignRoot)
}

export function control_fabric_threejs() {
  DesignRoot.mouseDown = false
  DesignRoot.targetControl = null
  DesignRoot.targetObject = null
  DesignRoot.targetObjectPos = null
  DesignRoot.rectPos = null

  DesignRoot.onAddLogo = false
  DesignRoot.onAddLogoObject = null

  DesignRoot.targetObjectHover = null
}

export function canvascolor_fabricjs_prepare() {
  // INIT ZONE COLOR PARAMETER

  try {
    // statements

    DesignRoot.imageLoad = new Image()
    DesignRoot.logoLoad = new Image()

    DesignRoot.titleZoneColor = []
    DesignRoot.defaultZoneColor = []
    DesignRoot.defaultZoneColorObject = []
    DesignRoot.defaultZoneColorMap = []
    DesignRoot.currentChangeColor = []
    DesignRoot.collectColorPosition = []
    DesignRoot.collectOtherColorPosition = []

    DesignRoot.hoverColor = '#ff0000'
    DesignRoot.bucketStatus = false
    DesignRoot.bucketColor = '#FFFFFF'

    // INIT ZONE COLOR PARAMETER

    for (var i = 0; i < DesignRoot.productDataSet.TEMPLATE_SET.length; i++) {
      if (
        DesignRoot.productDataSet.TEMPLATE_SET[i].templateId ===
        DesignRoot._ACTIVE_TEMPLATE_ID
      ) {
        DesignRoot.currentTemplateSET =
          DesignRoot.productDataSet.TEMPLATE_SET[i]
        DesignRoot._CURRENT_TEMPLATE_SRC =
          DesignRoot.productDataSet.TEMPLATE_SET[i].templateSrc
        DesignRoot._CURRENT_LOGO_SRC =
          DesignRoot.productDataSet.TEMPLATE_SET[i].templateLogo
        // DesignRoot._LOGO_TOGGLE = false;
        break
      }
    }

    if (DesignRoot._OVERLAY_BASE !== null) {
      DesignRoot.canvas.remove(DesignRoot._OVERLAY_BASE)
      DesignRoot._OVERLAY_BASE = null
    }
  } catch (e) {
    // statements
    console.error('design_template.canvascolor_fabricjs_prepare.error', e)
  }
}

export function canvascolor_fabricjs_init() {
  // PREPARE INIT CANVAS

  try {
    // statements

    canvascolor_fabricjs_prepare()

    if (DesignRoot._ACTIVE_COLOR_ZONE === true && DesignRoot._ACTIVE_TEMPLATE_ID !== 'BLANK') {
      canvas_coloring.prepareVarColorZone(DesignRoot.currentTemplateSET)

      if (DesignRoot.onLoad === true) {
        var mapColor = DesignRoot._LOAD_DATA.mapColor

        DesignRoot.defaultZoneColor.forEach(function(element, index) {
          console.log('mapColor', element, mapColor[element])

          var big = element.toUpperCase()
          var small = element.toLowerCase()

          if (mapColor[big]) {
            DesignRoot.currentChangeColor[index] = mapColor[big]
          }

          if (mapColor[small]) {
            DesignRoot.currentChangeColor[index] = mapColor[small]
          }
        })

        console.log(
          'canvasInit load',
          DesignRoot.defaultZoneColor,
          DesignRoot.defaultZoneColorObject,
          DesignRoot.currentChangeColor
        )
      }

      canvas_coloring.initCanvasColor(
        DesignRoot._CURRENT_TEMPLATE_SRC,
        DesignRoot._CURRENT_LOGO_SRC
      )
    } else if (
      DesignRoot._DEFAULT_TEMPLATE_ACTIVE === true &&
      DesignRoot._DEFAULT_TEMPLATE_SRC !== null
    ) {
      DesignRoot.imageLoad.crossOrigin = 'Anonymous'
      DesignRoot.imageLoad.onload = function() {
        DesignRoot.fabric_function.addTemplate(
          'template',
          DesignRoot.tools._wrapTimeToSrc(DesignRoot._DEFAULT_TEMPLATE_SRC),
          0,
          0
        )

        if (DesignRoot.onLoad === true) {
          DesignRoot.onLoad = false
        }

        DesignRoot.Loading.activeLoading(false)
        DesignRoot.tools.renderComponent('DesignRoot')
        DesignRoot.props.MainTemplate.setState({ loading: false })
      }
      DesignRoot.imageLoad.src = DesignRoot.tools._wrapTimeToSrc(
        DesignRoot._DEFAULT_TEMPLATE_SRC
      )
    } else {
      DesignRoot.imageLoad.onload = function() {
        DesignRoot.imageLoad.width = 3000

        DesignRoot.fabric_function.addBlankTemplate(
          'template',
          DesignRoot.imageLoad.width,
          DesignRoot.imageLoad.height,
          0,
          0
        )

        DesignRoot.fabric_function.addSeam(
          'template',
          DesignRoot._CURRENT_SEAM_SRC,
          0,
          0
        )

        if (DesignRoot.onLoad === true) {
          DesignRoot.onLoad = false
        }

        DesignRoot.Loading.activeLoading(false)
        DesignRoot.tools.renderComponent('DesignRoot')
        DesignRoot.props.MainTemplate.setState({ loading: false })
      }
      DesignRoot.imageLoad.src = DesignRoot._CURRENT_SVG_SRC
    }
  } catch (e) {
    // statements
    console.error('design_template.canvascolor_fabricjs_init.error', e)
  }

  // PREPARE INIT CANVAS
}

export function hacking() {
  if (DesignRoot.uiOnly === true) {
    DesignRoot._ACTIVE_COLOR_ZONE = false
    DesignRoot._ACTIVE_DRAW_XY = false
    DesignRoot._3D_UPDATE = false
    DesignRoot._DISPATCH_EVENT = false
    DesignRoot._3D_BUILD = false
    DesignRoot._2D_BUILD = false

    // ADDITIONAL
    DesignRoot._SHOW_2D = false
    DesignRoot._UI_ONLY = true
  }

  var devTool = tools.getQueryStringValue('devTool')
  var colorZone = tools.getQueryStringValue('colorZone')
  var renderTexture = tools.getQueryStringValue('renderTexture')
  var antialias = tools.getQueryStringValue('antialias')
  var localColor = tools.getQueryStringValue('localColor')
  var normalMap = tools.getQueryStringValue('normalMap')
  var redLine = tools.getQueryStringValue('redLine')
  var showCoord = tools.getQueryStringValue('showCoord')

  if (devTool === 'true') {
    DesignRoot._DOT_ACTIVE = true
    DesignRoot._DEV_TOOL = true
  }

  if (colorZone === 'false') {
    DesignRoot._ACTIVE_COLOR_ZONE = false
  }

  if (renderTexture === 'false') {
    DesignRoot._3D_UPDATE = false
  }

  DesignRoot._ANTIALIAS = true
  if (antialias === 'false') {
    DesignRoot._ANTIALIAS = false
  }

  DesignRoot._LOCAL_COLOR = false
  if (localColor === 'true') {
    DesignRoot._LOCAL_COLOR = true
  }

  DesignRoot._NORMAL_MAP = false
  if (normalMap === 'true') {
    DesignRoot._NORMAL_MAP = true
  }

  if (redLine === 'true') {
    DesignRoot._SEAM_TOGGLE = true
  }

  DesignRoot._SHOW_COORD = false
  if (showCoord === 'true') {
    DesignRoot._SHOW_COORD = true
  }
}
