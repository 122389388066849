import gql from 'graphql-tag'
import { navigate } from '@reach/router'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import client from '../../gql/admin-apollo-config'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import OpenInNew from '@material-ui/icons/OpenInNew'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import { Mutation, Query } from 'react-apollo'
import Button from '@material-ui/core/Button'
import {
  CONTENT_CREATE_ROW,
  CONTENT_CREATE_COLUMN,
  CONTENT_CREATE_COLUMN_ROW
} from '../../gql/admin-mutations'
import {
  GET_PAGE_CONTENT,
  ADMIN_SHOP_COLLECTIONS,
  ADMIN_SHOP_PRODUCTS,
  ADMIN_GET_CONTENT_THEME,
  ADMIN_SHOP_TEAMSTORES
} from '../../gql/admin-queries'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import SelectCollections from './SelectCollections'
import SelectProducts from './SelectProducts'
import SelectTeamstores from './SelectTeamstores'
import { getTextFromLanguage } from '../../../../helper/languageHelper'

import {
  TEXT_INPUT,
  BUTTON_INPUT,
  IMAGE_INPUT,
  INPUTFIELD_INPUT,
  MEDIA_INPUT,
  SOCIAL_MEDIA_INPUT,
  CARD_INPUT,
  DIVIDER_INPUT,
  SUBSCRIBE_CARD_INPUT,
  LOCATION_CARD_INPUT,
  CONTACT_CARD_INPUT,
  BANNER_INPUT,
  DISCOVER_CARD_INPUT,
  HUBSPOTFORM_CARD_INPUT
} from './MutationInputs'
import PageSlugsContainer from './page/PageSlugsContainer'
import DeleteColumnRow from './DeleteColumnRow'
import DeleteColumn from './DeleteColumn'
import DeleteRow from './DeleteRow'
import LanguageSwitch from './LanguageSwitch'
import ResponsiveViewSwitch from './ResponsiveViewSwitch'
import { generateSubIds, useTheme, slugWithUrlPrefix } from './utils'
import AdminSearchProduct from '../common/AdminSearchProduct'
import _remove from 'lodash/remove'

const uuidv4 = require('uuid/v4')
const drawerWidth = 240

const styles = theme => ({
  appBar: {
    left: 240,
    display: 'flex',
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: 'white',
    borderBottom: '1px solid #d5d5d5',
    height: 66
  }
})

const ProductPreview = ({ productId, langCode }) => {
  return (
    <Query
      variables={{ id: productId }}
      query={gql`
        query product($id: ID!) {
          product(id: $id) {
            id
            title {
              id
              text {
                langCode
                content
              }
            }
          }
        }
      `}
    >
      {({ loading, error, data }) => {
        if (loading) return <>{productId}</>
        if (error) return `Error!`
        let title = productId
        try {
          title = getTextFromLanguage(data.product.title.text, langCode)
        } catch (error) {}
        return <Grid item>{title}</Grid>
      }}
    </Query>
  )
}

class ContentAppBar extends Component {
  state = {
    open: false,
    openCollection: false,
    openProduct: false,
    openTeamstore: false,
    collectionIds: [],
    productIds: [],
    teamstoreIds: []
  }

  handleCollectionOpen = () => {
    this.setState({ openCollection: true })
  }

  handleCollectionClose = () => {
    this.setState({ openCollection: false })
  }

  handleProductOpen = () => {
    this.setState({ openProduct: true })
  }

  handleTeamstoreOpen = () => {
    this.setState({ openTeamstore: true })
  }

  handleTeamstoreClose = () => {
    this.setState({ openTeamstore: false })
  }

  handleProductClose = () => {
    this.setState({ openProduct: false })
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }))
  }

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return
    }

    this.setState({ open: false })
  }

  async UNSAFE_componentWillMount() {
    const theme = await client.query({
      query: ADMIN_GET_CONTENT_THEME,
      variables: { id: this.props.shopId }
    })
    if (
      theme.data.shop.contentSettings &&
      theme.data.shop.contentSettings.theme
    ) {
      this.setState({ themeData: theme.data.shop.contentSettings.theme })
    }
  }

  prepareInputData(input) {
    const { Template } = this.props
    input.sortOrder = Template.state.selectedCol
      ? Template.state.selectedCol.columnRows.length + 1
      : 1
    input.columnRowDetail.id = uuidv4()
    input.columnRowDetail = generateSubIds(input.columnRowDetail, [
      'text',
      'label',
      'placeholder',
      'description',
      'title',
      'callToAction',
      'termsContent',
      'content',
      'nameLabel',
      'namePlaceholder',
      'emailLabel',
      'emailPlaceholder',
      'contentLabel',
      'contentPlaceholder',
      'subTitle',
      'buttonText'
    ])

    const checkText =
      input.columnRowDetail.type === 'TEXT'
        ? 'h1'
        : input.columnRowDetail.type.toLowerCase()

    if (this.state.themeData && this.state.themeData[checkText]) {
      input = useTheme(
        input,
        this.state.themeData[checkText].style,
        input.columnRowDetail.type
      )
    }
    return input
  }

  handleCollectionChange = (value, checked) => {
    let newArray = this.state.collectionIds

    if (checked === true) {
      for (var i = 0; i < newArray.length; i++) {
        if (newArray[i] === value) {
          break
          return false
        }
      }

      newArray.push(value)
    } else {
      newArray = _remove(newArray, function(n) {
        return n !== value
      })
    }

    this.setState({ collectionIds: newArray })
  }

  handleProductChange = (value, checked) => {
    let newArray = this.state.productIds

    if (checked === true) {
      for (var i = 0; i < newArray.length; i++) {
        if (newArray[i] === value) {
          break
          return false
        }
      }

      newArray.push(value)
    } else {
      newArray = _remove(newArray, function(n) {
        return n !== value
      })
    }

    this.setState({ productIds: newArray })
  }

  handleTeamstoreChange = (value, checked) => {
    let newArray = this.state.teamstoreIds

    if (checked === true) {
      for (var i = 0; i < newArray.length; i++) {
        if (newArray[i] === value) {
          break
          return false
        }
      }

      newArray.push(value)
    } else {
      newArray = _remove(newArray, function(n) {
        return n !== value
      })
    }

    this.setState({ teamstoreIds: newArray })
  }

  render() {
    var {
      classes,
      pageId,
      slugs,
      Template,
      shopId,
      languages,
      shopUrl
    } = this.props
    const { open } = this.state
    var moduleMutation = ''

    const addColumnMutation = (
      <Mutation
        mutation={CONTENT_CREATE_COLUMN}
        variables={{
          pageId: pageId,
          rowId: Template.state.selectedRow,
          input: {
            sortOrder: Template.state.selectedRowObj
              ? Template.state.selectedRowObj.columns.length + 1
              : 1,
            direction: 'column',
            backgroundImg: null,
            columnRows: [],
            width: '100%',
            height: null,
            maxWidth: '1000px',
            padding: '0px'
          }
        }}
        update={(cache, { data: { createContentColumn } }) => {
          const { page } = cache.readQuery({
            query: GET_PAGE_CONTENT,
            variables: { id: pageId }
          })

          const rows = page.rows.map(rowData => {
            if (rowData.id === Template.state.selectedRow) {
              rowData.columns.push(createContentColumn)
            }
            return rowData
          })

          cache.writeQuery({
            query: GET_PAGE_CONTENT,
            variables: { id: pageId },
            data: {
              page: {
                ...page,
                rows
              }
            }
          })
        }}
        onCompleted={res => {
          Template.setState(
            {
              updateModule: true
            },
            this.setState({
              open: false,
              selectedRow: '',
              selectedCol: res.createContentColumn.id,
              selectedElement: '',
              selectedColRow: '',
              selectedRowObj: '',
              currentTab: 1,
              unsavedData: null
            })
          )
        }}
      >
        {createContentColumn => (
          <MenuItem data-cy="Add-Column" onClick={createContentColumn}>
            Column
          </MenuItem>
        )}
      </Mutation>
    )

    const addRowMutation = (
      <Mutation
        mutation={CONTENT_CREATE_ROW}
        update={(cache, { data: { createContentRow } }) => {
          const { page } = cache.readQuery({
            query: GET_PAGE_CONTENT,
            variables: { id: pageId }
          })
          cache.writeQuery({
            query: GET_PAGE_CONTENT,
            data: { page: page.rows.push(createContentRow) },
            variables: { id: pageId }
          })
        }}
        onCompleted={res => {
          Template.setState(
            {
              update: true,
              selectedRow: res.createContentRow.id
                ? res.createContentRow.id
                : '',
              selectedCol: '',
              selectedElement: '',
              selectedColRow: '',
              selectedRowObj: res.createContentRow
            },
            this.setState({ open: false })
          )
        }}
      >
        {createContentRow => (
          <MenuItem
            data-cy="Add-Container"
            onClick={() => {
              const { page } = client.readQuery({
                query: GET_PAGE_CONTENT,
                variables: { id: pageId }
              })
              createContentRow({
                variables: {
                  pageId: pageId,
                  input: {
                    sortOrder: page.rows.length + 1,
                    columns: 1
                  }
                }
              })
            }}
          >
            Row
          </MenuItem>
        )}
      </Mutation>
    )

    return (
      <AppBar position="fixed" className={classes.appBar}>
        {/* TODO pass or query page */}
        <Grid container alignItems="center" style={{ padding: 0, height: 66 }}>
          <Grid item>
            <Grid container justify="flex-start">
              <Toolbar style={{ justifyContent: 'center', minHeight: '20px' }}>
                <Tooltip title="Add">
                  <Button
                    data-cy="Add-Button"
                    onClick={this.handleToggle}
                    buttonRef={node => {
                      this.anchorEl = node
                    }}
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    aria-label="Add"
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>

                <Popper
                  open={open}
                  anchorEl={this.anchorEl}
                  transition
                  placement="bottom-start"
                  disablePortal
                  style={{ overflow: 'auto', maxHeight: 650 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      id="menu-list-grow"
                      style={{
                        transformOrigin:
                          placement === 'bottom-start'
                            ? 'center top'
                            : 'center bottom'
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={this.handleClose}>
                          <MenuList>
                            {!Template.state.selectedRow &&
                            !Template.state.selectedCol
                              ? addRowMutation
                              : null}
                            {Template.state.selectedRow &&
                            !Template.state.selectedCol ? (
                              <div>
                                {addColumnMutation}
                                {addRowMutation}
                              </div>
                            ) : null}
                            {Template.state.selectedCol &&
                            !Template.state.selectedRow ? (
                              <div>
                                <Mutation
                                  mutation={CONTENT_CREATE_COLUMN_ROW}
                                  variables={{
                                    columnId: Template.state.selectedCol.id,
                                    pageId: pageId,
                                    input: this.prepareInputData(BANNER_INPUT)
                                  }}
                                  update={(
                                    cache,
                                    { data: { createContentColumnRow } }
                                  ) => {
                                    const { page } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    })
                                    const colRows = page.rows.map(rowData => {
                                      // if (
                                      //   rowData.id ===
                                      //   Template.state.selectedRowPersist
                                      // ) {
                                      rowData.columns = rowData.columns.map(
                                        colData => {
                                          if (
                                            colData.id ===
                                            Template.state.selectedCol.id
                                          ) {
                                            colData.columnRows.push(
                                              createContentColumnRow
                                            )
                                          }
                                          return colData
                                        }
                                      )
                                      // }
                                      return rowData
                                    })

                                    cache.writeQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId },
                                      data: {
                                        page: {
                                          ...page,
                                          colRows
                                        }
                                      }
                                    })

                                    const { page2 } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    })
                                  }}
                                  onCompleted={() => {
                                    Template.setState(
                                      {
                                        updateModule: true
                                      },
                                      this.setState({ open: false })
                                    )
                                  }}
                                >
                                  {createContentColumnRow => (
                                    <MenuItem
                                      data-cy="Add-Banner"
                                      onClick={createContentColumnRow}
                                    >
                                      Banner
                                    </MenuItem>
                                  )}
                                </Mutation>

                                <Mutation
                                  mutation={CONTENT_CREATE_COLUMN_ROW}
                                  variables={{
                                    columnId: Template.state.selectedCol.id,
                                    pageId: pageId,
                                    input: this.prepareInputData(TEXT_INPUT)
                                  }}
                                  update={(
                                    cache,
                                    { data: { createContentColumnRow } }
                                  ) => {
                                    const { page } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    })
                                    const colRows = page.rows.map(rowData => {
                                      // if (
                                      //   rowData.id ===
                                      //   Template.state.selectedRowPersist
                                      // ) {
                                      rowData.columns = rowData.columns.map(
                                        colData => {
                                          if (
                                            colData.id ===
                                            Template.state.selectedCol.id
                                          ) {
                                            colData.columnRows.push(
                                              createContentColumnRow
                                            )
                                          }
                                          return colData
                                        }
                                      )
                                      // }
                                      return rowData
                                    })

                                    cache.writeQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId },
                                      data: {
                                        page: {
                                          ...page,
                                          colRows
                                        }
                                      }
                                    })

                                    const { page2 } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    })
                                  }}
                                  onCompleted={() => {
                                    Template.setState(
                                      {
                                        updateModule: true
                                      },
                                      this.setState({ open: false })
                                    )
                                  }}
                                >
                                  {createContentColumnRow => (
                                    <MenuItem
                                      data-cy="Add-Text"
                                      onClick={createContentColumnRow}
                                    >
                                      Text
                                    </MenuItem>
                                  )}
                                </Mutation>

                                <Mutation
                                  mutation={CONTENT_CREATE_COLUMN_ROW}
                                  variables={{
                                    columnId: Template.state.selectedCol.id,
                                    pageId: pageId,
                                    input: this.prepareInputData(IMAGE_INPUT)
                                  }}
                                  update={(
                                    cache,
                                    { data: { createContentColumnRow } }
                                  ) => {
                                    const { page } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    })

                                    const colRows = page.rows.map(rowData => {
                                      // if (
                                      //   rowData.id ===
                                      //   Template.state.selectedRowPersist
                                      // ) {
                                      rowData.columns = rowData.columns.map(
                                        colData => {
                                          if (
                                            colData.id ===
                                            Template.state.selectedCol.id
                                          ) {
                                            colData.columnRows.push(
                                              createContentColumnRow
                                            )
                                          }
                                          return colData
                                        }
                                      )
                                      // }
                                      return rowData
                                    })

                                    cache.writeQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId },
                                      data: {
                                        page: {
                                          ...page,
                                          colRows
                                        }
                                      }
                                    })
                                  }}
                                  onCompleted={() => {
                                    Template.setState(
                                      {
                                        updateModule: true
                                      },
                                      this.setState({ open: false })
                                    )
                                  }}
                                >
                                  {createContentColumnRow => (
                                    <MenuItem
                                      data-cy="Add-Image"
                                      onClick={createContentColumnRow}
                                    >
                                      Image
                                    </MenuItem>
                                  )}
                                </Mutation>

                                <Mutation
                                  mutation={CONTENT_CREATE_COLUMN_ROW}
                                  variables={{
                                    columnId: Template.state.selectedCol.id,
                                    pageId: pageId,
                                    input: this.prepareInputData(MEDIA_INPUT)
                                  }}
                                  update={(
                                    cache,
                                    { data: { createContentColumnRow } }
                                  ) => {
                                    const { page } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    })

                                    const colRows = page.rows.map(rowData => {
                                      // if (
                                      //   rowData.id ===
                                      //   Template.state.selectedRowPersist
                                      // ) {
                                      rowData.columns = rowData.columns.map(
                                        colData => {
                                          if (
                                            colData.id ===
                                            Template.state.selectedCol.id
                                          ) {
                                            colData.columnRows.push(
                                              createContentColumnRow
                                            )
                                          }
                                          return colData
                                        }
                                      )
                                      // }
                                      return rowData
                                    })

                                    cache.writeQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId },
                                      data: {
                                        page: {
                                          ...page,
                                          colRows
                                        }
                                      }
                                    })
                                  }}
                                  onCompleted={() => {
                                    Template.setState(
                                      {
                                        updateModule: true
                                      },
                                      this.setState({ open: false })
                                    )
                                  }}
                                >
                                  {createContentColumnRow => (
                                    <MenuItem
                                      data-cy="Add-Media"
                                      onClick={createContentColumnRow}
                                    >
                                      Media
                                    </MenuItem>
                                  )}
                                </Mutation>

                                <Mutation
                                  mutation={CONTENT_CREATE_COLUMN_ROW}
                                  variables={{
                                    columnId: Template.state.selectedCol.id,
                                    pageId: pageId,
                                    input: this.prepareInputData(BUTTON_INPUT)
                                  }}
                                  update={(
                                    cache,
                                    { data: { createContentColumnRow } }
                                  ) => {
                                    const { page } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    })

                                    const colRows = page.rows.map(rowData => {
                                      // if (
                                      //   rowData.id ===
                                      //   Template.state.selectedRowPersist
                                      // ) {
                                      rowData.columns = rowData.columns.map(
                                        colData => {
                                          if (
                                            colData.id ===
                                            Template.state.selectedCol.id
                                          ) {
                                            colData.columnRows.push(
                                              createContentColumnRow
                                            )
                                          }
                                          return colData
                                        }
                                      )
                                      // }
                                      return rowData
                                    })

                                    cache.writeQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId },
                                      data: {
                                        page: {
                                          ...page,
                                          colRows
                                        }
                                      }
                                    })
                                  }}
                                  onCompleted={() => {
                                    Template.setState(
                                      {
                                        updateModule: true
                                      },
                                      this.setState({ open: false })
                                    )
                                  }}
                                >
                                  {createContentColumnRow => (
                                    <MenuItem
                                      data-cy="Add-Button-Component"
                                      onClick={createContentColumnRow}
                                    >
                                      Button
                                    </MenuItem>
                                  )}
                                </Mutation>

                                <Mutation
                                  mutation={CONTENT_CREATE_COLUMN_ROW}
                                  variables={{
                                    columnId: Template.state.selectedCol.id,
                                    pageId: pageId,
                                    input: this.prepareInputData(
                                      INPUTFIELD_INPUT
                                    )
                                  }}
                                  update={(
                                    cache,
                                    { data: { createContentColumnRow } }
                                  ) => {
                                    const { page } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    })

                                    const colRows = page.rows.map(rowData => {
                                      // if (
                                      //   rowData.id ===
                                      //   Template.state.selectedRowPersist
                                      // ) {
                                      rowData.columns = rowData.columns.map(
                                        colData => {
                                          if (
                                            colData.id ===
                                            Template.state.selectedCol.id
                                          ) {
                                            colData.columnRows.push(
                                              createContentColumnRow
                                            )
                                          }
                                          return colData
                                        }
                                      )
                                      // }
                                      return rowData
                                    })

                                    cache.writeQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId },
                                      data: {
                                        page: {
                                          ...page,
                                          colRows
                                        }
                                      }
                                    })
                                  }}
                                  onCompleted={() => {
                                    Template.setState(
                                      {
                                        updateModule: true
                                      },
                                      this.setState({ open: false })
                                    )
                                  }}
                                >
                                  {createContentColumnRow => (
                                    <MenuItem
                                      data-cy="Add-Input-field"
                                      onClick={createContentColumnRow}
                                    >
                                      Input field
                                    </MenuItem>
                                  )}
                                </Mutation>

                                {/* Hide Instagram component until it's working */}

                                {/* <Mutation
                                  mutation={CONTENT_CREATE_COLUMN_ROW}
                                  variables={{
                                    columnId: Template.state.selectedCol.id,
                                    pageId: pageId,
                                    input: this.prepareInputData(
                                      SOCIAL_MEDIA_INPUT
                                    )
                                  }}
                                  update={(
                                    cache,
                                    { data: { createContentColumnRow } }
                                  ) => {
                                    const { page } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    });

                                    const colRows = page.rows.map(rowData => {
                                      // if (
                                      //   rowData.id ===
                                      //   Template.state.selectedRowPersist
                                      // ) {
                                      rowData.columns = rowData.columns.map(
                                        colData => {
                                          if (
                                            colData.id ===
                                            Template.state.selectedCol.id
                                          ) {
                                            colData.columnRows.push(
                                              createContentColumnRow
                                            );
                                          }
                                          return colData;
                                        }
                                      );
                                      // }
                                      return rowData;
                                    });

                                    cache.writeQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId },
                                      data: {
                                        page: {
                                          ...page,
                                          colRows
                                        }
                                      }
                                    });
                                  }}
                                  onCompleted={() => {
                                    Template.setState(
                                      {
                                        updateModule: true
                                      },
                                      this.setState({ open: false })
                                    );
                                  }}
                                >
                                  {createContentColumnRow => (
                                    <MenuItem
                                      data-cy="Add-Instagram"
                                      onClick={createContentColumnRow}
                                    >
                                      Instagram
                                    </MenuItem>
                                  )}
                                </Mutation> */}

                                <Mutation
                                  mutation={CONTENT_CREATE_COLUMN_ROW}
                                  variables={{
                                    columnId: Template.state.selectedCol.id,
                                    pageId: pageId,
                                    input: this.prepareInputData(CARD_INPUT)
                                  }}
                                  update={(
                                    cache,
                                    { data: { createContentColumnRow } }
                                  ) => {
                                    const { page } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    })

                                    const colRows = page.rows.map(rowData => {
                                      // if (
                                      //   rowData.id ===
                                      //   Template.state.selectedRowPersist
                                      // ) {
                                      rowData.columns = rowData.columns.map(
                                        colData => {
                                          if (
                                            colData.id ===
                                            Template.state.selectedCol.id
                                          ) {
                                            colData.columnRows.push(
                                              createContentColumnRow
                                            )
                                          }
                                          return colData
                                        }
                                      )
                                      // }
                                      return rowData
                                    })

                                    cache.writeQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId },
                                      data: {
                                        page: {
                                          ...page,
                                          colRows
                                        }
                                      }
                                    })
                                  }}
                                  onCompleted={() => {
                                    Template.setState(
                                      {
                                        updateModule: true
                                      },
                                      this.setState({ open: false })
                                    )
                                  }}
                                >
                                  {createContentColumnRow => (
                                    <MenuItem
                                      data-cy="Add-Card"
                                      onClick={createContentColumnRow}
                                    >
                                      Card
                                    </MenuItem>
                                  )}
                                </Mutation>

                                <Mutation
                                  mutation={CONTENT_CREATE_COLUMN_ROW}
                                  variables={{
                                    columnId: Template.state.selectedCol.id,
                                    pageId: pageId,
                                    input: this.prepareInputData(
                                      SUBSCRIBE_CARD_INPUT
                                    )
                                  }}
                                  update={(
                                    cache,
                                    { data: { createContentColumnRow } }
                                  ) => {
                                    const { page } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    })

                                    const colRows = page.rows.map(rowData => {
                                      // if (
                                      //   rowData.id ===
                                      //   Template.state.selectedRowPersist
                                      // ) {
                                      rowData.columns = rowData.columns.map(
                                        colData => {
                                          if (
                                            colData.id ===
                                            Template.state.selectedCol.id
                                          ) {
                                            colData.columnRows.push(
                                              createContentColumnRow
                                            )
                                          }
                                          return colData
                                        }
                                      )
                                      // }
                                      return rowData
                                    })

                                    cache.writeQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId },
                                      data: {
                                        page: {
                                          ...page,
                                          colRows
                                        }
                                      }
                                    })
                                  }}
                                  onCompleted={() => {
                                    Template.setState(
                                      {
                                        updateModule: true
                                      },
                                      this.setState({ open: false })
                                    )
                                  }}
                                >
                                  {createContentColumnRow => (
                                    <MenuItem
                                      data-cy="Add-Subscribe-Card"
                                      onClick={createContentColumnRow}
                                    >
                                      Subscribe Card
                                    </MenuItem>
                                  )}
                                </Mutation>

                                <Mutation
                                  mutation={CONTENT_CREATE_COLUMN_ROW}
                                  variables={{
                                    columnId: Template.state.selectedCol.id,
                                    pageId: pageId,
                                    input: this.prepareInputData(
                                      HUBSPOTFORM_CARD_INPUT
                                    )
                                  }}
                                  update={(
                                    cache,
                                    { data: { createContentColumnRow } }
                                  ) => {
                                    const { page } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    })

                                    const colRows = page.rows.map(rowData => {
                                      // if (
                                      //   rowData.id ===
                                      //   Template.state.selectedRowPersist
                                      // ) {
                                      rowData.columns = rowData.columns.map(
                                        colData => {
                                          if (
                                            colData.id ===
                                            Template.state.selectedCol.id
                                          ) {
                                            colData.columnRows.push(
                                              createContentColumnRow
                                            )
                                          }
                                          return colData
                                        }
                                      )
                                      // }
                                      return rowData
                                    })

                                    cache.writeQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId },
                                      data: {
                                        page: {
                                          ...page,
                                          colRows
                                        }
                                      }
                                    })
                                  }}
                                  onCompleted={() => {
                                    Template.setState(
                                      {
                                        updateModule: true
                                      },
                                      this.setState({ open: false })
                                    )
                                  }}
                                >
                                  {createContentColumnRow => (
                                    <MenuItem
                                      data-cy="Add-Hubspot-Card"
                                      onClick={createContentColumnRow}
                                    >
                                      Hubspot Form
                                    </MenuItem>
                                  )}
                                </Mutation>

                                {shopId === 'vermarc' ? (
                                  <Mutation
                                    mutation={CONTENT_CREATE_COLUMN_ROW}
                                    variables={{
                                      columnId: Template.state.selectedCol.id,
                                      pageId: pageId,
                                      input: this.prepareInputData(
                                        DISCOVER_CARD_INPUT
                                      )
                                    }}
                                    update={(
                                      cache,
                                      { data: { createContentColumnRow } }
                                    ) => {
                                      const { page } = cache.readQuery({
                                        query: GET_PAGE_CONTENT,
                                        variables: { id: pageId }
                                      })

                                      const colRows = page.rows.map(rowData => {
                                        // if (
                                        //   rowData.id ===
                                        //   Template.state.selectedRowPersist
                                        // ) {
                                        rowData.columns = rowData.columns.map(
                                          colData => {
                                            if (
                                              colData.id ===
                                              Template.state.selectedCol.id
                                            ) {
                                              colData.columnRows.push(
                                                createContentColumnRow
                                              )
                                            }
                                            return colData
                                          }
                                        )
                                        // }
                                        return rowData
                                      })

                                      cache.writeQuery({
                                        query: GET_PAGE_CONTENT,
                                        variables: { id: pageId },
                                        data: {
                                          page: {
                                            ...page,
                                            colRows
                                          }
                                        }
                                      })
                                    }}
                                    onCompleted={() => {
                                      Template.setState(
                                        {
                                          updateModule: true
                                        },
                                        this.setState({ open: false })
                                      )
                                    }}
                                  >
                                    {createContentColumnRow => (
                                      <MenuItem
                                        data-cy="Add-Discover-Card"
                                        onClick={createContentColumnRow}
                                      >
                                        Discover Card
                                      </MenuItem>
                                    )}
                                  </Mutation>
                                ) : null}

                                <Mutation
                                  mutation={CONTENT_CREATE_COLUMN_ROW}
                                  variables={{
                                    columnId: Template.state.selectedCol.id,
                                    pageId: pageId,
                                    input: this.prepareInputData(
                                      LOCATION_CARD_INPUT
                                    )
                                  }}
                                  update={(
                                    cache,
                                    { data: { createContentColumnRow } }
                                  ) => {
                                    const { page } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    })

                                    const colRows = page.rows.map(rowData => {
                                      // if (
                                      //   rowData.id ===
                                      //   Template.state.selectedRowPersist
                                      // ) {
                                      rowData.columns = rowData.columns.map(
                                        colData => {
                                          if (
                                            colData.id ===
                                            Template.state.selectedCol.id
                                          ) {
                                            colData.columnRows.push(
                                              createContentColumnRow
                                            )
                                          }
                                          return colData
                                        }
                                      )
                                      // }
                                      return rowData
                                    })

                                    cache.writeQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId },
                                      data: {
                                        page: {
                                          ...page,
                                          colRows
                                        }
                                      }
                                    })
                                  }}
                                  onCompleted={() => {
                                    Template.setState(
                                      {
                                        updateModule: true
                                      },
                                      this.setState({ open: false })
                                    )
                                  }}
                                >
                                  {createContentColumnRow => (
                                    <MenuItem
                                      data-cy="Add-Location-Card"
                                      onClick={createContentColumnRow}
                                    >
                                      Location Card
                                    </MenuItem>
                                  )}
                                </Mutation>

                                <Mutation
                                  mutation={CONTENT_CREATE_COLUMN_ROW}
                                  variables={{
                                    columnId: Template.state.selectedCol.id,
                                    pageId: pageId,
                                    input: this.prepareInputData(
                                      CONTACT_CARD_INPUT
                                    )
                                  }}
                                  update={(
                                    cache,
                                    { data: { createContentColumnRow } }
                                  ) => {
                                    const { page } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    })

                                    const colRows = page.rows.map(rowData => {
                                      // if (
                                      //   rowData.id ===
                                      //   Template.state.selectedRowPersist
                                      // ) {
                                      rowData.columns = rowData.columns.map(
                                        colData => {
                                          if (
                                            colData.id ===
                                            Template.state.selectedCol.id
                                          ) {
                                            colData.columnRows.push(
                                              createContentColumnRow
                                            )
                                          }
                                          return colData
                                        }
                                      )
                                      // }
                                      return rowData
                                    })

                                    cache.writeQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId },
                                      data: {
                                        page: {
                                          ...page,
                                          colRows
                                        }
                                      }
                                    })
                                  }}
                                  onCompleted={() => {
                                    Template.setState(
                                      {
                                        updateModule: true
                                      },
                                      this.setState({ open: false })
                                    )
                                  }}
                                >
                                  {createContentColumnRow => (
                                    <MenuItem
                                      data-cy="Add-Contact-Form"
                                      onClick={createContentColumnRow}
                                    >
                                      Contact Form
                                    </MenuItem>
                                  )}
                                </Mutation>

                                <MenuItem
                                  data-cy="Add-Collection-Card"
                                  onClick={this.handleCollectionOpen}
                                >
                                  Collection Card
                                </MenuItem>

                                <MenuItem
                                  data-cy="Add-Product-Card"
                                  onClick={this.handleProductOpen}
                                >
                                  Product Card
                                </MenuItem>

                                <MenuItem
                                  data-cy="Add-Teamstore-Card"
                                  onClick={this.handleTeamstoreOpen}
                                >
                                  Teamstore Card
                                </MenuItem>

                                <Mutation
                                  mutation={CONTENT_CREATE_COLUMN_ROW}
                                  variables={{
                                    columnId: Template.state.selectedCol.id,
                                    pageId: pageId,
                                    input: this.prepareInputData(DIVIDER_INPUT)
                                  }}
                                  update={(
                                    cache,
                                    { data: { createContentColumnRow } }
                                  ) => {
                                    const { page } = cache.readQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId }
                                    })

                                    const colRows = page.rows.map(rowData => {
                                      // if (
                                      //   rowData.id ===
                                      //   Template.state.selectedRowPersist
                                      // ) {
                                      rowData.columns = rowData.columns.map(
                                        colData => {
                                          if (
                                            colData.id ===
                                            Template.state.selectedCol.id
                                          ) {
                                            colData.columnRows.push(
                                              createContentColumnRow
                                            )
                                          }
                                          return colData
                                        }
                                      )
                                      // }
                                      return rowData
                                    })

                                    cache.writeQuery({
                                      query: GET_PAGE_CONTENT,
                                      variables: { id: pageId },
                                      data: {
                                        page: {
                                          ...page,
                                          colRows
                                        }
                                      }
                                    })
                                  }}
                                  onCompleted={() => {
                                    Template.setState(
                                      {
                                        updateModule: true
                                      },
                                      this.setState({ open: false })
                                    )
                                  }}
                                >
                                  {createContentColumnRow => (
                                    <MenuItem
                                      data-cy="Add-Divider"
                                      onClick={createContentColumnRow}
                                    >
                                      Divider
                                    </MenuItem>
                                  )}
                                </Mutation>
                              </div>
                            ) : null}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>

                <Dialog
                  open={this.state.openCollection}
                  onClose={this.handleCollectionClose}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle
                    id="form-dialog-title"
                    data-cy="Select-Collections"
                  >
                    Select Collections
                  </DialogTitle>
                  <DialogContent style={{ zIndex: 100 }}>
                    <Query
                      variables={{ id: shopId }}
                      query={ADMIN_SHOP_COLLECTIONS}
                    >
                      {({ loading, error, data }) => {
                        if (loading) return 'Loading...'
                        if (error) return `Error! ${error.message}`

                        return (
                          <Grid container={true}>
                            {data.shop.collections.edges.map(c => {
                              const collection = c.node

                              return (
                                <SelectCollections
                                  Template={Template}
                                  collection={collection}
                                  onChange={this.handleCollectionChange}
                                  collectionSET={this.state.collectionIds}
                                />
                              )
                            })}
                          </Grid>
                        )
                      }}
                    </Query>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      data-cy="Select-Collections-Cancel-btn"
                      onClick={this.handleCollectionClose}
                      color="secondary"
                    >
                      Cancel
                    </Button>
                    <Mutation
                      mutation={CONTENT_CREATE_COLUMN_ROW}
                      variables={{
                        columnId: Template.state.selectedCol.id,
                        pageId: pageId,
                        input: {
                          alignmentType: 'justifyContent',
                          alignment: 'center',
                          sortOrder: Template.state.selectedCol
                            ? Template.state.selectedCol.columnRows.length + 1
                            : 1,
                          columns: 1,
                          columnRowDetail: {
                            id: uuidv4(),
                            type: 'COLLECTION_CARD',
                            cardsToShow: 0,
                            collectionIds: this.state.collectionIds,
                            buttonText: {
                              id: uuidv4(),
                              text: [
                                {
                                  langCode: 'en_UK',
                                  content: 'Shop The Collection'
                                }
                              ]
                            }
                          }
                        }
                      }}
                      update={(cache, { data: { createContentColumnRow } }) => {
                        const { page } = cache.readQuery({
                          query: GET_PAGE_CONTENT,
                          variables: { id: pageId }
                        })

                        const colRows = page.rows.map(rowData => {
                          // if (
                          //   rowData.id ===
                          //   Template.state.selectedRowPersist
                          // ) {
                          rowData.columns = rowData.columns.map(colData => {
                            if (colData.id === Template.state.selectedCol.id) {
                              colData.columnRows.push(createContentColumnRow)
                            }
                            return colData
                          })
                          // }
                          return rowData
                        })

                        cache.writeQuery({
                          query: GET_PAGE_CONTENT,
                          variables: { id: pageId },
                          data: {
                            page: {
                              ...page,
                              colRows
                            }
                          }
                        })
                      }}
                      onCompleted={() => {
                        Template.setState(
                          {
                            updateModule: true
                          },
                          this.setState({ open: false })
                        )
                      }}
                    >
                      {createContentColumnRow => (
                        <Button
                          data-cy="Select-Collections-Save-btn"
                          onClick={() => {
                            createContentColumnRow()
                            this.handleCollectionClose()
                          }}
                          color="primary"
                        >
                          Save
                        </Button>
                      )}
                    </Mutation>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={this.state.openProduct}
                  onClose={this.handleProductClose}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Select Products
                  </DialogTitle>

                  <DialogContent
                    style={{
                      zIndex: 100,
                      minHeight: '400px',
                      minWidth: '400px'
                    }}
                  >
                    <Grid container>
                      {this.state.productIds
                        ? this.state.productIds.map(productId => {
                            return (
                              <Grid spacing={2} item key={'cp-' + productId}>
                                <ProductPreview
                                  langCode={Template.state.currentLanguage}
                                  productId={productId}
                                />
                                <Button
                                  onClick={() => {
                                    this.setState({
                                      productIds: this.state.productIds.filter(
                                        p => p !== productId
                                      )
                                    })
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Grid>
                            )
                          })
                        : null}
                    </Grid>
                    <AdminSearchProduct
                      text={'Search for product'}
                      classes={classes}
                      onSelect={product => {
                        try {
                          this.handleProductChange(product.id, true)
                        } catch (error) {}
                      }}
                      excludeIds={this.state.productIds}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleProductClose} color="secondary">
                      Cancel
                    </Button>
                    <Mutation
                      mutation={CONTENT_CREATE_COLUMN_ROW}
                      variables={{
                        columnId: Template.state.selectedCol.id,
                        pageId: pageId,
                        input: {
                          alignmentType: 'justifyContent',
                          alignment: 'center',
                          sortOrder: Template.state.selectedCol
                            ? Template.state.selectedCol.columnRows.length + 1
                            : 1,
                          columns: 1,
                          columnRowDetail: {
                            id: uuidv4(),
                            type: 'PRODUCT_CARD',
                            cardsToShow: 0,
                            productIds: this.state.productIds,
                            buttonText: {
                              id: uuidv4(),
                              text: [
                                {
                                  langCode: 'en_UK',
                                  content: 'ORDER NOW'
                                }
                              ]
                            }
                          }
                        }
                      }}
                      update={(cache, { data: { createContentColumnRow } }) => {
                        const { page } = cache.readQuery({
                          query: GET_PAGE_CONTENT,
                          variables: { id: pageId }
                        })

                        const colRows = page.rows.map(rowData => {
                          // if (
                          //   rowData.id ===
                          //   Template.state.selectedRowPersist
                          // ) {
                          rowData.columns = rowData.columns.map(colData => {
                            if (colData.id === Template.state.selectedCol.id) {
                              colData.columnRows.push(createContentColumnRow)
                            }
                            return colData
                          })
                          // }
                          return rowData
                        })

                        cache.writeQuery({
                          query: GET_PAGE_CONTENT,
                          variables: { id: pageId },
                          data: {
                            page: {
                              ...page,
                              colRows
                            }
                          }
                        })
                      }}
                      onCompleted={() => {
                        Template.setState(
                          {
                            updateModule: true
                          },
                          this.setState({ open: false })
                        )
                      }}
                    >
                      {createContentColumnRow => (
                        <Button
                          onClick={() => {
                            createContentColumnRow()
                            this.handleProductClose()
                          }}
                          color="primary"
                        >
                          Save
                        </Button>
                      )}
                    </Mutation>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={this.state.openTeamstore}
                  onClose={this.handleTeamstoreClose}
                  aria-labelledby="teamstore-form-dialog-title"
                >
                  <DialogTitle id="teamstore-form-dialog-title">
                    Select Teamstores
                  </DialogTitle>
                  <DialogContent style={{ zIndex: 100 }}>
                    <Query
                      variables={{ id: shopId }}
                      query={ADMIN_SHOP_TEAMSTORES}
                    >
                      {({ loading, error, data }) => {
                        if (loading) return 'Loading...'
                        if (error) return `Error! ${error.message}`

                        return (
                          <Grid container={true}>
                            {data.shop.salesChannels.edges.map(p => {
                              const teamstore = p.node

                              return (
                                <SelectTeamstores
                                  Template={Template}
                                  teamstore={teamstore}
                                  onChange={this.handleTeamstoreChange}
                                  teamstoreSET={this.state.teamstoreIds}
                                />
                              )
                            })}
                          </Grid>
                        )
                      }}
                    </Query>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.handleTeamstoreClose}
                      color="secondary"
                    >
                      Cancel
                    </Button>
                    <Mutation
                      mutation={CONTENT_CREATE_COLUMN_ROW}
                      variables={{
                        columnId: Template.state.selectedCol.id,
                        pageId: pageId,
                        input: {
                          alignmentType: 'justifyContent',
                          alignment: 'center',
                          sortOrder: Template.state.selectedCol
                            ? Template.state.selectedCol.columnRows.length + 1
                            : 1,
                          columns: 1,
                          columnRowDetail: {
                            id: uuidv4(),
                            type: 'TEAMSTORE_CARD',
                            cardsToShow: 0,
                            teamstoreIds: this.state.teamstoreIds
                          }
                        }
                      }}
                      update={(cache, { data: { createContentColumnRow } }) => {
                        const { page } = cache.readQuery({
                          query: GET_PAGE_CONTENT,
                          variables: { id: pageId }
                        })

                        const colRows = page.rows.map(rowData => {
                          // if (
                          //   rowData.id ===
                          //   Template.state.selectedRowPersist
                          // ) {
                          rowData.columns = rowData.columns.map(colData => {
                            if (colData.id === Template.state.selectedCol.id) {
                              colData.columnRows.push(createContentColumnRow)
                            }
                            return colData
                          })
                          // }
                          return rowData
                        })

                        cache.writeQuery({
                          query: GET_PAGE_CONTENT,
                          variables: { id: pageId },
                          data: {
                            page: {
                              ...page,
                              colRows
                            }
                          }
                        })
                      }}
                      onCompleted={() => {
                        Template.setState(
                          {
                            updateModule: true
                          },
                          this.setState({ open: false })
                        )
                      }}
                    >
                      {createContentColumnRow => (
                        <Button
                          onClick={() => {
                            createContentColumnRow()
                            this.handleTeamstoreClose()
                          }}
                          color="primary"
                        >
                          Save
                        </Button>
                      )}
                    </Mutation>
                  </DialogActions>
                </Dialog>

                {Template.state.selectedRow && !Template.state.selectedCol ? (
                  <DeleteRow pageId={pageId} Template={Template} />
                ) : !Template.state.selectedRow &&
                  Template.state.selectedCol ? (
                  <DeleteColumn pageId={pageId} Template={Template} />
                ) : !Template.state.selectedRow &&
                  !Template.state.selectedCol &&
                  Template.state.selectedElement ? (
                  <DeleteColumnRow pageId={pageId} Template={Template} />
                ) : (
                  <Button
                    aria-label="Delete"
                    style={{ color: 'grey' }}
                    disabled={true}
                  >
                    <DeleteIcon />
                  </Button>
                )}
                <LanguageSwitch
                  shopId={shopId}
                  languages={languages}
                  Template={Template}
                />
              </Toolbar>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="button"
              className={classes.closeButton}
              disableRipple={true}
              disableFocusRipple={true}
              onClick={e => {
                e.preventDefault()
                navigate(
                  '/admin/' + shopId + '/pagebuilder/pages/' + pageId + '/meta'
                )
              }}
            >
              Edit page setting
            </Button>
            {/* <Grid container justify="center">
              {slugs ? (
                <PageSlugsContainer
                  data={slugs}
                  page={{
                    id: pageId
                  }}
                />
              ) : null}
            </Grid> */}
          </Grid>
          <div style={{ display: 'flex', flex: 1 }} />
          <Grid item>
            <Grid container>
              <Button
                onClick={() => {
                  try {
                    const data = client.readQuery({
                      query: GET_PAGE_CONTENT,
                      variables: { id: pageId }
                    })
                    window.open(
                      `${shopUrl}/preview?type=page&id=${pageId}&language=${Template.state.currentLanguage}`
                    )
                  } catch (error) {
                    console.error('Open preview error', error)
                  }
                }}
                aria-label="Open preview"
              >
                <OpenInNew />
              </Button>

              <ResponsiveViewSwitch Template={Template} />
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    )
  }
}

ContentAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(ContentAppBar)
