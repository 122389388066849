import React, { Component } from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import {
  ADMIN_GET_FABRIC,
  ADMIN_SHOP_COLORS,
  ADMIN_GET_COLOR
} from '../../gql/admin-queries';
import { ADMIN_UPDATE_FABRIC } from '../../gql/admin-mutations';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper';
// import Dropzone from 'react-dropzone';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import uuid from 'uuid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Input from '../../../common/Input';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  chip: {
    borderRadius: '4px',
    background: '#B23566',
    color: 'white',
    marginRight: 5
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    marginTop: '24px !important',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  },
  paperSelect: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '0',
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    border: 'none',
    width: '100%',
    background: 'transparent',
    padding: '6px 16px',
    height: '42px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  },
  progress: {}
});

class FabricDetail extends Component {
  state = {
    name: '',
    showForm: false,
    code: '',
    printable: true,
    notprintable: false,
    showButton: true,
    id: this.props.fabricId,
    open: false,
    files: [],
    summary: '',
    chipData: [
      { key: 0, label: 'blue' },
      { key: 1, label: 'navy' },
      { key: 2, label: 'yellow' },
      { key: 3, label: 'green' },
      { key: 4, label: 'pink' }
    ]
  };

  onDrop(files) {
    this.setState({
      files: files.map(file => ({
        ...file,
        preview: URL.createObjectURL(file)
      }))
    });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleDelete = data => () => {
    this.setState(state => {
      const chipData = [...state.chipData];
      const chipToDelete = chipData.indexOf(data);
      chipData.splice(chipToDelete, 1);
      return { chipData };
    });
  };

  handleOpen = () => {
    this.setState({ open: true, showButton: false });
  };

  handleClose = () => {
    this.setState({ open: false, showButton: true });
  };

  render() {
    const { files } = this.state;
    const thumbs = files.map(file => (
      <div className={classes.thumb}>
        <div className={classes.thumbInner}>
          <img src={file.preview} className={classes.img} />
        </div>
      </div>
    ));

    const { fabricId, classes, shopId } = this.props;
    return (
      <div className={classes.root}>
        <Typography>
          <Grid container>
            <Grid
              container
              justify="flex-start"
              direction="row"
              alignItems="center"
              style={{ height: 60, marginBottom: 20 }}
            >
              <Typography
                data-cy="New-Material-header"
                style={{
                  fontSize: 28,
                  fontWeight: 400,
                  color: '#424242'
                }}
              >
                Material
              </Typography>

              <div style={{ flex: 1 }} />

              <Link
                to={`/admin/${shopId}/inventory/fabrics`}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  data-cy="Material-Back-btn"
                  style={{ marginLeft: 16 }}
                  variant="outlined"
                  color="primary"
                >
                  Back to materials
                </Button>
              </Link>
            </Grid>

            <Query query={ADMIN_GET_FABRIC} variables={{ id: fabricId }}>
              {({ loading, error, data: { fabricComposition } }) => {
                if (loading)
                  return <CircularProgress className={classes.progress} />;
                if (error) return <div>Error :(</div>;
                return (
                  <Mutation mutation={ADMIN_UPDATE_FABRIC}>
                    {(updateFabricComposition, { data, loading, error }) => {
                      return (
                        <Grid container style={{ marginTop: 24 }}>
                          {/*
***************************
Fabric details starts
***************************
*/}
                          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <Grid
                              container
                              style={{ padding: '24px 24px 24px 0px' }}
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography
                                  data-cy="Material-Details-header"
                                  variant="subtitle1"
                                  style={{ marginRight: 16 }}
                                >
                                  Material details
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="body1">
                                  Provide information on your Material
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                            <Paper style={{ padding: 24 }} elevation={0}>
                              <Grid
                                container
                                style={{ padding: '24px 24px 24px 0px' }}
                                spacing={1}
                                direction=" column"
                              >
                                <Grid item xs={12}>
                                  <Input
                                    dataCy="Fabric-Style-Number"
                                    label="Internal ID"
                                    placeholder="Fabric code"
                                    value={fabricComposition.code}
                                    onChange={e =>
                                      updateFabricComposition({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateFabricComposition: {
                                            ...fabricComposition,
                                            code: e.target.value
                                          }
                                        },
                                        variables: {
                                          id: fabricId,
                                          input: {
                                            code: e.target.value
                                          }
                                        }
                                      })
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Input
                                    dataCy="Fabric-Title"
                                    label="Fabric title"
                                    placeholder="Fabric name"
                                    value={fabricComposition.name}
                                    onChange={e =>
                                      updateFabricComposition({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateFabricComposition: {
                                            ...fabricComposition,
                                            name: e.target.value
                                          }
                                        },
                                        variables: {
                                          id: fabricId,
                                          input: {
                                            name: e.target.value
                                          }
                                        }
                                      })
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Input
                                    dataCy="Fabric-Content"
                                    label="Fabric content"
                                    placeholder="Fabric content"
                                    value={
                                      fabricComposition &&
                                      fabricComposition.fabricTypes
                                        ? fabricComposition.fabricTypes.map(
                                            (fabricType, i) => {
                                              return fabricType.fabricContents.map(
                                                (fabricContent, k) => {
                                                  return fabricContent
                                                    ? ' ' +
                                                        fabricContent.percent +
                                                        '% ' +
                                                        fabricContent.textile
                                                          .textileMaterial
                                                    : 'N/A';
                                                }
                                              );
                                            }
                                          )
                                        : 'Fabric content'
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <TextField
                                    dataCy="Fabric-Description"
                                    multiline={true}
                                    rowsMax="4"
                                    label="Fabric description"
                                    label="Fabric description"
                                    value={fabricComposition.summary}
                                    onChange={e =>
                                      updateFabricComposition({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateFabricComposition: {
                                            ...fabricComposition,
                                            summary: e.target.value
                                          }
                                        },
                                        variables: {
                                          id: fabricId,
                                          input: {
                                            summary: e.target.value
                                          }
                                        }
                                      })
                                    }
                                    margin="normal"
                                  />
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>

                          {/*
***************************
Fabric details ends
***************************
*/}

                          {/*
***************************
Fabric config starts
***************************
*/}

                          <Grid container style={{ marginTop: 24 }}>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                              <Grid
                                container
                                style={{ padding: '24px 24px 24px 0px' }}
                                spacing={1}
                                alignItems="center"
                              >
                                <Grid item>
                                  <Typography
                                    data-cy="Fabric-Configuration-header"
                                    variant="subtitle1"
                                    style={{ marginRight: 16 }}
                                  >
                                    Fabric configuration
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="body1">
                                    Provide configuration on your fabric
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                              <Paper style={{ padding: 24 }} elevation={0}>
                                <Grid
                                  container
                                  style={{ padding: '24px 24px 24px 0px' }}
                                  spacing={1}
                                  direction=" column"
                                >
                                  <Grid item xs={12}>
                                    <Input
                                      dataCy="Fabric-Weight"
                                      label="fabric weight"
                                      placeholder="fabric weight"
                                      value={_get(
                                        fabricComposition,
                                        'weights[0].weight',
                                        0
                                      )}
                                      onChange={e => {
                                        const newWeight = {
                                          weightUnit: _get(
                                            fabricComposition,
                                            'weights[0].weightUnit',
                                            'g'
                                          ),
                                          weight: e.target.value
                                        };
                                        updateFabricComposition({
                                          optimisticResponse: {
                                            __typename: 'Mutation',
                                            updateFabricComposition: {
                                              ...fabricComposition,
                                              weights: [
                                                {
                                                  ...newWeight,
                                                  __typename: 'FabricWeight'
                                                }
                                              ]
                                            }
                                          },
                                          variables: {
                                            id: fabricId,
                                            input: {
                                              weights: [newWeight]
                                            }
                                          }
                                        });
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Select
                                      dataCy="Fabric-Unit-Select"
                                      label="Unit"
                                      placeholder="gram or ounce"
                                      value={_get(
                                        fabricComposition,
                                        'weights[0].weightUnit',
                                        'g'
                                      )}
                                      onChange={e => {
                                        const newWeight = {
                                          weight: _get(
                                            fabricComposition,
                                            'weights[0].weight',
                                            0
                                          ),
                                          weightUnit: e.target.value
                                        };
                                        updateFabricComposition({
                                          optimisticResponse: {
                                            __typename: 'Mutation',
                                            updateFabricComposition: {
                                              ...fabricComposition,
                                              weights: [
                                                {
                                                  ...newWeight,
                                                  __typename: 'FabricWeight'
                                                }
                                              ]
                                            }
                                          },
                                          variables: {
                                            id: fabricId,
                                            input: {
                                              weights: [newWeight]
                                            }
                                          }
                                        });
                                      }}
                                    >
                                      <option value={'g'}>g</option>
                                      <option value={'oz'}>oz</option>
                                    </Select>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Select
                                      dataCy="Fabric-Printable-Select"
                                      label="Is it Printable ?"
                                      placeholder="yes or no"
                                      value={
                                        fabricComposition.printable === true
                                          ? 'yes'
                                          : 'no'
                                      }
                                      onChange={e => {
                                        updateFabricComposition({
                                          optimisticResponse: {
                                            __typename: 'Mutation',
                                            updateFabricComposition: {
                                              ...fabricComposition,
                                              printable:
                                                e.target.value === 'yes'
                                                  ? true
                                                  : false
                                            }
                                          },
                                          variables: {
                                            id: fabricId,
                                            input: {
                                              printable:
                                                e.target.value === 'yes'
                                                  ? true
                                                  : false
                                            }
                                          }
                                        });
                                      }}
                                    >
                                      <option value={'yes'}>yes</option>
                                      <option value={'no'}>no</option>
                                    </Select>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          </Grid>

                          {/*
***************************
Fabric config ends
***************************
*/}

                          {/*
***************************
Fabric price starts
***************************
*/}

                          <Grid container style={{ marginTop: 24 }}>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                              <Grid
                                container
                                style={{ padding: '24px 24px 24px 0px' }}
                                spacing={1}
                                alignItems="center"
                              >
                                <Grid item>
                                  <Typography
                                    data-cy="Fabric-Price-header"
                                    variant="subtitle1"
                                    style={{ marginRight: 16 }}
                                  >
                                    Fabric Price
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="body1">
                                    Provide pricing information on your fabric
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                              <Paper style={{ padding: 24 }} elevation={0}>
                                <Grid
                                  container
                                  style={{ padding: '24px 24px 24px 0px' }}
                                  spacing={1}
                                  direction=" column"
                                >
                                  <Grid item xs={12}>
                                    <Input
                                      dataCy="Fabric-Price"
                                      label="Price"
                                      placeholder="Enter price here"
                                      value={_get(
                                        fabricComposition,
                                        'fabricCost.formula.price.value',
                                        ''
                                      )}
                                      onChange={e => {
                                        const newId =
                                          fabricComposition.fabricCost &&
                                          fabricComposition.fabricCost.id
                                            ? fabricComposition.fabricCost.id
                                            : uuid.v1();
                                        updateFabricComposition({
                                          optimisticResponse: {
                                            __typename: 'Mutation',
                                            updateFabricComposition: {
                                              ...fabricComposition,
                                              fabricCost: {
                                                ...fabricComposition.fabricCost,
                                                formula: {
                                                  ..._get(
                                                    fabricComposition,
                                                    'fabricCost.formula',
                                                    {
                                                      ___typename: 'PricingRule'
                                                    }
                                                  ),
                                                  price: {
                                                    ..._get(
                                                      fabricComposition,
                                                      'fabricCost.formula.price',
                                                      {
                                                        ___typename: 'Pricing'
                                                      }
                                                    ),
                                                    value: parseFloat(
                                                      e.target.value
                                                    )
                                                  }
                                                }
                                              }
                                            }
                                          },
                                          variables: {
                                            id: fabricId,
                                            input: {
                                              fabricCost: fabricComposition.fabricCost
                                                ? {
                                                    ..._omit(
                                                      fabricComposition.fabricCost,
                                                      '__typename'
                                                    ),
                                                    formula: {
                                                      ..._omit(
                                                        fabricComposition
                                                          .fabricCost.formula,
                                                        '__typename'
                                                      ),
                                                      price: {
                                                        ..._omit(
                                                          fabricComposition
                                                            .fabricCost.formula
                                                            .price,
                                                          '__typename'
                                                        ),
                                                        value: parseFloat(
                                                          e.target.value
                                                        )
                                                      }
                                                    }
                                                  }
                                                : {
                                                    id: newId,
                                                    price: {
                                                      value: parseFloat(
                                                        e.target.value
                                                      )
                                                    }
                                                  }
                                            }
                                          }
                                        });
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Select
                                      dataCy="Fabric-Formula-Select"
                                      label="Formula"
                                      value={_get(
                                        fabricComposition,
                                        'fabricCost.calculationType',
                                        ''
                                      )}
                                      onChange={e => {
                                        const newId =
                                          fabricComposition.fabricCost &&
                                          fabricComposition.fabricCost.id
                                            ? fabricComposition.fabricCost.id
                                            : uuid.v1();
                                        updateFabricComposition({
                                          optimisticResponse: {
                                            __typename: 'Mutation',
                                            updateFabricComposition: {
                                              ...fabricComposition,
                                              fabricCost: {
                                                ...fabricComposition.fabricCost,
                                                id: newId,
                                                calculationType: e.target.value
                                              }
                                            }
                                          },
                                          variables: {
                                            id: fabricId,
                                            input: {
                                              fabricCost: fabricComposition.fabricCost
                                                ? {
                                                    ..._omit(
                                                      fabricComposition.fabricCost,
                                                      '__typename'
                                                    ),
                                                    formula: {
                                                      ..._omit(
                                                        fabricComposition
                                                          .fabricCost.formula,
                                                        '__typename'
                                                      ),
                                                      price: {
                                                        ..._omit(
                                                          fabricComposition
                                                            .fabricCost.formula
                                                            .price,
                                                          '__typename'
                                                        )
                                                      }
                                                    },
                                                    calculationType:
                                                      e.target.value
                                                  }
                                                : {
                                                    id: newId,
                                                    price: {
                                                      value: 0
                                                    },
                                                    calculationType:
                                                      e.target.value
                                                  }
                                            }
                                          }
                                        });
                                      }}
                                      style={{
                                        background: 'none',
                                        color: '#000'
                                      }}
                                    >
                                      <option value={'PERSQM'}>per sqm</option>
                                      <option value={'PERQTY'}>per qty</option>
                                      <option value={'PERPRODUCT'}>
                                        per product
                                      </option>
                                    </Select>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Select
                                      dataCy="Fabric-Currency-Select"
                                      label="currency"
                                      value={_get(
                                        fabricComposition,
                                        'fabricCost.formula.price.currency',
                                        ''
                                      )}
                                      onChange={e => {
                                        const newId =
                                          fabricComposition.fabricCost &&
                                          fabricComposition.fabricCost.id
                                            ? fabricComposition.fabricCost.id
                                            : uuid.v1();
                                        updateFabricComposition({
                                          optimisticResponse: {
                                            __typename: 'Mutation',
                                            updateFabricComposition: {
                                              ...fabricComposition,
                                              fabricCost: {
                                                ...fabricComposition.fabricCost,
                                                formula: {
                                                  ...fabricComposition
                                                    .fabricCost.formula,
                                                  price: fabricComposition
                                                    .fabricCost.formula
                                                    ? {
                                                        ...fabricComposition
                                                          .fabricCost.formula
                                                          .price,
                                                        currency: e.target.value
                                                      }
                                                    : {
                                                        id: newId,
                                                        currency: e.target.value
                                                      }
                                                }
                                              }
                                            }
                                          },
                                          variables: {
                                            id: fabricId,
                                            input: {
                                              fabricCost: fabricComposition.fabricCost
                                                ? {
                                                    ..._omit(
                                                      fabricComposition.fabricCost,
                                                      '__typename'
                                                    ),
                                                    formula: {
                                                      ..._omit(
                                                        fabricComposition
                                                          .fabricCost.formula,
                                                        '__typename'
                                                      ),
                                                      price: {
                                                        ..._omit(
                                                          fabricComposition
                                                            .fabricCost.formula
                                                            .price,
                                                          '__typename'
                                                        ),
                                                        currency: e.target.value
                                                      }
                                                    }
                                                  }
                                                : {
                                                    id: newId,
                                                    price: {
                                                      currency: e.target.value
                                                    }
                                                  }
                                            }
                                          }
                                        });
                                      }}
                                    >
                                      <option value={'EUR'}>EUR</option>
                                      <option value={'THB'}>THB</option>
                                      <option value={'USD'}>USD</option>
                                      <option value={'GBP'}>GBP</option>
                                    </Select>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          </Grid>

                          {/*
***************************
Fabric price ends
***************************
*/}

                          {/*
***************************
Fabric colors starts
***************************
*/}

                          <Grid container style={{ marginTop: 24 }}>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                              <Grid
                                container
                                style={{ padding: '24px 24px 24px 0px' }}
                                spacing={1}
                                alignItems="center"
                              >
                                <Grid item>
                                  <Typography
                                    data-cy="Fabric-Colors-Added-header"
                                    variant="subtitle1"
                                    style={{ marginRight: 16 }}
                                  >
                                    Fabric Colors Added
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="body1">
                                    Remove colors from your fabric
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                              <Paper style={{ padding: 24 }} elevation={0}>
                                <Grid
                                  container
                                  style={{ padding: '24px 24px 24px 0px' }}
                                  spacing={1}
                                  direction=" column"
                                >
                                  <Grid item xs={12}>
                                    <Table data-cy="fabric-added-table" className={classes.table}>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            style={{
                                              padding: '0px 8px 0px 16px'
                                            }}
                                          >
                                            Color
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              padding: '0px 8px 0px 16px'
                                            }}
                                            align="left"
                                          >
                                            Name
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              padding: '0px 8px 0px 16px'
                                            }}
                                            align="left"
                                          >
                                            Code
                                          </TableCell>

                                          <TableCell
                                            style={{
                                              padding: '0px 8px 0px 16px'
                                            }}
                                            align="left"
                                          >
                                            Make default
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              padding: '0px 8px 0px 16px'
                                            }}
                                            align="left"
                                          >
                                            remove
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>

                                      <TableBody>
                                        {fabricComposition.colorIds ? (
                                          fabricComposition.colorIds.map(
                                            colorId => {
                                              // defaultColorId
                                              return (
                                                <Query
                                                  query={ADMIN_GET_COLOR}
                                                  variables={{ id: colorId }}
                                                >
                                                  {({
                                                    loading,
                                                    error,
                                                    data: { color }
                                                  }) => {
                                                    if (loading)
                                                      return (
                                                        <CircularProgress
                                                          className={
                                                            classes.progress
                                                          }
                                                        />
                                                      );
                                                    if (error)
                                                      return (
                                                        <div>Error :(</div>
                                                      );
                                                    return (
                                                      <TableRow>
                                                        <TableCell
                                                          style={{
                                                            padding:
                                                              '0px 8px 0px 16px'
                                                          }}
                                                        >
                                                          <div
                                                            data-cy={
                                                              'Fabric-Colors-Added-' +
                                                              color.name
                                                            }
                                                            style={{
                                                              width: '40px',
                                                              height: '40px',
                                                              border:
                                                                '1px solid #333',
                                                              background:
                                                                color.hex
                                                            }}
                                                          />
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            padding:
                                                              '0px 8px 0px 16px'
                                                          }}
                                                        >
                                                          {color.name
                                                            ? color.name
                                                            : color.internalName}
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            padding:
                                                              '0px 8px 0px 16px'
                                                          }}
                                                        >
                                                          {color.code
                                                            ? color.code
                                                            : color.internalCode}
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            padding:
                                                              '0px 8px 0px 16px'
                                                          }}
                                                        >
                                                          {colorId ===
                                                          fabricComposition.defaultColorId ? (
                                                            <Button
                                                              data-cy={
                                                                'Fabric-Default-Color-btn-' +
                                                                color.name
                                                              }
                                                              color="primary"
                                                              variant="contained"
                                                              style={{
                                                                fontSize:
                                                                  '14px',
                                                                textTransform:
                                                                  'inherit'
                                                              }}
                                                            >
                                                              Default color
                                                            </Button>
                                                          ) : (
                                                            <Button
                                                              data-cy={
                                                                'Fabric-Mark-Default-btn-' +
                                                                color.name
                                                              }
                                                              onClick={() => {
                                                                updateFabricComposition(
                                                                  {
                                                                    optimisticResponse: {
                                                                      __typename:
                                                                        'Mutation',
                                                                      updateFabricComposition: {
                                                                        ...fabricComposition,
                                                                        defaultColorId: colorId
                                                                      }
                                                                    },
                                                                    variables: {
                                                                      id: fabricId,
                                                                      input: {
                                                                        defaultColorId: colorId
                                                                      }
                                                                    }
                                                                  }
                                                                );
                                                              }}
                                                              variant="text"
                                                            >
                                                              Mark as default
                                                            </Button>
                                                          )}
                                                        </TableCell>

                                                        <TableCell
                                                          style={{
                                                            padding:
                                                              '0px 8px 0px 16px'
                                                          }}
                                                        >
                                                          <Button
                                                            data-cy={
                                                              'Fabric-Remove-Colors-btn-' +
                                                              color.name
                                                            }
                                                            onClick={() => {
                                                              updateFabricComposition(
                                                                {
                                                                  optimisticResponse: {
                                                                    __typename:
                                                                      'Mutation',
                                                                    updateFabricComposition: {
                                                                      ...fabricComposition,
                                                                      colorIds: fabricComposition.colorIds.filter(
                                                                        o =>
                                                                          o !==
                                                                          colorId
                                                                      )
                                                                    }
                                                                  },
                                                                  variables: {
                                                                    id: fabricId,
                                                                    input: {
                                                                      colorIds: fabricComposition.colorIds.filter(
                                                                        o =>
                                                                          o !==
                                                                          colorId
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              );
                                                            }}
                                                            variant="text"
                                                          >
                                                            Remove
                                                          </Button>
                                                        </TableCell>
                                                      </TableRow>
                                                    );
                                                  }}
                                                </Query>
                                              );
                                            }
                                          )
                                        ) : (
                                          <TableRow>
                                            <TableCell>Empty</TableCell>
                                            <TableCell>Empty</TableCell>
                                            <TableCell>Empty</TableCell>
                                            <TableCell>Empty</TableCell>
                                          </TableRow>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          </Grid>

                          <Grid container style={{ marginTop: 24 }}>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                              <Grid
                                container
                                style={{ padding: '24px 24px 24px 0px' }}
                                spacing={1}
                                alignItems="center"
                              >
                                <Grid item>
                                  <Typography
                                    data-cy="Add-Fabric-Colors-header"
                                    variant="subtitle1"
                                    style={{ marginRight: 16 }}
                                  >
                                    Add fabric Colors
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="body1">
                                    Assign colors to your fabric
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                              <Paper style={{ padding: 24 }} elevation={0}>
                                <Grid
                                  container
                                  style={{ padding: '24px 24px 24px 0px' }}
                                  spacing={1}
                                  direction=" column"
                                >
                                  <Grid item xs={12}>
                                    <Table className={classes.table}>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            style={{
                                              padding: '0px 8px 0px 16px'
                                            }}
                                          >
                                            Color
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              padding: '0px 8px 0px 16px'
                                            }}
                                            align="left"
                                          >
                                            Name
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              padding: '0px 8px 0px 16px'
                                            }}
                                            align="left"
                                          >
                                            Code
                                          </TableCell>

                                          <TableCell
                                            style={{
                                              padding: '0px 8px 0px 16px'
                                            }}
                                            align="left"
                                          >
                                            Add to material
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>

                                      <Query
                                        query={ADMIN_SHOP_COLORS}
                                        variables={{ id: shopId }}
                                      >
                                        {({
                                          loading,
                                          error,
                                          data: { shop }
                                        }) => {
                                          if (loading)
                                            return <CircularProgress />;
                                          if (error) return <div>Error :(</div>;
                                          // return <div>{JSON.stringify(shop)}</div>
                                          if (shop.colors.edges.length > 0) {
                                            return (
                                              <TableBody>
                                                {_get(
                                                  shop,
                                                  'colors.edges',
                                                  []
                                                ).map(row => {
                                                  const color = row.node;
                                                  const colorHTML = (
                                                    <TableRow>
                                                      <TableCell
                                                        scope="row"
                                                        style={{
                                                          padding:
                                                            '0px 8px 0px 16px'
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            width: '40px',
                                                            height: '40px',
                                                            display:
                                                              'inline-block',
                                                            border:
                                                              '1px solid #333',
                                                            background:
                                                              color.hex
                                                          }}
                                                        />
                                                      </TableCell>

                                                      <TableCell
                                                        style={{
                                                          padding:
                                                            '0px 8px 0px 16px'
                                                        }}
                                                      >
                                                        {color.name
                                                          ? color.name
                                                          : color.internalName}{' '}
                                                      </TableCell>
                                                      <TableCell>
                                                        (
                                                        {color.code
                                                          ? color.code
                                                          : color.internalCode}
                                                        )
                                                      </TableCell>
                                                      <TableCell>
                                                        <Button
                                                          data-cy={
                                                            'Fabric-Add-Colors-btn-' +
                                                            color.name
                                                          }
                                                          style={{
                                                            display:
                                                              'inline-block'
                                                          }}
                                                          onClick={() => {
                                                            updateFabricComposition(
                                                              {
                                                                optimisticResponse: {
                                                                  __typename:
                                                                    'Mutation',
                                                                  updateFabricComposition: {
                                                                    ...fabricComposition,
                                                                    colorIds: fabricComposition.colorIds
                                                                      ? [
                                                                          ...fabricComposition.colorIds,
                                                                          color.id
                                                                        ]
                                                                      : [
                                                                          color.id
                                                                        ]
                                                                  }
                                                                },
                                                                variables: {
                                                                  id: fabricId,
                                                                  input: {
                                                                    colorIds: fabricComposition.colorIds
                                                                      ? [
                                                                          ...fabricComposition.colorIds,
                                                                          color.id
                                                                        ]
                                                                      : [
                                                                          color.id
                                                                        ]
                                                                  }
                                                                }
                                                              }
                                                            );
                                                          }}
                                                          key={color.id}
                                                        >
                                                          Add
                                                        </Button>
                                                      </TableCell>
                                                    </TableRow>
                                                  );
                                                  if (
                                                    fabricComposition.colorIds
                                                  ) {
                                                    if (
                                                      !_get(
                                                        fabricComposition,
                                                        'colorIds',
                                                        []
                                                      ).includes(color.id) &&
                                                      color.hex
                                                    ) {
                                                      return colorHTML;
                                                    }
                                                  } else {
                                                    return colorHTML;
                                                  }
                                                })}
                                              </TableBody>
                                            );
                                          } else {
                                            return <>No colors</>;
                                          }
                                        }}
                                      </Query>
                                    </Table>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    }}
                  </Mutation>
                );
              }}
            </Query>
          </Grid>
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(FabricDetail);
