import React, { useEffect, useState, useCallback } from 'react'
import {
  Editor,
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState
} from 'draft-js'
import {
  createStyles,
  withStyles,
  WithStyles,
} from '@material-ui/core'

import { isJSON, manageText, cleanTextInputField, addPx } from './utils'
import client from '../../gql/admin-apollo-config'
import { CONTENT_UPDATE_COLUMN_ROW } from '../../gql/admin-mutations'
import RichTextToolbar from './RichTextToolbar'
import RichTextSave from './RichTextSave'

const styles = () =>
  createStyles({
    editor: {
      '& li': {
        marginLeft: '20px'
      },
      '& ol': {
        listStyle: 'decimal'
      },
      '& ul': {
        listStyle: 'disc'
      },
      minHeight: '40px',
      padding: '10px',
      width: '100%'
    }
  })

interface Props extends WithStyles<typeof styles> {
  language?: string
  raw: string
  typoVariant?: string
  fontSize?: string
  isViewMode?: boolean
  columnRow?: any
  pageId?: string
  style?: any
  Template?: any
  dataCy?: string
}


const RichText: React.FunctionComponent<Props> = props => {
  const {
    classes,
    raw,
    typoVariant,
    fontSize,
    language,
    isViewMode,
    style,
    columnRow,
    pageId,
    Template,
    dataCy
  } = props

  const getInitContent = useCallback(() => {
    if (raw) {
      const content = isJSON(raw)
        ? convertFromRaw(JSON.parse(raw))
        : ContentState.createFromText(raw)
      return EditorState.createWithContent(content)
    } else {
      return EditorState.createWithContent(ContentState.createFromText('Type here'))
      // return EditorState.createEmpty()
    }
  }, [raw])

  const contentState = getInitContent()

  const [prevLanguage, setPrevLanguage] = useState('')
  const [editorState, setEditorState] = useState(contentState)
  const [isSaveEnabled, setIsSaveEnabled] = useState(false)

  useEffect(() => {
    if (!language || language === prevLanguage) {
      return
    }
    setPrevLanguage(language)
    setEditorState(getInitContent())
  }, [language, getInitContent])

  const handleChange = (text: EditorState): void => {
    if (isViewMode) {
      return
    }
    setEditorState(text)
    setIsSaveEnabled(true)
  }

  const handleSave = async (): Promise<any> => {
    if (isViewMode) {
      return
    }
    const rawText = convertToRaw(editorState.getCurrentContent())

    let t = manageText(
      columnRow.columnRowDetail,
      'text',
      language,
      JSON.stringify(rawText)
    )
    t = cleanTextInputField(t)
    console.log(t)

    await client.mutate({
      mutation: CONTENT_UPDATE_COLUMN_ROW,
      variables: {
        id: columnRow.id,
        input: {
          alignment: columnRow.alignment,
          columnRowDetail: {
            fontSize: addPx(fontSize),
            id: columnRow.id,
            text: t,
            typoVariant
          }
        },
        pageId
      }
    })

    Template.setState({
      selectedColRow: {
        ...Template.state.selectedColRow,
        fontSize: addPx(fontSize),
        id: columnRow.id,
        text: t,
        typoVariant,
        counter: Template.state.selectedColRow.counter
          ? Template.state.selectedColRow.counter + 1
          : 1
      }
    })

    setIsSaveEnabled(false)
  }

  return (
    <div
      data-cy={dataCy}
      className={classes.editor}
      style={{
        width: '100%',
        border: !isViewMode ? '1px solid #ccc' : 'none',
        cursor: !isViewMode ? 'default' : 'inherit'
      }}
    >
      {!isViewMode ? (
        <RichTextToolbar
          editorState={editorState}
          setEditorState={setEditorState}
        />
      ) : null}
      <div
        data-cy="Text-Editor"
        variant="body1"
        style={{
          ...style,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
        data-testid="richtext-editor"
      >
        <Editor
          editorState={editorState}
          onChange={handleChange}
          readOnly={isViewMode}
        />
      </div>
      {!isViewMode ? (
        <RichTextSave onSave={handleSave} disabled={!isSaveEnabled} />
      ) : null}
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(RichText)
