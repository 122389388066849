import React, { Component } from 'react'

import Main3D from './3D/Main3D'
import Loading from './tools/Loading'
import Dimension from './step/Dimension'
import FontLoad from './tools/FontLoad'
import MenuStep from './step/MenuStep'
import MenuDesignRoomStep from './step/MenuDesignRoomStep'
import BottomBar from './tools/Components/BottomBar'
import MarkerLayoutPlane from './tools/Components/MarkerLayoutPlane'
import Selection from './tools/Selection'
import ViewControl from './tools/Components/ViewControl'
import { API_STAGE } from '../../../brikl-config'

// MAIN CALL
import * as design_template from './tools/Functions/design_template'
// MAIN CALL

import * as template_control from './template_control'
import * as canvas_coloring from './tools/canvas_coloring'
import * as change_color from './tools/change_color'
import * as tools from './tools/tools'
import * as fabric_function from './FABRIC/fabric_function'
import * as svg_control from './FABRIC/svg_control'
import * as marker_layout_control from './FABRIC/marker_layout_control'
import * as keyboard_event from './tools/Functions/keyboard_event'
import * as control_tools_event from './tools/Functions/control_tools_event'
import * as save_load from './tools/Functions/save_load'
import * as control_view from './tools/Functions/control_view'

import './myCss.css'
import { Grid } from '@material-ui/core'

import SplitterLayout from 'react-splitter-layout'
import { PRODUCT_DESIGN } from '../../gql/queries'

import * as query from './mutation_query/query'
import * as local from './mutation_query/local'

import transparent from './TEMPLATE_ASSET/transparent.png'

class DesignTemplate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dynamicPlane: 'dynamicPlane',
      hidden3D: '',
      activeML: false
    }
    this.saveInterval = null
    this.SHOP_ID = this.props.SHOP_ID

    console.log('process.env', process.env.GATSBY_BRIKL_SALESCHANNEL_ID)

    this.design_template = design_template
    design_template.initComponent(this)
    design_template.declare_var(props)

    // INIT PRODUCT DATA
    if (this.props.localData === true) {
      local.productDataQuery(this)
    } else {
      query.productDataQuery(this)
    }
  }

  componentDidMount() {
    // AFTER 1ST RENDER

    // CONTROL FABRIC
    design_template.control_fabric_threejs()
    // CONTROL FABRIC

    fabric_function.initFabric()
    design_template.function_init_call()

    if (this.isSaveEnabled) {
      const saveIntervalTime = API_STAGE === 'PRODUCTION' ? 1 : 5
      this.template_control.capture4view()
      this.saveInterval = setInterval(
        this.template_control.capture4view,
        60000 * saveIntervalTime
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.saveInterval)
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    design_template.function_init_call()
  }

  splitDrag(size) {
    try {
      if (this.Main3D !== null) {
        this.Main3D.onWindowResize()
      }
    } catch (e) {
      console.error('DesignTemplate.splitDrag.error', e)
    }
  }

  componentDidUpdate() {
    this.splitDrag()
  }

  render() {
    const { dynamicPlane, hidden3D, activeML } = this.state

    console.log('DesignTemplate', this.productID) // proID

    var show2D = 'none'
    if (this._SHOW_2D === true) {
      show2D = 'block'
    }

    // SHOP CONDITION

    var planeSize = { height: '100vh', position: 'relative' }
    var bottomTool = null
    var markerLayoutPlane = null
    var hideMenu = 'block'

    if (this.props.shopId === 'yuepai') {
      planeSize = { height: 'calc(100vh - 50px)' }
      bottomTool = <BottomBar DesignRoot={this} />

      if (activeML === true) {
        markerLayoutPlane = <MarkerLayoutPlane DesignRoot={this} />
        var hideMenu = 'none'
      }
    }

    // SHOP CONDITION

    if (this._APP_TYPE !== '2d-3d') {
      return (
        <div className="DesignTemplate">
          <Loading DesignRoot={this} position="center" />
          <div style={{ display: show2D }}>
            <canvas id="canvasEditor" width="1" height="1" />
          </div>
          <div style={{ position: 'relative', width: 'calc(100vw - 420px)' }}>
            <Dimension DesignRoot={this} />
            <Main3D DesignRoot={this} productDataSet={this.productDataSet} />
          </div>
          <MenuStep
            langCode={this.props.langCode}
            shopConfiguration={this.props.shopConfiguration}
            DesignRoot={this}
          />
          <FontLoad DesignRoot={this} />
          <Selection DesignRoot={this} productDataSet={this.productDataSet} />
        </div>
      )
    } else {
      return (
        <div className="DesignTemplate">
          <Loading DesignRoot={this} position="center" />
          <ViewControl DesignRoot={this} />
          <span style={{ display: hideMenu }}>
            <MenuDesignRoomStep DesignRoot={this} />
          </span>
          <FontLoad DesignRoot={this} />

          {markerLayoutPlane}

          <div className={dynamicPlane + ' ' + hidden3D}>
            <SplitterLayout
              primaryIndex={1}
              onSecondaryPaneSizeChange={this.splitDrag.bind(this)}
              secondaryInitialSize={60}
              percentage={true}
              style={planeSize}
            >
              <div style={planeSize} id="PLANE_2D">
                <canvas
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%'
                  }}
                  width="1"
                  height="1"
                  id="canvasEditor"
                />
              </div>

              <div id="PLANE_3D" style={planeSize}>
                <Main3D
                  DesignRoot={this}
                  productDataSet={this.productDataSet}
                />
                <Dimension DesignRoot={this} />
              </div>
            </SplitterLayout>
          </div>

          {bottomTool}

          <div id="canvasRenderDom">
            <canvas id="canvasRender" width="1" height="1" />
          </div>

          <Selection DesignRoot={this} productDataSet={this.productDataSet} />
        </div>
      )
    }
  }
}

export default DesignTemplate
