import { ApolloConsumer, Query } from 'react-apollo';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { CUSTOM_PRODUCTS, PRODUCT_DESIGN } from '~/src/gql/queries';
import * as material_ui from '../tools/material_ui';
import _get from 'lodash/get';
import { getTextFromLanguage } from '~/src/helper/languageHelper';
import Loadable from 'react-loadable';
import LoadingComponent from '../../common/LoadingComponent';
import ProductCard from '../../store/product/gallery/ProductCard';
import { transformDataForLoad } from '../tools/Functions/load';
import { API_STAGE } from '~/brikl-config';

import * as tools from '../tools/tools';
let SHOP_ID = tools.getQueryStringValue('s');

const DesignTemplate = Loadable({
  loader: () => import('../DesignTemplate'),
  loading: LoadingComponent
});

export function product_list(self) {
  const {
    classes,
    pageContext,
    currentOrganisation,
    categoryTab,
    filterBy
  } = self.props;

  let langCode, urlPrefix;
  try {
    if (pageContext) {
      langCode = pageContext.langCode;
      urlPrefix = pageContext.urlPrefix;
    } else {
      langCode = 'en_UK';
      urlPrefix = 'en/';
    }
  } catch (error) {}

  let customProductVariables = {
    id: SHOP_ID
  };
  try {
    if (
      self.props.pageContext.teamstoreData &&
      self.props.pageContext.teamstoreData.id
    ) {
      customProductVariables.salesChannelIds = [
        self.props.pageContext.teamstoreData.id
      ];
    } else {
      customProductVariables.salesChannelIds = ['MYBRIKL'];
    }
  } catch (error) {}

  return (
    <Query query={CUSTOM_PRODUCTS} variables={customProductVariables}>
      {({ loading, error, data }) => {
        if (error) return <div>Error :(</div>;
        var customProducts = [];
        try {
          if (data.searchCustomProducts) {
            customProducts = data.searchCustomProducts;
          }
        } catch (error) {}
        try {
          if (categoryTab) {
            customProducts = customProducts.filter(p => {
              return p &&
                p.collections &&
                JSON.stringify(p.collections)
                  .toLowerCase()
                  .includes(categoryTab.toLowerCase())
                ? true
                : false;
            });
          }
        } catch (error) {}
        try {
          if (filterBy && filterBy !== '') {
            customProducts = customProducts.filter(p => {
              return p &&
                p.collections &&
                JSON.stringify(p.collections)
                  .toLowerCase()
                  .includes(filterBy.toLowerCase())
                ? true
                : false;
            });
          }
        } catch (error) {}

        return (
          <Grid
            container
            spacing={2}
            style={{ margin: 'auto', maxWidth: 1000, padding: 24 }}
          >
            {customProducts.length > 0
              ? customProducts.map(product => {
                  // const product = productNode.node;
                  return (
                    <ProductCard
                      taxSettings={
                        pageContext && pageContext.shop
                          ? pageContext.shop.taxSettings
                          : null
                      }
                      langCode={langCode}
                      urlPrefix={urlPrefix}
                      product={product}
                      buttonText={{
                        text: [
                          {
                            langCode,
                            content: 'Design now'
                          }
                        ]
                      }}
                      customUrl={`/${urlPrefix}new-design/?o=${currentOrganisation.id}&p=${product.id}&v=V2&sc=MYBRIKL`}
                    />
                  );
                })
              : loading
              ? 'Loading...'
              : 'No custom products'}
          </Grid>
        );
      }}
    </Query>
  );
}

export function main_template(self, loadingBG) {
  const { loadData, ready, scriptReady, loading } = self.state;
  return (
    <ApolloConsumer>
      {client => (
        <Query
          query={PRODUCT_DESIGN}
          variables={{
            shopId: self.props.shopId,
            id: self.props.productDesignId,
            ownedByOrganisationId: self.props.organisationId
              ? self.props.organisationId
              : self.props.ownedByOrganisationId
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return <div className="MainTemplate">{}</div>;
            if (error) return <div>Error :(</div>;
            let productDesign = null;
            let shopConfiguration = null;
            let loadDataFromDB = null;
            try {
              productDesign = data.getProductDesign;
              shopConfiguration = data.shopConfiguration;

              loadDataFromDB = transformDataForLoad(
                self.productID,
                productDesign.latestRevision,
                productDesign.product.designTemplates,
                productDesign.productElements
              );
              self.onLoad = !!(
                loadDataFromDB.templateId ||
                loadDataFromDB.allItem.length > 0 ||
                Object.keys(loadDataFromDB.directElm).length > 0 ||
                Object.keys(loadDataFromDB.mapColor).length > 0
              );

              console.log(
                'loadDataFromDB',
                self.onLoad,
                loadDataFromDB,
                productDesign
              );
            } catch (error) {}
            return productDesign && productDesign.id ? (
              <DesignTemplate
                langCode={self.props.langCode}
                shopConfiguration={shopConfiguration}
                urlPrefix={self.props.urlPrefix}
                productDesign={productDesign}
                client={client}
                ownedByOrganisationId={self.props.ownedByOrganisationId}
                productDesignId={self.props.productDesignId}
                productID={self.productID}
                onLoad={self.onLoad}
                loadData={loadDataFromDB}
                MainTemplate={self}
                SAVE_ID={self._SAVE_ID}
                templateID={self.templateID}
                localData={self.localData}
                SHOP_ID={self.SHOP_ID}
              />
            ) : (
              'Design not found'
            );
          }}
        </Query>
      )}
    </ApolloConsumer>
  );
}

export async function productDataQuery(self) {
  try {
    // statements

    const apolloClient = self.props.client;

    const productDesign = self.props.productDesign;

    console.log('productDesign', productDesign, self.SHOP_ID, self);

    // CHECK REQUIRE FILE & INIT DEFAULT FOR BLANK VAR

    try {
      if (productDesign.product.objSrc) {
        console.log('OBJ CHECKED', productDesign.product.objSrc);
      } else {
        console.log('OBJ NOT FOUND', productDesign.product.objSrc);
        alert('OBJ file missing. Please contact administrator.');
        return false;
      }

      if (productDesign.product.mtlSrc) {
        console.log('MTL CHECKED', productDesign.product.mtlSrc);
      } else {
        console.log('MTL NOT FOUND', productDesign.product.mtlSrc);
        alert('MTL file missing. Please contact administrator.');
        return false;
      }

      if (productDesign.product.baseTemplateSrc) {
        console.log('SVG CHECKED', productDesign.product.baseTemplateSrc);
      } else {
        console.log('SVG NOT FOUND', productDesign.product.baseTemplateSrc);
        alert('SVG file missing. Please contact administrator.');
        return false;
      }

      // templateSvg = productDesign.product.baseTemplateSrc;
    } catch (e) {
      console.error(
        'query.productDataQuery.CHECK REQUIRE FILE & INIT DEFAULT FOR BLANK VAR.error',
        e
      );
    }

    // CHECK REQUIRE FILE & INIT DEFAULT FOR BLANK VAR

    // var productConfig = require('./TEMPLATE_ASSET/' +
    //   self.productID +
    //   '/config.json');
    // productConfig = productConfig[self.productID];
    const productConfig = {
      id: productDesign.product.id,
      stack:
        self.SHOP_ID === 'yuepai'
          ? [
              {
                stackId: 'XL',
                stackSvg: 'STACKS/Print pattern XL-01.svg',
                legendColor: '#f00',
                svgCalElm: 'E4-1',
                svgCalCm: 41.71
              },
              {
                stackId: 'L',
                stackSvg: 'STACKS/Print pattern size L seam allowance.svg',
                legendColor: '#00f',
                svgCalElm: 'E4-1',
                svgCalCm: 39.23
              }
            ]
          : []
    };

    console.log('productConfig', productConfig);

    const OBJ = productDesign.product.objSrc;
    // const OBJ = require('../TEMPLATE_ASSET/M11-01.obj');
    const MTL = productDesign.product.mtlSrc;
    // var MTL = require('./TEMPLATE_ASSET/' +
    //   self.productID +
    //   '/' +
    //   productConfig.mtl);

    const MTL_PATTERN_PREFIX = 'PrintPattern';
    // var MTL_PATTERN_PREFIX = productConfig.mtlPatternPrefix;

    let redLineSrc = null;
    // if (productConfig.redLineSrc) {
    //   redLineSrc = require('./TEMPLATE_ASSET/' +
    //     self.productID +
    //     '/' +
    //     productConfig.redLineSrc);
    // }
    if (productDesign.product.seamSrc) {
      redLineSrc = productDesign.product.seamSrc;
    }

    let templateSvg = null;
    // if (productConfig.templateSvg) {
    //   templateSvg = require('./TEMPLATE_ASSET/' +
    //     self.productID +
    //     '/' +
    //     productConfig.templateSvg);
    // }
    if (productDesign.product.baseTemplateSrc) {
      templateSvg = productDesign.product.baseTemplateSrc;
    }

    // productDesign.product.modelConfiguration.dimension.baseTemplate
    // productDesign.product.modelConfiguration.dimension.baseSize
    // productDesign.product.modelConfiguration.dimension.pxToCm
    // var dimension = productConfig.dimension;

    if (
      productDesign.product.modelConfiguration !== null &&
      productDesign.product.modelConfiguration.dimension
    ) {
      var dimension = productDesign.product.modelConfiguration.dimension;
      self._PXTOCM = dimension.pxToCm;
    } else {
      console.log('DIMENSION WILL ASSUME');
      var dimension = {
        baseTemplate: 3000,
        baseSize: 'width',
        pxToCm: 0.5
      }; // DEFAULT DIMENSION
      self._PXTOCM = dimension.pxToCm;
    }

    // DEFAULT TEMPLATE XCEED

    if (productDesign.product.defaultTemplateSrc !== undefined && self._DEFAULT_TEMPLATE_ACTIVE === true) {
      self._DEFAULT_TEMPLATE_SRC = productDesign.product.defaultTemplateSrc;
    } else  {
      self._DEFAULT_TEMPLATE_ACTIVE = false;
    }

    // DEFAULT TEMPLATE XCEED

    console.log('dimension', dimension);

    // var TEMPLATE_LOGO = require('./TEMPLATE_ASSET/'+self.productID+"/"+self.productID+"-template-logo.png");

    // var TEMPLATE_BASE = [];
    // var TEMPLATE_REVIEW = [];
    // var TEMPLATE_COLORZONE = [];
    var TEMPLATE_SET_BY_ID = {};
    var TEMPLATE_SET_DATA = [];

    // for (var i = 0; i < productConfig.numberOfTemplate; i++) {
    //   var templateSet = productConfig.templateSet[i];

    //   var templateSrc = require('./TEMPLATE_ASSET/' +
    //     self.productID +
    //     '/' +
    //     templateSet.templateSrc);
    //   var templatePreview = require('./TEMPLATE_ASSET/' +
    //     self.productID +
    //     '/' +
    //     templateSet.templatePreview);
    //   var templateLogo = require('./TEMPLATE_ASSET/' +
    //     self.productID +
    //     '/' +
    //     templateSet.templateLogo);
    //   var templateColor = templateSet.colorData;

    //   // TEMPLATE_BASE.push(templateSrc);
    //   // TEMPLATE_REVIEW.push(templatePreview);
    //   // TEMPLATE_COLORZONE.push(templateColor);

    //   TEMPLATE_SET_DATA.push({
    //     templateId: templateSet.templateId,
    //     templateSrc: templateSrc,
    //     templatePreview: templatePreview,
    //     templateLogo: templateLogo,
    //     colorData: templateColor
    //   });

    //   TEMPLATE_SET_BY_ID[templateSet.templateId] = TEMPLATE_SET_DATA[i];
    // }
    let numberOfTemplates = 0;
    if (
      productDesign.product.designTemplates &&
      productDesign.product.designTemplates.length &&
      self._ACTIVE_COLOR_ZONE === true
    ) {
      numberOfTemplates = productDesign.product.designTemplates.length;
      productDesign.product.designTemplates.forEach(
        (designTemplate, designTemplateIndex) => {
          const template = designTemplate;
          console.log('template', template);
          console.log('template color', template.colors);

          if ( template.colors !== null ) {

            TEMPLATE_SET_DATA.push({
              templateId: template.id,
              templateSrc: template.templateFileSrc,
              templatePreview: template.previewImageSrc,
              templateLogo: template.logobaseFileSrc,
              colorData: template.colors.map(color => {
                if (color) {
                  return {
                    title: color.name,
                    color: color.customColorHex
                      ? color.customColorHex.toLowerCase()
                      : '#000000',
                    coord: {
                      x: color.coordinates.x,
                      y: color.coordinates.y
                    },
                    id: color.id
                  };
                }
              })
            });
            TEMPLATE_SET_BY_ID[template.id] =
              TEMPLATE_SET_DATA[designTemplateIndex];

          } // END CHECK COLORS
          else {

            alert('### HAVE TEMPLATE BUT NO COLOR ZONE : TEMPLATE WILL REJECT ###');
            console.log("### HAVE TEMPLATE BUT NO COLOR ZONE : TEMPLATE WILL REJECT ###");
            numberOfTemplates = numberOfTemplates--;
          }

          
        }
      );
    } else {
      self._ACTIVE_COLOR_ZONE = false;
      self._BLANK_TEMPLATE_ACTIVE === true;
    }

    if ((self._SVG_ACTIVE === true && self._ACTIVE_COLOR_ZONE === false) || self._BLANK_TEMPLATE_ACTIVE === true || TEMPLATE_SET_DATA.length === 0) {
      // ADD BLANK TEMPLATE

      try {

        // TODO fix self
        TEMPLATE_SET_DATA.push({
          templateId: 'BLANK',
          templatePreview: require('../TEMPLATE_ASSET/no-image.png')
        });

        TEMPLATE_SET_BY_ID['BLANK'] =
          TEMPLATE_SET_DATA[TEMPLATE_SET_DATA.length - 1];
      } catch (error) {
        console.error('BLANK TEMPLATE ERROR', error);
      }

      // ADD BLANK TEMPLATE
    }

    self.productDataSet = {
      ID: productConfig.id,
      productConfig: productConfig,
      productDesign: productDesign,
      productTitle: productDesign.product.title,
      productDescription: productDesign.product.description,
      OBJ: OBJ,
      MTL: MTL,
      MTL_PATTERN_PREFIX: MTL_PATTERN_PREFIX,
      NO_OF_TEMPLATE: numberOfTemplates,
      redLineSrc: redLineSrc,
      templateSvg: templateSvg,
      dimension: dimension,
      elements: productDesign.productElements,
      // ACTIVE_TEMPLATE_INDEX: 0,
      // ACTIVE_TEMPLATE_ID: productConfig.templateSet[0].templateId, // INIT FIRST TEMPLATE // USE self BE DEFAULT
      // TEMPLATE_BASE: TEMPLATE_BASE, // MIGHT REMOVE
      // TEMPLATE_REVIEW: TEMPLATE_REVIEW, // MIGHT REMOVE
      // COLOR_DATA: TEMPLATE_COLORZONE, // MIGHT REMOVE
      TEMPLATE_SET: TEMPLATE_SET_DATA,
      TEMPLATE_SET_BY_ID: TEMPLATE_SET_BY_ID // USE self BE DEFAULT
    };

    console.log('TEMPLATE_SET_DATA', TEMPLATE_SET_DATA);
    console.log('self.productDataSet', self.productDataSet);

    // CHECK REQUIRE VAR BEFORE GO NEXT
    if (TEMPLATE_SET_DATA.length === 0) {
      console.log('TEMPLATE_SET_DATA NOT FOUND', TEMPLATE_SET_DATA);
      alert('TEMPLATE_SET_DATA missing. Please contact administrator.');
      return false;
    }
    // CHECK REQUIRE VAR BEFORE GO NEXT

    if (self.templateID === null || self.templateID === 'LOAD') {
      self._ACTIVE_TEMPLATE_ID = self.productDataSet.TEMPLATE_SET[0].templateId;

      if (self._ACTIVE_COLOR_ZONE === true) {
        self._SELECT_COLOR_ZONE = self.productDataSet.TEMPLATE_SET[0].colorData[0].color.toLowerCase();
        self._CURRENT_TEMPLATE_SRC =
          self.productDataSet.TEMPLATE_SET[0].templateSrc;
        self._CURRENT_LOGO_SRC =
          self.productDataSet.TEMPLATE_SET[0].templateLogo;
      }

      self._CURRENT_SEAM_SRC = self.productDataSet.redLineSrc;
      self._CURRENT_SVG_SRC = self.productDataSet.templateSvg;
    } else {
      self._ACTIVE_TEMPLATE_ID = self.templateID;

      if (self._ACTIVE_COLOR_ZONE === true) {
        self._SELECT_COLOR_ZONE = self.productDataSet.TEMPLATE_SET_BY_ID[
          self._ACTIVE_TEMPLATE_ID
        ].colorData[0].color.toLowerCase();

        self._CURRENT_TEMPLATE_SRC =
          self.productDataSet.TEMPLATE_SET_BY_ID[
            self._ACTIVE_TEMPLATE_ID
          ].templateSrc;
        self._CURRENT_LOGO_SRC =
          self.productDataSet.TEMPLATE_SET_BY_ID[
            self._ACTIVE_TEMPLATE_ID
          ].templateLogo;
      }

      self._CURRENT_SVG_SRC = self.productDataSet.templateSvg;
    }

    if (self.SHOP_ID === 'yuepai') {
      self._STACK = [];

      for (var i = 0; i < productConfig.stack.length; i++) {
        var stackSvg = require('../TEMPLATE_ASSET/' +
          'YOGA' +
          '/' +
          productConfig.stack[i].stackSvg);

        self._STACK.push({
          stackId: productConfig.stack[i].stackId,
          stackSvg: stackSvg,
          legendColor: productConfig.stack[i].legendColor,
          svgCalElm: productConfig.stack[i].svgCalElm,
          svgCalCm: productConfig.stack[i].svgCalCm
        });
      }
    }

    // HANDLE LOAD DATA

    console.log('HANDLE LOAD', self.onLoad, self.loadData);

    if (self.onLoad === true) {
      var loadData = self.loadData;
      if (loadData.id === self.productDataSet.ID) {
        self._LOAD_DATA = loadData;

        var id = loadData.id;
        var templateId = loadData.templateId;
        var mapColor = loadData.mapColor;
        var allItem = loadData.allItem;
        var logoActive = loadData.logoActive;

        // INIT LOAD BLANK TEMPLATE

        if ( templateId === null && self._BLANK_TEMPLATE_ACTIVE === true ) {

          templateId = "BLANK";
          self._ACTIVE_TEMPLATE_ID = templateId;
          self._ACTIVE_COLOR_ZONE = false
        }

        // INIT LOAD BLANK TEMPLATE

        if (self._ACTIVE_TEMPLATE_ID !== templateId && self._ACTIVE_TEMPLATE_ID !== 'BLANK' ) {
          self._ACTIVE_TEMPLATE_ID = templateId;

          if ( self._ACTIVE_COLOR_ZONE === true ) {

            self._SELECT_COLOR_ZONE = self.productDataSet.TEMPLATE_SET_BY_ID[
              templateId
            ].colorData[0].color.toLowerCase();
          }

          self._CURRENT_TEMPLATE_SRC =
            self.productDataSet.TEMPLATE_SET_BY_ID[templateId].templateSrc;
          self._CURRENT_LOGO_SRC =
            self.productDataSet.TEMPLATE_SET_BY_ID[templateId].templateLogo;
          self._CURRENT_SVG_SRC = self.productDataSet.templateSvg;
        }

        if (logoActive && logoActive === true) {
          self._LOGO_TOGGLE = logoActive;
        }
      } else {
        self.onLoad = false;
      }
    }

    // HANDLE LOAD DATA

    console.log(
      '############################################################################'
    );
    console.log(
      '############################################################################'
    );
    console.log(
      '############################################################################'
    );
    console.log('PRODUCT CONFIG INIT', self.productDataSet, self);
    console.log('SHOP_ID', self.SHOP_ID);
    console.log('API_STAGE', API_STAGE);
    console.log('_ACTIVE_COLOR_ZONE', self._ACTIVE_COLOR_ZONE);
    console.log('_SVG_ACTIVE', self._SVG_ACTIVE);
    console.log('_LOGO_TOGGLE', self._LOGO_TOGGLE);
    console.log('_SEAM_TOGGLE', self._SEAM_TOGGLE);
    console.log('_ACTIVE_TEMPLATE_ID', self._ACTIVE_TEMPLATE_ID);

    // if (self._ACTIVE_TEMPLATE_ID === 'BLANK') {

    //   self._BLANK_TEMPLATE_ACTIVE = true;
    // }
    // else {

    //   self._BLANK_TEMPLATE_ACTIVE = false;
    // }

    console.log('_CURRENT_TEMPLATE_SRC', self._CURRENT_TEMPLATE_SRC);
    console.log('_CURRENT_LOGO_SRC', self._CURRENT_LOGO_SRC);
    console.log('_CURRENT_SEAM_SRC', self._CURRENT_SEAM_SRC);
    console.log('_CURRENT_SVG_SRC', self._CURRENT_SVG_SRC);
    console.log('_BLANK_TEMPLATE_ACTIVE', self._BLANK_TEMPLATE_ACTIVE);
    console.log('_DEFAULT_TEMPLATE_ACTIVE', self._DEFAULT_TEMPLATE_ACTIVE);
    console.log('_DEFAULT_TEMPLATE_SRC', self._DEFAULT_TEMPLATE_SRC);

    console.log('_LOAD_DATA', self._LOAD_DATA);
    console.log('_SAVE_ID', self._SAVE_ID);
    console.log(
      '############################################################################'
    );
    console.log(
      '############################################################################'
    );
    console.log(
      '############################################################################'
    );

    // self.Main3D.init();
  } catch (e) {
    // statements
    console.error('DesignTemplate.productDataQuery.error', e);
  }
}
