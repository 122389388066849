import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { navigate } from '@reach/router'
import _get from 'lodash/get'
import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import { ADMIN_SHOP_PRODUCTS } from '../../gql/admin-queries'
import AdminSearchProduct from '../common/AdminSearchProduct'
import ProductTable from '../UI/ProductTable'
import AddProduct from './AddProduct'

const styles = theme => ({
  progress: {},
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    margin: '0 auto',
    width: '100%'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242'
  },
  loadMoreContainer: {
    display: 'flex',
    margin: '10px 0px',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

class ProductList extends Component {
  state = {
    filter: '',
    showActive: 'ACTIVE'
  }
  render() {
    const {
      classes,
      defaultLanguage,
      shopId,
      styles,
      productPackageId,
      updateProductPackage,
      productPackage
    } = this.props

    return (
      <Query
        query={ADMIN_SHOP_PRODUCTS}
        variables={{
          id: shopId,
          first: 50,
          filter: {
            status: { eq: this.state.showActive ? 'ACTIVE' : 'INACTIVE' }
          }
        }}
      >
        {({ loading, error, data, fetchMore, refetch }) => {
          if (loading) return <CircularProgress className={classes.progress} />
          if (error) return <div>Error :(</div>
          const filteredProducts = data.shop.products.edges
          return (
            <div className={classes.root}>
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                style={{ minHeight: '60px', marginBottom: '20px' }}
              >
                <Grid item xs={12} sm={3}>
                  <Typography
                    data-cy="Header-Products"
                    component="h1"
                    className={classes.h1}
                  >
                    Products
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <AddProduct shopId={shopId} />
                </Grid>
              </Grid>
              <div>
                <input
                  onChange={() => {
                    this.setState({
                      showActive: !this.state.showActive
                    })
                  }}
                  type="checkbox"
                  checked={this.state.showActive ? 'checked' : ''}
                />
                Show active products
              </div>
              <div
                style={{
                  margin: '1em 0 2em 0'
                }}
              >
                <AdminSearchProduct
                  text={'Search'}
                  classes={classes}
                  onSelect={product => {
                    navigate(
                      '/admin/' + shopId + '/inventory/products/' + product.id
                    )
                  }}
                  excludeIds={[]}
                />
              </div>
              <div>
                <ProductTable
                  filteredProducts={filteredProducts}
                  defaultLanguage={defaultLanguage}
                  productPackageId={productPackageId}
                  productPackage={productPackage}
                  updateProductPackage={updateProductPackage}
                  shopId={shopId}
                />

                {/* {data.shop.products.pageInfo.hasNextPage && (
                                        <div class={classes.loadMoreContainer}>
                                          <Button
                                            color="primary"
                                            variant="outlined"
                                            onClick={async () => {
                                              fetchMore({
                                                variables: {
                                                  id: shopId,
                                                  after: data.shop.products.pageInfo.endCursor
                                                },
                                                updateQuery: (previousData, { fetchMoreResult }) => {
                                                  if (!fetchMoreResult) return previousData;

                                                  const result = {
                                                    shop: {
                                                      ...previousData.shop,
                                                      products: {
                                                        __typename:
                                                          previousData.shop.products.__typename,
                                                        edges: [
                                                          ...previousData.shop.products.edges,
                                                          ...fetchMoreResult.shop.products.edges
                                                        ],
                                                        pageInfo:
                                                          fetchMoreResult.shop.products.pageInfo
                                                      }
                                                    }
                                                  };

                                                  return result;
                                                }
                                              });
                                            }}
                                          >
                                            Load More
                                          </Button>
                                        </div>
                                      )} */}
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
}

export default withStyles(styles)(ProductList)
