import React, { Component } from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Query, Mutation } from 'react-apollo';
import {
  ADMIN_GET_DISCOUNT,
  ADMIN_SHOP_PRODUCTS
} from '../../gql/admin-queries';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  ADMIN_UPDATE_DISCOUNT,
  ADMIN_DELETE_DISCOUNT
} from '../../gql/admin-mutations';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import _get from 'lodash/get';
import uuid from 'uuid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '../../../common/Input';
import Select from '../../../common/Select';
import DiscountRules from './DiscountRules';
import AddCoupon from '../coupons/AddCoupon';
import DiscountCoupons from './DiscountCoupons';
import DiscountTitle from './DiscountTitle';
import DiscountAvailableFrom from './DiscountAvailableFrom';
import DiscountAvailableUntil from './DiscountAvailableUntil';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import AdminSearchProduct from '../common/AdminSearchProduct';
import { navigate } from 'gatsby-link';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%',
    position: 'relative'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: 'auto'
  },
  paperSelect: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '0',
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    border: 'none',
    width: '100%',
    background: 'transparent',
    padding: '6px 16px',
    height: '42px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  },
  rootCheckbox: {
    padding: '3px 8px',
    color: '#B23566 !important',
    height: 30,
    width: 30,
    '&$checked': {
      color: '#B23566'
    }
  },
  checkboxLabel: {
    color: '#B23566 !important'
  }
});

class DiscountDetail extends Component {
  state = {};
  render() {
    const {
      currencies,
      defaultLanguage,
      discountId,
      classes,
      isCouponPage,
      languages,
      shopId
    } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid
          container
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ height: 60, marginBottom: 20 }}
        >
          <Typography
            style={{
              fontSize: 28,
              fontWeight: 400,
              color: '#424242'
            }}
          >
            Discount
          </Typography>
          <div style={{ flex: 1 }} />
        </Grid>

        <Query
          query={ADMIN_GET_DISCOUNT}
          variables={{ id: discountId, first: 50 }}
        >
          {({ loading, error, data }) => {
            if (loading) return <CircularProgress />;
            if (error) return <div>Error :(</div>;
            if (!data || !data.discount) return null;
            const discount = data.discount;
            return (
              <Grid container>
                <Mutation
                  mutation={ADMIN_UPDATE_DISCOUNT}
                  update={(cache, { data: { updateDiscount } }) => {
                    if (updateDiscount.id) {
                      // const data = store.readQuery({
                      //   query: ADMIN_GET_DISCOUNT,
                      //   variables: { id: discountId }
                      // });
                      // data.product = updateDiscount;
                      // store.writeQuery({
                      //   query: ADMIN_GET_DISCOUNT,
                      //   variables: { id: discountId },
                      //   data
                      // });
                    }
                  }}
                >
                  {(updateDiscount, { data, loading, error }) => {
                    return (
                      <Grid container>
                        <FormControlLabel
                          style={{
                            position: 'absolute',
                            top: 36,
                            left: 200,
                            border: '1px solid #B23566',
                            borderRadius: 4,
                            paddingRight: 12
                          }}
                          control={
                            <Checkbox
                              classes={{
                                root: classes.rootCheckbox,
                                label: classes.checkboxLabel
                              }}
                              onChange={e => {
                                updateDiscount({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    updateDiscount: {
                                      ...discount,
                                      enabled: e.target.checked
                                    }
                                  },
                                  variables: {
                                    id: discountId,
                                    input: {
                                      enabled: e.target.checked
                                    }
                                  }
                                });
                              }}
                              placeholder="Enabled"
                              checked={
                                discount.enabled === true ? 'checked' : ''
                              }
                              id="enabled"
                            />
                          }
                          label="Enabled"
                        />
                        <Grid container>
                          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <Grid
                              container
                              style={{ padding: '24px 24px 24px 0px' }}
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item xs={12}>
                                <Typography
                                  variant="subtitle1"
                                  style={{ marginRight: 16 }}
                                >
                                  Discount
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body1">
                                  Provide discount information
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                            <Paper elevation={0} style={{ padding: 24 }}>
                              <Grid container spacing={1} direction="column">
                                <Grid item xs={12}>
                                  <Input
                                    label="Internal name"
                                    placeholder="Internal name"
                                    onChange={e => {
                                      updateDiscount({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateDiscount: {
                                            ...discount,
                                            internalNote: e.target.value
                                          }
                                        },
                                        variables: {
                                          id: discountId,
                                          input: {
                                            internalNote: e.target.value
                                          }
                                        }
                                      });
                                    }}
                                    type="text"
                                    value={discount.internalNote}
                                    id="internalNote"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Input
                                    label="Accounting ID (Used to identify in your accounting system)"
                                    placeholder="Enter accounting reference ID"
                                    value={discount.accountingId}
                                    onChange={e =>
                                      updateDiscount({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateDiscount: {
                                            ...discount,
                                            accountingId: e.target.value
                                          }
                                        },
                                        variables: {
                                          id: discountId,
                                          input: {
                                            accountingId: e.target.value
                                          }
                                        }
                                      })
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: 24 }}>
                          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <Grid
                              container
                              style={{ padding: '24px 24px 24px 0px' }}
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item xs={12}>
                                <Typography
                                  variant="subtitle1"
                                  style={{ marginRight: 16 }}
                                >
                                  Discount title
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body1"></Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                            <Paper elevation={0} style={{ padding: 24 }}>
                              <Grid container spacing={1} direction="column">
                                <DiscountTitle
                                  languages={languages}
                                  classes={classes}
                                  discount={discount}
                                  discountId={discountId}
                                  shopId={shopId}
                                  updateDiscount={updateDiscount}
                                />
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: 24 }}>
                          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <Grid
                              container
                              style={{ padding: '24px 24px 24px 0px' }}
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item xs={12}>
                                <Typography
                                  variant="subtitle1"
                                  style={{ marginRight: 16 }}
                                >
                                  Discount priority
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body1"></Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                            <Paper elevation={0} style={{ padding: 24 }}>
                              <Grid container spacing={1} direction="column">
                                <Grid item xs={12}>
                                  <Input
                                    label="Priority"
                                    placeholder="Priority"
                                    onChange={e => {
                                      updateDiscount({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateDiscount: {
                                            ...discount,
                                            priority: parseInt(
                                              e.target.value,
                                              10
                                            )
                                          }
                                        },
                                        variables: {
                                          id: discountId,
                                          input: {
                                            priority: parseInt(
                                              e.target.value,
                                              10
                                            )
                                          }
                                        }
                                      });
                                    }}
                                    placeholder="Priority"
                                    type="text"
                                    value={discount.priority}
                                    id="priority"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{ marginTop: 16, marginBottom: 16 }}
                                  >
                                    <Checkbox
                                      classes={{
                                        root: classes.cssRootInput,
                                        input: classes.cssinput
                                      }}
                                      onChange={e => {
                                        updateDiscount({
                                          optimisticResponse: {
                                            __typename: 'Mutation',
                                            updateDiscount: {
                                              ...discount,
                                              canBeCombinedWithOtherDiscounts:
                                                e.target.checked
                                            }
                                          },
                                          variables: {
                                            id: discountId,
                                            input: {
                                              canBeCombinedWithOtherDiscounts:
                                                e.target.checked
                                            }
                                          }
                                        });
                                      }}
                                      placeholder="canBeCombinedWithOtherDiscounts"
                                      checked={
                                        discount.canBeCombinedWithOtherDiscounts ===
                                        true
                                          ? 'checked'
                                          : ''
                                      }
                                      id="canBeCombinedWithOtherDiscounts"
                                    />
                                    <Typography
                                      variant="body1"
                                      style={{ width: '70%' }}
                                    >
                                      Can be combined with other discounts?
                                      <br />
                                      <span
                                        style={{
                                          fontSize: '90%'
                                        }}
                                      >
                                        If discounts can not be combined the
                                        priority will decide which discount is
                                        applied (lowest priority number first
                                        e.g. 1 then 2 etc.)
                                      </span>
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Select
                                    label="Discount Type"
                                    className={classes.select}
                                    value={discount.discountType}
                                    onChange={e => {
                                      updateDiscount({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateDiscount: {
                                            ...discount,
                                            discountType: e.target.value
                                          }
                                        },
                                        variables: {
                                          id: discountId,
                                          input: {
                                            discountType: e.target.value
                                          }
                                        }
                                      });
                                    }}
                                  >
                                    <option value={''}>Select a type</option>
                                    {/* <option value={'FIXED_ITEM_DISCOUNT'}>
                                    Fixed amount on an item
                                  </option>
                                  <option value={'PERCENT_ITEM_DISCOUNT'}>
                                    Percentage discount on an item
                                  </option> */}
                                    <option value={'FIXED_TOTAL_DISCOUNT'}>
                                      Fixed amount
                                      {/* on a cart total */}
                                    </option>
                                    <option value={'FREE_SHIPPING'}>
                                      Free shipping
                                    </option>
                                    <option value={'BUY_X_GET_Y'}>
                                      Buy X Get Y
                                    </option>

                                    <option value={'PERCENT_TOTAL_DISCOUNT'}>
                                      Percentage discount
                                      {/* on a cart total */}
                                    </option>
                                  </Select>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container spacing={1}>
                                    {discount.discountType &&
                                    discount.discountType.indexOf('_TOTAL_') !==
                                      -1 ? (
                                      <Grid item>
                                        <Input
                                          label={
                                            discount.discountType &&
                                            discount.discountType.indexOf(
                                              'PERCENT'
                                            ) !== -1
                                              ? 'Value in %'
                                              : 'Value'
                                          }
                                          onChange={e => {
                                            updateDiscount({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                updateDiscount: {
                                                  ...discount,
                                                  discountValue: parseFloat(
                                                    e.target.value
                                                  )
                                                }
                                              },
                                              variables: {
                                                id: discountId,
                                                input: {
                                                  discountValue: parseFloat(
                                                    e.target.value
                                                  )
                                                }
                                              }
                                            });
                                          }}
                                          placeholder="0"
                                          type="number"
                                          value={discount.discountValue}
                                          id="discountValue"
                                        />
                                      </Grid>
                                    ) : null}
                                    <Grid item xs={12}>
                                      {discount.discountType &&
                                      (discount.discountType.indexOf(
                                        'PERCENT'
                                      ) === -1 &&
                                        discount.discountType.indexOf(
                                          'BUY_X_GET_Y'
                                        ) === -1) ? (
                                        <Select
                                          label="Currency"
                                          className={classes.select}
                                          value={discount.discountCurrency}
                                          onChange={e => {
                                            updateDiscount({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                updateDiscount: {
                                                  ...discount,
                                                  discountCurrency:
                                                    e.target.value
                                                }
                                              },
                                              variables: {
                                                id: discountId,
                                                input: {
                                                  discountCurrency:
                                                    e.target.value
                                                }
                                              }
                                            });
                                          }}
                                        >
                                          <option value={''}>...</option>
                                          {currencies
                                            ? currencies.map((c, cl) => {
                                                return (
                                                  <option key={cl} value={c}>
                                                    {c}
                                                  </option>
                                                );
                                              })
                                            : null}
                                        </Select>
                                      ) : null}
                                      {discount.discountType &&
                                      discount.discountType.indexOf(
                                        'BUY_X_GET_Y'
                                      ) !== -1 ? (
                                        <Grid
                                          xs={12}
                                          style={{
                                            margin: '1em 0 2em 0'
                                          }}
                                        >
                                          <Typography
                                            vairant="body1"
                                            style={{ marginBottom: 8 }}
                                          >
                                            Customer will get for free:
                                          </Typography>

                                          {discount.freeProducts
                                            ? discount.freeProducts.map(
                                                freeProduct => {
                                                  return (
                                                    <Grid
                                                      container
                                                      justify="space-between"
                                                      alignItems="center"
                                                      spacing={2}
                                                      style={{ margin: 0 }}
                                                    >
                                                      <Grid
                                                        item
                                                        xs={11}
                                                        style={{
                                                          background: '#62082b',
                                                          cursor: 'pointer',
                                                          height: 36,
                                                          color: '#fff',
                                                          borderRadius: 3,
                                                          display: 'flex',
                                                          alignContent:
                                                            'center',
                                                          padding: 8
                                                        }}
                                                        key={
                                                          'dp' + freeProduct.id
                                                        }
                                                      >
                                                        <Typography variant="body1">
                                                          {getTextFromLanguage(
                                                            freeProduct.title
                                                              .text,
                                                            defaultLanguage
                                                          )}
                                                        </Typography>
                                                      </Grid>
                                                      <Grid item xs={1}>
                                                        <Button
                                                          style={{
                                                            minWidth: 0,
                                                            width: '100%'
                                                          }}
                                                          variant="contained"
                                                          color="primary"
                                                          onClick={() => {
                                                            updateDiscount({
                                                              optimisticResponse: {
                                                                __typename:
                                                                  'Mutation',
                                                                updateDiscount: {
                                                                  ...discount,
                                                                  productsGetForFree: discount.productsGetForFree.filter(
                                                                    p =>
                                                                      p !==
                                                                      freeProduct.id
                                                                  )
                                                                }
                                                              },
                                                              variables: {
                                                                id: discountId,
                                                                input: {
                                                                  productsGetForFree: discount.productsGetForFree.filter(
                                                                    p =>
                                                                      p !==
                                                                      freeProduct.id
                                                                  )
                                                                }
                                                              }
                                                            });
                                                          }}
                                                        >
                                                          <DeleteIcon></DeleteIcon>
                                                        </Button>
                                                      </Grid>
                                                    </Grid>
                                                  );
                                                }
                                              )
                                            : null}
                                          <br />
                                          <div>
                                            <AdminSearchProduct
                                              label={
                                                'Add products to get for free'
                                              }
                                              classes={classes}
                                              onSelect={product => {
                                                updateDiscount({
                                                  optimisticResponse: {
                                                    __typename: 'Mutation',
                                                    updateDiscount: {
                                                      ...discount,
                                                      productsGetForFree: discount.productsGetForFree
                                                        ? [
                                                            ...discount.productsGetForFree,
                                                            product.id
                                                          ]
                                                        : [product.id]
                                                    }
                                                  },
                                                  variables: {
                                                    id: discountId,
                                                    input: {
                                                      productsGetForFree: discount.productsGetForFree
                                                        ? [
                                                            ...discount.productsGetForFree,
                                                            product.id
                                                          ]
                                                        : [product.id]
                                                    }
                                                  }
                                                });
                                              }}
                                              excludeIds={
                                                discount.productsGetForFree
                                                  ? discount.productsGetForFree
                                                  : []
                                              }
                                            />
                                          </div>
                                        </Grid>
                                      ) : null}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: 24 }}>
                          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <Grid
                              container
                              style={{ padding: '24px 24px 24px 0px' }}
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item xs={12}>
                                <Typography
                                  variant="subtitle1"
                                  style={{ marginRight: 16 }}
                                >
                                  Discount availability
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body1"></Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                            <Paper elevation={0} style={{ padding: 24 }}>
                              <Grid container spacing={1} direction="column">
                                <DiscountAvailableFrom
                                  classes={classes}
                                  discountId={discountId}
                                  shopId={shopId}
                                  discount={discount}
                                  updateDiscount={updateDiscount}
                                />
                                <br />
                                <br />
                                <DiscountAvailableUntil
                                  classes={classes}
                                  discountId={discountId}
                                  shopId={shopId}
                                  discount={discount}
                                  updateDiscount={updateDiscount}
                                />
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>

                        {/* <Grid item xs={12}>
                          <InputLabel shrink className={classes.label}>
                            Available from
                          </InputLabel>
                          <Paper className={classes.paper}>
                            <InputBase
                              classes={{
                                root: classes.cssRootInput,
                                input: classes.cssinput
                              }}
                              onChange={e => {
                                updateDiscount({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    updateDiscount: {
                                      ...discount,
                                      availableFrom: e.target.value
                                    }
                                  },
                                  variables: {
                                    id: discountId,
                                    input: {
                                      availableFrom: e.target.value
                                    }
                                  }
                                });
                              }}
                              placeholder=""
                              type="datetime-local"
                              value={discount.availableFrom}
                              id="availableFrom"
                            />
                          </Paper>
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel shrink className={classes.label}>
                            Available until
                          </InputLabel>
                          <Paper className={classes.paper}>
                            <InputBase
                              classes={{
                                root: classes.cssRootInput,
                                input: classes.cssinput
                              }}
                              onChange={e => {
                                updateDiscount({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    updateDiscount: {
                                      ...discount,
                                      availableUntil: e.target.value
                                    }
                                  },
                                  variables: {
                                    id: discountId,
                                    input: {
                                      availableUntil: e.target.value
                                    }
                                  }
                                });
                              }}
                              placeholder=""
                              type="datetime-local"
                              value={discount.availableUntil}
                              id="availableUntil"
                            />
                          </Paper>
                        </Grid>                       */}

                        <Grid container style={{ marginTop: 24 }}>
                          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <Grid
                              container
                              style={{ padding: '24px 24px 24px 0px' }}
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item xs={12}>
                                <Typography
                                  variant="subtitle1"
                                  style={{ marginRight: 16 }}
                                >
                                  Rules
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body1">
                                  Define when your discount should be applied
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <DiscountRules
                            defaultLanguage={defaultLanguage}
                            classes={classes}
                            currencies={currencies}
                            shopId={shopId}
                            discountId={discountId}
                            discount={discount}
                            updateDiscount={updateDiscount}
                          />
                        </Grid>
                      </Grid>
                    );
                  }}
                </Mutation>

                {isCouponPage === true ? null : (
                  <Grid container style={{ marginTop: 24 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid
                        container
                        style={{ padding: '24px 24px 24px 0px' }}
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            style={{ marginRight: 16, marginBottom: 16 }}
                          >
                            Add new coupon
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <AddCoupon shopId={shopId} discount={discount} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Paper elevation={0} style={{ padding: 0 }}>
                        <Grid container direction="column">
                          <DiscountCoupons
                            languages={languages}
                            shopId={shopId}
                            coupons={discount.coupons.edges}
                          />
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                )}
                <br />
                <Grid container>
                  <Grid item xs={12}>
                    <h3>Danger zone</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <Mutation
                      mutation={ADMIN_DELETE_DISCOUNT}
                      update={(cache, { data: { deleteDiscount } }) => {}}
                    >
                      {(deleteDiscount, { data, loading, error }) => (
                        <Button
                          style={{
                            background: '#7d1d1d',
                            color: 'white'
                          }}
                          onClick={async () => {
                            await deleteDiscount({
                              variables: {
                                id: discountId
                              }
                            });
                            navigate(`/admin/${shopId}/settings/discounts`);
                          }}
                        >
                          Delete discount
                        </Button>
                      )}
                    </Mutation>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        </Query>
      </Grid>
    );
  }
}

export default withStyles(styles)(DiscountDetail);
