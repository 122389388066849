import React from 'react'
import { Grid, Button } from '@material-ui/core';
import { Delete, FileCopy, ArrowUpward, ArrowDownward, Flip, EditLocation, Visibility, VisibilityOff, Lock, LockOpen } from '@material-ui/icons';
import RepositionIcon from "./Reposition.svg";

interface Props {
  selectId: string,
  lock: boolean,
  visible: boolean,
  onDuplicate(id: string): void,
  onMirror(axis: string, id: string): void,
  onRemove(id: string): void,
  onRePosition(id: string): void,
  onMoveTo(direction: string, id: string): void,
  onToggleLock(id: string): void,
  onToggleVisible(id: string): void,
  onReCenter(id: string): void,
}


const ControlTools: React.FunctionComponent<Props> = (props) => {
  const {selectId, lock, visible, onDuplicate, onMirror, onRemove, onRePosition, onMoveTo, onToggleLock, onToggleVisible, onReCenter} = props

  const handleRemove = () => {
    console.log('Remove')

    onRemove(selectId);
  }

  const handleDuplicate = () => {
    console.log('Duplicate')
    onDuplicate(selectId)
  }

  const handleMirrorX = () => {
    console.log('Mirror X')
    onMirror('x', selectId)
  }

  const handleMirrorY = () => {
    console.log('Mirror Y')
    onMirror('y', selectId)
  }

  const handleRePosition = () => {

    console.log('handleRePosition')
    onRePosition(selectId)
  }

  const handleMoveForward = () => {

    console.log('handleMoveForward')
    onMoveTo('moveUp', selectId)
  }

  const handleMoveBackward = () => {

    console.log('handleMoveBackward')
    onMoveTo('moveDown', selectId)
  }

  const handleToggleLock = () => {

    console.log('handleToggleLock')
    onToggleLock(selectId)
  }

  const handleToggleVisible = () => {

    console.log('handleToggleVisible')
    onToggleVisible(selectId)
  }

  const handleReCenter = () => {

    console.log('handleReCenter')
    onReCenter(selectId)
  }

  var lockIcon = ( lock ) ? <Lock style={{ fontSize: 16 }} /> : <LockOpen style={{ fontSize: 16 }} />;
  var visibleIcon = ( visible ) ? <Visibility style={{ fontSize: 16 }} /> : <VisibilityOff style={{ fontSize: 16 }} />;

  return (
    <Grid container={true} justify='space-between' spacing={1}>

      {onRemove && (
      <Grid item={true} xs={3}>
       <Button style={{ marginBottom: 2, minWidth: 0, width: '100%' }} variant="outlined"  onClick={handleRemove} title="Remove&#13;(Delete)"><Delete style={{ fontSize: 16 }} /></Button>
        </Grid>
      )}

      {onDuplicate && (
        <Grid item={true} xs={3}>
        <Button style={{ marginBottom: 2, minWidth: 0, width: '100%' }} variant="outlined"  onClick={handleDuplicate} title="Duplicate&#13;(Ctrl + D)"><FileCopy style={{ fontSize: 16 }}/></Button>
        </Grid>
      )}

      {onMirror && (
        <Grid item={true} xs={3}>
          <Button style={{ marginBottom: 2, minWidth: 0, width: '100%' }} variant="outlined"  onClick={handleMirrorX} title="Mirror X&#13;(Ctrl + X)"><Flip style={{ fontSize: 16 }} /></Button>
          </Grid>
      )}
      {onMirror && (
        <Grid item={true} xs={3}>
          <Button style={{ marginBottom: 2, minWidth: 0, width: '100%' }} variant="outlined"  onClick={handleMirrorY} title="Mirror Y&#13;(Ctrl + Y)"><Flip style={{ fontSize: 16, transform: 'rotate(90deg)' }} /></Button>
          </Grid>
      )}

      {onMoveTo && (
        <Grid item={true} xs={3}>
          <Button style={{ marginBottom: 2, minWidth: 0, width: '100%' }} variant="outlined"  onClick={handleMoveForward} title="Move Up&#13;(Ctrl + ])"><ArrowUpward style={{ fontSize: 16 }} /></Button>
        </Grid>
      )}
      {onMoveTo && (
        <Grid item={true} xs={3}>
               <Button style={{ marginBottom: 2, minWidth: 0, width: '100%' }} variant="outlined"  onClick={handleMoveBackward} title="Move Down&#13;(Ctrl + [)"><ArrowDownward style={{ fontSize: 16 }} /></Button>
        </Grid>
      )}

      {onToggleLock && (
        <Grid item={true} xs={3}>
        <Button style={{ marginBottom: 2, minWidth: 0, width: '100%' }} variant="outlined"  onClick={handleToggleLock} title="Toggle Lock&#13;(Ctrl + /)">{lockIcon}</Button>
        </Grid>
      )}

      {onToggleVisible && (
        <Grid item={true} xs={3}>
        <Button style={{ marginBottom: 2, minWidth: 0, width: '100%' }} variant="outlined"  onClick={handleToggleVisible} title="Toggle Visible&#13;(Ctrl + ,)">{visibleIcon}</Button>
        </Grid>
      )}

      {onRePosition && (
        <Grid item={true} xs={3}>
        <Button style={{ marginBottom: 2, minWidth: 0, width: '100%', padding: 4 }} variant="outlined"  onClick={handleRePosition} title="Re Position&#13;(Ctrl + P)"><img src={RepositionIcon} style={{ width: '100%', height: '18px', objectFit: 'contain' }} /></Button>
        </Grid>
      )}

      {onReCenter && (
        <Grid item={true} xs={3}>
        <Button style={{ marginBottom: 2, minWidth: 0, width: '100%', padding: 4 }} variant="outlined"  onClick={handleReCenter} title="Re Center&#13;(Ctrl + R)"><EditLocation style={{ fontSize: 16 }} /></Button>
        </Grid>
      )}

    </Grid>
  )
}

export default ControlTools
