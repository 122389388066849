import gql from 'graphql-tag'
import {
  PrintingDetailFragment,
  PricingDetailFragment,
  TextFragment,
  DiscountFragment,
  ProductOptionValueFragment,
  PageTagFragment,
  ProductDesignTemplateFragment
} from '../../../gql/fragments'

export const ContentColumnFragment = gql`
  fragment contentColumnFragment on ContentColumn {
    id
    pageId
    rowId
    sortOrder
    direction
    backgroundColor
    padding
    width
    height
    maxWidth
    verticalAlign
  }
`

export const ContentDetailDiscoverCardFragment = gql`
  fragment contentDetailDiscoverCardFragment on ContentDetailDiscoverCard {
    title {
      id
      text {
        langCode
        content
      }
    }
    subTitle {
      id
      text {
        langCode
        content
      }
    }
    buttonText {
      id
      text {
        langCode
        content
      }
    }
    buttonUrl
  }
`

export const ContentDetailBannerFragment = gql`
  fragment contentDetailBannerFragment on ContentDetailBanner {
    imageUrl
    verticalAlign
    horizontalAlign
    title {
      id
      text {
        langCode
        content
      }
    }
    subTitle {
      id
      text {
        langCode
        content
      }
    }
    titleFontFamily
    subTitleFontFamily
    titleFontSize
    subTitleFontSize
    titleColor
    titleBackgroundColor
    subTitleColor
    buttonText {
      id
      text {
        langCode
        content
      }
    }
    buttonPadding
    buttonTextColor
    objectFit
    buttonBackgroundColor
    buttonBorder
    buttonBorderRadius
    buttonUrl
    urlType
    textAlign
    buttonFontFamily
    buttonFontSize
    containerPadding
    copyrightVerticalAlignment
    copyrightHorizontalAlignment
    copyrightFontColor
    copyrightFontFamily
    copyrightContent
    height
    collectionId
    productId
    productPackageId
    productPackage {
      id
      title {
        id
        text {
          langCode
          content
        }
      }
      slugs {
        content
        langCode
      }
    }
    teamstoreId
    teamstore {
      id
      name
      slugs {
        langCode
        content
      }
      title {
        id
        text {
          langCode
          content
        }
      }
    }
    product {
      id
      slug
    }
    collection {
      id
      slugs {
        langCode
        content
      }
    }
    selectedPageId
    page {
      id
      slugs {
        langCode
        content
      }
    }
  }
`

export const ContentDetailTextFragment = gql`
  fragment contentDetailTextFragment on ContentDetailText {
    alignment
    text {
      id
      text {
        content
        langCode
      }
    }
    typoVariant
    color
    bold
    italic
    underline
    crossed
    fontSize
    fontFamily
    fontWeight
    verticalAlign
    textTransform
    letterSpacing
  }
`

export const ContentDetailImageFragment = gql`
  fragment contentDetailImageFragment on ContentDetailImage {
    imageUrl
    width
    height
    maxWidth
    maxHeight
    objectFit
    imageAlt
    copyrightContent
    copyrightFontColor
    copyrightFontFamily
    copyrightVerticalAlignment
    copyrightHorizontalAlignment
    backgroundSize
    backgroundRepeat
    backgroundPosition
  }
`

export const ContentDetailHubspotFormFragment = gql`
  fragment contentDetailHubspotFormFragment on ContentDetailHubspotForm {
    portalId
    formId
    cssClass
    target
  }
`
export const ContentDetailCardFragment = gql`
  fragment contentDetailCardFragment on ContentDetailCard {
    thumbnailImg
    width
    height
    shadow
    backgroundColor
    link
    border
    borderRadius
    buttonFontSize
    titleFontSize
    descriptionFontSize
    titleColor
    titleBackgroundColor
    descriptionColor
    buttonTextColor
    titleAlign
    descriptionAlign
    buttonAlign
    buttonBorder
    backgroundSize
    backgroundRepeat
    backgroundPosition
    fontFamily
    urlType
    collectionId
    collection {
      id
      title {
        id
        text {
          langCode
          content
        }
      }
      slugs {
        content
        langCode
      }
    }

    productPackageId
    productPackage {
      id
      title {
        id
        text {
          langCode
          content
        }
      }
      slugs {
        content
        langCode
      }
    }
    teamstoreId
    teamstore {
      id
      name
      slugs {
        langCode
        content
      }
      title {
        id
        text {
          langCode
          content
        }
      }
    }
    productId
    product {
      id
      slug
    }

    pageId
    selectedPageId
    page {
      id
      slugs {
        langCode
        content
      }
    }
    title {
      id
      text {
        content
        langCode
      }
    }
    description {
      id
      text {
        content
        langCode
      }
    }
    callToAction {
      id
      text {
        content
        langCode
      }
    }
  }
`

export const ContentDetailSubscribeCardFragment = gql`
  fragment contentDetailSubscribeCardFragment on ContentDetailSubscribeCard {
    width
    height
    border
    borderRadius
    padding
    hasTerms
    isAccepted
    hasInput
    title {
      id
      text {
        content
        langCode
      }
    }
    content {
      id
      text {
        content
        langCode
      }
    }
    callToAction {
      id
      text {
        content
        langCode
      }
    }
    termsContent {
      id
      text {
        content
        langCode
      }
    }
    placeholder {
      id
      text {
        content
        langCode
      }
    }
    label {
      id
      text {
        content
        langCode
      }
    }
  }
`

export const ContentDetailContactCardFragment = gql`
  fragment contentDetailContactCardFragment on ContentDetailContactCard {
    hasTerms
    title {
      id
      text {
        content
        langCode
      }
    }
    nameLabel {
      id
      text {
        content
        langCode
      }
    }
    namePlaceholder {
      id
      text {
        content
        langCode
      }
    }
    emailLabel {
      id
      text {
        content
        langCode
      }
    }
    emailPlaceholder {
      id
      text {
        content
        langCode
      }
    }
    contentLabel {
      id
      text {
        content
        langCode
      }
    }
    contentPlaceholder {
      id
      text {
        content
        langCode
      }
    }
  }
`

export const ContentDetailLocationCardFragment = gql`
  fragment contentDetailLocationCardFragment on ContentDetailLocationCard {
    hasPremiumDealership
    url
    shopName
    address
    website
    email
    phone
    openDays
    openHours
    closedDays
    dealershipLink
  }
`

export const ContentDetailButtonFragment = gql`
  fragment contentDetailButtonFragment on ContentDetailButton {
    text {
      id
      text {
        content
        langCode
      }
    }
    width
    height
    padding
    color
    backgroundColor
    border
    borderRadius
    url
    fontFamily
    fontSize
    buttonLinkType
  }
`

export const ContentDetailInputFieldFragment = gql`
  fragment contentDetailInputFieldFragment on ContentDetailInputField {
    text {
      id
      text {
        content
        langCode
      }
    }
    width
    height
    padding
    color
    backgroundColor
    border
    borderRadius
    placeholder {
      id
      text {
        content
        langCode
      }
    }
    label {
      id
      text {
        content
        langCode
      }
    }
    inputType
    disabled
    required
  }
`

export const ContentDetailDividerFragment = gql`
  fragment contentDetailDividerFragment on ContentDetailDivider {
    border
    width
    padding
    margin
    text {
      id
      text {
        content
        langCode
      }
    }
    textAlign
    textPadding
    textBackground
    textColor
    fontFamily
    fontSize
  }
`

export const ContentDetailCollectionCardFragment = gql`
  fragment contentDetailCollectionCardFragment on ContentDetailCollectionCard {
    cardsToShow
    collectionIds
    backgroundSize
    backgroundRepeat
    backgroundPosition
    fontFamily
    buttonText {
      id
      text {
        content
        langCode
      }
    }
    collections {
      id
      thumbnail
      mainBanner
      slugs {
        langCode
        content
      }
      title {
        id
        text {
          langCode
          content
        }
      }
    }
  }
`
export const ContentDetailTeamstoreCardFragment = gql`
  fragment contentDetailTeamstoreCardFragment on ContentDetailTeamstoreCard {
    cardsToShow
    teamstoreIds
    backgroundSize
    backgroundRepeat
    backgroundPosition
    fontFamily
    teamstores {
      id
      type
      name
      logo
      brandColor
      slugs {
        langCode
        content
      }
      title {
        id
        text {
          langCode
          content
        }
      }
    }
  }
`
export const ContentDetailProductCardFragment = gql`
  fragment contentDetailProductCardFragment on ContentDetailProductCard {
    cardsToShow
    productIds
    backgroundSize
    backgroundRepeat
    backgroundPosition
    buttonText {
      id
      text {
        content
        langCode
      }
    }
    fontFamily
    products {
      id
      inventory
      sku
      isCustomProduct
      hasPersonalization
      media {
        id
        productId
        variantId
        variantOptionId
        optionValue
        sortOrder
        isThumbnail
        isBanner
        image
      }
      slugs {
        langCode
        content
      }
      title {
        id
        text {
          langCode
          content
        }
      }
      price {
        currency
        value
      }
    }
  }
`
export const TeamstoreFragment = gql`
  fragment teamstoreFragment on SalesChannel {
    id
    enabled
    hasOutOfStock
    type
    privacyType
    name
    logo
    mainBanner
    brandColor
    availableFrom
    availableUntil
    contactPerson
    contactEmail
    contactPhone
    hasPassword
    hasPersonalization
    password
    purchaseLimitPerCustomer
    title {
      id
      text {
        langCode
        content
      }
    }
    settings {
      id
    }
    pages {
      edges {
        node {
          id
        }
      }
    }
    slugs {
      langCode
      content
    }
    parentShop {
      id
      name
    }

    introductionText {
      id
      text {
        langCode
        content
      }
    }

    groups {
      id
      name
      title {
        id
        text {
          langCode
          content
        }
      }
    }
    targetCategories {
      id
      name
      title {
        id
        text {
          langCode
          content
        }
      }
    }
    shop {
      id
    }
    customizationSettings {
      customizationOptionGroups {
        id
        options {
          id
          type
          title {
            id
            text {
              langCode
              content
            }
          }
          pricingRules {
            ...pricingDetail
          }
        }
      }
    }
    shippingSettings {
      id
      shippingZones {
        id
        name
        applyToAllCountries
        countryCodes
        regionCodes
        shippingRates {
          id
          priority
          type
          minimumTotal {
            currency
            value
          }
          minimumWeight
          weightUnit
          rate {
            currency
            value
          }
        }
      }
    }
    productPackages {
      edges {
        node {
          productPackageId
          salesChannelId
          productPackage {
            id
            title {
              id
              text {
                langCode
                content
              }
            }
          }
        }
      }
    }
    products {
      edges {
        node {
          productId
          salesChannelId
          product {
            id
            title {
              id
              text {
                langCode
                content
              }
            }
          }
        }
      }
    }
    collections {
      edges {
        node {
          id
          featured
          sortOrder
          collectionId
          salesChannelId
          collection {
            id
            no
            thumbnail
            slugs {
              langCode
              content
            }
            title {
              id
              text {
                langCode
                content
              }
            }
          }
        }
      }
    }
  }

  ${PricingDetailFragment}
`
export const CollectionFragment = gql`
  fragment collectionFragment on Collection {
    id
    accountingId
    no
    title {
      id
      text {
        langCode
        content
      }
    }
    description {
      id
      text {
        langCode
        content
      }
    }
    slugs {
      langCode
      content
    }
    thumbnail
    mainBanner
    parentCollections {
      parentId
      childId
    }
    childCollections {
      id
      sortOrder
      parentId
      childId
      childCollection {
        title {
          id
          text {
            langCode
            content
          }
        }
        slugs {
          langCode
          content
        }
        thumbnail
        mainBanner
      }
    }
    productPackages {
      featured
      sortOrder
      productPackage {
        id
        no
        status
        price {
          value
          currency
        }
        title {
          id
          text {
            langCode
            content
          }
        }
        media {
          id
          productId
          variantId
          variantOptionId
          optionValue
          sortOrder
          isThumbnail
          isBanner
          image
        }
        slugs {
          content
          langCode
        }
      }
    }
    products {
      featured
      sortOrder
      product {
        id
        no
        status
        isCustomProduct
        price {
          value
          currency
        }
        title {
          id
          text {
            langCode
            content
          }
        }
        media {
          id
          productId
          variantId
          variantOptionId
          optionValue
          sortOrder
          isThumbnail
          isBanner
          image
        }
        slugs {
          content
          langCode
        }
        assignedProductAttributes {
          id
          attribute {
            id
            title {
              id
              text {
                langCode
                content
              }
            }
            no
            sortOrder
            isFeatured
            isFeaturedAttributeTitle
            image
            icon
          }
        }
        discounts {
          discount {
            id
            canBeCombinedWithOtherDiscounts
            availableFrom
            availableUntil
            enabled
            hasCoupon
            discountType
            discountValue
            discountCurrency
            discountRules {
              id
              operator
              discountRuleObjectType
              discountRuleCondition
            }
          }
        }
      }
    }
  }
`
export const VariantFragment = gql`
  fragment variantFragment on Variant {
    id
    title {
      id
      text {
        langCode
        content
      }
    }
    options {
      id
      value
      sortOrder
      title {
        id
        text {
          langCode
          content
        }
      }
    }
  }
`
export const ProductAttributeFragment = gql`
  fragment productAttributeFragment on ProductAttribute {
    id
    no
    sortOrder
    isFeatured
    isFeaturedAttributeTitle
    icon
    image
    title {
      id
      text {
        langCode
        content
      }
    }
    description {
      id
      text {
        langCode
        content
      }
    }
  }
`
export const CouponFragment = gql`
  fragment couponFragment on Coupon {
    id
    enabled
    accountingId
    couponCode
    title {
      id
      text {
        langCode
        content
      }
    }
    discountId
    discount {
      ...discountFragment
    }
    maxUsagePerCustomer
    maxUsageOverall
    currentUsageOverall
    currentUsageCustomer {
      userId
      couponId
      organisationId
    }
    availableFrom
    availableUntil
    internalNote
  }
  ${DiscountFragment}
`
export const ProductPreviewFragment = gql`
  fragment productPreviewFragment on Product {
    id
    shopId
    name
    active
    categoryName
    slug
    no
    accountingId
    dummyStyles
    isCustomProduct
    hasPersonalization
    inventory
    sku
    #v2
    status
    price {
      currency
      value
      includesTax
      taxPercent
    }
    title {
      id
      text {
        langCode
        content
      }
    }
    media {
      id
      productId
      variantId
      variantOptionId
      optionValue
      sortOrder
      isThumbnail
      isBanner
      image
    }
  }
`
export const TextDesignStepFragment = gql`
  fragment textDesignStepFragment on TextStep {
    id
    stepSortOrder
    type
    fonts {
      id
      name
      weights
      source
    }
    defaultPrintingTechnique
    textPrintingTechniques {
      ...printingTechniqueDetail
    }
  }
  ${TextFragment}
  ${PricingDetailFragment}
  ${PrintingDetailFragment}
`
export const FabricDesignStepFragment = gql`
  fragment fabricDesignStepFragment on FabricStep {
    id
    stepSortOrder
    type
    showTooltip
  }
`
export const ColorDesignStepFragment = gql`
  fragment colorDesignStepFragment on ColorStep {
    id
    stepSortOrder
    type
    defaultPrintingTechnique
    colorPrintingTechniques {
      ...printingTechniqueDetail
    }
    printLibrary {
      id
      name
      src
    }
  }
  ${TextFragment}
  ${PricingDetailFragment}
  ${PrintingDetailFragment}
`
export const LogoDesignStepFragment = gql`
  fragment logoDesignStepFragment on LogoStep {
    id
    stepSortOrder
    type
    maxLogos
    defaultPrintingTechnique
    logoPrintingTechniqueIds
    logoPrintingTechniques {
      ...printingTechniqueDetail
    }
  }
  ${TextFragment}
  ${PricingDetailFragment}
  ${PrintingDetailFragment}
`
export const ShopDesignStepFragment = gql`
  fragment designStepFragment on ShopDesignStep {
    id
    stepSortOrder
    type
    ...fabricDesignStepFragment
    ...colorDesignStepFragment
    ...textDesignStepFragment
    ...logoDesignStepFragment
  }
  ${FabricDesignStepFragment}
  ${ColorDesignStepFragment}
  ${TextDesignStepFragment}
  ${LogoDesignStepFragment}
`
export const ShopSettingFragment = gql`
  fragment shopSettingsFragment on Shop {
    id
    name
    email
    description
    languages
    languageWithoutUrlPrefix
    defaultLanguage
    currencies
    defaultCurrency
    countries
    defaultCountry
    businessAddress {
      address1
      city
      company
      countryCode
      firstName
      lastName
      email
      phone
      province
      zip
      isValidAddress
    }
    printingTechniques(shopId: $id, first: 20) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...printingTechniqueDetail
        }
      }
    }
    brandSettings {
      logo
      mainColor
      secondaryColor
    }
    tempDiscount {
      orderType
      pricingNote
      leadTimeNote
      leadTimeDays
      minQuantity
      maxQuantity
      multiplier
    }
    notificationSettings {
      id
      shopId
      notifications {
        event
        emailSubject
        emailBody
        isEmailActive
        webhookReceiver {
          id
          email
          isWebhookActive
        }
      }
    }
    shippingSettings {
      id
      shippingFrom {
        id
        company
        phone
        address1
        address2
        city
        zip
        countryCode
        country
      }
      shippingZones {
        id
        name
        applyToAllCountries
        regionCodes
        countryCodes
        shippingRates {
          id
          priority
          type
          minimumTotal {
            value
            currency
          }
          minimumWeight
          weightUnit
          rate {
            value
            currency
          }
        }
      }
      shippingBoxes {
        id
        name
        length
        width
        height
        lengthUnit
        emptyWeight
        weightUnit
      }
    }
    designStudioSettings {
      designSteps {
        ...designStepFragment
      }
    }
    taxSettings {
      shouldIncludeTax
      defaultTaxRate
      taxRates {
        id
        countryCode
        percent
      }
    }
    socialLinks {
      externalLink
      icon
    }
    paymentOptions {
      name
      icon
    }
  }
  ${ShopDesignStepFragment}
`
export const ProductDetailsFragment = gql`
  fragment productDetailsFragment on Product {
    id
    name
    accountingId
    inventory
    sku
    shopId
    title {
      id
      text {
        langCode
        content
      }
    }
    description {
      id
      text {
        langCode
        content
      }
    }
    slugs {
      langCode
      content
    }
    no
    active
    status
    categoryName
    categorySlug
    dummyStyles
    isCustomProduct
    hasPersonalization
    inventory
    sku
    customBasePrice {
      currency
      value
    }
    finishedDesigns {
      designs {
        salesChannelType
        boardProduct {
          id
        }
      }
    }
    modelConfiguration {
      modelRatio
      modelTextureRatio
      modelSide
      modelRotation {
        x
        y
        z
      }
      actualWidthCm
      actualHeightCm
      actualWidthELM
      dimension {
        baseTemplate
        baseSize
        pxToCm
      }
    }
    manualAttatchObject
    manualAttatchPosition {
      x
      y
      z
    }
    tempCustomPriceAdditions {
      id
      minOrderQuantity
      calculationType
      formula {
        priority
        price {
          currency
          value
        }
        qty
        sqm
      }
    }
    lastElementRevision
    objSrc
    hasObjFile
    mtlSrc
    hasMtlFile
    seamSrc
    hasSeamFile
    baseTemplateSrc
    hasBaseTemplateFile
    hasDefaultTemplateFile
    defaultTemplateSrc
    labelPosition
    measurementTableSrc
    measurementTable {
      measurements {
        elementId
        part {
          id
          text {
            langCode
            content
          }
        }
        description {
          id
          text {
            langCode
            content
          }
        }
        sizeUnits {
          size
          value
          content
          length
        }
      }
    }
    measurementDiagramFront
    measurementDiagramBack
    defaultBasePrice {
      currency
      value
    }
    careLabels {
      id
      name
      src
    }
    latestElements {
      id
      createdAt
      productId
      elementRevision
      code
      fullCode
      defaultName
      name
      width
      height
      sqm
      defaultFabricId
      fabricGroupId
      fabricCompositionIds
    }
    media {
      id
      productId
      variantId
      variantOptionId
      optionValue
      sortOrder
      isThumbnail
      isBanner
      image
    }
    assignedProductAttributes {
      id
      attribute {
        id
        title {
          id
          text {
            langCode
            content
          }
        }
        no
        sortOrder
        isFeatured
        isFeaturedAttributeTitle
        image
        icon
      }
    }
    collections {
      collection {
        id
        no
        slugs {
          langCode
          content
        }
        title {
          id
          text {
            langCode
            content
          }
        }
      }
    }
    salesChannels {
      productId
      salesChannelId
      salesChannel {
        id
        name
        title {
          id
          text {
            langCode
            content
          }
        }
      }
    }
    price {
      currency
      value
    }
    hasPersonalization
    variants {
      id
      options
      variant {
        id
        title {
          id
          text {
            langCode
            content
          }
        }
        options {
          id
          value
          sortOrder
          title {
            id
            text {
              langCode
              content
            }
          }
        }
      }
    }
    optionValues {
      ...productOptionValueFragment
    }
    designStudioVersion
    designTemplates {
      ...productDesignTemplateFragment
    }
    discounts {
      discount {
        id
        title {
          id
          text {
            content
            langCode
          }
        }
        canBeCombinedWithOtherDiscounts
        availableFrom
        availableUntil
        enabled
        hasCoupon
        discountType
        discountValue
        discountCurrency
        discountRules {
          id
          operator
          discountRuleObjectType
          discountRuleCondition
        }
      }
    }
  }
  ${ProductOptionValueFragment}
  ${ProductDesignTemplateFragment}
`
