import React, { Component } from 'react'
import { ApolloProvider } from 'react-apollo'
import AdminRoutes from './AdminRoutes'
import client from './gql/admin-apollo-config'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { adminTheme } from './AdminTheme'
import OrganisationProvider, {
  OrganisationContext
} from '../user/context/OrganisationContext'
import UserProvider from '../user/context/UserContext'
import { DragDropContextProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { Router } from '@reach/router'
import AdminShops from './AdminShops'
import AdminLogin from './AdminLogin'
import AdminSideMenu from './components/common/AdminSideMenu'
import AdminVerify from './AdminVerify'
import AdminTOTP from './AdminTOTP'
import AdminForgot from './AdminForgot'
import AdminConfirm from './AdminConfirm'
import AdminNewPassword from './AdminNewPassword'
import { I18n } from '@aws-amplify/core'
import { vocab } from '../../lang/vocabularies'
require('./admin-aws-config')

I18n.putVocabularies(vocab)

class ShopRoot extends Component {
  // static getDerivedStateFromProps() {
  //   try {
  //     const langCode = 'en_UK'
  //     I18n.setLanguage(langCode)
  //   } catch (error) {}
  // }
  componentWillMount() {
    try {
      const langCode = 'en_UK'
      I18n.setLanguage(langCode)
    } catch (error) {}
  }

  render() {
    const { children, isAdmin, shopId } = this.props
    let path
    try {
      path = window.location.pathname.split('/')
    } catch (error) {}

    return (
      <div>
        <div
          style={{
            display: 'flex',
            width: '100%'
          }}
        >
          <AdminSideMenu shopId={path[2]} isAdmin={isAdmin} />
          <main
            style={{
              flex: 1,
              background: '#fafafa',
              maxHeight: '100vh',
              overflowY: 'auto',
              minHeight: '100vh'
            }}
          >
            {children}
          </main>
        </div>
      </div>
    )
  }
}

class AdminHome extends Component {
  state = {
    isAdmin: false
  }
  render() {
    return (
      <MuiThemeProvider theme={adminTheme}>
        <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>
            <div className="Admin" id="Admin" style={{ width: '100%' }}>
              <DragDropContextProvider backend={HTML5Backend}>
                <OrganisationProvider>
                  <UserProvider>
                    <OrganisationContext.Consumer>
                      {currentOrganisation => (
                        <Router>
                          <AdminShops exact path="/" />
                          <ShopRoot isAdmin={this.state.isAdmin} path="admin/">
                            <AdminLogin exact path="login" />
                            <AdminVerify exact path="verify" />
                            <AdminConfirm exact path="confirm" />
                            <AdminForgot exact path="forgot" />
                            <AdminNewPassword exact path="newpassword" />
                            <AdminTOTP exact path="totp" />
                            <AdminRoutes
                              isAdmin={this.state.isAdmin}
                              parent={this}
                              path=":shopId/*"
                              currentOrganisation={currentOrganisation}
                            />
                          </ShopRoot>
                        </Router>
                      )}
                    </OrganisationContext.Consumer>
                  </UserProvider>
                </OrganisationProvider>
              </DragDropContextProvider>
            </div>
          </ApolloHooksProvider>
        </ApolloProvider>
      </MuiThemeProvider>
    )
  }
}

export default AdminHome
