import React, { Component } from 'react'
import { Router, navigate } from '@reach/router'
import Grid from '@material-ui/core/Grid'
import Amplify from '@aws-amplify/core'
import client from './gql/admin-apollo-config'
import { GET_ADMIN_USER } from './gql/admin-queries'
import styled from 'styled-components'
import InventoryMenu from './components/common/InventoryMenu'
import ReportingMenu from './components/common/ReportingMenu'
import OrderMenu from './components/common/OrderMenu'
import SettingMenu from './components/common/SettingMenu'
import SalesChannelsMenu from './components/common/SalesChannelsMenu'
import DesignroomMenu from './components/common/DesignroomMenu'
import PagebuilderMenu from './components/common/PagebuilderMenu'
import { Query } from 'react-apollo'
import Helmet from 'react-helmet'
import ProductPackages from './components/products/ProductPackages'
import Products from './components/products/Products'
import Collections from './components/collections/Collections'
import ProductAttributes from './components/products/ProductAttributes'
import Variants from './components/products/variants/Variants'
import Discounts from './components/discounts/Discounts'
import Coupons from './components/coupons/Coupons'
import Fabrics from './components/fabrics/Fabrics'
import FabricGroupList from './components/fabrics/FabricGroupList'
import Printing from './components/printing/Printing'
import Colors from './components/colors/Colors'
import Orders from './components/orders/Orders'
import Customers from './components/customers/Customers'
import Users from './components/users/Users'
import DesignRoom from './components/designroom/DesignRoom'
import DesignroomProducts from './components/designroom/DesignroomProducts'
import DesignroomDesigns from './components/designroom/DesignroomDesigns'
import Settings from './components/settings/Settings'
import IntegrationsSettings from './components/settings/IntegrationsSettings'
import ShippingSettings from './components/settings/ShippingSettings'
import NotificationSettings from './components/settings/NotificationSettings'
import DesignStudioSettings from './components/settings/DesignStudioSettings'
import PageNotFound from './components/common/PageNotFound'

import Taxes from './components/settings/Taxes'

import Accounts from './components/settings/Accounts'

import TeamStore from './components/teamstore/Teamstore'

import TeamstoreDetail from './components/teamstore/TeamstoreDetail'
import Media from './components/media/Media'
import Pages from './components/pagebuilder/Pages'
import PageTags from './components/pagebuilder/PageTags'

import Modules from './components/pagebuilder/ModulesContainer'

import Theme from './components/pagebuilder/ThemePage'
import Deployment from './components/pagebuilder/DeploymentPage'

import Menus from './components/pagebuilder/Menu'

import ReportOrders from './components/reporting/ReportOrders'
import ReportOrderItems from './components/reporting/ReportOrderItems'
import ReportTeamstore from './components/reporting/ReportTeamstore'

import ReportCustomers from './components/reporting/ReportCustomers'
import ReportCoupons from './components/reporting/ReportCoupons'

import ReportingHome from './components/reporting/ReportingHome'
import Techpack from './components/techpack/Techpack'
import { setShopId } from '../../helper/storage'
const gql = require('graphql-tag')

const jwtDecode = require('jwt-decode')

class Inventory extends Component {
  render() {
    const { children, shopId } = this.props
    return (
      <div>
        <div
          style={{
            display: 'flex',
            width: '100%'
          }}
        >
          <InventoryMenu shopId={shopId} />
          <main
            style={{
              flex: 1,
              background: '#f1f1f1',
              maxHeight: '100vh',
              overflowY: 'auto',
              minHeight: '100vh'
            }}
          >
            {children}
          </main>
        </div>
      </div>
    )
  }
}

class Reports extends Component {
  render() {
    const { children, shopId } = this.props
    return (
      <div>
        <div
          container
          style={{
            display: 'flex',
            width: '100%'
          }}
        >
          <ReportingMenu shopId={shopId} />
          <main
            style={{
              flex: 1,
              background: '#f1f1f1',
              maxHeight: '100vh',
              overflowY: 'auto',
              minHeight: '100vh'
            }}
          >
            {children}
          </main>
        </div>
      </div>
    )
  }
}

class Setting extends Component {
  render() {
    const { children, shopId } = this.props
    return (
      <div>
        <div
          style={{
            display: 'flex',
            width: '100%'
          }}
        >
          <SettingMenu shopId={shopId} />
          <main
            style={{
              flex: 1,
              background: '#f1f1f1',
              maxHeight: '100vh',
              overflowY: 'auto',
              minHeight: '100vh'
            }}
          >
            {children}
          </main>
        </div>
      </div>
    )
  }
}
class SalesChannels extends Component {
  render() {
    const { children, shopId } = this.props
    return (
      <div>
        <div
          style={{
            display: 'flex',
            width: '100%'
          }}
        >
          <SalesChannelsMenu shopId={shopId} />
          <main
            style={{
              flex: 1,
              background: '#f1f1f1',
              maxHeight: '100vh',
              overflowY: 'auto',
              minHeight: '100vh'
            }}
          >
            {children}
          </main>
        </div>
      </div>
    )
  }
}

class DesignroomTab extends Component {
  render() {
    const { children, shopId } = this.props
    return (
      <div>
        <div
          style={{
            display: 'flex',
            width: '100%'
          }}
        >
          <DesignroomMenu shopId={shopId} />
          <main
            style={{
              flex: 1,
              background: '#f1f1f1',
              maxHeight: '100vh',
              overflowY: 'auto',
              minHeight: '100vh'
            }}
          >
            {children}
          </main>
        </div>
      </div>
    )
  }
}

class PageBuilder extends Component {
  render() {
    const { children, shopId } = this.props
    return (
      <div>
        <div
          style={{
            display: 'flex',
            width: '100%'
          }}
        >
          <PagebuilderMenu shopId={shopId} />
          <main
            style={{
              flex: 1,
              background: '#f1f1f1',
              maxHeight: '100vh',
              minHeight: '100vh',
              overflowY: 'auto'
            }}
          >
            {children}
          </main>
        </div>
      </div>
    )
  }
}

class Routes extends Component {
  async componentDidMount() {
    try {
      setShopId(this.props.shopId)
    } catch (error) {}
    const { currentOrganisation, parent } = this.props
    try {
      const session = await Amplify.Auth.currentSession()
      if (session) {
        // console.log('session', session);
        var decoded = jwtDecode(session.idToken.jwtToken)
        console.log('decoded', decoded)
        // console.log('decoded.groups', decoded['cognito:groups']);

        if (decoded['cognito:groups'][0].indexOf(':admin') !== -1) {
          // alert('Admin access')
          if (window.location.pathname === '/admin/') {
            navigate('/')
          }
          parent.setState({
            isAdmin: true
          })
        } else {
          alert('No admin access')
          // navigate("/");
        }
      }
    } catch (error) {
      console.error(error)
      navigate('/admin/login')
    }
  }
  render() {
    if (!this.props.shopId) return null
    const shopId = this.props.shopId
    if (shopId === 'inventory') {
      navigate('/')
    }

    const isAdmin = this.props.isAdmin
    return (
      <div style={{ width: '100%' }}>
        <Query
          fetchPolicy={'no-cache'}
          query={gql`
            query shop($id: ID!) {
              shop(id: $id) {
                id
                name
                brandSettings {
                  logo
                  mainColor
                  secondaryColor
                }
                url
                features
                defaultCurrency
                currencies
                countries
                defaultCountry
                defaultLanguage
                languages
                languageWithoutUrlPrefix
                modules {
                  edges {
                    node {
                      id
                      label
                      contentDetail {
                        id
                        type
                        ... on ContentDetailSocialLinks {
                          socialLinks {
                            externalLink
                            icon
                          }
                        }
                        ... on ContentDetailPaymentOptions {
                          paymentOptions {
                            name
                            icon
                          }
                        }
                        ... on ContentDetailShopLogo {
                          color
                          isAlternative
                          isLink
                          width
                          height
                          maxWidth
                          maxHeight
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
          variables={{ id: shopId }}
        >
          {({ loading, error, data }) => {
            if (loading) return 'Loading...'
            if (error) console.error('AdminRoutes.error', error)
            let SHOP_DEFAULT_LANGUAGE = null
            let SHOP_LANGUAGE_WITHOUT_PREFIX = null
            let urlPrefix = null
            let shop = null
            try {
              shop = data.shop
            } catch (error) {}
            try {
              SHOP_DEFAULT_LANGUAGE = data.shop ? shop.defaultLanguage : null
            } catch (error) {}
            try {
              SHOP_LANGUAGE_WITHOUT_PREFIX = data.shop.languageWithoutUrlPrefix
            } catch (error) {}
            try {
              urlPrefix =
                SHOP_DEFAULT_LANGUAGE === SHOP_LANGUAGE_WITHOUT_PREFIX
                  ? ''
                  : SHOP_DEFAULT_LANGUAGE.substring(0, 2) + '/'
            } catch (error) {}
            if (!shop) {
              return null
            }
            return (
              <>
                <Helmet
                  title={`${shop && shop.name ? shop.name : ''} Dashboard`}
                />
                <Router>
                  <Inventory path="inventory">
                    <Products
                      shopUrl={shop ? shop.url : ''}
                      defaultLanguage={SHOP_DEFAULT_LANGUAGE}
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="products"
                    />
                    <Products
                      shopUrl={shop ? shop.url : ''}
                      defaultLanguage={SHOP_DEFAULT_LANGUAGE}
                      urlPrefix={urlPrefix}
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="products/:productId"
                    />
                    <ProductPackages
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="productpackages"
                    />
                    <Collections
                      shopUrl={shop ? shop.url : ''}
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="collections"
                    />
                    <ProductAttributes
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="attributes"
                    />
                    <Variants
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="variants"
                    />
                    <Fabrics
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="fabrics"
                    />
                    <FabricGroupList
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="material-groups"
                    />
                    <Printing
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="printing"
                    />
                    <Colors
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="colors"
                    />
                  </Inventory>
                  <Printing
                    shop={shop}
                    shopId={shopId}
                    isAdmin={isAdmin}
                    exact
                    path="inventory/printing/:printingId"
                  />
                  <Colors
                    shop={shop}
                    shopId={shopId}
                    isAdmin={isAdmin}
                    exact
                    path="inventory/colors/:colorId"
                  />
                  <ProductPackages
                    shop={shop}
                    shopId={shopId}
                    isAdmin={isAdmin}
                    exact
                    path="inventory/productpackages/:productPackageId"
                  />
                  <Collections
                    shopUrl={shop ? shop.url : ''}
                    shop={shop}
                    shopId={shopId}
                    isAdmin={isAdmin}
                    exact
                    path="inventory/collections/:collectionId"
                  />
                  <Variants
                    shop={shop}
                    shopId={shopId}
                    isAdmin={isAdmin}
                    exact
                    path="inventory/variants/:variantId"
                  />
                  <ProductAttributes
                    shop={shop}
                    shopId={shopId}
                    isAdmin={isAdmin}
                    exact
                    path="inventory/attributes/:productAttributeId"
                  />
                  <Fabrics
                    shop={shop}
                    shopId={shopId}
                    isAdmin={isAdmin}
                    exact
                    path="inventory/fabrics/:fabricId"
                  />
                  <Reports path="reporting">
                    <ReportingHome
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="general"
                    />
                    <Users
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="users"
                    />
                    <Users
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="users/:userId"
                    />
                    <ReportOrders
                      defaultLanguage={shop.defaultLanguage}
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="orders"
                    />
                    <ReportOrderItems
                      defaultLanguage={shop.defaultLanguage}
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="order-items"
                    />
                    <ReportTeamstore
                      defaultLanguage={shop.defaultLanguage}
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="teamstore"
                    />
                    <ReportCustomers
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="customers"
                    />
                    <ReportCoupons
                      shop={shop}
                      defaultLanguage={shop.defaultLanguage}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="coupons"
                    />
                  </Reports>
                  <Setting path="settings">
                    <Settings
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="general"
                    />
                    <Discounts
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="discounts"
                      languages={shop.languages}
                      defaultLanguage={shop.defaultLanguage}
                    />
                    <Discounts
                      shop={shop}
                      shopId={shopId}
                      s
                      isAdmin={isAdmin}
                      exact
                      path="discounts/:discountId"
                      languages={shop.languages}
                      defaultLanguage={shop.defaultLanguage}
                    />
                    <Coupons
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="coupons"
                      languages={shop.languages}
                      defaultLanguage={shop.defaultLanguage}
                    />
                    <Coupons
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="coupons/:couponId"
                      languages={shop.languages}
                      defaultLanguage={shop.defaultLanguage}
                    />
                    <ShippingSettings
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="shipping"
                    />
                    <IntegrationsSettings
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="integrations"
                    />
                    <NotificationSettings
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="notifications"
                    />
                    <Taxes
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="taxes"
                    />
                    <Accounts
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="accounts"
                    />
                    <Deployment
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="deployments"
                    />
                  </Setting>
                  <DesignroomTab path="designroom">
                    <DesignroomProducts
                      shop={shop}
                      shopId={shopId}
                      languages={shop ? shop.languages : []}
                      defaultLanguage={shop ? shop.defaultLanguage : null}
                      isAdmin={isAdmin}
                      exact
                      path="products"
                    />
                    <DesignroomDesigns
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="designs"
                    />
                    <DesignStudioSettings
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="settings"
                    />
                  </DesignroomTab>
                  <DesignRoom
                    shop={shop}
                    shopId={shopId}
                    languages={shop ? shop.languages : []}
                    defaultLanguage={shop ? shop.defaultLanguage : null}
                    isAdmin={isAdmin}
                    exact
                    path="designroom/room"
                  />
                  <SalesChannels path="saleschannels">
                    <TeamStore
                      shopUrl={shop ? shop.url : ''}
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      languages={shop ? shop.languages : []}
                      defaultLanguage={shop ? shop.defaultLanguage : null}
                      exact
                      path="teamstores"
                    />
                    <TeamstoreDetail
                      shopUrl={shop ? shop.url : ''}
                      shop={shop}
                      shopId={shopId}
                      defaultCurrency={shop ? shop.defaultCurrency : null}
                      languages={shop ? shop.languages : []}
                      defaultLanguage={shop ? shop.defaultLanguage : null}
                      path="teamstores/:teamstoreId/*"
                    />
                  </SalesChannels>
                  <Customers
                    shop={shop}
                    shopId={shopId}
                    isAdmin={isAdmin}
                    exact
                    path="customers"
                  />
                  <Customers
                    shop={shop}
                    shopId={shopId}
                    isAdmin={isAdmin}
                    exact
                    path="customers/:customerId"
                  />
                  <Orders
                    defaultLanguage={shop.defaultLanguage}
                    defaultCurrency={shop.defaultCurrency}
                    shop={shop}
                    shopId={shopId}
                    isAdmin={isAdmin}
                    exact
                    path="orders"
                  />
                  <Orders
                    defaultCurrency={shop.defaultCurrency}
                    defaultLanguage={shop.defaultLanguage}
                    shop={shop}
                    shopId={shopId}
                    isAdmin={isAdmin}
                    path="orders/:orderId"
                  />
                  <PageBuilder path="pagebuilder">
                    <Media
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="media"
                    />
                    <Pages
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="pages"
                    />
                    <Pages
                      client={client}
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="pages/:pageId/meta"
                    />
                    <Pages
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="pages/:pageId/content"
                    />
                    <PageTags
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="tags/:tagId"
                    />
                    <PageTags
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="tags"
                    />
                    <Modules
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="modules"
                    />
                    <Menus
                      languages={shop ? shop.languages : []}
                      defaultLanguage={shop ? shop.defaultLanguage : null}
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="menus"
                    />
                    <Theme
                      languages={shop ? shop.languages : []}
                      defaultLanguage={shop ? shop.defaultLanguage : null}
                      shop={shop}
                      shopId={shopId}
                      isAdmin={isAdmin}
                      exact
                      path="theme"
                    />
                  </PageBuilder>
                  {/* <Settings shopId={shopId}
                  isAdmin={isAdmin} exact path="settings" />
            shop={shop}
            <ShippingSettings shopId={shopId}
            isAdmin={isAdmin} exact path="settings/shipping" />
            shop={shop}
            <NotificationSettings shopId={shopId}
            isAdmin={isAdmin} exact path="settings/notification" /> */}
                  {/* <Analytics shopId={shopId}
                  isAdmin={isAdmin} exact path="analytics" />
            shop={shop}
            <SalesChannels shopId={shopId}
            isAdmin={isAdmin} exact path="channels" /> */}
                  <Techpack
                    shop={shop}
                    shopId={shopId}
                    isAdmin={isAdmin}
                    exact
                    path="techpack"
                  />
                  <PageNotFound path="*" />
                </Router>
              </>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default Routes
