import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { Slider } from '@material-ui/core';
import Select from '../../common/Select';
import Input from '../../common/Input';

import * as template_control from '../template_control';

const styles = theme => ({
  fullWidth: {
    width: '100%',
    justifyContent: 'left'
  }
});

class Exports extends Component {
  render() {
    const { classes, DesignRoot } = this.props;

    return (
      <Grid
        container
        style={{
          padding: '8px 24px 24px',
          overflowY: 'auto',
          height: 'calc(100vh - 90px)',
          width: '100%'
        }}
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item className={classes.fullWidth}>
          <hr />
        </Grid>

        <Grid item className={classes.fullWidth}>
          <Button
            onClick={template_control.export2DSceneSVG.bind(this, 'SVG')}
            className={classes.fullWidth}
          >
            Export Pattern SVG
          </Button>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Button
            onClick={template_control.export2DSceneTIFF.bind(this, 'PNG')}
            className={classes.fullWidth}
          >
            Export Pattern PNG
          </Button>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Button
            onClick={template_control.export2DSceneTIFF.bind(this, 'JPG')}
            className={classes.fullWidth}
          >
            Export Pattern JPG
          </Button>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Button
            onClick={template_control.export2DSceneTIFF.bind(this, 'TIFF')}
            className={classes.fullWidth}
          >
            Export Pattern TIFF
          </Button>
        </Grid>

        <Grid item className={classes.fullWidth}>
          <Button
            onClick={template_control.triggerExport3DScene.bind(this)}
            className={classes.fullWidth}
          >
            Export 3D as an image
          </Button>
        </Grid>
        {/* <Grid item className={classes.fullWidth}>
          <Button
            onClick={template_control.toggleRedLine.bind(this)}
            className={classes.fullWidth}
          >
            Show Seam allowance
          </Button>
        </Grid> */}
        <Grid item className={classes.fullWidth}>
          <Button
            onClick={template_control.toggleLogoOverlay.bind(this)}
            className={classes.fullWidth}
          >
            Toggle logo layer
          </Button>
        </Grid>

        {/* <Grid item className={classes.fullWidth}>
          <Button className={classes.fullWidth}>Export Techpack</Button>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Button className={classes.fullWidth}>Share preview</Button>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Button className={classes.fullWidth}>Export different Sizes</Button>
        </Grid> */}

        {/* <Grid item className={classes.fullWidth}>
          <Button
            className={classes.fullWidth}
            onClick={template_control.save.bind(this)}
          >
            Save
          </Button>
        </Grid>

        <Grid item className={classes.fullWidth}>
          <hr />
        </Grid>

        <Grid item className={classes.fullWidth}>
          <Button
            className={classes.fullWidth}
            onClick={template_control.view3D.bind(this, 'RESET')}
          >
            Reset view
          </Button>
        </Grid> */}

        <Grid item className={classes.fullWidth}>
          <hr />

        </Grid>
      </Grid>
    );
  }
}

Exports.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Exports);

// <button
//   className="exportBTN"
//   onClick={template_control.logDebug.bind(this, 'productDataSet')}
// >
//   Current Product Config
// </button>
// <button
//   className="exportBTN"
//   onClick={template_control.export3DScene.bind(this)}
// >
//   Export 3D Scene
// </button>
// <button
//   className="exportBTN"
//   onClick={template_control.export2DScene.bind(this)}
// >
//   Export Pattern PNG
// </button>
// <button
//   className="exportBTN"
//   onClick={template_control.export2DSceneTIFF.bind(this)}
// >
//   Export Pattern TIFF
// </button>
// <button
//   className="exportBTN"
//   onClick={template_control.save.bind(this)}
// >
//   Save
// </button>

// <button
//   className="exportBTN"
//   onClick={template_control.toggleLogoOverlay.bind(this)}
// >
//   Toggle Default Logo
// </button>

// <button
//   className="exportBTN"
//   onClick={template_control.toggleRedLine.bind(this)}
// >
//   Toggle Seam allowance
// </button>
// <button
//   className="exportBTN"
//   onClick={template_control.renderTexture.bind(this)}
// >
//   Render Texture Manually
// </button>
// <button
//   className="exportBTN"
//   onClick={template_control.toggle2DScene.bind(this)}
// >
//   Toggle 2D Scene
// </button>
// <br />
// <br />
// <Selection
//   DesignRoot={DesignRoot}
//   productDataSet={DesignRoot.productDataSet}
// />
