import React, {useState} from 'react'
import {InputLabel, MenuItem, Select, createStyles, withStyles, WithStyles, Grid, Typography} from '@material-ui/core'
import {getTextFromLanguage} from '../../../../../helper/languageHelper'


const styles = () => createStyles({
})


interface Props extends WithStyles<typeof styles> {
  data: any,
  collections: any[],
  onChange(productId: string|null): void
}


const AddMenuLinkCollection: React.FunctionComponent<Props> = (props) => {
  const { data, collections, onChange } = props

  const [selectedCollection, setSelectedCollection] = useState(data.collectionId)


  const handleChange = (e: any): void => {
    setSelectedCollection(e.target.value)
    onChange(e.target.value)
  }


  return (
    <>
      <InputLabel>Choose a Collection to link</InputLabel>
      <Select
        value={selectedCollection}
        onChange={handleChange}
        inputProps={{
          id: 'pageId-simple',
          name: 'pageId'
        }}
        style={{border: '1px solid #eee', margin: '8px 0px 16px', borderRadius: 4}}
      >
        <MenuItem
          value={null}
        >
          -
        </MenuItem>
        {collections && collections.edges ? collections.edges.map((collection: any) => (
          <MenuItem
            key={collection.node.id}
            value={collection.node.id}
          >
            {getTextFromLanguage(collection.node.title.text, 'en_UK')}
          </MenuItem>
        )): null}
      </Select>
      <Grid container={true} alignItems="center" style={{margin: '8px 0px 16px'}}>
        <Grid item={true} xs={5} style={{background: '#eee', height: 2}}>
          &nbsp;
        </Grid>
        <Grid item={true} xs={2} >
          <Typography variant="subtitle1" style={{textAlign: 'center'}}>
            Or
          </Typography>
        </Grid>
        <Grid item={true} xs={5} style={{background: '#eee', height: 2}}>
          &nbsp;
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(AddMenuLinkCollection)
