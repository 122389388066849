import React from 'react'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid, AppBar, Tabs, Tab, Tooltip, Button } from '@material-ui/core'
import { Menu, Close } from '@material-ui/icons'
import Package from '../../assets/package.svg'
import Layout from '../../assets/layout.svg'
import TrendingUp from '../../assets/trending-up.svg'
import Setting from '../../assets/settings.svg'
import Order from '../../assets/inbox.svg'
import User from '../../assets/user.svg'
import Home from '@material-ui/icons/Home'
import Exit from '@material-ui/icons/ExitToApp'
import Logo from '../../assets/BrikLCircle.png'
import teamStore from '../../assets/Store.svg'
import pageBuilder from '../../assets/page-builder.svg'
import designRoom from '../../assets/designRoom.svg'
import ReportingIcon from '../../assets/reporting.svg'
import Template from '../content/Template'
import { navigate } from 'gatsby'
import Auth from '@aws-amplify/auth'

import UserProvider, { UserContext } from '../../../user/context/UserContext'
var store = require('store')

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        style: {
          textDecoration: 'none',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
          // textShadow: isCurrent ? "1px #eee" : "none"
        }
      }
    }}
  />
)

const styles = theme => ({
  adminMenuDesktop: {
    width: '60px',
    minHeight: '100vh',
    borderRight: '1px solid #bbbbbb',
    background: '#B23566',
    paddingTop: 20,
    paddingBottom: 20,
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  adminMenuMobile: {
    position: 'fixed',
    zIndex: 10,
    width: '60px',
    height: '100vh',
    background: '#B23566',
    paddingTop: 20,
    paddingBottom: 20,
    float: 'left',
    overflowY: 'auto'
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginRight: 28
    }
  },
  menuItem: {
    width: '40px',
    height: '40px',
    minWidth: '40px',
    minHeight: '40px',
    maxWidth: '40px',
    maxHeight: '40px',
    borderRadius: '100%',
    background: 'none',
    padding: '8px',
    margin: '0px auto 16px',
    '&:hover': {
      background: '#6A0F34',
      cursor: 'pointer'
    },
    '&:focus': {
      background: '#6A0F34'
    }
  },
  icon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  logoContainer: {
    width: '40px',
    height: '40px',
    background: '#ffffff',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
  },
  logo: {
    width: '40px',
    height: '40px',
    objectFit: 'cover',
    margin: '0px auto 16px'
  },
  tabSelected: {
    background: '#6A0F34'
  },
  menuItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
  },
  lightTooltip: {
    backgroundColor: '#000',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 11
  },
  border: {
    width: 32,
    height: '100vh',
    background: '#B23566',
    position: 'fixed',
    borderRight: '2px solid #BBB',
    top: 0,
    left: 0
  }
})

class AdminSideMenu extends React.Component {
  state = {
    value: 0,
    show: false
  }

  handleClick = () => {
    this.setState(state => ({ show: !state.show }))
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  render() {
    const { classes, isAdmin, shopId, shopUrl } = this.props
    const { value, show } = this.state
    return (
      <div>
        <Grid
          container
          direction="column"
          className={classes.adminMenuDesktop}
          justify="flex-start"
        >
          {isAdmin ? (
            <div
              style={{
                width: '60px'
              }}
            >
              <NavLink
                style={{ textDecoration: 'none' }}
                to={'/admin/' + shopId + '/inventory/products'}
                data-cy="Inventory"
              >
                <Tooltip
                  title="Catalog"
                  placement="right"
                  classes={{ tooltip: classes.lightTooltip }}
                >
                  <Button
                    classes={{
                      root: classes.menuItem,
                      contained: classes.tabSelected
                    }}
                  >
                    <img
                      src={Package}
                      alt="Inventory Icon"
                      className={classes.icon}
                      data-cy="Inventory-Icon"
                    />
                  </Button>
                </Tooltip>
              </NavLink>
              <NavLink
                data-cy="Page-Builder"
                style={{ textDecoration: 'none' }}
                to={'/admin/' + shopId + '/pagebuilder/pages'}
              >
                <Tooltip
                  title="Page Builder"
                  placement="right"
                  classes={{ tooltip: classes.lightTooltip }}
                >
                  <Button
                    classes={{
                      root: classes.menuItem,
                      contained: classes.tabSelected
                    }}
                  >
                    <img
                      src={pageBuilder}
                      alt="template Icon"
                      className={classes.icon}
                      data-cy="Page-Builder-Icon"
                    />
                  </Button>
                </Tooltip>
              </NavLink>
              <NavLink
                data-cy="Orders"
                style={{ textDecoration: 'none' }}
                to={'/admin/' + shopId + '/orders'}
              >
                <Tooltip
                  title="Orders"
                  placement="right"
                  classes={{ tooltip: classes.lightTooltip }}
                >
                  <Button
                    classes={{
                      root: classes.menuItem,
                      contained: classes.tabSelected
                    }}
                  >
                    <img
                      src={Order}
                      alt="orders Icon"
                      className={classes.icon}
                      data-cy="Orders-Icon"
                    />
                  </Button>
                </Tooltip>
              </NavLink>
              <NavLink
                data-cy="Customers"
                style={{ textDecoration: 'none' }}
                to={'/admin/' + shopId + '/customers'}
              >
                <Tooltip
                  title="Customers"
                  placement="right"
                  classes={{ tooltip: classes.lightTooltip }}
                >
                  <Button
                    classes={{
                      root: classes.menuItem,
                      contained: classes.tabSelected
                    }}
                  >
                    <img
                      src={User}
                      alt="customer Icon"
                      className={classes.icon}
                      data-cy="Customers-Icon"
                    />
                  </Button>
                </Tooltip>
              </NavLink>

              <NavLink
                data-cy="Design-room"
                style={{ textDecoration: 'none' }}
                to={'/admin/' + shopId + '/designroom/products'}
              >
                <Tooltip
                  title="Design room"
                  placement="right"
                  classes={{ tooltip: classes.lightTooltip }}
                >
                  <Button
                    classes={{
                      root: classes.menuItem,
                      contained: classes.tabSelected
                    }}
                  >
                    <img
                      src={designRoom}
                      alt="Design room Icon"
                      className={classes.icon}
                      data-cy="Design-room-Icon"
                    />
                  </Button>
                </Tooltip>
              </NavLink>

              <NavLink
                style={{ textDecoration: 'none' }}
                to={'/admin/' + shopId + '/saleschannels/teamstores'}
                data-cy="Team-stores"
              >
                <Tooltip
                  title="Saleschannels"
                  placement="right"
                  classes={{ tooltip: classes.lightTooltip }}
                >
                  <Button
                    classes={{
                      root: classes.menuItem,
                      contained: classes.tabSelected
                    }}
                  >
                    <img
                      src={teamStore}
                      alt="Saleschannel Icon"
                      className={classes.icon}
                      data-cy="Saleschannels-Icon"
                    />
                  </Button>
                </Tooltip>
              </NavLink>

              <NavLink
                style={{ textDecoration: 'none' }}
                to={'/admin/' + shopId + '/settings/general'}
                data-cy="Settings"
              >
                <Tooltip
                  title="Settings"
                  placement="right"
                  classes={{ tooltip: classes.lightTooltip }}
                >
                  <Button
                    classes={{
                      root: classes.menuItem,
                      contained: classes.tabSelected
                    }}
                  >
                    <img
                      src={Setting}
                      alt="Setting Icon"
                      className={classes.icon}
                      data-cy="Settings-Icon"
                    />
                  </Button>
                </Tooltip>
              </NavLink>
              <NavLink
                style={{ textDecoration: 'none' }}
                to={'/admin/' + shopId + '/reporting/general'}
                data-cy="Reporting"
              >
                <Tooltip
                  title="Reporting"
                  placement="right"
                  classes={{ tooltip: classes.lightTooltip }}
                >
                  <Button
                    classes={{
                      root: classes.menuItem,
                      contained: classes.tabSelected
                    }}
                  >
                    <img
                      src={ReportingIcon}
                      alt="Reporting Icon"
                      className={classes.icon}
                      data-cy="Reporting-Icon"
                    />
                  </Button>
                </Tooltip>
              </NavLink>
            </div>
          ) : null}

          <div style={{ flex: 1 }} />
          {/* <Tooltip
            title="Go to Webshop"
            placement="right"
            classes={{ tooltip: classes.lightTooltip }}
          >
            <Button
              data-cy="Go-to-Webshop-btn"
              classes={{
                root: classes.menuItem,
                contained: classes.tabSelected
              }}
              target="_blank"
              style={{ textDecoration: 'none' }}
              href={shopUrl}
            >
              <Home
                data-cy="Home-Icon"
                style={{ color: '#fff', borderRadius: '1px' }}
              />
            </Button>
          </Tooltip> */}
          <UserContext.Consumer>
            {currentUser => {
              return (
                <Tooltip
                  title="Log out"
                  placement="right"
                  classes={{ tooltip: classes.lightTooltip }}
                >
                  <Button
                    classes={{
                      root: classes.menuItem
                    }}
                    data-cy="Log-out-btn"
                    onClick={async () => {
                      await currentUser.signOut()
                      await Auth.signOut()
                      try {
                        if (window !== `undefined`) {
                          store.clearAll()
                          window.location.reload()
                        }
                      } catch (error) {}
                      navigate('/')
                    }}
                  >
                    <Exit
                      data-cy="Exit-Icon"
                      style={{ transform: 'scaleX(-1)', color: '#fff' }}
                    />
                  </Button>
                </Tooltip>
              )
            }}
          </UserContext.Consumer>
          <img
            src={Logo}
            alt="BrikL Logo"
            className={classes.logo}
            data-cy="BrikL-Logo"
          />
        </Grid>

        <div className={classes.mobile}>
          <div className={classes.border} />
          <Button
            data-cy="Toggle-admin-menu-mobile"
            variant="contained"
            color="secondary"
            style={{
              position: 'fixed',
              top: 16,
              left: 10,
              margin: 'auto',
              minWidth: 40,
              width: 40,
              minHeight: 40,
              borderRadius: '100%',
              zIndex: 4
            }}
            onClick={this.handleClick}
          >
            <Menu style={{ color: '#FFF' }} />
          </Button>
          {show ? (
            <Grid
              container
              direction="column"
              className={classes.adminMenuMobile}
              justify="flex-start"
            >
              <Grid container justify="center">
                {isAdmin ? (
                  <div style={{ height: '100vh', width: '100%' }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <Button
                        classes={{
                          root: classes.menuItem,
                          contained: classes.tabSelected
                        }}
                        onClick={this.handleClick}
                      >
                        <Close
                          data-cy="Close-admin-menu-mobile"
                          style={{ color: '#fff' }}
                        />
                      </Button>
                    </div>
                    <NavLink
                      style={{ textDecoration: 'none' }}
                      onClick={this.handleClick}
                      to={'/admin/' + shopId + '/inventory/products'}
                      data-cy="Inventory-responsive"
                    >
                      <Tooltip
                        title="Inventory & collection"
                        placement="right"
                        classes={{ tooltip: classes.lightTooltip }}
                      >
                        <Button
                          classes={{
                            root: classes.menuItem,
                            contained: classes.tabSelected
                          }}
                        >
                          <img
                            title="Inventory"
                            src={Package}
                            alt="Inventory Icon"
                            className={classes.icon}
                            data-cy="Inventory-Icon-responsive"
                          />
                        </Button>
                      </Tooltip>
                    </NavLink>
                    <NavLink
                      data-cy="Page-Builder-responsive"
                      style={{ textDecoration: 'none' }}
                      onClick={this.handleClick}
                      to={'/admin/' + shopId + '/pagebuilder/pages'}
                    >
                      <Tooltip
                        title="Page Builder"
                        placement="right"
                        classes={{ tooltip: classes.lightTooltip }}
                      >
                        <Button
                          classes={{
                            root: classes.menuItem,
                            contained: classes.tabSelected
                          }}
                        >
                          <img
                            title="Page Builder"
                            src={pageBuilder}
                            alt="template Icon"
                            className={classes.icon}
                            data-cy="Page-Builder-Icon-responsive"
                          />
                        </Button>
                      </Tooltip>
                    </NavLink>
                    <NavLink
                      data-cy="Orders-responsive"
                      style={{ textDecoration: 'none' }}
                      onClick={this.handleClick}
                      to={'/admin/' + shopId + '/orders'}
                    >
                      <Tooltip
                        title="Orders"
                        placement="right"
                        classes={{ tooltip: classes.lightTooltip }}
                      >
                        <Button
                          classes={{
                            root: classes.menuItem,
                            contained: classes.tabSelected
                          }}
                        >
                          <img
                            title="Orders"
                            src={Order}
                            alt="orders Icon"
                            className={classes.icon}
                            data-cy="Orders-Icon-responsive"
                          />
                        </Button>
                      </Tooltip>
                    </NavLink>
                    <NavLink
                      data-cy="Customers-responsive"
                      style={{ textDecoration: 'none' }}
                      onClick={this.handleClick}
                      to={'/admin/' + shopId + '/customers'}
                    >
                      <Tooltip
                        title="Customers"
                        placement="right"
                        classes={{ tooltip: classes.lightTooltip }}
                      >
                        <Button
                          classes={{
                            root: classes.menuItem,
                            contained: classes.tabSelected
                          }}
                        >
                          <img
                            title="Customers"
                            src={User}
                            alt="customer Icon"
                            className={classes.icon}
                            data-cy="Customers-Icon-responsive"
                          />
                        </Button>
                      </Tooltip>
                    </NavLink>

                    <NavLink
                      data-cy="Design-room-responsive"
                      style={{ textDecoration: 'none' }}
                      onClick={this.handleClick}
                      to={'/admin/' + shopId + '/designroom/products'}
                    >
                      <Tooltip
                        title="Design room"
                        placement="right"
                        classes={{ tooltip: classes.lightTooltip }}
                      >
                        <Button
                          classes={{
                            root: classes.menuItem,
                            contained: classes.tabSelected
                          }}
                        >
                          <img
                            data-cy="Design-room-Icon-responsive"
                            title="Design room"
                            src={designRoom}
                            alt="Design room Icon"
                            className={classes.icon}
                          />
                        </Button>
                      </Tooltip>
                    </NavLink>

                    <NavLink
                      data-cy="Team-stores-responsive"
                      style={{ textDecoration: 'none' }}
                      onClick={this.handleClick}
                      to={'/admin/' + shopId + '/saleschannels/teamstores'}
                    >
                      <Tooltip
                        title="Team stores"
                        placement="right"
                        classes={{ tooltip: classes.lightTooltip }}
                      >
                        <Button
                          classes={{
                            root: classes.menuItem,
                            contained: classes.tabSelected
                          }}
                        >
                          <img
                            title="Saleschannels"
                            src={teamStore}
                            alt="Saleschannel Icon"
                            className={classes.icon}
                            data-cy="Saleschannels-Icon-responsive"
                          />
                        </Button>
                      </Tooltip>
                    </NavLink>

                    <NavLink
                      data-cy="Settings-responsive"
                      style={{ textDecoration: 'none' }}
                      onClick={this.handleClick}
                      to={'/admin/' + shopId + '/settings/general'}
                    >
                      <Tooltip
                        title="Settings"
                        placement="right"
                        classes={{ tooltip: classes.lightTooltip }}
                      >
                        <Button
                          classes={{
                            root: classes.menuItem,
                            contained: classes.tabSelected
                          }}
                        >
                          <img
                            title="Settings"
                            src={Setting}
                            alt="Setting Icon"
                            className={classes.icon}
                            data-cy="Settings-Icon-responsive"
                          />
                        </Button>
                      </Tooltip>
                    </NavLink>
                    <NavLink
                      data-cy="Reporting-responsive"
                      style={{ textDecoration: 'none' }}
                      onClick={this.handleClick}
                      to={'/admin/' + shopId + '/reporting/general'}
                    >
                      <Tooltip
                        title="Reporting"
                        placement="right"
                        classes={{ tooltip: classes.lightTooltip }}
                      >
                        <Button
                          classes={{
                            root: classes.menuItem,
                            contained: classes.tabSelected
                          }}
                        >
                          <img
                            title="Reporting"
                            src={ReportingIcon}
                            alt="Reporting Icon"
                            className={classes.icon}
                            data-cy="Reporting-Icon-responsive"
                          />
                        </Button>
                      </Tooltip>
                    </NavLink>
                    <div style={{ flex: 1 }} />
                  </div>
                ) : null}

                {/* <Tooltip
                  title="Go to Webshop"
                  placement="right"
                  classes={{ tooltip: classes.lightTooltip }}
                >
                  <Button
                    classes={{
                      root: classes.menuItem,
                      contained: classes.tabSelected
                    }}
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                    onClick={this.handleClick}
                    href={shopUrl}
                  >
                    <Home style={{ color: '#fff', borderRadius: '1px' }} />
                  </Button>
                </Tooltip> */}
                <UserContext.Consumer>
                  {currentUser => {
                    return (
                      <Tooltip
                        title="Log out"
                        placement="right"
                        classes={{ tooltip: classes.lightTooltip }}
                      >
                        <Button
                          classes={{
                            root: classes.menuItem
                          }}
                          onClick={async () => {
                            await currentUser.signOut()
                            await Auth.signOut()
                            try {
                              if (window !== `undefined`) {
                                store.clearAll()
                                window.location.reload()
                              }
                            } catch (error) {}
                            navigate('/')
                          }}
                        >
                          <Exit
                            style={{ transform: 'scaleX(-1)', color: '#fff' }}
                            data-cy="Exit-Icon"
                          />
                        </Button>
                      </Tooltip>
                    )
                  }}
                </UserContext.Consumer>
                <img
                  src={Logo}
                  alt="BrikL Logo"
                  className={classes.logo}
                  data-cy="BrikL-Logo"
                />
              </Grid>
            </Grid>
          ) : null}
        </div>
      </div>
    )
  }
}

AdminSideMenu.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AdminSideMenu)
