import React from 'react'
import { showCurrencySymbol } from '../../../helper/currency'
import { getCountry, getDefaultCountry } from '../../../helper/storage'
import { UserContext } from '../../user/context/UserContext'

const EUCountries = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NO',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK'
]

const round = (value, decimals) => {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

const Price = ({
  taxSettings,
  price,
  discounts,
  currencyRates,
  font,
  letterSpacing,
  setVat,
  productId
}) => {
  if (!price) return null
  if (!price.value) return null
  const currency = showCurrencySymbol(price.currency)
  let priceValue = price.value
  return (
    <UserContext.Consumer>
      {currentUser => {
        let countryCode = null
        try {
          if (window) {
            countryCode = getDefaultCountry() || getCountry()
          } else {
            countryCode = 'WORLD'
          }
        } catch (error) {}
        if (!countryCode) {
          countryCode = 'WORLD'
        }
        if (process.env.GATSBY_BRIKL_SHOP_ID === 'vermarc') {
          countryCode = 'BE'
        }
        if (!countryCode) return null
        let priceString = ''

        priceValue = price.value

        let discounPriceValue = null
        let discountPriceString = ''
        try {
          if (discounts) {
            discounts.forEach(discount => {
              if (
                discount.discount.availableFrom &&
                discount.discount.availableFrom > new Date().getTime()
              ) {
                // not active yet
              } else {
                if (
                  discount.discount.availableUntil &&
                  discount.discount.availableUntil < new Date().getTime()
                ) {
                  // expired discount
                } else {
                  if (discount.discount.hasCoupon === true) {
                    //coupon with discount only
                  } else {
                    if (
                      discount.discount.enabled === true &&
                      discount.discount.discountType ===
                        'PERCENT_TOTAL_DISCOUNT'
                    ) {
                      // TODO check combined with
                      discounPriceValue =
                        priceValue -
                        priceValue * (discount.discount.discountValue / 100)
                    }
                    if (
                      discount.discount.enabled === true &&
                      discount.discount.discountType === 'FIXED_TOTAL_DISCOUNT'
                    ) {
                    }
                  }
                }
              }
            })
          }
        } catch (error) {
          console.error('Error applying discounts', error)
        }

        let taxRate = 0
        try {
          if (taxSettings && taxSettings.defaultTaxRate) {
            taxRate = taxSettings.defaultTaxRate
          }
        } catch (error) {
          console.error('price.taxapply1', error)
        }
        let taxRateToApply = null
        try {
          if (taxSettings && taxSettings.taxRates && countryCode) {
            if (EUCountries.includes(countryCode)) {
              // search if user is in tax rate within EU but different to EU
              taxRateToApply = taxSettings.taxRates.find(
                taxRate => taxRate.countryCode === countryCode
              )
              if (taxRateToApply) {
              } else {
                // if tax rate not applied to user country look for EU tax rate
                taxRateToApply = taxSettings.taxRates.find(
                  taxRate => taxRate.countryCode === 'EUROPEAN_UNION'
                )
              }
            } else {
              taxRateToApply = taxSettings.taxRates.find(
                taxRate => taxRate.countryCode === countryCode
              )
            }

            if (taxRateToApply) {
              taxRate = taxRateToApply.percent
            }
          }
        } catch (error) {
          console.error('price.taxapply2', error)
        }

        if (setVat) {
          taxRate = setVat
        }

        try {
          priceValue = priceValue * (1 + taxRate / 100)
        } catch (error) {}

        try {
          if (discounts) {
            discounts.forEach(discount => {
              if (
                discount.discount.availableFrom &&
                discount.discount.availableFrom > new Date().getTime()
              ) {
                // not active yet
              } else {
                if (
                  discount.discount.availableUntil &&
                  discount.discount.availableUntil < new Date().getTime()
                ) {
                  // expired discount
                } else {
                  if (discount.discount.hasCoupon === true) {
                    //coupon with discount only
                  } else {
                    let hasDiscountRuleInvalid = false
                    try {
                      discount.discount.discountRules.forEach(dr => {
                        if (
                          (dr.discountRuleObjectType &&
                            dr.discountRuleObjectType === 'CART_VALUE') ||
                          dr.discountRuleObjectType === 'CART_ITEM_VALUE' ||
                          dr.discountRuleObjectType === 'CART_ITEM_QUANTITY' ||
                          dr.discountRuleObjectType === 'CUSTOMER'
                        ) {
                          hasDiscountRuleInvalid = true
                        }
                      })
                    } catch (error) {}

                    if (
                      hasDiscountRuleInvalid === false &&
                      discount.discount.enabled === true &&
                      discount.discount.discountType ===
                        'PERCENT_TOTAL_DISCOUNT'
                    ) {
                      if (discounPriceValue) {
                        discounPriceValue =
                          discounPriceValue * (1 + taxRate / 100)
                      }
                    }
                    if (
                      hasDiscountRuleInvalid === false &&
                      discount.discount.enabled === true &&
                      discount.discount.discountType === 'FIXED_TOTAL_DISCOUNT'
                    ) {
                      discounPriceValue =
                        priceValue - discount.discount.discountValue
                    }
                  }
                }
              }
            })
          }
        } catch (error) {
          console.error('Error applying discounts', error)
        }

        let roundToDecimal = 2
        if (
          (process.env.GATSBY_BRIKL_SALESCHANNEL_ID ===
            '60679619-9db3-44e8-819d-f4979ab99e7e' ||
            process.env.GATSBY_BRIKL_SALESCHANNEL_ID ===
              '5ae7de80-3dc8-4d90-a148-9b39bc2dd03d') &&
          process.env.GATSBY_BRIKL_SHOP_ID === 'vermarc'
        ) {
          roundToDecimal = 0
        }

        if (discounPriceValue) {
          discounPriceValue = Number(
            Math.round(discounPriceValue + 'e' + roundToDecimal) +
              'e-' +
              roundToDecimal
          ).toFixed(2)
          discountPriceString =
            discounPriceValue.toLocaleString() + ' ' + currency
        }

        try {
          priceValue = Number(
            Math.round(priceValue + 'e' + roundToDecimal) +
              'e-' +
              roundToDecimal
          ).toFixed(2)
        } catch (error) {}
        try {
          priceString =
            currency === '£'
              ? '£ ' + priceValue.toLocaleString()
              : currency === '$'
              ? 'USD ' + priceValue.toLocaleString()
              : priceValue.toLocaleString() + ' ' + currency
        } catch (error) {
          console.error('price.taxapply3', error)
        }
        return (
          <>
            {discounPriceValue ? (
              <span>
                <span
                  style={{
                    padding: '4px',
                    borderRadius: '4px',
                    background: '#2b2b2b',
                    color: 'white',
                    marginRight: '1em'
                  }}
                >
                  {discountPriceString}
                </span>
                <span
                  style={{
                    fontSize: '90%',
                    opacity: 0.8,
                    textDecoration: 'line-through'
                  }}
                >
                  {priceString}
                </span>
              </span>
            ) : (
              <span
                style={{
                  fontSize: font,
                  letterSpacing: letterSpacing
                }}
              >
                {priceString}
              </span>
            )}
          </>
        )
      }}
    </UserContext.Consumer>
  )
}

export default Price
