import React, { Component } from 'react'
import { Grid, Paper, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import TechpackReview from '../../../checkout/TechpackReview'
import moment from 'moment'
import _get from 'lodash/get'
import { Typography } from '@material-ui/core'
import { Mutation } from 'react-apollo'
import Select from '../../../common/Select'
import { ADMIN_GET_ORDER } from '../../gql/admin-queries'
import _sortBy from 'lodash/sortBy'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import {
  ADMIN_UPDATE_ORDER_STATUS,
  ADMIN_CREATE_SHIPPING_LABEL,
  ADMIN_DOWNLOAD_WINGS_XML,
  ADMIN_UPDATE_ORDER_PAYMENT_STATUS
} from '../../gql/admin-mutations'
import Address from './Address'
import OrderItem from './OrderItem'
const styles = theme => ({
  root: {}
})

class OrderDetail extends Component {
  state = {
    creatingShippingLabel: false,
    labelLink: null
  }
  render() {
    const {
      order,
      currency,
      currencies,
      defaultLanguage,
      handleShowCustom
    } = this.props
    if (!order) return <div>Order not found</div>
    let discounts = []
    let freeProducts = []

    try {
      order.appliedDiscounts.forEach(
        (appliedDiscount, appliedDiscountIndex) => {
          if (
            appliedDiscount.linkedDiscount &&
            appliedDiscount.linkedDiscount.discountType === 'BUY_X_GET_Y'
          ) {
            freeProducts.push(
              <Grid
                container
                key={'freepr-' + appliedDiscountIndex}
                style={{ padding: '16px 0 0' }}
              >
                <Grid item xs={1}>
                  <img
                    src={
                      appliedDiscount.linkedDiscount.freeProducts[0].media &&
                      appliedDiscount.linkedDiscount.freeProducts[0].media.find(
                        pm => pm.isThumbnail
                      )
                        ? appliedDiscount.linkedDiscount.freeProducts[0].media.find(
                            pm => pm.isThumbnail
                          ).image
                        : null
                    }
                    height={40}
                    alt={getTextFromLanguage(
                      appliedDiscount.linkedDiscount.freeProducts[0].title.text,
                      order.langCode
                    )}
                    boardProductName={null}
                    boardProductId={null}
                  />
                </Grid>
                <Grid style={{ paddingLeft: '1em' }} item xs={3}>
                  <Typography variant="h7" component="span">
                    incl. free (
                    {getTextFromLanguage(
                      appliedDiscount.linkedDiscount.freeProducts[0].title.text,
                      order.langCode
                    )}
                    )
                  </Typography>
                </Grid>
                <Grid style={{}} item xs={2}>
                  {(0).toFixed(2).toLocaleString()}{' '}
                  {appliedDiscount.discountDeduction.currency}
                </Grid>
                <Grid item xs={1} />
              </Grid>
            )
          } else {
            let appliedDiscountNote =
              appliedDiscount.coupon && appliedDiscount.coupon.couponCode
                ? `Discount (${appliedDiscount.coupon.couponCode}):`
                : 'Discount:'
            if (
              appliedDiscount.linkedDiscount &&
              appliedDiscount.linkedDiscount.discountType === 'FREE_SHIPPING'
            ) {
              appliedDiscountNote = appliedDiscountNote + ` Free Shipping`
            } else {
              if (
                appliedDiscount.linkedDiscount.discountType ===
                'FIXED_TOTAL_DISCOUNT'
              ) {
                appliedDiscountNote =
                  appliedDiscountNote +
                  ` -${appliedDiscount.linkedDiscount.discountValue}`
              } else {
                appliedDiscountNote =
                  appliedDiscountNote +
                  ` -${appliedDiscount.linkedDiscount.discountValue}%`
              }
            }

            discounts.push(
              <Grid
                key={'appliedDiscountIndex-' + appliedDiscountIndex}
                container
                style={{ padding: '16px 0 0' }}
              >
                <Grid style={{}} item xs={3}>
                  <Typography variant="h7" component="span">
                    {appliedDiscountNote}
                  </Typography>
                </Grid>
                <Grid style={{}} item xs={3}>
                  <Typography variant="h7" component="span">
                    -{' '}
                    {appliedDiscount.discountDeduction.value
                      .toFixed(2)
                      .toLocaleString()}{' '}
                    {appliedDiscount.discountDeduction.currency}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  onClick={() => {
                    window.open(
                      '/admin/' +
                        order.shopId +
                        '/settings/discounts/' +
                        appliedDiscount.linkedDiscount.id
                    )
                  }}
                >
                  View Discount
                </Grid>
              </Grid>
            )
          }
        }
      )
    } catch (error) {}

    let shippingLabels = []
    try {
      shippingLabels = order.shippingLabels.map((s, index) => {
        return (
          <Grid item xs={12} key={'shippinglabel-' + index}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={s && s.files && s.files[0] ? s.files[0].url : null}
            >
              Download label{' '}
              {s.createdAt
                ? ' from ' +
                  moment.unix(s.createdAt / 1000).format('MM/DD/YYYY HH:mm:ss')
                : ''}
            </a>
          </Grid>
        )
      })
    } catch (error) {}
    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper style={{ padding: 24 }}>
            <section>
              {order ? (
                <Grid container spacing={3}>
                  <Mutation
                    mutation={ADMIN_UPDATE_ORDER_STATUS}
                    update={(cache, { data: { updateOrderStatus } }) => {
                      // if (updateOrderStatus.id) {
                      const data = cache.readQuery({
                        query: ADMIN_GET_ORDER,
                        variables: { id: order.id }
                      })
                      data.order.status = updateOrderStatus.status
                      cache.writeQuery({
                        query: ADMIN_GET_ORDER,
                        variables: { id: order.id },
                        data
                      })
                      // }
                    }}
                  >
                    {(updateOrderStatus, { data, loading, error }) => (
                      <Grid item xs={3}>
                        <Select
                          label="Order status:"
                          value={order.status}
                          onChange={e => {
                            updateOrderStatus({
                              optimisticResponse: {
                                __typename: 'Mutation',
                                updateOrderStatus: {
                                  ...order,
                                  status: e.target.value
                                }
                              },
                              variables: {
                                id: order.id,
                                ownedByOrganisationId:
                                  order.ownedByOrganisationId,
                                status: e.target.value,
                                currency
                              }
                            })
                          }}
                        >
                          <option value={'SUBMITTED'}>Submitted</option>
                          <option value={'ONHOLD'}>On Hold</option>
                          <option value={'PRODUCTION'}>Production</option>
                          <option value={'SHIPPED'}>Shipped</option>
                        </Select>
                      </Grid>
                    )}
                  </Mutation>
                </Grid>
              ) : null}
              <Grid container style={{ marginBottom: 5, marginTop: 24 }}>
                <Typography variant="subtitle1">
                  Order ID: <span style={{ fontWeight: 300 }}>{order.id}</span>
                </Typography>
              </Grid>
              <Grid container style={{ marginBottom: 5, marginTop: 24 }}>
                <Typography variant="subtitle1">
                  Order No: <span style={{ fontWeight: 300 }}>{order.no}</span>
                </Typography>
              </Grid>
              <Grid container style={{ marginBottom: 10 }}>
                <Typography variant="subtitle1">
                  Created:{' '}
                  <span style={{ fontWeight: 300 }}>
                    {moment
                      .unix(order.createdAt / 1000)
                      .format('DD MMM YYYY HH:mm:ss')}
                  </span>
                </Typography>
              </Grid>
              <Grid container style={{ marginBottom: 10 }}>
                <Typography variant="subtitle1" component="p">
                  Total:{' '}
                  <span style={{ fontWeight: 300 }}>
                    {_get(order, 'total.currency', '') +
                      ' ' +
                      _get(order, 'total.value', 0)
                        .toFixed(2)
                        .toLocaleString()}
                  </span>
                </Typography>
              </Grid>
              <Grid container style={{ marginBottom: 10 }}>
                <Typography variant="subtitle1" component="p">
                  Included discounts:{' '}
                </Typography>
                {discounts}
                {freeProducts}
              </Grid>
              <Grid container style={{ marginBottom: 10 }}>
                <Typography variant="subtitle1" component="p">
                  Payment method:{' '}
                  <span style={{ fontWeight: 300 }}>
                    {order.paymentProvider}
                  </span>
                </Typography>
              </Grid>
              <Grid container style={{ marginBottom: 10 }}>
                <Typography variant="subtitle1" component="div">
                  Payment status:{' '}
                  <span style={{ fontWeight: 300 }}>{order.paymentStatus}</span>
                </Typography>
              </Grid>
              <Mutation
                mutation={ADMIN_UPDATE_ORDER_PAYMENT_STATUS}
                update={(cache, { data: { updateOrderPaymentStatus } }) => {
                  // if (updateOrderPaymentStatus.id) {
                  const data = cache.readQuery({
                    query: ADMIN_GET_ORDER,
                    variables: { id: order.id }
                  })
                  data.order.paymentStatus =
                    updateOrderPaymentStatus.paymentStatus
                  cache.writeQuery({
                    query: ADMIN_GET_ORDER,
                    variables: { id: order.id },
                    data
                  })
                  // }
                }}
              >
                {(updateOrderPaymentStatus, { data, loading, error }) => (
                  <div>
                    {order.paymentStatus === 'PENDING' ? (
                      <Button
                        disabled={this.state.markingAsPaid}
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          this.setState({
                            markingAsPaid: true
                          })
                          try {
                            await updateOrderPaymentStatus({
                              optimisticResponse: {
                                __typename: 'Mutation',
                                updateOrderPaymentStatus: {
                                  ...order,
                                  paymentStatus: 'PAID'
                                }
                              },
                              variables: {
                                id: order.id,
                                ownedByOrganisationId:
                                  order.ownedByOrganisationId,
                                paymentStatus: 'PAID',
                                currency
                              }
                            })
                            window.location.reload()
                          } catch (error) {
                            this.setState({
                              markingAsPaid: true
                            })
                          }
                        }}
                      >
                        Mark order as paid
                      </Button>
                    ) : null}
                  </div>
                )}
              </Mutation>
              <Grid container style={{ margin: '10px 0' }}>
                <Typography variant="subtitle1" component="div">
                  {order.paymentProvider === 'STRIPE' ||
                  order.paymentProvider === 'BANCONTACT' ||
                  order.paymentProvider === 'SOFORT' ||
                  order.paymentProvider === 'IDEAL' ? (
                    <Grid container style={{ marginBottom: 10 }}>
                      <Typography variant="subtitle1" component="div">
                        Stripe status: {order.paymentProviderStatus}
                        <br />
                        <a
                          href={`https://dashboard.stripe.com/${
                            process.env.GATSBY_API_STAGE === 'production'
                              ? ''
                              : 'test/'
                          }payments/${order.paymentIntentId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ fontWeight: 300 }}
                        >
                          Open in Stripe
                        </a>
                      </Typography>
                    </Grid>
                  ) : null}
                </Typography>
              </Grid>

              <Grid container style={{ marginBottom: 10 }}>
                <Typography variant="subtitle1" component="p">
                  Language:{' '}
                  <span style={{ fontWeight: 300 }}>{order.langCode}</span>
                </Typography>
              </Grid>
              <br />
              <Typography variant="body1">
                Shipping cost:{' '}
                {order.shippingCost
                  ? `${order.shippingCost.value.toFixed(2).toLocaleString()} ${
                      order.shippingCost.currency
                    }`
                  : ''}
              </Typography>
              <br />
              <Typography variant="body1">
                Shipping method: {order.shippingMethodId}
              </Typography>
              <br />
              <br />
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h5">Shipping address</Typography>

                  {order.shopId === 'vermarc' ? (
                    <>
                      <Mutation
                        mutation={ADMIN_CREATE_SHIPPING_LABEL}
                        update={(cache, { data: { createShippingLabel } }) => {
                          if (
                            createShippingLabel &&
                            createShippingLabel.files &&
                            createShippingLabel.files[0].url
                          ) {
                            this.setState({
                              labelLink: createShippingLabel.files[0].url
                            })
                          }
                        }}
                      >
                        {(createShippingLabel, { data, loading, error }) => (
                          <>
                            <Button
                              style={{
                                margin: '1em 0'
                              }}
                              variant="contained"
                              color="primary"
                              disabled={
                                this.state.creatingShippingLabel === true
                                  ? true
                                  : false
                              }
                              onClick={async () => {
                                if (
                                  confirm(
                                    'Upon creating a shipping label the customer will receive and email with the tracking information.'
                                  ) === true
                                ) {
                                  this.setState({
                                    creatingShippingLabel: true
                                  })
                                  try {
                                    await createShippingLabel({
                                      variables: {
                                        ownedByOrganisationId:
                                          order.ownedByOrganisationId,
                                        orderId: order.id
                                      }
                                    })
                                  } catch (error) {}

                                  this.setState({
                                    creatingShippingLabel: false
                                  })
                                }
                              }}
                            >
                              {this.state.creatingShippingLabel === true
                                ? 'Creating a label...'
                                : 'Create shipping label'}
                            </Button>
                            {this.state.labelLink ? (
                              <a
                                style={{
                                  margin: '1em 0',
                                  display: 'block'
                                }}
                                rel="noopener noreferrer"
                                target="_blank"
                                href={this.state.labelLink}
                              >
                                Download new label
                              </a>
                            ) : null}
                            <Grid item xs={12}>
                              {shippingLabels}
                            </Grid>
                          </>
                        )}
                      </Mutation>
                    </>
                  ) : null}
                  <br />
                  <Address address={order.shippingAddress} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5">Billing address</Typography>
                  <br />
                  <Address address={order.billingAddress} />
                </Grid>
              </Grid>
              {order.shopId === 'vermarc' ? (
                <Mutation
                  mutation={ADMIN_DOWNLOAD_WINGS_XML}
                  update={(cache, { data: { downloadWingsXML } }) => {}}
                >
                  {(downloadWingsXML, { data, loading, error }) => (
                    <>
                      <br />
                      {order.createdAt > 1568775315068 ? (
                        <Button
                          // color="primary"
                          variant="contained"
                          onClick={async () => {
                            const result = await downloadWingsXML({
                              variables: {
                                id: order.id
                              }
                            })
                            if (
                              result &&
                              result.data &&
                              result.data.downloadWingsXML &&
                              result.data.downloadWingsXML !== ''
                            ) {
                              window.open(result.data.downloadWingsXML)
                            } else {
                              alert('No XML to download')
                            }
                          }}
                        >
                          Download Wings XML
                        </Button>
                      ) : null}
                    </>
                  )}
                </Mutation>
              ) : null}
              {/* {order && order.orderedCart && order.orderedCart.cartItems
                ? order.orderedCart.cartItems.map((item, index) => (
                    <TechpackReview
                      key={'cart-item' + index}
                      isSupplier={true}
                      cart={order.orderedCart}
                      cartItem={item}
                    />
                  ))
                : null} */}
            </section>
          </Paper>
          <br />
          <Typography
            style={{
              fontSize: 28,
              fontWeight: 'medium',
              color: '#424242'
            }}
          >
            Order Items
          </Typography>
          <br />
          {order && order.orderItems
            ? order.orderItems.map(orderItem => {
                return (
                  <Paper
                    key={orderItem.id}
                    style={{ padding: 24, marginBottom: 10 }}
                  >
                    <section>
                      <Grid container style={{ marginBottom: 10 }}>
                        <OrderItem
                          shopId={order.shopId}
                          defaultLanguage={defaultLanguage}
                          orderItem={orderItem}
                          handleShowCustom={handleShowCustom}
                        />
                      </Grid>
                    </section>
                  </Paper>
                )
              })
            : 'No order items'}
          <br />
          <Typography
            style={{
              fontSize: 28,
              fontWeight: 'medium',
              color: '#424242'
            }}
          >
            Order History
          </Typography>
          <br />

          {order && order.history
            ? _sortBy(order.history, 'createdAt').map(orderHistoryItem => {
                return (
                  <Paper
                    key={orderHistoryItem.id}
                    style={{ padding: 24, marginBottom: 10 }}
                  >
                    <section>
                      <Grid container style={{}}>
                        <Typography variant="subtitle1" component="div">
                          <span style={{ fontWeight: 300 }}>
                            {moment
                              .unix(order.createdAt / 1000)
                              .format('DD MMM YYYY HH:mm:ss')}
                          </span>
                          {': '}
                          {orderHistoryItem.log}
                          {orderHistoryItem.errorMessage ? (
                            <>
                              <br />
                              Error: {orderHistoryItem.errorMessage}
                            </>
                          ) : (
                            ''
                          )}
                        </Typography>
                      </Grid>
                    </section>
                  </Paper>
                )
              })
            : 'No order items'}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(OrderDetail)
