import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ADMIN_SHOP_COLORS } from '../../gql/admin-queries';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { navigate } from '@reach/router';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddColor from './AddColor';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242',
    flex: 1
  }
});

class ColorList extends Component {
  render() {
    const { classes, shopId } = this.props;
    return (
      <Grid container className={classes.root} direction="column">
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          <Typography
            component="h1"
            className={classes.h1}
            data-cy="Header-Colors"
          >
            Colors
          </Typography>
          <AddColor shopId={shopId} />
        </Grid>
        <Query query={ADMIN_SHOP_COLORS} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            if (loading) return <CircularProgress />;
            if (error) return <div>Error :(</div>;
            return (
              <div>
                {shop.colors.edges.length > 0 ? (
                  <Paper>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <TableRow key={'order-loading'} hover>
                            <TableCell component="th" scope="row">
                              {'Loading...'}
                            </TableCell>
                            <TableCell>{}</TableCell>
                            <TableCell>{}</TableCell>
                          </TableRow>
                        ) : error ? (
                          'Error'
                        ) : (
                          shop.colors.edges.map(row => {
                            const color = row.node;
                            return (
                              <TableRow
                                data-cy={'color-list-row-' + color.name}
                                onClick={() =>
                                  navigate(
                                    '/admin/' +
                                      shopId +
                                      '/inventory/colors/' +
                                      color.id
                                  )
                                }
                                key={color.id}
                                hover
                              >
                                <TableCell
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  <Grid container alignItems="center">
                                    <span
                                      data-cy={'colorbox-' + color.name}
                                      style={{
                                        display: 'inline-block',
                                        width: '20px',
                                        height: '20px',
                                        margin: '0 1em',
                                        background: color.hex
                                      }}
                                    />
                                    {color.id}
                                  </Grid>
                                </TableCell>
                                <TableCell>{color.name}</TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <div>
                    <Typography variant="body1">No colors yet</Typography>
                  </div>
                )}
              </div>
            );
          }}
        </Query>
      </Grid>
    );
  }
}

export default withStyles(styles)(ColorList);
