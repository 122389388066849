import { Provider, Table as BTable, Button } from '@brikl-ui/core'
import Typography from '@material-ui/core/Typography'
import { navigate } from '@reach/router'
import _get from 'lodash/get'
import React from 'react'
import Imgix from 'react-imgix'
import { IMGIX_URL, MEDIA_CDN_URL } from '../../../../../brikl-config'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
interface RowProps {
  filteredProducts: any[]
  defaultLanguage: string
  productPackageId: string
  productPackage: any
  updateProductPackage: any
  shopId: string
}

export default class ProductTable extends React.Component<RowProps, void> {
  public render() {
    const removeProductFromPackage = async (productId: string) =>
      this.props.updateProductPackage({
        variables: {
          input: {
            productIds: this.props.productPackage.productIds.filter(
              (pId: string) => pId !== productId
            )
          }
        }
      })

    const assignProductInPackage = async (productId: string) =>
      this.props.updateProductPackage({
        variables: {
          input: {
            productIds: this.props.productPackage.productIds
              ? [...this.props.productPackage.productIds, productId]
              : [productId]
          }
        }
      })

    const products = this.props.filteredProducts || []

    const dataTable = products.map(product => {
      const title: string = getTextFromLanguage(
        _get(product.node, 'title.text', []),
        this.props.defaultLanguage
      )

      const link =
        this.props.productPackage === true
          ? `/admin/${this.props.shopId}/inventory/productpackages/${product.node.id}`
          : `/admin/${this.props.shopId}/inventory/products/${product.node.id}`

      const customText =
        product.node.isCustomProduct === true ? 'Custom' : 'Stock'

      const lang = getTextFromLanguage(
        _get(product.node, 'title.text', []),
        this.props.defaultLanguage
      )
        ? getTextFromLanguage(
            _get(product.node, 'title.text', []),
            this.props.defaultLanguage
          )
        : getTextFromLanguage(_get(product.node, 'title.text', []), 'fr_FR')
        ? getTextFromLanguage(_get(product.node, 'title.text', []), 'nl_NL')
        : null

      const productThumbnail =
        product.node.media &&
        product.node.media.find(
          (image: any) => image && image.isThumbnail === true
        )

      const imgSrc =
        productThumbnail && productThumbnail.image
          ? productThumbnail.image.replace(MEDIA_CDN_URL, IMGIX_URL)
          : null

      const isProductInPackage =
        this.props.productPackage &&
        this.props.productPackage.productIds &&
        this.props.productPackage.productIds.includes(product.node.id)

      return {
        id: product.node.id,
        title,
        link,
        isProductInPackage,
        customText,
        lang,
        src: imgSrc,
        no: product.node.no,
        personalization: product.node.hasPersonalization ? 'yes' : 'no',
        status: product.node.status
      }
    })

    const extraColumns = []
    if (this.props.productPackageId) {
      extraColumns.push({
        render: (row: any) => {
          const isProductInPackage =
            this.props.productPackage.productIds &&
            this.props.productPackage.productIds.includes(row.id)

          return (
            <>
              {isProductInPackage ? (
                <Button
                  onClick={async () => await removeProductFromPackage(row.id)}
                >
                  Remove
                </Button>
              ) : (
                <Button
                  onClick={async () => await assignProductInPackage(row.id)}
                >
                  Assign
                </Button>
              )}
            </>
          )
        }
      })
    }

    return (
      <div>
        {this.props.filteredProducts.length > 0 ? (
          <>
            <Provider>
              <BTable
                // searchable
                onRowClick={(event, row) => {
                  if (row) {
                    navigate(row.link)
                  }
                }}
                columns={[
                  {
                    title: 'Style Number',
                    field: 'no'
                  },
                  {
                    title: 'Thumbnail',
                    render: row => {
                      return (
                        row.src && (
                          <Imgix
                            height={50}
                            width={50}
                            htmlAttributes={{
                              alt: '',
                              style: {
                                width: '50px',
                                height: '50px',
                                objectFit: 'contain'
                              }
                            }}
                            src={row.src}
                            imgixParams={{
                              auto: 'compress'
                            }}
                          />
                        )
                      )
                    }
                  },

                  {
                    title: 'Title',
                    field: 'title'
                  },
                  {
                    title: 'Type',
                    field: 'customText'
                  },
                  {
                    title: 'Personalization',
                    field: 'personalization'
                  },
                  {
                    title: 'Status',
                    field: 'status'
                  }
                ]}
                data={dataTable}
              />
            </Provider>
          </>
        ) : (
          <Typography variant="body1">No product yet</Typography>
        )}
      </div>
    )
  }
}
