import React, { Component } from 'react';
import { Query } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';

import Typography from '@material-ui/core/Typography';
import { ADMIN_SHOP_USERS } from '../../gql/admin-queries';
import { navigate } from '@reach/router';
// import CustomerDetail from './CustomerDetail';
import moment from 'moment';
import _sortBy from 'lodash/sortBy';
const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    marginLeft: 20,
    fontSize: 30,
    padding: 25,
    fontWeight: 'medium',
    color: '#424242'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});

class Customers extends Component {
  state = {
    expanded: null,
    showOrders: true,
    userType: 'USER'
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  render() {
    const { classes, customerId, shopId } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid
          container
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          {!customerId && (
            <Typography
              style={{
                fontSize: 28,
                fontWeight: 'medium',
                color: '#424242'
              }}
            >
              Users
            </Typography>
          )}
        </Grid>
        {!customerId && (
          <Grid container>
            <Grid item xs={12}>
              <Grid container direction="column">
                <Paper>
                  <Query query={ADMIN_SHOP_USERS} variables={{ id: shopId }}>
                    {({ loading, error, data }) => {
                      var shopUsers = [];
                      try {
                        shopUsers = _sortBy(
                          data.shop.users.edges,
                          'createdAt'
                        ).reverse();
                      } catch (error) {}
                      return (
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell>User ID</TableCell>
                              <TableCell>Email</TableCell>
                              <TableCell>Signed up</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {loading ? (
                              <TableRow key={'customer-loading'}>
                                <TableCell component="th" scope="row">
                                  {'Loading...'}
                                </TableCell>
                                <TableCell>{}</TableCell>
                              </TableRow>
                            ) : error ? (
                              'Error'
                            ) : (
                              shopUsers.map(row => {
                                const user = row.node;
                                if (user.email && user.email !== 'N/A') {
                                  return (
                                    <TableRow key={user.id}>
                                      <TableCell
                                        onClick={
                                          () => null
                                          // navigate('/admin/customers/' + order.id)
                                        }
                                        style={{}}
                                        component="th"
                                        scope="row"
                                      >
                                        {user.id}
                                      </TableCell>
                                      <TableCell>{user.email}</TableCell>
                                      <TableCell>
                                        {moment
                                          .unix(user.createdAt / 1000)
                                          .format('MM/DD/YYYY')}
                                      </TableCell>
                                    </TableRow>
                                  );
                                } else {
                                  return null;
                                }
                              })
                            )}
                          </TableBody>
                        </Table>
                      );
                    }}
                  </Query>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* {customerId ? (
          <Grid container>
            <Grid item xs={12}>
              <Grid container direction="column">
                <Paper className={classes.root}>
                  <Query
                    query={ADMIN_GET_ORDER}
                    variables={{ id: customerId, currency: 'THB' }}
                  >
                    {({ loading, error, data: { order } }) =>
                      <CustomerDetail order={order} />
                    }
                  </Query>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        ) : null} */}
      </Grid>
    );
  }
}

export default withStyles(styles)(Customers);
